import { User } from "../../@types/user/user";
import { Workstation } from "../../@types/workstation/workstation";
import { PROD_SETTING } from "../../constants/m2m-client";
import { DEV_LOCATION, DEV_SETTING, STAGE_LOCATION, CLIENT, EXE } from "../../constants/m2m-client";

const HOST_NAME = window.location.hostname;
const TOKEN = sessionStorage.getItem("x-access-token");

// 🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥
export class WorkstationClientService {
  domain: string = PROD_SETTING.DOMAIN;
  brokerIp: string = PROD_SETTING.BROKER_IP;
  version: number = PROD_SETTING.VERSION;

  uwHostname: string = "";
  workstationName: string = "";
  baremetalip: string = "";
  encodeData: string = "";
  constructor(workstation: Workstation, user: User) {
    this.uwHostname = workstation.uwHostname || "";
    this.workstationName = workstation.name || "";
    this.baremetalip = this.getBaremetalIp(workstation);

    this.domain = PROD_SETTING.DOMAIN;
    this.brokerIp = PROD_SETTING.BROKER_IP;
    this.version = PROD_SETTING.VERSION;

    // FIXME: 배포 환경별 env 파일로 환경변수 관리가 필요
    if (
      HOST_NAME === DEV_LOCATION.IP ||
      HOST_NAME === DEV_LOCATION.LOCAL_DOMAIN ||
      HOST_NAME === DEV_LOCATION.TOBE_DOMAIN ||
      HOST_NAME === DEV_LOCATION.LOCALHOST
    ) {
      this.domain = DEV_SETTING.DOMAIN;
      this.brokerIp = DEV_SETTING.BROKER_IP;
      this.version = DEV_SETTING.VERSION;
    }
    this.encodeData = this.encodeProtocol(
      `userid=${user.userId}` +
        `&broker=${this.brokerIp}` +
        `&workstation=${this.uwHostname}` +
        `&baremetalip=${this.baremetalip}`
    );
  }

  static run = (client: WorkstationClientService) => {
    window.open(
      `m2m-run://${client.domain}/connect` +
        `?version=${client.version}` +
        `&name=${client.workstationName}` +
        `&data=${client.encodeData}` +
        `&token=${TOKEN}`
    );
  };

  private encodeProtocol = (protocol: string) => {
    var i;
    var result = "";
    for (i = 0; i < protocol.length; i++) {
      result += protocol.charCodeAt(i).toString(16);
    }
    return result;
  };

  private getBaremetalIp = (workstation: Workstation) => {
    return (workstation.baremetal && workstation.baremetal.ipv4) || "";
  };

  static getDownloadLink = (os: "window" | "linux" | "mac") => {
    if (os === "window") {
      // "m2m.grida.dev"
      if (HOST_NAME === STAGE_LOCATION.DOMAIN) {
        return `${CLIENT.STAGE}${EXE.WINDOW}`;
      }

      // "192.168.0.12"
      if (HOST_NAME === DEV_LOCATION.IP) {
        return `${CLIENT.DEV}${EXE.WINDOW}`;
      }

      return `${CLIENT.PROD}${EXE.WINDOW}`;
    }

    if (os === "linux") {
      // "m2m.grida.dev"
      if (HOST_NAME === STAGE_LOCATION.DOMAIN) {
        return `${CLIENT.STAGE}${EXE.LINUX}`;
      }
      //  "192.168.0.12"
      if (HOST_NAME === DEV_LOCATION.IP) {
        return `${CLIENT.DEV}${EXE.LINUX}`;
      }

      return `${CLIENT.PROD}${EXE.LINUX}`;
    }

    if (os === "mac") {
      // "m2m.grida.dev"
      if (HOST_NAME === STAGE_LOCATION.DOMAIN) {
        return `${CLIENT.STAGE}${EXE.MAC}`;
      }
      //  "192.168.0.12"
      if (HOST_NAME === DEV_LOCATION.IP) {
        return `${CLIENT.DEV}${EXE.MAC}`;
      }

      return `${CLIENT.PROD}${EXE.MAC}`;
    }

    return "";
  };
}
