import { Button } from "@mui/material";
import React from "react";
import { ArrowLeft, ArrowRight } from "react-feather";
import styled from "styled-components";

interface Props {
  toLeft: () => void;
  toRight: () => void;
}

const ProjectCenterButton = ({ toLeft, toRight }: Props) => {
  return (
    <Wrapper>
      <Button size="small" onClick={() => toLeft()} variant="contained">
        <ArrowLeft />
      </Button>
      <Button size="small" onClick={() => toRight()} variant="contained">
        <ArrowRight />
      </Button>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: center;
  width: 60px;
  button {
    margin: 20px 0;
    height: 30px;
    width: 30px;
  }
`;
export default ProjectCenterButton;
