import React from "react";

import styled from "styled-components";

export const DropDownSetting = () => {
  return <div></div>;
};

DropDownSetting.Wrapper = (props: { children: React.ReactNode }) => {
  return <DropdownWrapper>{props.children}</DropdownWrapper>;
};

DropDownSetting.Content = (props: { children: React.ReactNode }) => {
  return <DropdownContent>{props.children}</DropdownContent>;
};

DropDownSetting.CenterContent = (props: { children: React.ReactNode }) => {
  return <CenterDropdownContent>{props.children}</CenterDropdownContent>;
};

DropDownSetting.Title = (props: { children: React.ReactNode }) => {
  return <DropdownTitle>{props.children}</DropdownTitle>;
};

DropDownSetting.Button = (props: { children: React.ReactNode }) => {
  return <DropdownButton>{props.children}</DropdownButton>;
};

const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
  padding: 0px 10px;
`;

const DropdownContent = styled.ul`
  display: none;
  position: absolute;
  z-index: 1;
  background-color: #fff;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 0px 0px 5px 5px;
  text-align: center;
  padding: 0;

  li {
    list-style: none;
    padding: 10px 7px;
    cursor: pointer;
    &:hover {
      background-color: var(--GridaBlue);
      color: #fff;
    }
  }
  ${DropdownWrapper}:hover & {
    display: block;
  }
`;

const CenterDropdownContent = styled.ul`
  display: none;
  position: absolute;
  z-index: 1;
  background-color: #fff;
  min-width: 130px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 0px 0px 5px 5px;
  text-align: center;
  padding: 0;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% - 1px);

  li {
    display: flex;
    align-items: center;
    gap: 10px;

    list-style: none;
    padding: 10px 7px;
    cursor: pointer;
    &:hover {
      background-color: var(--GridaBlue);
      color: #fff;
    }
  }
  ${DropdownWrapper}:hover & {
    display: block;
  }
`;

const DropdownTitle = styled.button`
  padding: 15px;
  font-size: 16px;
  border: none;

  background: none;
  cursor: pointer;
  min-width: 120px;

  display: flex;
  justify-content: center;
  align-items: center;

  span {
    margin-right: 5px;
  }
`;

const DropdownButton = styled.div`
  cursor: pointer;
  img {
  }
  width: 15px;
  text-align: end;
`;

const DropdownItem = styled.li`
  padding: 10px 7px;
  cursor: pointer;
  &:hover {
    list-style: none;
    background-color: var(--GridaBlue);
    color: #fff;
  }
`;
