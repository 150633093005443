import React from "react";
import { Code } from "../../../../../../../../@types/infra/code";
import styled from "styled-components";

interface Props {
  typeList: Code[];
  setType: React.Dispatch<React.SetStateAction<string>>;
  type: string;
}

const TypeSelectBox = ({ typeList, setType, type }: Props) => {
  return (
    <Wrapper>
      <SelectWrapper onChange={(e) => setType(e.target.value)} value={type}>
        {typeList.length > 0 &&
          typeList.map((tp) => (
            <option key={tp.cd} value={tp.cd} id={tp.cd}>
              {tp.name}
            </option>
          ))}
      </SelectWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const SelectWrapper = styled.select`
  width: 80px;
  height: 25px;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  text-indent: 5px;
  font-size: 11px;

  &:focus {
    outline: none;
  }
`;

export default TypeSelectBox;
