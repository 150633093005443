import React from "react";
import styled, { FlattenSimpleInterpolation, css } from "styled-components";

interface Props {
  children: React.ReactNode;
  size?: "sm" | "md" | "lg";
}

const SIZES = {
  sm: css`
    width: 500px;
    @media (min-width: 1024px) {
      width: 600px;
    }
  `,
  md: css`
    // 기본값
    width: 600px;
    @media (min-width: 1024px) {
      width: 800px;
    }
  `,
  lg: css`
    width: 650px;
  `,
};

const LoginBackGroundWrapper = ({ children, size }: Props) => {
  const sizeStyle = SIZES[size || "md"];

  return (
    <BackGround>
      <Wrapper sizeStyle={sizeStyle}>{children}</Wrapper>
    </BackGround>
  );
};

export default LoginBackGroundWrapper;

const BackGround = styled.section`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: #fff;
  // 페이지 완전 중간 정렬
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 1024px) {
    background-color: #fff;
  }
`;
interface StyleProps {
  sizeStyle: FlattenSimpleInterpolation;
}
const Wrapper = styled.div<StyleProps>`
  margin-top: 50px;
  ${(p) => p.sizeStyle}
  height: 100%;
  &::-webkit-scrollbar {
    background-color: rgb(255, 255, 255);
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(219, 219, 219, 0.514);
    border-radius: 10px;
    height: 10px;
  }
`;
