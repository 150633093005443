import React from "react";
import styled from "styled-components";
import { useT } from "../../constants/i18n/useT";
import MainBlueButton from "./components/button/main-blue-button";
import { useNavigate } from "react-router-dom";

const Main1 = () => {
  const navigate = useNavigate();

  const t = useT();

  const goService = () => {
    if (sessionStorage.getItem("x-access-token")) navigate("/workstation");
    else navigate("/login");
  };

  return (
    <Wrapper>
      <div className="title_box">
        <h1>
          M2M
          <br />
        </h1>
        <p className="sub_title">
          {t(
            "main.creates_metaverse_to_metaverse",
            "Creates Metaverse To Metaverse"
          )}
        </p>
        <p className="content">
          {t(
            "main.we_provide_the_optimal_environment_for_vfx",
            "GPU 기반 고성능 서버 및 HP Anyware(PCoIP ultra)를 사용해 VFX, Postproduction 작업에 대해 최상의 환경을 제공합니다."
          )}
        </p>
      </div>

      <div className="img_box">
        <img src="/assets/main/top_img.png" alt="main1" />
      </div>

      <div className="main_button">
        <MainBlueButton onClick={() => goService()} big>
          {t("billing.workstation_service", "Workstation Service")}
        </MainBlueButton>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  background: linear-gradient(90deg, #fff9f9, #f2f6ff, #f0f4ff 100%);
  height: 100vh;
  width: 100%;
  padding-top: 100px;
  .title_box {
    padding: 20px;
    h1 {
      font-size: 40px;
    }
    .sub_title {
      font-size: 18px;
      color: #333;
      font-weight: 600;
      margin-top: 20px;
      margin-bottom: 40px;
      line-height: 25px;
    }
    .content {
      color: #5e7290;
      font-size: 14px;
      width: 92%;
    }
  }
  .img_box {
    width: 80%;
    max-width: 400px;
    margin: 50px auto;
    img {
      width: 90%;
      height: 90%;
    }
  }
  .main_button {
    width: 100%;
    margin-top: 100px;
    button {
      margin: auto;
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    .title_box {
      h1 {
        font-size: 50px;
      }
      .sub_title {
        font-size: 25px;
      }
      .content {
        font-size: 18px;
      }
    }
  }

  // 1024px ~
  @media (min-width: 1024px) {
    width: 100%;
    .title_box {
      position: absolute;
      top: 30%;
      left: 10%;
      z-index: 1;
      h1 {
        font-size: 65px;
      }
      .sub_title {
        font-size: 45px;
        margin-bottom: 40px;
        line-height: 25px;
      }
      .content {
        font-size: 15px;
        line-height: 30px;
        max-width: 80%;
      }
    }
    .img_box {
      width: 400px;
      position: absolute;
      left: 60%;
      top: 20%;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .main_button {
      position: absolute;
      width: 500px;
      top: 60%;
      left: 10%;
    }
  }
  @media (min-width: 1800px) {
    .img_box {
      width: 30%;
      max-width: 800px;
    }
  }
`;

export default Main1;
