import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { userState } from "../../../../../constants/recoil/user";
import { MAX_LENGTH } from "../../../../../constants/max-length";
import { useAlert } from "../../../../../components/alert/alert.service";
import Timer from "../../../../../components/timer/Timer";
import Button from "../../../../../components/button/Button";
import { useT } from "../../../../../constants/i18n/useT";
import { UpdateUser } from "../../../../../service/user/user.update.service";
import { InputService } from "../../../../../service/common/input.service";
import ModalPopup from "../../../../../components/modal/popup";
import { ModalProps } from "../../../../../components/modal";
import { REGEX } from "../../../../../constants/regex";

interface Props extends ModalProps {
  getUser: () => void;
}
const UpdateEmail = (props: Props) => {
  const [user] = useRecoilState(userState);
  const [newUser] = useState(new UpdateUser());
  const [session, setSession] = useState(1);
  const [verified, setVerified] = useState(false);
  const [failed, setFailed] = useState("");
  const [email, setEmail] = useState("");

  const [recentEmail, setRecentEmail] = useState("");

  const [emailNumber, setEmailNumber] = useState("");
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const t = useT();
  const { setAlert, setSuccess } = useAlert();

  const changeEmail = () => {
    if (!REGEX.EMAIL.test(email))
      return setAlert(
        t("user.please_format_the_email_properly", "메일 형식을 맞춰주세요")
      );
    newUser.userGuid = user.userGuid;
    newUser.mail = email;
    newUser.sendCertification().then((res) => {
      if (res.state === "success") {
        setRecentEmail(email);
        setSession(2);
        setMinutes(10);
        setSeconds(0);
        setEmailNumber("");
        return;
      }
      return setAlert(res.message);
    });
  };
  const onSubmit = () => {
    newUser
      .postChangeMail(emailNumber)
      .then((res) => {
        if (res.state === "success") {
          setSuccess(
            t("user.email_has_been_changed", "이메일이 변경되었습니다.")
          );
        }
      })
      .finally(() => {
        props.setOpen(false);
        props.getUser();
      });
  };
  const checkEmailNum = () => {
    newUser.userValidEmailNumber(emailNumber).then((res) => {
      if (res.state === "success") return setVerified(true);
      setFailed(
        res.message ||
          t(
            "common.error",
            "현재 서버가 원활하지 않습니다. 잠시 후 시도해주세요."
          )
      );
    });
  };

  useEffect(() => {
    if (emailNumber.length === 6) checkEmailNum();
  }, [emailNumber]);

  const sessionMessage = () => {
    if (session === 1) return t("common.confirm", "확인");
    return t("common.resend", "재전송");
  };

  const getType = () => (verified ? "confirm" : "disable");

  return (
    <ModalPopup
      buttonText={t("user.update_email", "이메일 변경")}
      onSubmit={onSubmit}
      type={getType()}
      {...props}
    >
      <Wrapper>
        <div className="user_pop_content">
          <div className="user_pop_con">
            {session === 1 && (
              <p className="pop_logout_text">
                {t("user.please_enter_your_email", "이메일을 입력하세요")}
              </p>
            )}
            {session === 2 && (
              <p className="pop_logout_text">
                {t(
                  "user.the_verification_code_has_been_sent_via_email",
                  "이메일로 인증번호를 보냈습니다."
                )}{" "}
                <p id="blue">{recentEmail}</p>
              </p>
            )}
            <div className="content_con">
              <input
                onKeyDown={(e) => InputService.pressEnter(e, changeEmail)}
                maxLength={MAX_LENGTH.MAIL}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                id="mail"
                placeholder={
                  t("user.please_enter_your_email", "이메일을 입력하세요") || ""
                }
              />
              <Button size="xs" color="primary" onClick={() => changeEmail()}>
                {sessionMessage()}
              </Button>
            </div>
            {session !== 1 && (
              <div className={["content_con", "veri_con"].join(" ")}>
                <p>{t("user.verification_code", "인증번호를 보냈습니다.")}</p>
                <input
                  value={emailNumber}
                  onChange={(e) =>
                    InputService.handleNumberInput(
                      e.target.value,
                      setEmailNumber,
                      6
                    )
                  }
                  id="mail"
                  placeholder="000000"
                  readOnly={verified}
                />
                {!verified && emailNumber.length === 6 && (
                  <p className="verified" style={{ color: "#ce6e6e" }}>
                    {failed}
                  </p>
                )}
                {!verified && emailNumber.length < 6 && (
                  <Timer
                    minutes={minutes}
                    setMinutes={setMinutes}
                    seconds={seconds}
                    setSeconds={setSeconds}
                  />
                )}
                {verified && (
                  <p className="verified">
                    {t(
                      "user.the_verification_code_is_correct",
                      "인증번호가 일치합니다."
                    )}
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </Wrapper>
    </ModalPopup>
  );
};
const Wrapper = styled.div`
  min-height: 300px;
  .user_pop_con {
    height: 200px;
  }
  .content_con {
    margin: auto;
    width: 90%;
    display: flex;
    justify-content: space-between;
    button {
      height: 30px;
    }
    input {
      width: 185px;
      padding-left: 10px;
      height: 28px;
    }
  }
  .pop_logout_text {
    width: 90%;
    margin: auto;
    margin-top: 0px;
    height: 50px;
    min-height: 70px;
    text-align: start;
    font-weight: 600;
  }
  .veri_con {
    margin: auto;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    p {
      /* margin-right: 20px; */
    }
    input {
      margin-left: 10px;
      width: 60px;
      padding-left: 10px;
      height: 28px;
    }
    .verified {
      color: #7193ba;
      margin-left: 10px;
      font-size: 12px;
    }
  }
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`;

export default UpdateEmail;
