import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import { User } from "../../@types/user/user";
import { AuthService } from "../../service/user/user.service";
const { persistAtom } = recoilPersist();

export const userState = atom<User>({
  key: "user",
  default: new AuthService(),
  effects_UNSTABLE: [persistAtom],
});
