import React from "react";
import styled from "styled-components";
import { Pagination } from "@mui/material";
import NewSelectBox from "./new-selectBox";

interface Props {
  totalCount: number;
  currentPage: number;
  onPageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
  itemsPerPage: number;
  handleItemsPerPageChange: (value: number) => void;
}

const NewPagenation: React.FC<Props> = ({
  totalCount,
  currentPage,
  onPageChange,
  itemsPerPage,
  handleItemsPerPageChange,
}) => {
  const pageCount = Math.ceil(totalCount / itemsPerPage);

  return (
    <StyledPaginationWrapper>
      <Pagination
        count={pageCount}
        page={currentPage}
        color="primary"
        showFirstButton
        showLastButton
        onChange={(event, page) => {
          onPageChange(event, page);
        }}
      />

      <NewSelectBox
        itemsPerPage={itemsPerPage}
        onChange={e => handleItemsPerPageChange(parseInt(e.target.value))}
      />
    </StyledPaginationWrapper>
  );
};

const StyledPaginationWrapper = styled.div`
  margin: 20px auto;
  display: flex;
  justify-content: center;
`;

export default NewPagenation;
