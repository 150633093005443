import {
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";
import React from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { Code } from "../../../../../../@types/infra/code";
import { useT } from "../../../../../../constants/i18n/useT";
import { userState } from "../../../../../../constants/recoil/user";
import { StorageUser } from "../../../../../../@types/storage/response";

interface Props {
  user: StorageUser;
  handleRadio: (user: StorageUser) => void;
  isChecked: (user: StorageUser) => boolean;
  updatePriv?: (user: StorageUser, e: SelectChangeEvent<any>) => void;
  privs?: Code[];
  readOnly?: boolean;
}
const PrivUserEach = ({
  handleRadio,
  isChecked,
  updatePriv,
  user,
  privs,
  readOnly,
}: Props) => {
  const [me, setMe] = useRecoilState(userState);

  const checkSameCompany = () => {
    if (!me.company) return false;
    if (user.companyGuid === me.company.companyGuid) return true;
    return false;
  };

  if (readOnly)
    return (
      <ReadOnly>
        <h3>{user.fullName}</h3>
        <div />
        <p>{user.privilege && user.privilege.name}</p>
      </ReadOnly>
    );
  const t = useT();
  return (
    <UserEach>
      <Checkbox
        checked={isChecked(user)}
        value={user}
        onClick={() => handleRadio(user)}
      />
      <UserName onClick={() => handleRadio(user)}>
        {user.fullName}
        {!checkSameCompany() && (
          <Tooltip
            title={
              t(
                "storage.not_affiliated_with_the_company",
                "제휴 회사 소속이 아닙니다."
              ) || ""
            }
            arrow
          >
            <div className="not_company">i</div>
          </Tooltip>
        )}
      </UserName>
      {privs && updatePriv && (
        <PrivilSelectBox>
          <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
            <Select
              onChange={(e) => updatePriv(user, e)}
              className="select"
              value={user.privilege && user.privilege.cd}
            >
              {privs.map((priv: Code, idx) => (
                <MenuItem key={idx} value={priv.cd} id={priv.cd}>
                  {priv.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </PrivilSelectBox>
      )}
    </UserEach>
  );
};
const ReadOnly = styled.article`
  display: flex;
  height: 50px;
  width: 90%;
  margin: auto;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 0.5px solid rgb(189, 189, 189);
  h3 {
    padding-left: 20px;
    font-weight: 500;
  }
  p {
    padding-right: 20px;
  }
`;
const UserEach = styled.article`
  display: flex;
  height: 50px;
  width: 90%;
  margin: auto;
  text-align: center;
  align-items: center;
  background-color: #fff;
  border-bottom: 0.5px solid rgb(189, 189, 189);
  cursor: pointer;
  .MuiSelect-select {
    font-size: 13px;
    padding: 4px 0;
  }
  .not_company {
    margin-left: 5px;
    text-align: center;
    border: 0.5px solid #b5b5b5;
    color: #b5b5b5;
    height: 15px;
    width: 15px;
    border-radius: 100%;
    padding-bottom: 1px;
  }
`;
const UserName = styled.div`
  width: 250px;
  text-align: start;
  display: flex;
`;
const PrivilSelectBox = styled.div``;
export default PrivUserEach;
