import React from "react";

import { useT } from "../../../../constants/i18n/useT";
import { ModalProps } from "../../../../components/modal";
import ModalPopup from "../../../../components/modal/popup";
import { SignUpService } from "../../../../service/user/signup.service";
import { useNavigate } from "react-router-dom";

interface Props extends ModalProps {
  message: string;
}

const SignupRestoreAlert = (props: Props) => {
  const t = useT();
  const navigate = useNavigate();
  const onSubmit = () => {
    SignUpService.sendRecoveryMail(props.message).then((res) => {
      if (res.state === "success") {
        alert(
          t(
            "signup.your_email_has_been_sent",
            "메일이 전송되었습니다. 메일을 확인해주세요"
          )
        );
        props.setOpen(false);
        navigate("/login");
      }
    });
  };

  return (
    <ModalPopup
      {...props}
      buttonText={t("common.confirm", "확인")}
      onSubmit={onSubmit}
    >
      <h1>
        {t(
          "signup.registered_account_exists",
          "가입된 계정이 존재합니다. 계정 복구를 진행하시겠습니까?"
        )}
      </h1>
      <p>
        {t(
          "signup.when_you_click_ok",
          "확인을 누르시면 아래의 메일로 복구 링크가 전송됩니다."
        )}
      </p>
      <span id="blue">{props.message}</span>
    </ModalPopup>
  );
};

export default SignupRestoreAlert;
