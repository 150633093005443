import { APICLIENT, API } from "../api";

export class AuthService {
  userGuid?: string;
  fullName?: string;
  userId?: string;
  constructor(userGuid?: string, fullName?: string, userId?: string) {
    this.userGuid = userGuid;
    this.fullName = fullName;
    this.userId = userId;
  }
  static login = async (userId: string, password: string) => {
    const response = await APICLIENT.NONAUTH_NOALERT.post(`${API.AUTH_LOGIN}/login`, {
      data: { userId, password },
    });
    return response.data;
  };
  static cookieLogin = async () => {
    const response = await APICLIENT.NONAUTH_NOALERT.get(`${API.AUTH_LOGIN}/cookie-login`);
    return response.data;
  };

  static getSession = async () => {
    const response = await APICLIENT.NONAUTH_NOALERT.get(`${API.AUTH_LOGIN}/session`);
    return response.data;
  };

  static terminateLogin = async (userId: string, password: string) => {
    const response = await APICLIENT.NONAUTH_NOALERT.post(`${API.AUTH_LOGIN}/login`, {
      data: {
        userId: userId,
        password: password,
        isTerminateOld: true,
      },
    });
    return response.data;
  };

  static logout = async () => {
    const response = await APICLIENT.AUTH_NOALERT.get(`${API.AUTH_LOGIN}/logout`);
    return response.data;
  };

  static updatePW = async (currentPassword: string, newPassword: string) => {
    const response = await APICLIENT.AUTH_NOALERT.post(`${API.AUTH_DEFUALT}/updatePassword`, {
      data: {
        currentPassword: currentPassword,
        newPassword: newPassword,
      },
    });
    return response.data;
  };
  // Guid 정보
  static getUser = async (type: "GUID" | "ID", id: string) => {
    if (type === "GUID") {
      const response = await APICLIENT.AUTH.get(`${API.USER}/get/${id}`);
      return response.data;
    }
    if (type === "ID") {
      const response = await APICLIENT.AUTH_NOALERT.get(`${API.USER}/get/user/${id}`);
      return response.data;
    }
  };

  // 유저리스트
  static getUserList = async () => {
    const response = await APICLIENT.AUTH.post(`${API.USER}/getList`, {
      paginate: { offset: 0, limit: 100 },
    });
    return response.data;
  };

  static sendEmail = async (userId: string, mail: string) => {
    const response = await APICLIENT.NONAUTH.post(`${API.AUTH_MAIL}/sendVerificationMail`, {
      data: { userId, mail },
    });
    return response.data;
  };

  static withdraw = async () => {
    const response = await APICLIENT.AUTH_NOALERT.get(`${API.USER}/withdraw`);
    return response.data;
  };
}
