import React, { useState } from "react";
import styled from "styled-components";
import { useT } from "../../../constants/i18n/useT";
import { useAlert } from "../../../components/alert/alert.service";
import { MAX_LENGTH } from "../../../constants/max-length";
import Button from "../../../components/button/Button";
import Star from "../../../components/input/essential-star";
import ModalForm from "../../../components/modal/modal-form";
import CheckBoxCircle from "../../../components/input/input-label/circle-checkbox";
import { InputService } from "../../../service/common/input.service";
import { REGEX } from "../../../constants/regex";
import { ModalProps } from "../../../components/modal";
import { NonMemberContactForm } from "../../../@types/user/contact/non-member-form";
import { IoIosMail } from "@react-icons/all-files/io/IoIosMail";

const ContactModal = (props: ModalProps) => {
  const [contactForm, setContactForm] = useState(new NonMemberContactForm());

  const { setAlert, setSuccess } = useAlert();
  const t = useT();
  const onSubmit = () => {
    if (!contactForm.mail.match(REGEX.EMAIL)) {
      return setAlert(t("main.please_check_the_email_format", "이메일 형식을 확인해주세요"));
    }
    if (contactForm.title === "") {
      return setAlert(t("main.please_enter_a_title", "제목을 입력해주세요"));
    }
    if (contactForm.content === "") {
      return setAlert(t("main.please_enter_a_content", "내용을 입력해주세요"));
    }
    if (contactForm.isIndividualInfoUseAgree === false) {
      return setAlert(
        t(
          "main.to_respond_to_the_inquiry_we_will_collect",
          "문의에 대한 답변을 드리기 위해 개인정보 수집 및 이용에 동의해주세요"
        )
      );
    }

    NonMemberContactForm.submit(contactForm).then(res => {
      if (res.state === "success") {
        props.setOpen(false);
        setSuccess(t("main.your_inquiry_has_been_submitted", "문의가 접수되었습니다. "));
      }
    });
  };

  return (
    <ModalForm {...props}>
      <button className="close_btn" onClick={() => props.setOpen(false)}>
        X
      </button>
      <Wrapper>
        <PopTopText>
          <h1>
            <IconWrapper>
              <IoIosMailIcon />
            </IconWrapper>
            {t("user.request", "문의하기")}
          </h1>
          <p>
            {t(
              "user.if_you_have_any_inquiries_about_service",
              "서비스 상담 및 기술 지원, 제휴에 대해 문의하시면 빠르게 답변 드리겠습니다."
            )}
          </p>
        </PopTopText>

        <Divider />

        <div className="pop_con_wrap">
          <form className="g_input_a">
            <div className="input_wrap">
              <label htmlFor="mail" className="input_label">
                {t("user.email", "이메일")} <Star />
              </label>
              <input
                maxLength={MAX_LENGTH.MAIL}
                value={contactForm.mail}
                onChange={e => {
                  setContactForm({
                    ...contactForm,
                    mail: e.target.value,
                  });
                }}
                type="text"
                id="mail"
                placeholder={t("user.please_enter_your_email", "이메일을 입력하세요") || ""}
              />
            </div>
            {/* 이름 */}
            <div className="input_wrap">
              <label htmlFor="name" className="input_label">
                {t("user.name", "이름")}
              </label>
              <input
                maxLength={MAX_LENGTH.NAME}
                value={contactForm.fullName}
                onChange={e => {
                  setContactForm({
                    ...contactForm,
                    fullName: e.target.value,
                  });
                }}
                type="text"
                id="name"
                placeholder={t("user.please_enter_your_name", "이름을 입력하세요") || ""}
              />
            </div>
            {/* 전화번호 */}
            <div className="input_wrap">
              <label htmlFor="phone" className="input_label">
                {t("user.phone_number", "전화번호")}
              </label>
              <input
                value={contactForm.mobile}
                onChange={e => {
                  setContactForm({
                    ...contactForm,
                    mobile: InputService.handleMobileNumber(e),
                  });
                }}
                id="phone"
                placeholder={
                  t(
                    "user.when_reply_is_posted",
                    "답변이 완료되면 문자를 발송해 드리니 정확하게 입력해 주세요"
                  ) || ""
                }
              />
            </div>
            {/* 회사 */}
            <div className="input_wrap">
              <label htmlFor="company" className="input_label">
                {t("user.company_name", "회사명")}
              </label>
              <input
                maxLength={MAX_LENGTH.COMPANY}
                value={contactForm.individualCompanyName}
                onChange={e => {
                  setContactForm({
                    ...contactForm,
                    individualCompanyName: e.target.value,
                  });
                }}
                type="text"
                id="company"
                placeholder={t("user.please_enter_the_company_name", "회사명을 입력하세요") || ""}
              />
            </div>
            <div className="input_wrap">
              <label htmlFor="title" className="input_label">
                {t("user.title", "제목")} <Star />
              </label>
              <input
                maxLength={MAX_LENGTH.CONTACT_TITLE}
                value={contactForm.title}
                onChange={e => {
                  setContactForm({
                    ...contactForm,
                    title: e.target.value,
                  });
                }}
                type="text"
                id="title"
                placeholder={t("user.please_enter_the_title", "제목을 입력하세요") || ""}
              />
            </div>
            <div className="input_wrap">
              <label htmlFor="contents" className="input_label">
                {t("user.content", "내용")}
                <span className="small_gray">({contactForm.content.length}/500)</span>
                <Star />
              </label>
              <textarea
                cols={30}
                rows={8}
                value={contactForm.content}
                onChange={e => {
                  setContactForm({
                    ...contactForm,
                    content: e.target.value,
                  });
                }}
                maxLength={MAX_LENGTH.CONTACT_CONTENT}
                id="contents"
                placeholder={t("main.please_enter_a_content") || ""}
              ></textarea>
            </div>
          </form>
          <div className="yak_title">
            <CheckBoxCircle
              check={contactForm.isIndividualInfoUseAgree}
              onClick={() => {
                setContactForm({
                  ...contactForm,
                  isIndividualInfoUseAgree: !contactForm.isIndividualInfoUseAgree,
                });
              }}
              name="terms_check"
              title={t("user.i_agree_to_the_terms", "개인정보 수집 및 이용")}
              addText={t("user.i_agree_with", "에 동의합니다.")}
            />
          </div>
          <ButtonBox>
            <Button onClick={() => onSubmit()} size="full" color="primary">
              {t("user.request", "문의하기")}
            </Button>
          </ButtonBox>
        </div>
      </Wrapper>
    </ModalForm>
  );
};

const Wrapper = styled.div`
  .pop_con_wrap {
    padding: 30px;
  }
  max-height: 97vh;
  overflow-y: auto;
  .small_gray {
    font-size: 12px;
    color: #c2c2c2;
  }
  .yak_title {
    margin: 20px 0 40px 0;
  }
  .yak_title label span {
    color: var(--GridaBlue);
    text-decoration: underline;
    cursor: pointer;
  }
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .g_input_a {
    border-bottom: 1px solid #333;
    padding-bottom: 25px;
    .input_wrap {
      margin-top: 0;

      label {
        width: 25%;
      }
      input,
      textarea {
        width: 75%;
        margin-bottom: 10px;
        box-sizing: border-box;
        border: 1px solid #ccc;
        padding: 10px;
        border-radius: 2px;
        &:focus {
          outline: none;
          border-color: #007bff;
          box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
        }
      }
      textarea {
        height: 120px;
        resize: none;
      }
    }
  }
`;

const PopTopText = styled.div`
  padding: 20px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    display: flex;
    align-items: center;
    padding: 15px;
  }
  p {
    padding: 0 20px;
  }
`;

const IconWrapper = styled.div`
  background-color: var(--Active);
  border-radius: 10%;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
`;

const Divider = styled.div`
  width: 90%;
  height: 1.5px;
  background-color: #333;
  margin: auto;
`;

const ButtonBox = styled.div`
  width: 100%;
  margin: auto;
  text-align: center;
`;

const IoIosMailIcon = styled(IoIosMail)`
  color: white;
`;

export default ContactModal;
