import { APICLIENT } from "../api";
const apiUser = "/api/v1/account/agreement";

interface PolicyInfo {
  agreementVersion: string;
  content: string;
  agreementTypeCd?: string;
}
export class PolicyService {
  termsOfUse: PolicyInfo = { content: "", agreementVersion: "1.0.0" };
  personalInfo: PolicyInfo = { content: "", agreementVersion: "1.0.0" };

  static getLatest = async () => {
    const response = await APICLIENT.NONAUTH.get(`${apiUser}/getLatest`);
    return response.data;
  };
}
