import React, { useEffect, useState } from "react";
import { Workstation } from "../../@types/workstation/workstation";
import { useRecoilState } from "recoil";
import { userState } from "../../constants/recoil/user";
import { useAlert } from "../alert/alert.service";
import InfraCreateButton from "../button/infra/create-button";
import WSStatusTab from "./workstation-status-tab";
import WSCreate from "../../pages/workstation/components/modal/create/modal";
import TabWrapper from "./tab-wrapper";
import { CreditService } from "../../service/billing/credit.service";
import { Workstationlist } from "../../@types/workstation/response";
interface Props {
  everyWorkstations: Workstationlist;
  reset: (workstationList?: Workstationlist) => void;
  getList: () => void;
  workstations: Workstation[];
  setCreateWs: React.Dispatch<React.SetStateAction<boolean>>;
}

type WorkstationType =
  | "myWorkstations"
  | "sharedAsDirectorWorkstations"
  | "sharedAsMemberWorkstations"
  | "sharedAsNoProjectWorkstations";

const WSNavbar = ({
  workstations,
  everyWorkstations,
  reset,
  getList,
  setCreateWs,
}: Props) => {
  const [popCreate, setPopCreate] = useState(false);
  const [status, setStatus] = useState("all");
  const [user] = useRecoilState(userState);

  const { setCreditAlert } = useAlert();

  const openCreateWS = () => {
    CreditService.hasEnoughCredit(user.userGuid || "").then((res) => {
      if (res.state === "success") {
        if (res.data) {
          setPopCreate(true);
        } else {
          setCreditAlert(true);
        }
      }
    });
  };

  useEffect(() => {
    if (status === "all") {
      return reset();
    }

    let workstationList = new Workstationlist();
    const ws_list: WorkstationType[] = [
      "myWorkstations",
      "sharedAsDirectorWorkstations",
      "sharedAsMemberWorkstations",
      "sharedAsNoProjectWorkstations",
    ];

    ws_list.map((list) => {
      everyWorkstations[list].map((workstation: Workstation) => {
        if (
          workstation.status &&
          workstation.status.cd === `InfraWsStatus.${status}`
        ) {
          workstationList[list].push(workstation);
        }
      });
    });
    reset(workstationList);
  }, [status, everyWorkstations]);

  return (
    <TabWrapper>
      <InfraCreateButton type="workstation" onClick={() => openCreateWS()} />
      <WSStatusTab status={status} setStatus={setStatus} />
      {popCreate && (
        <WSCreate
          getList={getList}
          setOpen={setPopCreate}
          isOpen={popCreate}
          workstations={workstations}
          everyWorkstations={everyWorkstations}
          setCreateWs={setCreateWs}
        />
      )}
    </TabWrapper>
  );
};

export default WSNavbar;
