import React from "react";
import styled from "styled-components";
import LoginFooter from "./login-footer";
import LoginBackGroundWrapper from "./login-background-wrapper";

interface Props {
  children: React.ReactNode;
}
const LoginForm = ({ children }: Props) => {
  return (
    <LoginBackGroundWrapper size="sm">
      <Title>
        <div className="title_box">
          <h3 className="title">M2M</h3>
          <h3 className="sub_title">Creates Metaverse To Metaverse</h3>
        </div>
        <img alt="img" src={"/assets/main/top_img.png"} className="top_img" />
      </Title>
      <Content>{children}</Content>
      <LoginFooter />
    </LoginBackGroundWrapper>
  );
};

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;

  .title_box {
    max-width: 300px;
    width: 80%;
  }
  .top_img {
    margin-top: 40px;
    width: 80%;
    height: 80%;
    max-width: 240px;
    max-height: 200px;
  }
  .title {
    font-size: 37px;
    font-weight: bold;
    color: #333;
  }
  .sub_title {
    margin-top: 10px;
    font-size: 17px;
    font-weight: bold;
    color: #7c7c7c;
  }
  @media (min-width: 1024px) {
    .title_box {
      max-width: 500px;
    }
    .top_img {
      margin-top: 40px;
      width: 80%;
      height: 80%;
      max-width: 280px;
      max-height: 225px;
    }
    .title {
      font-size: 50px;
    }
    .sub_title {
      margin-top: 10px;
      font-size: 20px;
    }
  }
`;

const Content = styled.div`
  width: 80%;
  height: 220px;
  margin: auto;
  margin-top: 30px;
`;

export default LoginForm;
