import React from "react";
import { Project } from "../../../../@types/project/project";
import styled from "styled-components";
import ProjectHeader from "./header/project.header";
import { DateService } from "../../../../service/common/date.service";
import { useT } from "../../../../constants/i18n/useT";
import { Tooltip } from "@mui/material";

interface Props {
  project: Project;
  handlePopUpdate: (projectId?: number) => void;
  handlePopDelete: (projectId?: number) => void;
  editType?: "updateOnly" | "all" | "none";
}

const ProjectCard = ({ project, handlePopUpdate, handlePopDelete, editType }: Props) => {
  const t = useT();

  const getStorageName = () => {
    let result = (
      <>
        {project.storages &&
          project.storages.map(storage => {
            return <p key={storage.storage.storageId}>{storage.storage.name}</p>;
          })}
      </>
    );
    return result;
  };

  const getWorkstationName = () => {
    let result = (
      <>
        {project.workstations &&
          project.workstations.map(workstation => {
            return (
              <p key={workstation.workstation.workstationId}>{workstation.workstation.name}</p>
            );
          })}
      </>
    );
    return result;
  };

  const getMemberName = () => {
    let result = (
      <>
        {project.users &&
          project.users.map(user => {
            return <p key={user.user.userId}>{user.user.fullName}</p>;
          })}
      </>
    );
    return result;
  };

  return (
    <Wrapper>
      <ProjectHeader
        handlePopUpdate={handlePopUpdate}
        handlePopDelete={handlePopDelete}
        project={project}
        editType={editType}
      />

      <div className="content_box">
        <Content>
          <div className="content_title">{t("common.period", "기간")}</div>
          {project.plannedStartDt && project.plannedEndDt && (
            <div className="content_text">
              {project.plannedStartDt.split(" ")[0]} ~ {project.plannedEndDt.split(" ")[0]}
            </div>
          )}
        </Content>
        <Content>
          <div className="content_title">Members</div>
          <Tooltip title={getMemberName()}>
            <div className="underline underlined">{project.usersCount}</div>
          </Tooltip>
        </Content>
        <Content>
          <div className="content_title">Workstations</div>
          <Tooltip title={getWorkstationName()}>
            <div className="underline underlined">{project.workstationsCount}</div>
          </Tooltip>
        </Content>
        <Content>
          <div className="content_title">Storages</div>
          <Tooltip title={getStorageName()}>
            <div className="underline underlined">{project.storagesCount}</div>
          </Tooltip>
        </Content>
        {/* {project.isPublic !== undefined && (
          <Content>
            <div className="content_title">{t("common.public_or_private", "공개 여부")}</div>
            <div className="content_text">
              {project.isPublic ? t("common.public", "공개") : t("common.private", "비공개")}
            </div>
          </Content>
        )} */}

        <Description>
          <div className="content_title">Note</div>
          <div className="content_text">{project.description}</div>
        </Description>
      </div>
      <CardFooter>
        {project.createdDt && (
          <Content id="footer_content">
            <div className="content_title">Created</div>
            <div className="content_text">{DateService.getTimeExceptSecond(project.createdDt)}</div>
          </Content>
        )}

        {project.updatedDt && (
          <Content id="footer_content">
            <div className="content_title">Updated</div>
            <div className="content_text">{DateService.getTimeExceptSecond(project.updatedDt)}</div>
          </Content>
        )}
      </CardFooter>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 280px;
  height: 100%;
  border: 1px solid #ddd;
  margin: 22px;
  margin-bottom: 25px;
  background: #fff;
  border-radius: 10px;

  &:hover {
    box-shadow: 2px 2px 7px #e1e1e1;
    transition: 0.3s;
  }

  @media screen and (min-width: 768px) {
    width: 320px;
  }
  .content_box {
    padding: 10px 20px;
    padding-top: 30px;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: auto;
  margin-bottom: 15px;
  .content_title {
    width: 70px;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #333;
  }
  .content_text {
    font-size: 13px;
    color: #333;
  }

  .underlined {
    text-decoration: underline;
  }
`;
const Description = styled.div`
  width: 90%;
  margin: 15px auto;

  .content_title {
    width: 70px;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #333;
  }
  .content_text {
    font-size: 13px;
    color: #5e5e5e;
    // 마지막 줄 ellipsis
    height: 50px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }

  .underlined {
    text-decoration: underline;
  }
`;

const CardFooter = styled.div`
  padding: 10px 20px;
  border-top: 1px solid #ddd;
  padding-bottom: 3px;
  height: 40px;
  #footer_content {
    margin-bottom: 5px;
  }
  .content_title {
    font-size: 11px;
    font-weight: 400;
    color: #5e5e5e;
  }
  .content_text {
    font-size: 9px;
    font-weight: 400;
    color: #5e5e5e;
  }
`;

export default ProjectCard;
