import React from "react";
import { useT } from "../../../constants/i18n/useT";
import styled from "styled-components";
import { HiDotsVertical } from "@react-icons/all-files/hi/HiDotsVertical";
import { FiHardDrive } from "@react-icons/all-files/fi/FiHardDrive";
import { FiMonitor } from "@react-icons/all-files/fi/FiMonitor";

interface Props {
  type: "workstation" | "storage" | "project";
  onClick?: () => void;
}

const InfraCreateButton = ({ type, onClick }: Props) => {
  // Project 생성 , Workstation 생성, Storage 생성
  const t = useT();
  if (type === "workstation")
    return (
      <ButtonWrapper onClick={onClick}>
        <StyledFiMonitor size={20} />
        {t("workstation.create_workstation", "Workstation 생성")}
      </ButtonWrapper>
    );
  if (type === "storage")
    return (
      <ButtonWrapper onClick={onClick}>
        <StyledFiHardDrive size={20} />
        {t("storage.create_storage", "Storage 생성")}
      </ButtonWrapper>
    );
  // if (type === "project")
  return (
    <ButtonWrapper onClick={onClick}>
      <StyledFiHardDrive size={20} />
      {t("project.create_project", "Project 생성")}
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.button`
  min-width: 120px;
  height: 30px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  font-size: 10px;
  border: 0;
  border-radius: 5px;
  background-color: var(--GridaBlue);

  &:hover {
    background-color: #006ee3;
  }

  @media (min-width: 1024px) {
    min-width: 160px;
    font-size: 14px;
    height: 40px;
    padding: 15px;
  }
`;

const StyledFiMonitor = styled(FiMonitor)`
  margin-right: 5px;
`;

const StyledFiHardDrive = styled(FiHardDrive)`
  margin-right: 5px;
`;

export default InfraCreateButton;
