import { Workstationlist } from "../../@types/workstation/response";
import { Workstation } from "../../@types/workstation/workstation";
import { APICLIENT } from "../api";
const WORKSTATION_API = "/api/v1/infra-web-user/workstations";
const INFRA_WEB_USER = "/api/v1/infra-web-user";

export class WorkstationService {
  name: string;
  constructor() {
    this.name = "";
  }
  static getList = async () => {
    const response = await APICLIENT.AUTH.get(`${WORKSTATION_API}/getList`);
    return response.data;
  };

  static getMyWorkstations = async (userGuid: string, filter: string, progressRate: string) => {
    const response = await APICLIENT.AUTH.get(
      `${INFRA_WEB_USER}/${userGuid}/workstation?filter=${filter}&progressRate=${progressRate}`
    );
    return response.data;
  };

  static getUnassignedSharedWorkstations = async (
    userGuid: string,
    filter: string,
    projectAssigned: string,
    progressRate: string
  ) => {
    const response = await APICLIENT.AUTH.get(
      `${INFRA_WEB_USER}/${userGuid}/workstation?filter=${filter}&projectAssigned=${projectAssigned}&progressRate=${progressRate}`
    );
    return response.data;
  };

  static getProjectSharedWorkstationsForArtist = async (
    userGuid: string,
    filter: string,
    projectAssigned: string,
    projectRole: string,
    progressRate: string
  ) => {
    const response = await APICLIENT.AUTH.get(
      `${INFRA_WEB_USER}/${userGuid}/workstation?filter=${filter}&projectAssigned=${projectAssigned}&projectRole=${projectRole}&progressRate=${progressRate}`
    );
    return response.data;
  };

  static getProjectSharedWorkstationsForDirector = async (
    userGuid: string,
    filter: string,
    projectAssigned: string,
    projectRole: string,
    progressRate: string
  ) => {
    const response = await APICLIENT.AUTH.get(
      `${INFRA_WEB_USER}/${userGuid}/workstation?filter=${filter}&projectAssigned=${projectAssigned}&projectRole=${projectRole}&progressRate=${progressRate}`
    );
    return response.data;
  };

  static getUi = async () => {
    const response = await APICLIENT.AUTH.get(`${WORKSTATION_API}/getUiData`);
    return response.data;
  };
  static delete = async (workstationId: string) => {
    const response = await APICLIENT.AUTH.get(`${WORKSTATION_API}/delete/${workstationId}`);
    return response.data;
  };
  static getById = async (workstationId: string) => {
    const response = await APICLIENT.AUTH.get(`${WORKSTATION_API}/get/${workstationId}`);
    return response.data;
  };

  static getWorkstationById = async (workstationId: string) => {
    const response = await APICLIENT.AUTH.get(`${WORKSTATION_API}/${workstationId}`);
    return response.data;
  };

  static restart = async (workstationId: string) => {
    const response = await APICLIENT.AUTH.get(`${WORKSTATION_API}/restart/${workstationId}`);
    return response.data;
  };

  static start = async (workstationId: string) => {
    const response = await APICLIENT.AUTH.get(`${WORKSTATION_API}/start/${workstationId}`);
    return response.data;
  };

  static shutdown = async (workstationId: string) => {
    const response = await APICLIENT.AUTH.get(`${WORKSTATION_API}/shutdown/${workstationId}`);
    return response.data;
  };
  static logout = async (workstationId: string) => {
    const response = await APICLIENT.AUTH.get(`${WORKSTATION_API}/logout/${workstationId}`);
    return response.data;
  };
  update = async () => {
    const response = await APICLIENT.AUTH_NOALERT.post(`${WORKSTATION_API}/update`, {
      data: this,
    });
    return response.data;
  };
  create = async () => {
    const response = await APICLIENT.AUTH_NOALERT.post(`${WORKSTATION_API}/create`, {
      data: this,
    });
    return response.data;
  };
  static updateUser = async (data: any) => {
    const response = await APICLIENT.AUTH_NOALERT.post(`${WORKSTATION_API}/users/update`, { data });
    return response.data;
  };

  static getUsingList = (data: Workstationlist) => {
    const usingWorkstation: string[] = [];
    data.myWorkstations.map((workstation: Workstation) => {
      if (workstation.status && workstation.status.cd !== "InfraWsStatus.PowerOff") {
        usingWorkstation.push(workstation.name);
      }
    });
    data.sharedAsDirectorWorkstations.map((workstation: Workstation) => {
      if (workstation.status && workstation.status.cd !== "InfraWsStatus.PowerOff") {
        usingWorkstation.push(workstation.name);
      }
    });
    data.sharedAsMemberWorkstations.map((workstation: Workstation) => {
      if (workstation.status && workstation.status.cd !== "InfraWsStatus.PowerOff") {
        usingWorkstation.push(workstation.name);
      }
    });
    data.sharedAsNoProjectWorkstations.map((workstation: Workstation) => {
      if (workstation.status && workstation.status.cd !== "InfraWsStatus.PowerOff") {
        usingWorkstation.push(workstation.name);
      }
    });
    return usingWorkstation;
  };

  static mountWorkstation = async (workstationId: any) => {
    const response = await APICLIENT.AUTH_NOALERT.get(`${WORKSTATION_API}/${workstationId}/mount`);
    return response.data;
  };
}
