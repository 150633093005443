import React, { useState } from "react";
import styled from "styled-components";
import { useT } from "../../../../constants/i18n/useT";
import SearchInput from "../../../../components/input/search/infra-input";

interface Props {
  setProjectTerm: React.Dispatch<React.SetStateAction<string>>;
}

const ProjectSearch = ({ setProjectTerm }: Props) => {
  const [value, setValue] = useState("");

  const t = useT();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setProjectTerm(e.target.value);
  };

  return (
    <Wrapper>
      <SearchInput
        placeholder={t("project.project", "프로젝트") || ""}
        onChange={handleChange}
        value={value}
      />
    </Wrapper>
  );
};

const Wrapper = styled.article`
  margin: 30px;
`;

export default ProjectSearch;
