import React, { useState } from "react";
import { useT } from "../../../../constants/i18n/useT";
import CalculateModal from "../../../billing/calculate/modal";
import ContactModal from "../../../contact/non-member/modal";
import styled from "styled-components";
import MainBlueButton from "./main-blue-button";

const ContactAndCalculateButtonBox = ({ type }: { type: "row" | "column" }) => {
  const [popCalculate, setPopCalculate] = useState(false);
  const [popContact, setPopContact] = useState(false);
  const t = useT();

  return (
    <Wrapper className={type}>
      <MainBlueButton onClick={() => setPopContact(true)}>
        {t("main.contact", "Contact")}
      </MainBlueButton>

      <MainBlueButton color="dark" onClick={() => setPopCalculate(true)}>
        {t("billing.pricing_calculator", "요금 계산기")}
      </MainBlueButton>

      {popCalculate && (
        <CalculateModal setOpen={setPopCalculate} isOpen={popCalculate} />
      )}
      {popContact && (
        <ContactModal setOpen={setPopContact} isOpen={popContact} />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  margin: auto;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  button {
    margin-bottom: 50px;
  }
  @media (min-width: 1024px) {
    &.row {
      flex-direction: row;
      button {
        margin-bottom: 0;
        margin-right: 50px;
      }
    }
  }
`;

export default ContactAndCalculateButtonBox;
