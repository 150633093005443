import React, { useState } from "react";
import styled from "styled-components";
import RoundButton from "../../components/button/round-button";
import LoginForm from "./components/login-wrapper";
import { useRecoilState } from "recoil";
import { userState } from "../../constants/recoil/user";
import { useAlert } from "../../components/alert/alert.service";
import { useT } from "../../constants/i18n/useT";
import { EmailAlertModal } from "./components/alert-email/modal";
import { SessionLoginModal } from "./components/session-login/modal";
import LoginFailed from "./components/login-failed/modal";
import { InputService } from "../../service/common/input.service";
import { AuthService } from "../../service/user/user.service";
import { WindowService } from "../../service/common/window.service";
import InputLabel from "../../components/input/input-label/input";

const LoginPage = () => {
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useRecoilState(userState);
  const [popAlert, setPopAlert] = useState(false);
  const [popLoginFailed, setPopLoginFailed] = useState(false);
  const [popSessionLogin, setPopSessionLogin] = useState(false);
  const [email, setEmail] = useState("");
  const [isResponse, setIsResponse] = useState(false);

  const t = useT();

  const { setAlert } = useAlert();

  const postLogin = () => {
    setIsResponse(true);
    AuthService.login(id, password)
      .then(res => {
        console.log(res.data);
        console.log(res);

        setUser(res.data);
        WindowService.goWorkstation();
      })

      .catch(err => {
        if (err.response.status === 400) {
          setAlert(
            t("user.this_is_a_suspended_account", "정지된 계정입니다. 관리자에게 문의하세요.")
          );

          return;
        }

        if (err.response.status === 401) {
          setAlert(
            t(
              "user.the_information_of_the_user_you_are_looking",
              "찾으시는 유저의 정보가 없습니다."
            )
          );

          return;
        }

        if (err.response.status === 403) {
          setAlert(t("user.you_do_not_have_permission", "권한이 없습니다."));

          return;
        }

        if (err.response.status === 404) {
          setAlert(t("user.deleted_account", "삭제된 계정입니다."));

          return;
        }

        if (err.response.status === 409) {
          setAlert(t("user.email_authentication_required", "이메일 인증이 필요합니다."));

          return;
        }

        if (err.response.status === 500) {
          setAlert(t("common.error", "현재 서버가 원활하지 않습니다. 잠시 후 시도해주세요."));

          return;
        }

        // if (err.response.data.state === "fail") {
        //   setAlert(err.response.data.message);
        // } else setAlert(t("common.error", "현재 서버가 원활하지 않습니다. 잠시 후 시도해주세요."));
      })

      .finally(() => setIsResponse(false));
  };

  return (
    <LoginForm>
      <Wrapper>
        <InputLabel
          value={id}
          onChange={e => setId(e.target.value)}
          label={t("user.id", "아이디")}
          placeholder={t("user.please_enter_id", "아이디를 입력하세요") || ""}
        />
        <InputLabel
          value={password}
          onChange={e => setPassword(e.target.value)}
          type="password"
          label={t("user.password", "패스워드")}
          placeholder={t("user.please_enter_password", "비밀번호를 입력하세요") || ""}
          onKeyDown={e => InputService.pressEnter(e, postLogin)}
        />
        <div className="login_btn">
          <RoundButton
            onClick={() => postLogin()}
            disabled={isResponse}
            color={isResponse ? "disabled" : "blue"}
            size="full_lg"
          >
            {t("user.sign_in", "로그인")}
          </RoundButton>
        </div>
      </Wrapper>
      {popAlert && (
        <EmailAlertModal userId={id} isOpen={popAlert} setOpen={setPopAlert} message={email} />
      )}
      {popSessionLogin && (
        <SessionLoginModal
          isOpen={popSessionLogin}
          setOpen={setPopSessionLogin}
          userId={id}
          password={password}
        />
      )}
      {popLoginFailed && <LoginFailed isOpen={popLoginFailed} setOpen={setPopLoginFailed} />}
    </LoginForm>
  );
};

const Wrapper = styled.div`
  .login_btn {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
`;

export default LoginPage;
