import { css } from "styled-components";

export const ButtonStyle = {
  SIZES: {
    xs: css`
      font-size: 0.5rem;
      border-radius: 4px;
      height: 25px;
      width: 55px;
      @media (min-width: 768px) {
        font-size: 0.8rem;
        width: 60px;
      }
    `,
    sm: css`
      font-size: 0.8rem;
      border-radius: 4px;
      width: 115px;
      height: 30px;
    `,
    md: css`
      font-size: 1rem;
      border-radius: 8px;
      width: 140px;
      height: 40px;
    `,
    lg: css`
      font-size: 1.1rem;
      border-radius: 8px;
      width: 200px;
      height: 50px;
    `,
    xl: css`
      font-size: 1.1rem;
      border-radius: 8px;
      width: 45%;
      height: 40px;
    `,
    xxl: css`
      font-size: 1.2rem;
      border-radius: 8px;
      width: 45%;
      height: 60px;
    `,

    full: css`
      font-size: 1rem;
      border-radius: 8px;
      width: 90%;
      height: 40px;
      @media (min-width: 1024px) {
        font-size: 1.1rem;
        height: 40px;
      }
    `,
    full_lg: css`
      font-size: 1.2rem;
      border-radius: 8px;
      width: 100%;
      height: 50px;
    `,
  },
  COLOR: {
    skyblue: css`
      background-color: var(--InActive);
      color: var(--Active);
      &:hover {
        background: var(--InActiveHover);
      }
    `,
    white: css`
      background-color: #f9f9f9;
      border: 0.3px solid #c9c9c9;
      color: var(--Active);
      &:hover {
        background: #f2f2f2;
      }
    `,
    primary: css`
      background-color: var(--Active);
      color: #fff;
      &:hover {
        background: var(--ActiveHover);
      }
    `,
    black: css`
      color: #fff;
      background-color: #333;
      &:hover {
        background: #4c4c4c;
      }
    `,
    blue: css`
      color: #fff;
      background-color: var(--GridaBlue);
      &:hover {
        background: var(--GridaBlueHover);
      }
    `,
    warning: css`
      color: #fff;
      background-color: var(--Warning);
      &:hover {
        background: var(--WarningHover);
      }
    `,
    disabled: css`
      background-color: #9b9b9b;
      color: #fff;
      &:hover {
        cursor: default;
        background-color: #8b8b8b;
      }
    `,
    cancel: css`
      background-color: var(--GridaBlue);
      color: #fff;
      &:hover {
        background: var(--GridaBlueHover);
      }
    `,
    delete: css`
      background-color: var(--GridaBlue);
      color: #fff;
      &:hover {
        background: var(--GridaBlueHover);
      }
    `,
  },
  ROUND: {
    round: css`
      border-radius: 50px;
    `,
  },
};

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "xxl" | "full" | "full_lg";
  color?:
    | "primary" // onSubmit
    | "skyblue" // 닫기 및 그 외 기능
    | "blue" // 링크
    | "black" // User
    | "white"
    | "warning"
    | "disabled"
    | "cancel"
    | "delete";
  children: string;
  round?: boolean;
  // onClick?: () => void;
}
