import React, { useEffect } from "react";
import styled from "styled-components";
import Button from "../../../../components/button/Button";
import { useT } from "../../../../constants/i18n/useT";

interface Props {
  title: string;
  content?: string;
  type?: string;
  action?: (value: React.SetStateAction<boolean>) => void;
}

const UserInfoElement = ({ title, content, type, action }: Props) => {
  const t = useT();
  const changeEmail = () => {
    if (type === "email" && action) {
      return (
        <Button size="xs" color="white" onClick={() => action(true)}>
          {t("user.update_email_button", "이메일 변경")}
        </Button>
      );
    }
  };
  const changeCompany = () => {
    if (type === "company" && action) {
      return (
        <Button size="xs" color="white" onClick={() => action(true)}>
          {t("user.update_company_button", "회사 수정")}
        </Button>
      );
    }
  };

  return (
    <Wrapper>
      <div className="title">
        {title}
        {changeEmail()}
        {changeCompany()}
      </div>
      <div className="content">{content}</div>
    </Wrapper>
  );
};

const Wrapper = styled.li`
  display: flex;
  margin-bottom: 5px;
  width: 90%;
  max-width: 500px;
  font-size: 0.8rem;
  .title {
    width: 130px;
  }
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  .content {
    color: #5e7290;
    width: 150px;
    text-align: start;
  }
  button {
    margin-left: 10px;
    width: 80px;
  }
  @media (min-width: 768px) {
    font-size: 1rem;
    .title {
      width: 150px;
    }
    .content {
      width: 200px;
    }
  }
`;

export default UserInfoElement;
