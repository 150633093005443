import axios, { AxiosInstance, AxiosResponse } from "axios";

enum STAGE_LOCATION {
  DOMAIN = "m2m.grida.dev",
  CLIENT = "https://m2m.grida.dev",
}

export const BASE_URL = (() => {
  const HOST_NAME = window.location.hostname;
  const PORT_NUM = window.location.port;

  if (HOST_NAME === STAGE_LOCATION.DOMAIN) {
    return STAGE_LOCATION.CLIENT;
  }
  return `http://${HOST_NAME}:${PORT_NUM}`;
})();

export const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-type": "application/json",
    "x-ui-type": "UI_TYPE_USER",
  },
});

export const axiosNoAuthNoAlertInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-type": "application/json",
    "x-ui-type": "UI_TYPE_USER",
  },
});

export const axiosInstanceWithToken = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-type": "application/json",
    "x-ui-type": "UI_TYPE_USER",
    Authorization: `Bearer ${sessionStorage.getItem("x-access-token")}`,
  },
});

export const requestSetting = (instance: AxiosInstance) => {
  instance.interceptors.request.use((config: any) => {
    config.headers["x-locale"] = sessionStorage.getItem("x-locale") || "ko_KR";
    return config;
  });
  return instance;
};

export const checkToken = (response: AxiosResponse) => {
  // x-access-token이 헤더에 있다는 것은 토큰이 갱신되었다는 것을 의미

  if (response.headers["x-access-token"]) {
    sessionStorage.setItem(
      "x-access-token",
      response.headers["x-access-token"]
    );
    window.location.reload();
  }
};
