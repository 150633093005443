import React from "react";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
  title: string;
}

const UserListForm = ({ children, title }: Props) => {
  return (
    <Wrapper>
      {title && (
        <div className="title">
          <h3>{title}</h3>
        </div>
      )}
      <Box>{children}</Box>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .title {
    height: 30px;
  }
  h3 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    color: blue;
  }
`;

const Box = styled.div`
  background-color: #fff;
  border: 1px solid #cccccc;
  overflow-y: auto;
  padding: 10px;
  padding-top: 20px;
  height: 400px;
  width: 300px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  &::-webkit-scrollbar {
    background-color: rgb(255, 255, 255);
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(112, 112, 112, 0.514);
    border-radius: 10px;
    height: 10px;
  }
`;
export default UserListForm;
