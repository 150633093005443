import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useT } from "../../constants/i18n/useT";
import Button from "../button/Button";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

const ServiceAllDown = () => {
  const navigate = useNavigate();
  const t = useT();

  return (
    <Wrapper>
      <ReportProblemIcon style={{ fontSize: 100, color: "#FFA726" }} />
      <Title>{(t("service_down_message"), "서비스 이용이 원활하지 않습니다.")}</Title>
      <Description>
        {
          (t("service_down_description"),
          "현재 서비스에 문제가 발생했습니다. 나중에 다시 시도해주세요.")
        }
      </Description>
      <StyledButton onClick={() => navigate("/")}>{(t("go_home"), "홈으로")}</StyledButton>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
`;

const Title = styled.h1`
  margin: 20px 0;
  font-size: 28px;
  color: #333;
`;

const Description = styled.p`
  margin: 10px 0 20px;
  font-size: 16px;
  color: #666;
`;

const StyledButton = styled(Button)`
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border-radius: 8px;
  padding: 10px 20px;
  text-align: center;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

export default ServiceAllDown;
