import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useT } from "../../../../constants/i18n/useT";
import { CreditService } from "../../../../service/billing/credit.service";

const UserCreditLeftBox = () => {
  const [periods, setPeriods] = useState([10, 30, 60]);
  const [ways, setWays] = useState(["Email, Workstation"]);

  useEffect(() => {
    CreditService.getAlarmWaysPeriods().then((res) => {
      if (res.state === "success") {
        setPeriods(res.data.periods);
        setWays(res.data.ways);
      }
    });
  }, []);

  const t = useT();

  return (
    <Wrapper>
      <h5>{t("billing.charge_notification", "충전 알림")}</h5>
      <div className="content_box">
        <div className="title">
          {t("billing.notification_interval", "알림 간격")} :
        </div>
        <span id="blue">
          {periods.map((period) => period + t("billing.min", "분")).join(", ")}
        </span>
      </div>
      <div className="content_box">
        <div className="title">
          {t("billing.notification_channel", "알림 방법")}:
        </div>
        <span id="blue">{ways.join(", ")}</span>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.article`
  border-right: 1px dashed #ddd;
  border: 1px solid #ddd;
  height: 150px;
  background: #fff;
  width: 100%;
  text-align: center;
  padding: 20px 0;
  .content_box {
    .title {
      width: 40%;
      min-width: 100px;
      font-weight: 600;
      padding-right: 20px;
    }
    padding: 10px 0;
    display: flex;
    .blue {
      font-size: 0.8rem;
    }
  }

  @media screen and (min-width: 768px) {
    width: 50%;
  }
`;

export default UserCreditLeftBox;
