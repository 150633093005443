import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { useT } from "../../../constants/i18n/useT";
import { useRecoilState } from "recoil";
import { localeState } from "../../../constants/recoil/locale";

const fadeIn = keyframes`
from {
  opacity: 0;
}
to {
  opacity: 1;
}
`;

const Page2 = () => {
  const t = useT();
  const [locale] = useRecoilState(localeState);

  const [isOpen, setIsOpen] = useState(false);
  const [img, setImg] = useState("");

  const openModal = (imgSrc: string) => {
    setImg(imgSrc);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <p>
        {t(
          "workstation.mac_client_download_message4",
          "M2MClientLauncher 프로그램을 클릭하여 실행합니다."
        )}
      </p>
      <br />
      <p>
        {t(
          "workstation.mac_client_download_message5",
          "이 때 Mac 보안 설정을 수정합니다. 악성 소프트웨어 확인 경고창이 뜰 수 있습니다. “확인”버튼을 클릭하세요."
        )}
      </p>
      <Container>
        {locale === "ko_KR" && (
          <Image
            src="/assets/user/download/download2.png"
            onClick={() => openModal("/assets/user/download/download2.png")}
            alt="Download"
          />
        )}
        {locale === "en_US" && (
          <Image
            src="/assets/user/download/en_download1.png"
            onClick={() => openModal("/assets/user/download/en_download1.png")}
            alt="Download"
          />
        )}
        {locale === "ja_JP" && (
          <Image
            src="/assets/user/download/jp_download1.png"
            onClick={() => openModal("/assets/user/download/jp_download1.png")}
            alt="Download"
          />
        )}
        {locale === "zh_CN" && (
          <Image
            src="/assets/user/download/cn_download1.png"
            onClick={() => openModal("/assets/user/download/cn_download1.png")}
            alt="Download"
          />
        )}
      </Container>

      {isOpen && (
        <Modal onClick={closeModal}>
          <ModalContent src={img} onClick={(e) => e.stopPropagation()} />
          <CloseButton onClick={closeModal}>&times;</CloseButton>
        </Modal>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  border-radius: 10px;
  margin-top: 15px;
  width: 70%;
  height: auto;
`;

const Modal = styled.div`
  position: fixed;
  z-index: 1050;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  animation: ${fadeIn} 0.5s ease-out;
`;

const ModalContent = styled.img`
  margin: auto;
  display: block;
  max-width: 90%;
  max-height: 80%;
  margin-top: 10%;
  animation: ${fadeIn} 0.5s ease-out;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
`;
export default Page2;
