import React from "react";
import { useLocation } from "react-router-dom";
import ContactIndex from "./contact/page";
import CreditHistory from "./credit/page";
import UserAlarms from "./userAlarms/page";
import UserInfo from "./info/page";
import ServicePageWrapper from "../../components/wrapper/service-page";
import MyPageTab from "../../components/tab/mypage-tab";

enum UserMainPagePath {
  INFO = "/mypage/info",
  CREDIT = "/mypage/credit",
  USER_ALARMS = "/mypage/user-alarms",
  CONTACT = "/mypage/contact",
}

const UserMainPage = () => {
  const { pathname } = useLocation();
  return (
    <section>
      <ServicePageWrapper>
        <MyPageTab />
        {pathname === UserMainPagePath.INFO && <UserInfo />}
        {pathname === UserMainPagePath.CREDIT && <CreditHistory />}
        {pathname === UserMainPagePath.USER_ALARMS && <UserAlarms />}
        {pathname === UserMainPagePath.CONTACT && <ContactIndex />}
      </ServicePageWrapper>
    </section>
  );
};

export default UserMainPage;
