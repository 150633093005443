import React from "react";
import { useT } from "../../constants/i18n/useT";
import styled from "styled-components";

interface Props {
  status: string;
  setStatus: React.Dispatch<React.SetStateAction<string>>;
}

const WSStatusTab = ({ status, setStatus }: Props) => {
  const t = useT();
  const getActive = (_staus: string) => {
    return status === _staus ? "active" : "";
  };

  const handleStatusChange = (
    event: React.MouseEvent<HTMLAnchorElement>,
    newStatus: string
  ) => {
    event.preventDefault();
    setStatus(newStatus);
  };
  return (
    <Wrapper>
      <li className={getActive("all")}>
        <a href="" onClick={(e) => handleStatusChange(e, "all")}>
          {t("workstation.all", "전체")}
        </a>
      </li>
      <li className={getActive("Running")}>
        <a href="" onClick={(e) => handleStatusChange(e, "Running")}>
          {t("workstation.running", "실행중")}
        </a>
      </li>
      <li className={getActive("PowerOff")}>
        <a href="" onClick={(e) => handleStatusChange(e, "PowerOff")}>
          {t("workstation.stopped", "정지")}
        </a>
      </li>
      <li className={getActive("Booting")}>
        <a href="" onClick={(e) => handleStatusChange(e, "Booting")}>
          {t("workstation.preparing", "준비중")}
        </a>
      </li>
    </Wrapper>
  );
};

const Wrapper = styled.ul`
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 400;
  color: #666;
  li {
    cursor: pointer;
    a {
      box-sizing: border-box;
      min-width: 40px;
      width: 100%;
      padding: 0 5px;
      line-height: 25px;
      font-weight: 300;
      display: inline-block;
      text-align: center;
      border: 1px solid #ddd;
      &:hover {
        color: #898989;
        font-weight: 500;
      }
    }
    &:first-child a {
      border-radius: 5px 0px 0px 5px;
    }
    &.active a {
      color: var(--GridaBlue);
      font-weight: 500;
      border-color: var(--GridaBlue);
    }
  }
  @media (min-width: 1024px) {
    margin-left: 35px;
    font-size: 14px;
    font-weight: 500;
    li {
      a {
        min-width: 60px;
        line-height: 37px;
      }
    }
  }
`;

export default WSStatusTab;
