import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { Company, CompanyService } from "../../../../service/user/company.service";
import { useT } from "../../../../constants/i18n/useT";
import { RegisterCompany } from "../../../../@types/user/company";
interface Props {
  company: RegisterCompany;
  setCompany: React.Dispatch<React.SetStateAction<RegisterCompany>>;
}

const CompanyInput = ({ company, setCompany }: Props) => {
  const [popCompany, setPopCompany] = useState(false);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [value, setValue] = useState("");

  const search = () => {
    if (value !== "") {
      CompanyService.getList(value).then((res) => {
        if (res.state === "success") {
          setCompanies(res.data);
        }
      });
    }
  };

  useEffect(() => {
    search();
  }, [value]);

  const hasFocus = () => {
    const inputEl = document.getElementById("company");

    return inputEl === document.activeElement;
  };

  const 일반회사등록 = () => {
    setCompany(new RegisterCompany(value));
    setPopCompany(false);
  };
  const 제휴회사등록 = (_company: Company) => {
    if (_company.name !== "") {
      setCompany(new RegisterCompany(_company.name, _company.companyGuid));
    }
    setPopCompany(false);
  };

  const getMyCompany = () => {
    return company.name;
  };

  const cancelCompany = () => {
    setCompany(new RegisterCompany());
    setValue("");
  };

  const searchResult = (companyName: string) => {
    const lowerValue = value.toLowerCase();
    const lowerCompanyName = companyName.toLowerCase();
    const point = lowerCompanyName.indexOf(lowerValue);

    return (
      <span>
        {companyName.slice(0, point)}
        <span id="blue">{companyName.slice(point, point + value.length)}</span>
        {companyName.slice(point + value.length)}
      </span>
    );
  };
  const t = useT();

  return (
    <Wrapper>
      <div className="label_box">
        <label htmlFor="company" className="input_label">
          {t("signup.company_name", "회사명")}
        </label>
        <div className="selected">
          <p>{getMyCompany()}</p>
          {company.name && <img src="/assets/user/x.png" onClick={() => cancelCompany()} />}
        </div>
      </div>
      <div className="input_wrap">
        {!popCompany && (
          <button onClick={() => setPopCompany(true)} className="company_button">
            {t("signup.search_company", "회사 검색")}
          </button>
        )}
        <div className="input_wrapper">
          <input
            value={value}
            className={popCompany ? "show" : "hidden"}
            onChange={(e) => setValue(e.target.value)}
            id="company"
            placeholder={t("signup.please_search_for_a_company", "회사를 검색하세요.") || ""}
          />
          {popCompany && hasFocus() && value !== "" && (
            <div className="company_list">
              {companies.map((icompany) => (
                <p onClick={() => 제휴회사등록(icompany)} key={icompany.companyGuid}>
                  {icompany.name && searchResult(icompany.name)}
                </p>
              ))}
              {value !== "" && (
                <div className="register" onClick={() => 일반회사등록()}>
                  <p>
                    {t("signup.register", "등록")} <span id="blue">{value}</span>
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.article`
  margin: auto;
  margin-top: 18px;
  width: 100%;
  .label_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    label {
      display: block;
      font-size: 14px;
      font-weight: bold;
      color: #979797;
      margin-left: 5px;
    }
    .selected {
      display: flex;
      align-items: center;
      padding-left: 10px;
      font-size: 14px;
      font-weight: bold;
      color: #333;
      img {
        margin-bottom: 10px;
        height: 18px;
        padding-left: 5px;
        cursor: pointer;
      }
    }
  }

  input {
    display: block;
    width: 100%;
    height: 35px;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    &:focus {
      border: 1px solid #50a0fa;
    }
    text-indent: 10px;
    &::placeholder {
      font-size: 10px;
    }
  }

  .input_wrapper {
    position: relative;
    width: 100%;
  }
  .company_list {
    position: absolute;
    width: 100%;
    max-height: 150px;
    z-index: 10;
    background-color: #fff;
    border: 1px solid #ccc;
    /* top: 0px; */
    overflow-y: auto;
    p {
      padding: 10px 5px;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        background-color: #f6f6f6;
      }
    }
    .register {
      cursor: pointer;
      background-color: #ebebeb;
      text-align: center;
      &:hover {
        background-color: #f6f6f6;
      }
      p {
        padding: 10px 5px;
      }
    }
  }
  .show {
    opacity: 1;
    transition: width 2s;
    width: 100%;
  }
  .hidden {
    opacity: 0;
    transition: width 1s;
    width: 0px !important;
  }
  .input_wrap {
    display: flex;
    align-items: center;
  }
  .company_button {
    width: 80px;
    height: 30px;
    background-color: var(--Black);
    color: var(--White);
    font-size: 12px;
  }

  @keyframes hidden {
    0% {
      opacity: 0.1;
      width: 0px !important;
    }
    50% {
      opacity: 0.5;
      width: 150px !important;
      background-color: #7b7b19;
    }
    100% {
      opacity: 1;
      width: 350px !important;
      background-color: #2e2e0a;
    }
  }
`;
export default CompanyInput;
