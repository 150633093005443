import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { WorkstationClientService } from "../../service/workstation/client.service";
import Button from "../../components/button/Button";
import Page1 from "./pages/page1";
import Page2 from "./pages/page2";
import Page3 from "./pages/page3";
import Page4 from "./pages/page4";
import Page5 from "./pages/page5";
import { useT } from "../../constants/i18n/useT";
import { TiVendorMicrosoft } from "@react-icons/all-files/ti/TiVendorMicrosoft";
import { IoLogoApple } from "@react-icons/all-files/io/IoLogoApple";
import { FcLinux } from "@react-icons/all-files/fc/FcLinux";

const ClientDownLoad = () => {
  const t = useT();

  const goSearchPage = (os: "window" | "linux" | "mac") => {
    const link = WorkstationClientService.getDownloadLink(os);
    window.location.replace(link);
  };

  const [step, setStep] = useState(1);

  const nextStep = () => {
    setStep(step + 1);
    if (step === 5) {
      setStep(1);
    }
  };

  const prevStep = () => {
    if (step > 1) setStep(step - 1);
  };

  return (
    <div className="sub_page">
      <div className="content">
        <Content>
          <Card>
            <CardTitle>
              <IconWrapper>
                <TiVendorMicrosoft size={50} />
              </IconWrapper>
              Microsoft Windows OS
            </CardTitle>
            <TopBox>
              <p>Windows 10 (64 Bit)</p>
              <p>Windows 10 IoT 64 21H2 Enterprise LTSC</p>
              <p>Windows 11</p>
            </TopBox>
            <BottonBox>
              <p>{t("workstation.excute_exe", "다운로드 받은 exe 설치 파일을 실행합니다.")}</p>
              <p>{t("workstation.install", "다운로드 받은 exe 설치 파일을 실행합니다.")}</p>
            </BottonBox>
            <DownloadButton onClick={() => goSearchPage("window")}>
              {t("workstation.download", "Download")}
            </DownloadButton>
          </Card>
          <Card>
            <CardTitle>
              <IconWrapper>
                <IoLogoApple size={50} />
              </IconWrapper>
              Apple MAC OS
            </CardTitle>
            <TopBox>
              <p>macOS 12 (Monterey)</p>
              <p>macOS 13 (Ventura)</p>
              <p>macOS 14 (Sonoma)</p>
            </TopBox>
            <BottonBox>
              <ContentWrapper>
                {step === 1 && <Page1 />}
                {step === 2 && <Page2 />}
                {step === 3 && <Page3 />}
                {step === 4 && <Page4 />}
                {step === 5 && <Page5 />}
              </ContentWrapper>

              <StepFooter>
                <ArrowButton disabled={step === 1} onClick={prevStep}>
                  &lt;
                </ArrowButton>

                <StepIndicator>{`${step} / 5`}</StepIndicator>

                <ArrowButton onClick={nextStep}>&gt;</ArrowButton>
              </StepFooter>
            </BottonBox>
            <DownloadButton onClick={() => goSearchPage("mac")}>
              {t("workstation.download", "Download")}
            </DownloadButton>
          </Card>
          <Card>
            <CardTitle>
              <IconWrapper>
                <FcLinux size={50} />
              </IconWrapper>
              Linux OS
            </CardTitle>
            <TopBox>
              <p>Ubuntu Desktop 22.04</p>
            </TopBox>
            <BottonBox>
              <p>
                {t(
                  "workstation.delete_proid_verison",
                  "이전 설치 버전을 다음 명령으로 삭제합니다."
                )}
              </p>
              <p>$ sudo apt-get remove m2m-client</p>
              <p>$ sudo apt-get remove pcoip-client</p>
              <br />
              <p>
                {t(
                  "workstation.decompress_install",
                  "다운로드받은 파일의 압축을 풀고 다음 명령으로 설치합니다."
                )}
              </p>
              <br />
              <p>$ tar zxvf m2m-client-x.x.x.tar.gz</p>
              <p>$ cd m2m-client</p>
              <p>$ sudo ./install.sh</p>
            </BottonBox>
            <DownloadButton onClick={() => goSearchPage("linux")}>
              {t("workstation.download", "Download")}
            </DownloadButton>
          </Card>
        </Content>
        <BottomCard>
          <BoxTitle>{t("workstation.recommand_hardware", "Recommended Hardware")}</BoxTitle>
          <BoxContent>
            <p>
              {t(
                "workstation.recommand_ram1",
                "듀얼 1920x1080 디스플레이는 1.6GHz 듀얼 코드 프로세서와 최소 4GB램을 추천함"
              )}
            </p>
            <p>
              {t(
                "workstation.recommand_ram2",
                "듀얼 4K/UHD 디스플레이는 2.8 GHz 쿼드코어 프로세서와 최소 8 듀얼 채널 RAM을 추천함"
              )}
            </p>
          </BoxContent>
        </BottomCard>
      </div>
    </div>
  );
};

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 60px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Card = styled.div`
  align-items: center;
  justify-content: space-between; 
  margin-bottom: 25px;
  margin-top: 45px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  border-radius: 5px;
  
  flex: 1;  
  min-width: 280px;
  max-width: calc(33.33% - 70px);
  padding: 20px;
  

  &:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.25);
  }

  @media screen and (max-width: 768px) {
    max-width: 90%;
    width: calc(100% - 60px);
    margin-left: 10px;
    margin-right: 10px;
    height: auto;
`;

const BottomCard = styled.div`
  flex-grow: 1;
  height: 100px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
  padding: 30px;

  @media screen and (max-width: 768px) {
    margin-left: 0;
    margin-right: 0;
  }
`;

const CardTitle = styled.h4`
  font-size: 30px;
  margin-bottom: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
`;

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  margin-right: 5px;
`;

const BoxTitle = styled.h4`
  font-size: 30px;
  margin-bottom: 10px;
`;

const BoxContent = styled.div`
  p {
    margin-bottom: 10px;
  }
`;
const TopBox = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  height: 100px;
  margin-top: 10px;

  p {
    margin-top: 8px;
    padding-left: 10px;
  }
`;

const BottonBox = styled.div`
  border: 1px solid #e5e5e5;
  justify-content: space-between;
  border-radius: 5px;
  height: 510px;
  margin-top: 20px;

  padding: 10px;

  .p {
    margin-top: 5px;
  }
`;
const DownloadButton = styled(Button)`
  cursor: pointer;
  margin-top: auto;
  position: relative;
  margin-top: 15px;
  margin-left: auto;
  display: block;

  max-width: 100%;
`;

const StepFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
`;

const StepIndicator = styled.div`
  font-size: 16px;
  margin: 0 15px;
`;

const ContentWrapper = styled.div`
  height: 475.5px;
`;

const ArrowButton = styled.button`
  border: none;
  background: none;
  color: inherit;
  font-size: 20px;
  padding: 10px 20px;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export default ClientDownLoad;
