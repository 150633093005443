import React from "react";
import { useT } from "../../../../constants/i18n/useT";
import styled from "styled-components";
import { Price } from "../../../../@types/billing/price";

interface Props {
  price: Price;
  type: "CPU" | "GPU" | "STORAGE";
}

const MainPriceCard = ({ price, type }: Props) => {
  const t = useT();
  const TITLE = {
    CPU: t("billing.high_performance_cpu_servers", "고성능 CPU 서버"),
    GPU: t("billing.high_performance_gpu_servers", "고성능 GPU 서버"),
    STORAGE: t("billing.storage_service", "Storage Service"),
  };
  const IMAGE = {
    CPU: "/assets/main/price01.png",
    GPU: "/assets/main/price02.png",
    STORAGE: "/assets/main/price03.png",
  };
  const SERVICE_BOX = {
    CPU: "Workstation Service",
    GPU: "Workstation Service",
    STORAGE: "Storage Service",
  };
  const BOTTOM_INFO = {
    CPU: (
      <ul>
        <li>
          ·{" "}
          {t(
            "billing.we_provide_windows10_pro_as",
            "기본 OS로 Windows 10 Pro를 제공합니다."
          )}
        </li>
        <li>
          ·{" "}
          {t(
            "billing.we_provide_pcoip_server_and_client_connections",
            "고화질 서버 접속을 위해 PCoIP server 및 client 접속을 제공합니다."
          )}
        </li>
      </ul>
    ),
    GPU: (
      <ul>
        <li>
          ·{" "}
          {t(
            "billing.we_provide_windows10_pro_as",
            "기본 OS로 Windows 10 Pro를 제공합니다."
          )}{" "}
        </li>
        <li>
          ·{" "}
          {t(
            "billing.we_provide_pcoip_server_and_client_connections",
            "고화질 서버 접속을 위해 PCoIP server 및 client 접속을 제공합니다."
          )}
        </li>
      </ul>
    ),
    STORAGE: (
      <ul>
        <li>
          ·{" "}
          {t(
            "main.the_volume_for_the_root_disk",
            "Workstation의 루트 디스크로 이용되는 볼륨은 Workstation 사용에 관계없이 부과됩니다"
          )}
        </li>
        <li>
          ·{" "}
          {t(
            "main.the_volume_for_the_shared_storage",
            "공유 스토리지로 이용되는 볼륨은 workstation 사용에 관계 없이 부과됩니다."
          )}
        </li>
      </ul>
    ),
  };
  return (
    <Wrapper>
      <div className="icon_image">
        <img alt="img" src={IMAGE[type]} />
      </div>
      <Title>
        <div className="service_box">{SERVICE_BOX[type]}</div>
        <h5>{TITLE[type]}</h5>
        {type === "GPU" && (
          <dl>
            <dt>GPU</dt>
            <dd>{price.spec && price.spec.gpu && price.spec.gpu.name}</dd>
          </dl>
        )}
        {type !== "STORAGE" && (
          <>
            <dl>
              <dt>CPU</dt>
              <dd>{price.spec && price.spec.cpu && price.spec.cpu.name}</dd>
            </dl>
            <dl>
              <dt>RAM</dt>
              <dd>{price.spec && price.spec.ram && price.spec.ram.name}</dd>
            </dl>
            <dl>
              <dt>DISK</dt>
              <dd>{t("billing.user_selection", "사용자 선택")}</dd>
            </dl>
          </>
        )}
        {type === "STORAGE" && (
          <>
            <dl>
              <dt> {t("billing.type", "타입")}</dt>
              <dd>
                Premium(SSD) <br />
                {t("main.storage_volume", "스토리지 볼륨")}
              </dd>
            </dl>
            <dl>
              <dt>{t("billing.billing_rate", "과금 기준")}</dt>
              <dd>{price.size}</dd>
            </dl>
          </>
        )}
      </Title>
      <Content>
        <p className="price_title">
          {t("billing.pricing_per_krw", "요금 (원/분, 부가세 별도)")}
        </p>
        <dl className="price_list">
          <dt>{t("billing.time")}</dt>
          <dd>
            {price.unitPrice}
            {t("billing.krw", "원")}
          </dd>
        </dl>
        <dl className="price_list">
          <dt>1 {t("billing.year_contract", "년 계약")}</dt>
          <dd>
            {price.reserved1YearPrice}
            {t("billing.krw", "원")}
          </dd>
        </dl>
        <dl className="price_list">
          <dt>2 {t("billing.year_contract", "년 계약")}</dt>
          <dd>
            {price.reserved2YearPrice}
            {t("billing.krw", "원")}
          </dd>
        </dl>
      </Content>
      <Bottom>{BOTTOM_INFO[type]}</Bottom>
    </Wrapper>
  );
};

const Wrapper = styled.article`
  margin-top: 30px;
  width: 250px;
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  .icon_image {
    img {
      height: 120px;
    }
    text-align: center;
    background: #f2f5fe;
    padding: 10px;
    overflow: hidden;
  }
  &:nth-child(1) {
    margin-right: 5vw;
  }
  &:nth-child(2) {
    margin-left: 5vw;
  }
  &:nth-child(3) {
    margin-right: 2vw;
  }
  @media (min-width: 1024px) {
    width: 350px;

    .icon_image {
      img {
        height: 180px;
      }

      padding: 15px;
    }
    &:nth-child(1) {
      margin-right: 0;
    }
    &:nth-child(2) {
      margin-left: 0;
      margin-bottom: 150px;
    }
    &:nth-child(3) {
      margin-right: 0;
      margin-bottom: 50px;
    }
  }
`;
const Title = styled.section`
  padding: 10px 20px;
  position: relative;
  border-bottom: 1px dashed #ddd;
  font-family: "Noto Sans CJK KR", Arial, sans-serif;
  .service_box {
    position: absolute;
    left: 30px;
    top: -15px;
    line-height: 30px;
    border-radius: 5px;
    width: 180px;
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    color: var(--GridaBlue);
    box-shadow: 2px 2px 7px #ddd;
  }
  h5 {
    font-size: 16px;
    margin: 15px 0;
    color: #333;
    letter-spacing: -1px;
  }
  dl {
    font-size: 11px;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    dt {
      width: 60px;
      font-weight: 550;
      flex: none;
    }
    dd {
      color: #5e7290;
    }
  }
  @media (min-width: 1024px) {
    h5 {
      font-size: 18px;
      margin-top: 15px;
      margin-bottom: 15px;
    }
    dl {
      font-size: 12px;
      dt {
        width: 60px;
      }
    }
  }
`;
const Content = styled.section`
  padding: 1rem 1.2rem;
  dl {
    font-size: 0.8rem;
    margin-bottom: 7px;
    dt {
      padding-left: 1.2rem;
      background: url("/assets/main/price_check.png") left center no-repeat;
    }
  }
  .price_list {
    display: flex;
    justify-content: space-between;
  }
  .price_title {
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 10px;
  }
`;
const Bottom = styled.section`
  background: #f1f1f1;
  font-size: 10px;
  color: #666;
  padding: 10px 15px;
  color: #888;
  li {
    letter-spacing: -0.3px;
    padding-left: 7px;
    text-indent: -5px;
    line-height: 15px;
    margin-top: 3px;
  }

  @media (min-width: 1024px) {
    padding: 1rem 1.5rem;

    li:first-child {
      margin-top: 0px;
    }
    li {
      letter-spacing: -0.3px;
      padding-left: 7px;
      text-indent: -7px;
      line-height: 1rem;
      margin-top: 7px;
    }
  }
`;
export default MainPriceCard;
