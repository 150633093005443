import { css } from "styled-components";
import { Workstation } from "../../../../../@types/workstation/workstation";

export type StatusType =
  | "PowerOff"
  | "Booting"
  | "PowerOn"
  | "NotPowerOn"
  | "Running"
  | "ShuttingDown"
  | "NotPowerOff"
  | "Error";

export enum Status {
  "PowerOn" = "InfraWsStatus.PowerOn",
  "Upload" = "InfraWsStatus.Upload",
  "BmBooted" = "InfraWsStatus.BmBooted",
  "Download" = "InfraWsStatus.Download",
  "DwBooted" = "InfraWsStatus.DwBooted",
  "Running" = "InfraWsStatus.Running",
  "NotPowerOff" = "InfraWsStatus.NotPowerOff",
  "NotPowerOn" = "InfraWsStatus.NotPowerOn",
}

export const CARD_COLOR = {
  PowerOff: css`
    color: var(--PowerOff) !important;
  `,
  Booting: css`
    color: var(--Running) !important;
  `,
  PowerOn: css`
    color: var(--Booting) !important;
  `,
  NotPowerOn: css`
    color: var(--Error) !important;
  `,
  Running: css`
    color: var(--Running) !important;
  `,
  ShuttingDown: css`
    color: var(--ShuttingDown) !important;
  `,
  NotPowerOff: css`
    color: var(--NotPowerOff) !important;
  `,
  Error: css`
    color: var(--Error) !important;
  `,
};

export class CardService {
  static getColorStyle = (status: StatusType) => {
    return CARD_COLOR[status] || CARD_COLOR["Running"];
  };
  static getHeaderColor = (status: StatusType) => {
    return CARD_HEADER_COLOR[status] || CARD_HEADER_COLOR["Running"];
  };

  static getStatus = (workstation: Workstation): StatusType => {
    if (!workstation.status) return "Running";
    if (!workstation.status.cd) return "Running";
    return workstation.status.cd.split(".")[1] as StatusType;
  };
}

export const CARD_HEADER_COLOR = {
  PowerOff: css`
    background: var(--PowerOff);
    color: white;
  `,
  Booting: css`
    background: var(--Booting);
  `,
  PowerOn: css`
    background: var(--Booting);
  `,
  NotPowerOn: css`
    background: var(--NotPowerOff);
  `,
  Running: css`
    background: var(--Running);
  `,
  ShuttingDown: css`
    background: var(--ShuttingDown);
  `,
  NotPowerOff: css`
    background: var(--NotPowerOff);
  `,
  Error: css`
    background: var(--Error);
    color: white;
  `,
};
