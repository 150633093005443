import React, { useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { userState } from "../../../../../constants/recoil/user";

import { useT } from "../../../../../constants/i18n/useT";
import { useAlert } from "../../../../../components/alert/alert.service";
import { AuthService } from "../../../../../service/user/user.service";
import { WindowService } from "../../../../../service/common/window.service";
import { ModalProps } from "../../../../../components/modal";
import ModalPopup from "../../../../../components/modal/popup";

const Withdraw = (props: ModalProps) => {
  const [name, setName] = useState("");
  const [user] = useRecoilState(userState);
  const { setAlert } = useAlert();
  const onSubmit = () => {
    if (name !== user.userId)
      return setAlert(
        t("user.you_have_entered_the_wrong_id", "ID를 잘못 입력하셨습니다.")
      );
    AuthService.withdraw().then((res) => {
      if (res.state === "success") {
        alert(
          t(
            "user.you_have_been_withdrawn_from_membership",
            "회원 탈퇴되었습니다. 지금까지 그리다 서비스를 이용해 주셔서 감사합니다."
          )
        );
        WindowService.clearBrowser();
        WindowService.goMain();
        WindowService.reload();
        return;
      }
      setAlert(res.message);
    });
  };
  const t = useT();
  return (
    <ModalPopup
      size="sm"
      type="delete"
      buttonText={t("user.withdraw", "회원탈퇴")}
      {...props}
      onSubmit={onSubmit}
    >
      <Wrapper>
        <div className="user_pop_content">
          <div className="user_pop_con">
            <h5 id="red" className="pop_logout_title">
              {t(
                "user.would_you_like_to_withdraw_from_membership",
                "회원탈퇴 하시겠습니까?"
              )}
            </h5>
            <p className="pop_logout_text">
              {t(
                "user.you_will_no_longer_be_able_to_use",
                "m2m의 서비스를 이용할 수 없게 됩니다. "
              )}
              {t(
                "user.if_you_wish_to_withdraw_from_membership",
                "회원 탈퇴를 원하시면 회원 ID를 입력해주세요"
              )}
            </p>
            <input
              onChange={(e) => setName(e.target.value)}
              value={name}
              placeholder={user.userId}
            />
          </div>
        </div>
      </Wrapper>
    </ModalPopup>
  );
};
const Wrapper = styled.div`
  input {
    width: 270px;
    margin-left: 30px;
    padding-left: 10px;
  }
  .pop_logout_text {
    height: 50px;
  }
  .pop_logout_title {
    margin-bottom: 0;
  }
`;
export default Withdraw;
