import React, { useEffect } from "react";
import styled from "styled-components";
import ProjectListForm from "../../components/form.container.list.inner";
import { ProjectStorageUiData } from "../../../../../../../../@types/project/ui/storage";

import NameWithCheckBox from "../../components/item/name-with-checkbox";
import NotFoundData from "../../components/item/notfound";
import { useRecoilState } from "recoil";
import { projectUiDataState } from "../../../../../../../../constants/recoil/project";
import { Project } from "../../../../../../../../@types/project/project";
import { useT } from "../../../../../../../../constants/i18n/useT";

interface Props {
  rightStorages: ProjectStorageUiData[];
  setRightStorages: React.Dispatch<
    React.SetStateAction<ProjectStorageUiData[]>
  >;
  project?: Project;
}

const ProjectStorageRightComponent = ({
  rightStorages,
  setRightStorages,
  project,
}: Props) => {
  const [projectUiData] = useRecoilState(projectUiDataState);

  useEffect(() => {
    const projectStorages: ProjectStorageUiData[] = [];

    // if (project && project.unassignedStorages) {
    //   project.unassignedStorages.forEach((storage) => {
    if (projectUiData.unassignedStorages) {
      projectUiData.unassignedStorages.forEach((storage) => {
        let newUser = new ProjectStorageUiData(storage);
        projectStorages.push(newUser);
      });
    }
    setRightStorages(projectStorages);
    return () => setRightStorages([]);
  }, [projectUiData]);

  const handleCheckbox = (storage: ProjectStorageUiData) => () => {
    const index = rightStorages.findIndex(
      (s) => s.storageId === storage.storageId
    );
    const newStorageList = [...rightStorages];
    newStorageList[index].isChecked = !newStorageList[index].isChecked;
    setRightStorages(newStorageList);
  };
  const t = useT();
  return (
    <ProjectListForm>
      {rightStorages.map((storage: ProjectStorageUiData) => (
        <NameWithCheckBox
          key={storage.storageId}
          title={ProjectStorageUiData.fullName(storage)}
          isChecked={storage.isChecked}
          handleCheckbox={handleCheckbox(storage)}
        />
      ))}
      {rightStorages.length === 0 && (
        <NotFoundData
          title={t("project.there_is_no_storage", "Storage가 없습니다.")}
        />
      )}
    </ProjectListForm>
  );
};

const Wrapper = styled.div``;
export default ProjectStorageRightComponent;
