import { ProjectService } from "../project/project.service";
import { StorageUserUiData } from "../../@types/storage/ui/storage.user";
import { CompanyService } from "../user/company.service";
import { MemberService } from "../common/member.service";
import { ProjectCompanyUserResponse } from "../../@types/project/response";

interface Props {
  projectId?: number;
  companyGuid?: string;
  ownerUserGuid?: string;
  leftUsers: StorageUserUiData[];
}

export class StorageUpdateService {
  static getMembers = async ({
    projectId,
    companyGuid,
    leftUsers,
    ownerUserGuid = "",
  }: Props): Promise<StorageUserUiData[]> => {
    let users = [] as StorageUserUiData[];

    if (projectId) {
      users = await this.getUsersByProject(projectId, leftUsers);
      return users;
    }
    if (companyGuid) {
      users = await this.getUsersByCompany(companyGuid, leftUsers, ownerUserGuid);
      return users;
    }

    return users;
  };

  private static getUsersByCompany = async (
    companyGuid: string,
    leftUsers: StorageUserUiData[],
    ownerUserGuid: string
  ) => {
    const users: StorageUserUiData[] = [];
    await CompanyService.getUserList(companyGuid).then(res => {
      if (res.state === "success") {
        res.data.forEach((usr: ProjectCompanyUserResponse) => {
          if (usr.userGuid === ownerUserGuid) {
            return;
          }
          if (MemberService.isDuplicateInTarget(usr, leftUsers)) return;
          users.push(new StorageUserUiData(usr.userGuid, usr.fullName));
        });
      }
    });
    return users;
  };

  private static getUsersByProject = async (projectId: number, leftUsers: StorageUserUiData[]) => {
    let newRightUsers: StorageUserUiData[] = [];

    await ProjectService.getUserList(projectId).then(res => {
      if (res.state === "success") {
        res.data.map((user: any) => {
          if (MemberService.isDuplicateInTarget(user.user, leftUsers)) {
            return;
          }
          let newUser = new StorageUserUiData(user.user.userGuid, user.user.fullName);
          if (user.storageUserId) {
            newUser.storageUserId = user.storageUserId;
          }
          newRightUsers.push(newUser);
        });
      }
    });
    return newRightUsers;
  };
}
