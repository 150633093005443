import React from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { useT } from "../../../constants/i18n/useT";
import { storagePriceState } from "../../../constants/recoil/price";

interface Props {
  cpuTotal: number;
  gpuTotal: number;
  storageTotal: number;
  handle100Input: (value: string, setState: React.Dispatch<React.SetStateAction<number>>) => void;
  setStorageTotal: React.Dispatch<React.SetStateAction<number>>;
}

const BillingPriceStorage = ({
  cpuTotal,
  gpuTotal,
  storageTotal,
  handle100Input,
  setStorageTotal,
}: Props) => {
  const [storagePrice] = useRecoilState(storagePriceState);
  const t = useT();

  return (
    <Wrapper>
      <Title>
        <h5>{t("billing.storage_service", "Storage Service")}</h5>
        <div className="line_bar"></div>
      </Title>
      <PriceBox>
        <PriceEach>
          <label htmlFor="disk">{t("billing.disk_storage", "Disk Storage")}</label>
          <input value={(cpuTotal + gpuTotal) * 50} readOnly type="number" min={0} id="disk" />
          <span className="input_left_text">GB</span>
          <Price>
            <span id="blue">
              {storagePrice.monthBill &&
                ((storagePrice.monthBill * (cpuTotal + gpuTotal)) / 2).toLocaleString("ko-KR")}
            </span>{" "}
            {t("billing.krw", "원")} <br />
          </Price>
        </PriceEach>
        <PriceEach>
          <label htmlFor="storage">{t("billing.shared_storage", "공유 스토리지")}</label>
          <input
            value={storageTotal}
            onChange={e => handle100Input(e.target.value, setStorageTotal)}
            type="number"
            min={0}
            step={100}
            id="storage"
          />
          <span className="input_left_text">GB</span>
          <Price>
            <span id="blue">
              {storagePrice.monthBill &&
                (storagePrice.monthBill * (storageTotal / 100)).toLocaleString("ko-KR")}
            </span>{" "}
            {t("billing.krw", "원")}{" "}
          </Price>
        </PriceEach>
      </PriceBox>
    </Wrapper>
  );
};

const Wrapper = styled.article`
  padding: 20px;
  background: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  h5 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }
  .line_bar {
    flex-grow: 1;
    height: 3px;
    background: #333;
    margin-left: 10px;
  }
`;

const PriceBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const PriceEach = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  label {
    flex-shrink: 0;
    width: 140px;
    color: #5e7290;
    font-weight: bold;
  }

  input {
    width: 100px;
    height: 40px;
    padding: 0 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: right;
    font-size: 16px;
  }

  .input_left_text {
    margin-left: 10px;
    color: #5e7290;
    font-weight: bold;
  }
`;

const Price = styled.p`
  margin: 0;
  font-size: 17px;
  color: #333;
  .g_point_a {
    color: #007bff;
    font-weight: bold;
  }
`;

export default BillingPriceStorage;
