import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useT } from "../../../../../constants/i18n/useT";
import CircleProgress from "../../../../../components/loading/CircleProgress";
import ModalPopup from "../../../../../components/modal/popup";

interface Props {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentStorage: any;
  assignedWorkstations?: any[] | [];
  assignedStorages?: any[] | [];
  ws: any[];
  setWs: React.Dispatch<React.SetStateAction<any[]>>;
  setUser: React.Dispatch<React.SetStateAction<any[]>>;
}

const shareWorkstation = ({
  isOpen,
  setOpen,
  assignedWorkstations,
  assignedStorages,
  currentStorage,
  ws,
  setWs,
  setUser,
}: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedWorkstations, setSelectedWorkstations] = useState<string[]>([]);
  const [driveLetters, setDriveLetters] = useState<{ [key: string]: string }>({});

  const DriveLetter = ["Z", "Y", "X", "W", "V", "U", "T", "S", "R", "Q"];

  useEffect(() => {
    setUser([]);
    setWs([]);
  }, []);

  useEffect(() => {
    setIsLoading(false);

    if (currentStorage && currentStorage.workstations) {
      setSelectedWorkstations(currentStorage.workstations.map((ws: any) => ws.workstationId));
      const initialDriveLetters = currentStorage.workstations.reduce(
        (DriveLetter: any, ws: any) => {
          DriveLetter[ws.workstationId] =
            ws.driveLetter || getNextAvailableDriveLetter(Object.values(DriveLetter));
          return DriveLetter;
        },
        {}
      );
      setDriveLetters(initialDriveLetters);
    }
  }, [currentStorage]);

  const t = useT();

  const getNextAvailableDriveLetter = (usedLetters: string[] = []) => {
    for (let letter of DriveLetter) {
      if (!usedLetters.includes(letter)) {
        return letter;
      }
    }
    return "Z";
  };

  const handleCheckboxChange = (workstationId: string) => {
    setSelectedWorkstations(prevSelected => {
      if (prevSelected.includes(workstationId)) {
        const updatedDriveLetters = { ...driveLetters };
        delete updatedDriveLetters[workstationId];
        setDriveLetters(updatedDriveLetters);
        return prevSelected.filter(id => id !== workstationId);
      } else {
        const nextDriveLetter = getNextAvailableDriveLetter(Object.values(driveLetters));
        setDriveLetters(prevDriveLetters => ({
          ...prevDriveLetters,
          [workstationId]: nextDriveLetter,
        }));
        return [...prevSelected, workstationId];
      }
    });
  };

  const handleDriveLetterChange = (workstationId: string, driveLetter: string) => {
    setDriveLetters(prevDriveLetters => ({
      ...prevDriveLetters,
      [workstationId]: driveLetter,
    }));
  };

  const onSubmit = () => {
    const updatedWorkstations = selectedWorkstations.map(workstationId => ({
      workstationId,
      driveLetter: driveLetters[workstationId] || "Z",
    }));

    const existingWorkstationIds = currentStorage.workstations.map((ws: any) => ws.workstationId);
    const removedWorkstations = existingWorkstationIds
      .filter((id: any) => !selectedWorkstations.includes(id))
      .map((workstationId: any) => ({
        workstationId,
        driveLetter: driveLetters[workstationId] || "Z",
        useStateCd: "UseState.Deleted",
      }));

    const finalWorkstations = [
      ...updatedWorkstations.filter(st => !existingWorkstationIds.includes(st.workstationId)),
      ...removedWorkstations,
    ];
    setWs(finalWorkstations);
    setOpen(false);

    setSelectedWorkstations([]);
    setDriveLetters({});
  };

  return (
    <ModalPopup
      size="md"
      isOpen={isOpen}
      setOpen={setOpen}
      buttonText={t("common.save", "저장")}
      onSubmit={onSubmit}
    >
      <Wrapper>
        <div className="storage-name">{currentStorage.name}</div>
        <div className="label_box">
          <label>{t("workstation.workstation", "워크스테이션")}</label>
          <label>{t("storage.drive", "드라이브")}</label>
        </div>
        <div className="storage_box">
          {isLoading && (
            <div className="loading">
              <CircleProgress />
            </div>
          )}
          {!isLoading && (
            <>
              {assignedWorkstations && assignedWorkstations.length > 0 ? (
                assignedWorkstations.map(ws => (
                  <div key={ws.workstation.workstationId}>
                    <EachStorage>
                      <div className="checkbox">
                        <input
                          type="checkbox"
                          checked={selectedWorkstations.includes(ws.workstation.workstationId)}
                          onChange={() => handleCheckboxChange(ws.workstation.workstationId)}
                        />
                        <span>{ws.workstation.name}</span>
                      </div>
                      {selectedWorkstations.includes(ws.workstation.workstationId) && (
                        <select
                          className="select_drive"
                          value={
                            driveLetters[ws.workstation.workstationId] ||
                            getNextAvailableDriveLetter(Object.values(driveLetters))
                          }
                          onChange={e =>
                            handleDriveLetterChange(ws.workstation.workstationId, e.target.value)
                          }
                        >
                          {DriveLetter.map(letter => (
                            <option key={letter} value={letter}>
                              {letter}
                            </option>
                          ))}
                        </select>
                      )}
                    </EachStorage>
                  </div>
                ))
              ) : (
                <div className="no_storage">
                  {t("storage.noStoragesAssigned", "할당된 스토리지가 없습니다")}
                </div>
              )}
            </>
          )}
        </div>
      </Wrapper>
    </ModalPopup>
  );
};

const Wrapper = styled.div`
  margin: auto;
  margin-top: 10px;
  width: 100%;
  text-align: left;
  .storage-name {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .label_box {
    display: flex;
    justify-content: space-between;
    .description {
      font-size: 12px;
      color: #979797;
    }
    label {
      display: block;
      font-size: 14px;
      font-weight: bold;
      color: #979797;
      margin-left: 5px;
    }
  }

  .storage_box {
    width: 100%;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    margin-top: 10px;
    height: 200px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #d4d4d4;
      border-radius: 5px;
    }
    .loading {
      margin-top: 70px;
    }
  }
  .no_storage {
    padding: 10px;
    color: gray;
    font-size: 13px;
  }
`;

const EachStorage = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  height: 30px;
  .checkbox {
    display: flex;
    align-items: center;
    font-size: 12px;
    cursor: pointer;
    input {
      margin-right: 10px;
    }
  }
  select {
    width: 100px;
    height: 25px;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
  }

  .shared_icon {
    margin-left: 5px;
  }
`;

export default shareWorkstation;
