import React from "react";
import styled from "styled-components";
import { Link as ScrollLink } from "react-scroll";
import { useT } from "../../../constants/i18n/useT";
import { FaUserCircle, FaCoins, FaBell, FaHeadset } from "react-icons/fa"; // FaHistory 대신 FaCoins 사용

const UserInfoLeft = () => {
  const t = useT();

  return (
    <Wrapper>
      <TitleMenuItem>{t("common.user_info", "사용자 정보")}</TitleMenuItem>
      <Menu>
        <ScrollLink to="userInfo1" smooth={true} duration={500} offset={-100}>
          <MenuItem>
            <IconWrapper>
              <FaUserCircle />
            </IconWrapper>
            1. {t("common.my_information", "내정보")}
          </MenuItem>
        </ScrollLink>
        <ScrollLink to="userInfo2" smooth={true} duration={500} offset={-100}>
          <MenuItem>
            <IconWrapper>
              <FaCoins />
            </IconWrapper>
            2. {t("common.credit_history", "Credit 내역")}
          </MenuItem>
        </ScrollLink>
        <ScrollLink to="userInfo3" smooth={true} duration={500} offset={-100}>
          <MenuItem>
            <IconWrapper>
              <FaBell />
            </IconWrapper>
            3. {t("common.notifications_and_logs", "알림 및 로그")}
          </MenuItem>
        </ScrollLink>
        <ScrollLink to="userInfo4" smooth={true} duration={500} offset={-100}>
          <MenuItem>
            <IconWrapper>
              <FaHeadset />
            </IconWrapper>
            4. {t("common.customer_support", "고객센터")}
          </MenuItem>
        </ScrollLink>
      </Menu>
    </Wrapper>
  );
};

export default UserInfoLeft;

const Wrapper = styled.div`
  padding: 15px;
  background-color: #fafafa;
  border-radius: 10px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);

  @media (min-width: 650px) {
    position: fixed;
    top: 120px;
    left: 20px;
    width: 220px;
  }
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 12px;
  border-radius: 6px;
  font-size: 14px;
  color: #555;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: #e0e0e0;
    color: #017bfb;
  }
`;

const IconWrapper = styled.div`
  margin-right: 10px;
  font-size: 16px;
`;

const TitleMenuItem = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
`;
