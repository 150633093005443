import { MAX_LENGTH } from "../../constants/max-length";
import { REGEX } from "../../constants/regex";

export const InputService = {
  handleNumberInput: (
    value: string,
    setState: React.Dispatch<React.SetStateAction<string>>,
    MaxLength?: number
  ) => {
    if (value.length <= (MaxLength || 10) && Number(value) >= 0)
      setState(value);
  },

  handleMobileNumber: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event.target.value;

    if (value.length < MAX_LENGTH.MOBILE && Number(value) >= 0) {
      return value;
    }
    return value.slice(0, value.length - 1);
  },

  onlyKorEng: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const curValue = event.target.value;
    const notNum = REGEX.NOT_NUMBER;
    return curValue.replace(notNum, "");
  },

  pressEnter: (event: React.KeyboardEvent, onSubmit: () => void) => {
    if (event.key === "Enter") {
      onSubmit();
    }
  },

  pressTab: (event: React.KeyboardEvent, onSubmit: (b: boolean) => void) => {
    if (event.key === "Tab") {
      onSubmit(true);
    }
  },
};
