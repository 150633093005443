export class UnitSize {
  unit: string;
  size: number;
  byte: number;

  // Storage.SharedSize.10GB 형태로 들어오
  constructor(rootSize: string) {
    if (rootSize.split(".")[0] === "Storage") {
      rootSize = rootSize.split(".")[2];
    }
    const regex = /[^0-9.]/g;
    this.size = Number(rootSize.replace(regex, ""));
    this.unit = rootSize.slice(rootSize.replace(regex, "").length);
    this.byte = Number(this.getByte());
  }
  private getByte = () => {
    switch (this.unit) {
      case "B":
        return this.size;
      case "KB":
        return this.size * 2 ** 10;
      case "MB":
        return this.size * 2 ** 20;
      case "GB":
        return this.size * 2 ** 30;
      case "TB":
        return this.size * 2 ** 40;
      default:
        return 0;
    }
  };
}

export class UnitPercent {
  totalSize: UnitSize;
  usedSize: UnitSize;
  usedSizeToString: string;
  value: number;
  constructor(total?: string, used?: string) {
    this.totalSize = new UnitSize(total || "");
    this.usedSize = new UnitSize(used || "0.00B");
    this.value = this.usedSize.byte / this.totalSize.byte;
    this.usedSizeToString = `${this.usedSize.size} ${this.usedSize.unit}`;
    if (this.value > 0 && this.value < 0.01) this.value = 0.01;
  }
}
