import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Storage } from "../../../../../../@types/storage/storage";
import { useT } from "../../../../../../constants/i18n/useT";
import { userState } from "../../../../../../constants/recoil/user";
import StorageDelete from "../../../modal/delete/modal";
import StorageUpdateInfo from "../../../modal/update/modal";
import ShowPrivilege from "../../../modal/share/read-only-modal";
import STUpdateUserPage from "../../../modal/share/modal";
import MountWorkstation from "../../../modal/mount/modal";
import { CardDropdown } from "../../../../../../components/dropdown/card-dropdown";

interface Props {
  storage: Storage;
  getList: () => void;
  own: boolean;
}

const StorageMenu = ({ storage, getList, own }: Props) => {
  const [user] = useRecoilState(userState);
  const [popUpdate, setPopUpdate] = useState(false);
  const [popDelete, setPopDelete] = useState(false);
  const [popUpdatePriv, setPopUpdatePriv] = useState(false);
  const [popMountWs, setPopMountWs] = useState(false);

  const [popReadPriv, setPopReadPriv] = useState(false);

  const t = useT();
  if (own) {
    return (
      <>
        <CardDropdown type="storage">
          <CardDropdown.MenuElement
            title={t("common.update", "수정")}
            onClick={() => setPopUpdate(true)}
          />
          {user.company && (
            <CardDropdown.MenuElement
              title={t("common.share_setting", "공유")}
              onClick={() => setPopUpdatePriv(true)}
            />
          )}
          {/* individual 권한 수정 예정 */}
          {!user.company && (
            <CardDropdown.MenuElement
              title={t("common.share_setting", "공유")}
              onClick={() => setPopUpdatePriv(true)}
            />
          )}
          {/* {storage.project && storage.project.projectId && ( */}
          <CardDropdown.MenuElement
            title={t("storage.mount", "공유")}
            onClick={() => setPopMountWs(true)}
          />
          {/* )} */}
          <CardDropdown.MenuElement
            title={t("common.delete", "삭제")}
            onClick={() => setPopDelete(true)}
          />
        </CardDropdown>
        {popUpdate && (
          <StorageUpdateInfo
            getList={getList}
            storage={storage}
            setOpen={setPopUpdate}
            isOpen={popUpdate}
          />
        )}
        {popUpdatePriv && (
          <STUpdateUserPage
            getList={getList}
            projectId={storage.project && storage.project.projectId}
            storage={storage}
            setOpen={setPopUpdatePriv}
            isOpen={popUpdatePriv}
          />
        )}
        {popMountWs && (
          <MountWorkstation
            getList={getList}
            // projectId={storage.project && storage.project.projectId}
            storage={storage}
            setOpen={setPopMountWs}
            isOpen={popMountWs}
          />
        )}
        {popDelete && (
          <StorageDelete
            getList={getList}
            storage={storage}
            setOpen={setPopDelete}
            isOpen={popDelete}
          />
        )}
      </>
    );
  }

  return (
    <>
      <CardDropdown type="storage" status="notMine">
        <CardDropdown.MenuElement
          title={t("storage.view_shared", "공유 조회")}
          onClick={() => setPopReadPriv(true)}
        />
      </CardDropdown>
      {popReadPriv && (
        <ShowPrivilege storage={storage} setOpen={setPopReadPriv} isOpen={popReadPriv} />
      )}
    </>
  );
};

export default StorageMenu;
