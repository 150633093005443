import { ProjectUserUiData } from "../../../../@types/project/ui/user";
import { Project } from "../../../../@types/project/project";
import { useT } from "../../../../constants/i18n/useT";
import { User } from "../../../../@types/user/user";
import { CompanyService } from "../../../../service/user/company.service";
import { MemberService } from "../../../../service/common/member.service";
import { AuthService } from "../../../../service/user/user.service";
import { ProjectUserMode } from "../../../../@types/project/ui/user-search-mode";
import { ProjectCompanyUserResponse } from "../../../../@types/project/response";
import { useAlert } from "../../../../components/alert/alert.service";

interface Props {
  leftUsers: ProjectUserUiData[];
  setLeftUsers: React.Dispatch<React.SetStateAction<ProjectUserUiData[]>>;
  rightUsers: ProjectUserUiData[];
  setRightUsers: React.Dispatch<React.SetStateAction<ProjectUserUiData[]>>;
  searchUsers: ProjectUserUiData[];
  setSearchUsers: React.Dispatch<React.SetStateAction<ProjectUserUiData[]>>;
  sUser: User;
}
export const _projectMember = ({
  leftUsers,
  setLeftUsers,
  rightUsers,
  setRightUsers,
  searchUsers,
  setSearchUsers,
  sUser,
}: Props) => {
  const { setAlert } = useAlert();

  const t = useT();

  const isDuplicateInTarget = (
    user: { userGuid?: string },
    targetUsers: { userGuid?: string }[]
  ) => {
    const index = targetUsers.findIndex((u) => u.userGuid === user.userGuid);
    if (index === -1) return false;
    return true;
  };

  const toLeft = (mode: ProjectUserMode): void => {
    if (mode === ProjectUserMode.COMPANY) {
      let newLeftUsers = [...leftUsers];
      let newRightUsers = [...rightUsers];
      rightUsers.forEach((user) => {
        if (!user.isChecked) return;
        if (user.deleted) {
          const index = newLeftUsers.findIndex(
            (u) => u.projectUserId === user.projectUserId
          );
          newLeftUsers[index] = {
            ...user,
            isChecked: false,
            deleted: false,
          };
        } else {
          if (!isDuplicateInTarget(user, newLeftUsers)) {
            newLeftUsers.push({ ...user, isChecked: false });
          }
        }
        newRightUsers = newRightUsers.filter(
          (u) => u.userGuid !== user.userGuid
        );
      });
      setLeftUsers(newLeftUsers);
      setRightUsers(newRightUsers);
    } else {
      let newLeftUsers = [...leftUsers];

      searchUsers.forEach((user) => {
        if (!user.isChecked) return;
        if (!isDuplicateInTarget(user, newLeftUsers)) {
          newLeftUsers.push({ ...user, isChecked: false });
        } else {
          setAlert(
            t(
              "project.this_member_has_already_been_added",
              "이미 추가된 멤버입니다."
            )
          );
        }
      });
      setLeftUsers(newLeftUsers);
      setSearchUsers([]);
    }
  };

  const toRight = () => {
    let newLeftUsers = [...leftUsers];
    let newRightUsers = [...rightUsers];

    leftUsers.forEach((user) => {
      if (!user.isChecked) return;
      if (user.projectUserId) {
        newRightUsers.push({ ...user, isChecked: false, deleted: true });
        const index = newLeftUsers.findIndex(
          (u) => u.projectUserId === user.projectUserId
        );
        newLeftUsers[index] = {
          ...user,
          isChecked: false,
          deleted: true,
        };
      } else {
        newLeftUsers = newLeftUsers.filter((u) => u.userGuid !== user.userGuid);
        if (!isDuplicateInTarget(user, newRightUsers)) {
          newRightUsers.push({ ...user, isChecked: false });
        }
      }
      setLeftUsers(newLeftUsers);
      setRightUsers(newRightUsers);
    });

    setLeftUsers(newLeftUsers);
  };

  const hadlerUserToUiData = (project: Project): ProjectUserUiData[] => {
    let newUsers: ProjectUserUiData[] = [];
    if (project.users) {
      const users = project.users.map((user) => {
        const newUser = new ProjectUserUiData(user.user, user.projectRole);
        newUser.projectUserId = user.projectUserId;
        return newUser;
      });
      newUsers = users;
    } else newUsers = [];

    if (sUser.company) {
      setRightProjectUsersByCompany(
        sUser.company.companyGuid || "",
        newUsers,
        project
      );
    }
    return newUsers;
  };

  const setRightProjectUsersByCompany = (
    companyGuid: string,
    leftUserList: ProjectUserUiData[],
    project: Project
  ) => {
    CompanyService.getUserList(companyGuid).then((res) => {
      if (res.state === "success") {
        const users: ProjectUserUiData[] = [];
        res.data.forEach((_user: ProjectCompanyUserResponse) => {
          if (project.user && _user.userGuid === project.user.userGuid) {
            return;
          }
          if (MemberService.isDuplicateInTarget(_user, leftUserList)) return;
          users.push(new ProjectUserUiData(_user));
        });
        setRightUsers(users);
      }
    });
  };
  const setRightNoProjectUserByCompany = (company?: {
    companyGuid?: string;
  }) => {
    if (company && company.companyGuid)
      CompanyService.getUserList(company.companyGuid).then((res) => {
        if (res.state === "success") {
          const users: ProjectUserUiData[] = [];
          res.data.forEach((_user: ProjectCompanyUserResponse) => {
            if (_user.userGuid === sUser.userGuid) return;
            users.push(new ProjectUserUiData(_user));
          });
          setRightUsers(users);
        }
      });
  };
  return {
    toLeft,
    toRight,
    hadlerUserToUiData,
    setRightNoProjectUserByCompany,
  };
};

interface Props2 {
  userList: ProjectUserUiData[];
  setLeftUsers: React.Dispatch<React.SetStateAction<ProjectUserUiData[]>>;
}

export const _projectMemberLeft = ({ userList, setLeftUsers }: Props2) => {
  const handleCheckbox = (user: ProjectUserUiData) => {
    const index = MemberService.findIndex(user, userList);
    const newUserList = [...userList];
    newUserList[index].isChecked = !newUserList[index].isChecked;
    setLeftUsers(newUserList);
  };

  const handleRole = (user: ProjectUserUiData) => (type: string) => {
    const index = MemberService.findIndex(user, userList);
    const newUserList = [...userList];
    newUserList[index].projectRole = { cd: type };
    setLeftUsers(newUserList);
  };

  return {
    handleCheckbox,
    handleRole,
  };
};

interface Props3 {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  setRightUsers: React.Dispatch<React.SetStateAction<ProjectUserUiData[]>>;
  setNotFound: React.Dispatch<React.SetStateAction<boolean>>;
  ownerGuid?: string;
  setSearchUsers: React.Dispatch<React.SetStateAction<ProjectUserUiData[]>>;
}
export const _projectMemberRight = ({
  value,
  setValue,
  setRightUsers,
  setNotFound,
  ownerGuid,
  setSearchUsers,
}: Props3) => {
  const t = useT();
  const { setAlert } = useAlert();

  const getSearchUser = () => {
    setValue("");
    if (!value) return;
    setNotFound(false);
    AuthService.getUser("ID", value)
      .then((res) => {
        if (res.state === "success") {
          if (res.data.userGuid === ownerGuid) {
            setAlert(
              t(
                "project.project_owner_cannot_be_added_as_a_member",
                "Project Owner를 멤버로 추가할 수 없습니다."
              )
            );
            return setNotFound(true);
          }
          return setSearchUsers([new ProjectUserUiData(res.data)]);
        }
        setNotFound(true);
        setSearchUsers([]);
      })
      .catch(() => setNotFound(true));
  };
  return {
    getSearchUser,
    t,
  };
};
