import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ProjectComponentContainer from "../components/form.container";
import ProjectCenterButton from "../components/button/center.button";
import ProjectWSLeftComponent from "./left";
import ProjectWSRightComponent from "./right";
import { ProjectWorkstationUiData } from "../../../../../../../@types/project/ui/workstation";
import { ProjectStorageUiData } from "../../../../../../../@types/project/ui/storage";
import { Project } from "../../../../../../../@types/project/project";

import { useT } from "../../../../../../../constants/i18n/useT";

import { useAlert } from "../../../../../../../components/alert/alert.service";

interface Props {
  project?: Project;
  leftWorkstations: ProjectWorkstationUiData[];
  setLeftWorkstations: React.Dispatch<React.SetStateAction<ProjectWorkstationUiData[]>>;
  leftStorages: ProjectStorageUiData[];
  setLeftStorages: React.Dispatch<React.SetStateAction<ProjectStorageUiData[]>>;
  setSt: React.Dispatch<React.SetStateAction<any[]>>;
  st: any[];
  setWsUser: React.Dispatch<React.SetStateAction<any[]>>;
  wsUser: any[];
  setCurrentWorkstation: React.Dispatch<React.SetStateAction<any[]>>;
  currentWorkstation: any[];
  assignedWorkstations?: any[] | [];
  assignedStorages?: any[] | [];
  getList: () => void;
}

const ProjectWSComponent = ({
  project,
  leftWorkstations,
  setLeftWorkstations,
  leftStorages,
  setLeftStorages,
  setSt,
  st,
  setWsUser,
  wsUser,
  currentWorkstation,
  setCurrentWorkstation,
  assignedWorkstations,
  assignedStorages,
  getList,
}: Props) => {
  const [rightWorkstations, setRightWorkstations] = useState<ProjectWorkstationUiData[]>([]);

  const { setAlert } = useAlert();

  useEffect(() => {
    // console.log(assignedWorkstations, "ProjectWSComponent에서 받은 assignedWorkstations");
  }, [assignedWorkstations]);

  const t = useT();

  const isDuplicateInTarget = (
    target: { workstationId?: string },
    list: { workstationId?: string }[]
  ) => {
    const index = list.findIndex(u => u.workstationId === target.workstationId);
    if (index === -1) return false;
    return true;
  };
  const toLeft = () => {
    let newLeftWorkstations = [...leftWorkstations];
    let newRightWorkstations = [...rightWorkstations];
    rightWorkstations.forEach(workstation => {
      if (workstation.isChecked) {
        if (workstation.deleted) {
          const index = newLeftWorkstations.findIndex(
            w => w.workstationId === workstation.workstationId
          );
          newLeftWorkstations[index] = {
            ...workstation,
            isChecked: false,
            deleted: false,
          };
        } else {
          if (!isDuplicateInTarget(workstation, newLeftWorkstations)) {
            newLeftWorkstations.push({ ...workstation, isChecked: false });
          } else {
            setAlert(
              t("project.workstation_has_already_been_added", "이미 추가된 Workstation입니다.")
            );
          }
        }
        newRightWorkstations = newRightWorkstations.filter(
          w => w.workstationId !== workstation.workstationId
        );
      }
    });

    setLeftWorkstations(newLeftWorkstations);
    setRightWorkstations(newRightWorkstations);
  };

  const toRight = () => {
    let newLeftWorkstations = [...leftWorkstations];
    let newRightWorkstations = [...rightWorkstations];
    leftWorkstations.forEach(workstation => {
      if (!workstation.isChecked) return;
      if (workstation.projectWorkstationId) {
        newRightWorkstations.push({
          ...workstation,
          isChecked: false,
          deleted: true,
        });
        const index = newLeftWorkstations.findIndex(
          w => w.workstationId === workstation.workstationId
        );
        newLeftWorkstations[index] = {
          ...workstation,
          isChecked: false,
          deleted: true,
        };
      } else {
        if (!isDuplicateInTarget(workstation, newRightWorkstations)) {
          newRightWorkstations.push({ ...workstation, isChecked: false });
        } else {
          setAlert(
            t("project.workstation_has_already_been_added", "이미 추가된 Workstation입니다.")
          );
        }
        newLeftWorkstations = newLeftWorkstations.filter(
          w => w.workstationId !== workstation.workstationId
        );
      }
    });
    setLeftWorkstations(newLeftWorkstations);
    setRightWorkstations(newRightWorkstations);
  };

  useEffect(() => {
    if (project && project.workstations) {
      setLeftWorkstations(
        assignedWorkstations!.map(workstation => {
          const newWorkstation = new ProjectWorkstationUiData(workstation.workstation);
          if (workstation.projectWorkstationId) {
            newWorkstation.projectWorkstationId = workstation.projectWorkstationId;
          }
          return newWorkstation;
        })
      );
    }
  }, [assignedWorkstations]);

  useEffect(() => {}, [leftWorkstations]);

  return (
    <ProjectComponentContainer
      title={t("project.workstation_allocation", "Workstation 할당 및 설정") || ""}
      tooltip={
        t(
          "project.except_if_is_already_assigned_to_project_or_storage",
          "이미 project에 할당된 경우, 사용자들이 공유된 경우 제외"
        ) || ""
      }
    >
      <ProjectWSLeftComponent
        project={project}
        leftWorkstations={leftWorkstations}
        setLeftWorkstations={setLeftWorkstations}
        leftStorages={leftStorages}
        setLeftStorages={setLeftStorages}
        assignedWorkstations={assignedWorkstations}
        assignedStorages={assignedStorages}
        st={st}
        setSt={setSt}
        wsUser={wsUser}
        setWsUser={setWsUser}
        currentWorkstation={currentWorkstation}
        setCurrentWorkstation={setCurrentWorkstation}
        getList={getList}
      />
      <ProjectCenterButton toLeft={toLeft} toRight={toRight} />
      <ProjectWSRightComponent
        project={project}
        rightWorkstations={rightWorkstations}
        setRightWorkstations={setRightWorkstations}
      />
    </ProjectComponentContainer>
  );
};

const Wrapper = styled.div``;

export default ProjectWSComponent;
