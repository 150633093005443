import React, { useState } from "react";
import styled from "styled-components";
import { ConvertService } from "../../../../service/common/convert.service";
import { User } from "../../../../@types/user/user";
import UserCreditCharge from "./charge-credit/modal";
import UserContactModal from "../../contact/create/modal";
import Button from "../../../../components/button/Button";
import { useT } from "../../../../constants/i18n/useT";

interface Props {
  user: User;
}

const UserCreditRightBox = ({ user }: Props) => {
  const [popCharge, setPopCharge] = useState(false);
  const [popContact, setPopContact] = useState(false);
  const t = useT();
  return (
    <Wrapper>
      <h5>Credit</h5>
      <p className="credit">
        <strong>
          {user.creditBalance && ConvertService.setPrice(user.creditBalance.balance!)}
        </strong>
      </p>
      <div className="my_box_btn">
        <Button color="blue" onClick={() => setPopCharge(true)}>
          {t("billing.prepaid_recharge", "충전")}
        </Button>
      </div>
      {popCharge && (
        <UserCreditCharge
          setOpen={setPopCharge}
          setPopContact={setPopContact}
          isOpen={popCharge}
        />
      )}
      {popContact && (
        <UserContactModal isOpen={popContact} setOpen={setPopContact} />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.article`
  border: 1px solid #ddd;
  height: 150px;
  background: #fff;
  width: 100%;
  text-align: center;
  padding: 20px 0;
  .credit {
    height: 40px;
  }
  @media screen and (min-width: 768px) {
    width: 50%;
    border-left: none;
  }
`;

export default UserCreditRightBox;
