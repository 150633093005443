import React from "react";
import styled, { FlattenSimpleInterpolation, css } from "styled-components";
import Star from "../essential-star";

interface Props {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  label: string;
  placeholder?: string | null;
  maxLength?: number;
  essential?: boolean;
  isValid?: boolean;
  height?: "md" | "lg";
}
const HEIGHT = {
  md: css`
    height: 105px;
  `,
  lg: css`
    height: 180px;
  `,
};

const TextAreaLabel = ({
  label,
  value,
  onChange,
  essential,
  placeholder,
  maxLength,
  isValid,
  height,
}: Props) => {
  const DEFAULT_MAX_LENGTH = 50;
  const isError = () => {
    if (isValid === undefined) return "";
    if (value === "") return "";
    if (isValid) return "";
    return "error";
  };

  const sizeStyle = HEIGHT[height || "md"];

  return (
    <Wrapper sizeStyle={sizeStyle}>
      <label>
        {label}
        {essential && <Star />}
      </label>
      <textarea
        className={isError()}
        placeholder={placeholder || ""}
        value={value}
        onChange={onChange}
        maxLength={maxLength || DEFAULT_MAX_LENGTH}
      />
    </Wrapper>
  );
};

interface StyleProps {
  sizeStyle: FlattenSimpleInterpolation;
}

const Wrapper = styled.div<StyleProps>`
  margin: auto;
  margin-top: 10px;
  width: 100%;
  text-align: left;
  label {
    display: block;
    font-size: 14px;
    font-weight: bold;
    color: #979797;
    margin-left: 5px;
  }

  textarea {
    display: block;
    padding-top: 10px;
    width: 100%;
    ${(p) => p.sizeStyle}
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    margin-top: 10px;
    outline: none;
    resize: none;
    text-indent: 10px;
    &:focus {
      border: 1px solid #50a0fa;
    }
    &::placeholder {
    }
  }

  @media (min-width: 768px) {
    font-size: 11px;
  }
`;

export default TextAreaLabel;
