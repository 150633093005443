import React, { useEffect } from "react";
import LoginPage from "../page";
import { useRecoilState } from "recoil";
import { userState } from "../../../constants/recoil/user";
import { AuthService } from "../../../service/user/user.service";
import { TokenService } from "../../../service/common/token.service";
import { WindowService } from "../../../service/common/window.service";

// impersonation 기능을 위한 페이지로
// 이 페이지가 열리면 메시지 이벤트를 받아서
// 토큰을 받아서 로그인 처리를 한다.
const AdminLoginPage = () => {
  const [, setUser] = useRecoilState(userState);
  const PROTOCOL = window.location.protocol;
  const HOST_NAME = window.location.hostname;
  const ADMIN_PORT = process.env.REACT_APP_ADMIN_PAGE_PORT;

  const ADMIN_HOST = "10.10.1.165";
  const ADMIN_HOST2 = "10.10.1.232";

  const response = (event: any) => {
    if (
      event.origin === `${PROTOCOL}//${ADMIN_HOST}:${ADMIN_PORT}` ||
      event.origin === `${PROTOCOL}//${ADMIN_HOST2}:${ADMIN_PORT}`
    ) {
      const token = event.data;
      sessionStorage.setItem("x-access-token", token);
      const decoded = TokenService.parseJwt(token);
      setUser(new AuthService(decoded.userGuid, decoded.given_name, decoded.preferred_username));
      WindowService.goWorkstation();
    }
  };

  useEffect(() => {
    WindowService.clearBrowser();
    setUser(new AuthService());
    window.addEventListener("message", response);
    if (window.opener) {
      window.opener.postMessage("OK", `${PROTOCOL}//${ADMIN_HOST2}:${ADMIN_PORT}/user`);
      console.log(`post message to ${PROTOCOL}//${ADMIN_HOST}:${ADMIN_PORT}/user`);
    }
    return () => window.removeEventListener("message", response);
  }, []);

  return <LoginPage />;
};

export default AdminLoginPage;
