import React from "react";
import styled from "styled-components";

const Hourglass = () => {
  return (
    <Wrapper>
      <span className="hourglass">
        <span>
          <span />
          <span />
        </span>
      </span>
    </Wrapper>
  );
};

const Wrapper = styled.span`
  margin-left: 40px;
  margin-bottom: 5px;
  .hourglass {
    margin-left: 5px;
    width: 30px;
    transition: 1.5s ease-out;
    animation: fadeIn 2s;
  }
  .hourglass span {
    padding-left: 0 !important;
  }
  .hourglass > span {
    display: inline-block;
    position: relative;
    width: 15px;
    height: 10px;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-name: spin;
    animation-timing-function: linear;
  }
  .hourglass > span span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #e0efff;
    overflow: hidden;
    -webkit-clip-path: polygon(0 0, 100% 0, 50% 100%, 0 0);
    clip-path: polygon(0 0, 100% 0, 50% 100%, 0 0);
  }
  /* #hourglass_span{ padding-left: 0px;} */
  .hourglass span span:first-of-type {
    transform: translateY(-50%);
  }
  .hourglass span span:last-of-type {
    transform: translateY(50%) rotate(180deg);
  }
  .hourglass span span::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(164, 121, 65);
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-name: slide;
    animation-timing-function: linear;
  }
  .hourglass span span:last-of-type::before {
    animation-delay: -2s;
  }
  @keyframes slide {
    0% {
      transform: translateY(0%);
    }

    25% {
      transform: translateY(100%);
    }

    50% {
      transform: translateY(100%);
    }

    75% {
      transform: translateY(0%);
    }

    100% {
      transform: translateY(0%);
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    25% {
      transform: rotate(0deg);
    }

    50% {
      transform: rotate(180deg);
    }

    75% {
      transform: rotate(180deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

export default Hourglass;
