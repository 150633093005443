import { Project } from "../../../@types/project/project";

interface Props {
  isPublic: boolean;
  setIsPublic: React.Dispatch<React.SetStateAction<boolean>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
}
export const _projectInfo = ({
  isPublic,
  setIsPublic,
  setName,
  setDescription,
}: Props) => {
  const handleCheck = () => setIsPublic(!isPublic);

  const setProjectInfo = (project: Project) => {
    setName(project.name || "");
    setDescription(project.description || "");
    setIsPublic(project.isPublic || true);
  };

  return { handleCheck, setProjectInfo };
};

export const _projectInfoDate = ({
  setStartDate,
  setEndDate,
}: {
  setStartDate: React.Dispatch<React.SetStateAction<Date>>;
  setEndDate: React.Dispatch<React.SetStateAction<Date>>;
}) => {
  const setProjectDate = (project: Project) => {
    if (project.plannedStartDt) {
      setStartDate(new Date(project.plannedStartDt));
    }
    if (project.plannedEndDt) {
      setEndDate(new Date(project.plannedEndDt));
    }
  };
  return { setProjectDate };
};
