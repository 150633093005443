import React from "react";
import styled from "styled-components";
import { Element as ScrollElement } from "react-scroll";
import { useT, useCurrentLanguage } from "../../../constants/i18n/useT";

const Storage = () => {
  const t = useT();
  const currentLanguage = useCurrentLanguage();

  return (
    <Wrapper>
      <ScrollElement name="storage1">
        <Title>1. {t("common.storage_retrieval_and_creation", "스토리지 조회 및 생성")}</Title>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_storage1",
            `웹페이지 왼쪽 컬럼에 ‘Storage’ 버튼을 클릭하면 스토리지 페이지로 이동할 수 있습니다. 스토리지 페이지로 이동하면 생성된 스토리지가 카드 형식으로 표시됩니다. 생성된 스토리지가 없는 경우는 “No data found” 가 표시됩니다.`
          )}
        </Paragraph>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_storage2",
            `스토리지 생성을 위해선 ‘Storage 생성’ 버튼을 클릭합니다. 아래와 같은 윈도우가 팝업되며 스토리지 이름 입력하고 용량 및 FTP 활성화 여부를 선택하여 스토리지를 생성할 수 있습니다. FTP 활성화 옵션을 선택한 경우 FTP를 이용하여 외부와 스토리지 간의 파일 전송이 가능하며 FTP 활성화 옵션은 생성 이후에는 변경이 불가능합니다.`
          )}
        </Paragraph>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_storage3",
            "스토리지가 성공적으로 생성되면 다음과 같이 스토리지 카드가 표시됩니다."
          )}
        </Paragraph>

        <Spacer />

        <ImageWrapper>
          <BigImage
            src={`/assets/guide/${
              currentLanguage === "ko_KR" ? "create-st.png" : "en/en_create-st.png"
            }`}
            alt="create-st"
          />
          <BigImage
            src={`/assets/guide/${
              currentLanguage === "ko_KR" ? "create-st2.png" : "en/en_create-st2.png"
            }`}
            alt="create-st2"
          />
        </ImageWrapper>
      </ScrollElement>

      <Spacer />

      <ScrollElement name="storage2">
        <Title>2. {t("common.storage_settings", "스토리지 설정 변경")}</Title>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_storage4",
            `생성한 스토리지의 이름과 저장용량, 접근 권한을 수정할 수 있고 워크스테이션에 마운트 설정도 가능합니다.`
          )}
        </Paragraph>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_storage5",
            `이름과 저장용량을 수정하기 위해선 아래 그림과 같이 ‘수정’을 클릭합니다. 수정을 클릭하면 앞의 스토리지 추가 윈도우가 다시 팝업되며 이름과 용량을 변경할 수 있습니다. 용량은 증가시키는 가능하나 감소하는 것은 불가합니다.`
          )}
        </Paragraph>

        <Spacer />

        <ImageWrapper>
          <WidthImage
            src={`/assets/guide/${
              currentLanguage === "ko_KR" ? "edit-st.png" : "en/en_edit-st.png"
            }`}
            alt="edit-st"
          />
        </ImageWrapper>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_storage6",
            `스토리지 접근 권한 변경을 위해 ‘공유 설정’ 메뉴를 클릭하면 아래와 같은 공유 설정 윈도우가 팝업됩니다. 다른 사용자가 스토리지에 접근할 수 있도록 하기 위해 사용자 검색에서 사용자 ID를 입력합니다. ID와 일치하는 사용자가 존재하면 이름과 사용자 ID가 나타나고 선택 후 ‘<-’ 버튼을 클릭하면 해당 사용자가 스토리지 공유 사용자 리스트로 이동됩니다. ‘->’ 버튼을 공유 사용자 리스트에서 제외도 가능합니다. 이제 ‘저장’ 버튼을 누르면 현재 공유 설정이 실제로 적용됩니다. 저장 버튼을 누르지 않고 닫기 혹은 윈도우를 닫으면 공유 설정이 변경되지 않고 이전 설정으로 돌아가니 주의가 필요합니다.`
          )}
        </Paragraph>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_storage7",
            `공유 사용자가 등록이 되면 접근 권한을 설정합니다. 이를 위하여 왼쪽의 공유 사용자 박스에서 해당 사용자의 권한 리스트에서 적절한 권한을 선택합니다. ‘Read’와 ‘Read/Write’ 권한의 선택이 가능합니다.`
          )}
        </Paragraph>

        <ImageWrapper>
          <WidthImage
            src={`/assets/guide/${
              currentLanguage === "ko_KR" ? "share-st.png" : "en/en_share-st.png"
            }`}
            alt="share-st"
          />
        </ImageWrapper>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_storage8",
            `스토리지 카드에서 자신의 워크스테이션에 직접 마운트할 수도 있습니다. 이를 위하여 스토리지 카드에서 ‘마운트’를 클릭합니다. 이제 팝업된 마운트 설정 윈도우에서 워크스테이션과 스토리지를 마운트할 드라이브를 선택하고 ‘저장’ 버튼을 클릭합니다. 마운트에 성공하면 스토리지 카드에 해당 스토리지가 mount 된 워크스테이션의 정보가 표시됩니다.`
          )}
        </Paragraph>

        <ImageWrapper>
          <WidthImage
            src={`/assets/guide/${
              currentLanguage === "ko_KR" ? "mount-st.png" : "en/en_mount-st.png"
            }`}
            alt="mount-st"
          />
        </ImageWrapper>

        <ImageWrapper>
          <BigImage
            src={`/assets/guide/${
              currentLanguage === "ko_KR" ? "mount-st2.png" : "en/en_mount-st2.png"
            }`}
            alt="mount-st2"
          />
        </ImageWrapper>
      </ScrollElement>

      <Spacer />

      <ScrollElement name="storage3">
        <Title>3. {t("common.storage_deletion", "스토리지 삭제")}</Title>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_storage9",
            `스토리지 오른쪽 위의 곳에 마우스를 가져가고 ‘삭제’ 버튼을 클릭하면 스토리지 삭제가 가능합니다. 스토리지를 삭제하게 되면 저장된 데이터가 영구히 삭제되기 때문에 주의가 필요합니다.`
          )}
        </Paragraph>

        <Spacer />

        <ImageWrapper>
          <WidthImage
            src={`/assets/guide/${
              currentLanguage === "ko_KR" ? "delete-st.png" : "en/en_delete-st.png"
            }`}
            alt="delete-st"
          />
        </ImageWrapper>
      </ScrollElement>
    </Wrapper>
  );
};

export default Storage;

const Wrapper = styled.div`
  padding: 25px;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 20px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

const Paragraph = styled.p`
  font-size: 16px;
  line-height: 1.5;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 13px;
  }
`;

const Spacer = styled.div`
  height: 20px;

  @media (max-width: 768px) {
    height: 15px;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    margin-top: 30px;
    margin-bottom: 40px;
  }

  @media (max-width: 480px) {
    margin-top: 20px;
    margin-bottom: 30px;
  }
`;

const ResponsiveImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const BigImage = styled(ResponsiveImage)`
  height: 400px;

  @media (max-width: 768px) {
    height: 300px;
  }

  @media (max-width: 480px) {
    height: 200px;
  }
`;

const WidthImage = styled(ResponsiveImage)`
  width: 800px;

  @media (max-width: 1024px) {
    width: 600px;
  }

  @media (max-width: 768px) {
    width: 400px;
  }

  @media (max-width: 480px) {
    width: 300px;
  }
`;

const HeightImage = styled(ResponsiveImage)`
  height: 650px;

  @media (max-width: 1024px) {
    height: 500px;
  }

  @media (max-width: 768px) {
    height: 400px;
  }

  @media (max-width: 480px) {
    height: 300px;
  }
`;

const OriginalImage = styled(ResponsiveImage)``;
