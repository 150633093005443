import React, { useState } from "react";
import styled from "styled-components";
import UserContactDetail from "../detail/modal";
import { useT } from "../../../../constants/i18n/useT";
import { Answer, Contact } from "../../../../@types/user/contact";

interface Props {
  article: Contact;
  idx: number;
}

const UserContactEach = ({ article, idx }: Props) => {
  const [popDetail, setPopDetail] = useState(false);
  const t = useT();

  const getStatus = (answers: Answer[]) => {
    if (answers.length > 0)
      return `🟢 ${t("user.answer_complete", "답변완료")}`;
    else return `🟡 ${t("user.in_progress", "답변대기")}`;
  };
  return (
    <>
      <Wrapper onClick={() => setPopDetail(true)}>
        <td>{idx + 1}</td>
        <td id="left">{article.title}</td>
        <td>{article.createdDt ? article.createdDt.split(" ")[0] : "-"}</td>
        <td>
          <div className="state_table">{getStatus(article.answers!)} </div>
        </td>
      </Wrapper>
      {popDetail && (
        <UserContactDetail
          isOpen={popDetail}
          contact={article}
          setOpen={setPopDetail}
        />
      )}
    </>
  );
};
const Wrapper = styled.tr`
  background-color: #fff;
  cursor: pointer;
  border: 0.5px solid #efefef;
  &:hover {
    background-color: #efefef8a;
  }
  td {
    padding: 15px 0;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .state_table {
    width: 70px;
    margin: auto;
    text-align: start;
  }
  #left {
    text-align: start;
    padding-left: 5px;
  }
`;
export default UserContactEach;
