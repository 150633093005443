import { CircularProgress } from "@mui/material";
import React from "react";
import styled from "styled-components";

interface Props {
  size?: "big" | "medium" | "small";
}

enum Size {
  big = 60,
  medium = 40,
  small = 25,
}

const CircleProgress = ({ size }: Props) => {
  const getSize = () => {
    if (size) return `${Size[size]}px`;
    else return `${Size.small}px`;
  };
  return (
    <Wrapper className={size === "big" ? "big_size" : ""}>
      <CircularProgress size={getSize()} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  &.big_size {
    align-items: center;
  }
`;

export default CircleProgress;
