import React, { useCallback } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useT } from "../../constants/i18n/useT";

const MyPageTab = () => {
  const { pathname } = useLocation();
  const t = useT();

  const checkActive = useCallback(
    (path: string) => {
      return pathname === path ? "active" : "";
    },
    [pathname]
  );
  const navigate = useNavigate();
  const goto = (path: string) => {
    navigate(path);
  };

  return (
    <Wrapper>
      <NavBar>
        <ul>
          <li onClick={() => goto("/mypage/info")} className={checkActive("/mypage/info")}>
            {t("common.my_information", "내 정보")}
          </li>
          <li onClick={() => goto("/mypage/credit")} className={checkActive("/mypage/credit")}>
            {t("common.credit_history", "Credit 내역")}
          </li>
          <li
            onClick={() => goto("/mypage/user-alarms")}
            className={checkActive("/mypage/user-alarms")}
          >
            {t("common.noti_log", "알림 및 로그")}
          </li>
          <li className={checkActive("/mypage/contact")} onClick={() => goto("/mypage/contact")}>
            {t("common.customer_service", "고객센터")}
          </li>
        </ul>
      </NavBar>
    </Wrapper>
  );
};
const NavBar = styled.nav`
  margin: 0 auto;
  margin-top: 45px;
  width: 95%;
  ul {
    display: flex;
    justify-content: space-between;
    /* padding: 0 5px; */
    max-width: 500px;
    width: 100%;
    li {
      width: 33.3%;
      text-align: center;
      padding: 8px 0;
      border-radius: 5px 5px 0 0;
      background: #fff;
      box-shadow: 0px 0px 5px rgba(122, 122, 122, 0.1);
      cursor: pointer;
      color: #666666;
      font-size: 0.8rem;
    }
  }
  .active {
    background: var(--GridaBlue);
    color: #ffffff;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    border-bottom: 2px solid var(--GridaBlue);
    ul {
    }
  }
  @media screen and (min-width: 1024px) {
    border-bottom: 2px solid var(--GridaBlue);
    ul {
      li {
        width: 200px;
      }
    }
  }
`;
const Wrapper = styled.div`
  width: 100%;
  @media screen and (min-width: 768px) {
    width: auto;
  }
`;

export default MyPageTab;
