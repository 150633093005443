import { Workstation } from "../../workstation/workstation";
export class ProjectWorkstationUiData {
  workstationId?: string;
  name?: string;
  isChecked: boolean = false;
  deleted?: boolean = false;
  projectWorkstationId?: number;
  user?: { fullName?: string };
  owner?: { fullName?: string };
  shareWith?: any;
  storages?: any;

  constructor(
    workstation: Workstation,
    storages?: Array<{
      storageId?: any;
      driveLetter?: any;
      useStateCd?: "UseState.Deleted" | undefined;
    }>,
    shareWith?: Array<{ userGuid: string }>
  ) {
    this.workstationId = workstation.workstationId;
    this.name = workstation.name;
    if (workstation.owner) {
      this.user = { fullName: workstation.owner.fullName };
    }

    if (workstation.owner) {
      this.owner = { fullName: workstation.owner.fullName };
    }

    this.shareWith = workstation.shareWith;
    this.storages = workstation.storages;
  }

  static fullName(workstation: ProjectWorkstationUiData) {
    if (workstation.user) {
      // return `${workstation.name} (${workstation.user.fullName})`;
      return `${workstation.name}`;
    }
    return workstation.name;
  }
}
