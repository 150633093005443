import React, { useState, useEffect } from "react";
import ProjectListForm from "../../components/form.container.list.inner";
import { ProjectStorageUiData } from "../../../../../../../../@types/project/ui/storage";
import { ProjectWorkstationUiData } from "../../../../../../../../@types/project/ui/workstation";
import NameWithCheckBox from "../../components/item/name-with-checkbox";
import styled, { keyframes } from "styled-components";
import { Button } from "@mui/material";
import { useT } from "../../../../../../../../constants/i18n/useT";
import { Tooltip } from "@mui/material";
import StorageUpdateUser from "../../../../../../../storage/components/modal/share/modal";
import { Project } from "../../../../../../../../@types/project/project";
import ShareWorkstation from "../../../../../../../storage/components/modal/share/shareWorstation";
import ShareStorageUser from "../../../../update/modal/shareStorageUser";

interface Props {
  leftStorages: ProjectStorageUiData[];
  setLeftStorages: React.Dispatch<React.SetStateAction<ProjectStorageUiData[]>>;
  leftWorkstations: ProjectWorkstationUiData[];
  setLeftWorkstations: React.Dispatch<React.SetStateAction<ProjectWorkstationUiData[]>>;
  handlePublic: () => void;
  project?: any;
  getList: () => void;
  assignedWorkstations?: any[] | [];
  assignedStorages?: any[] | [];
  ws: any[];
  setWs: React.Dispatch<React.SetStateAction<any[]>>;
  user: any[];
  setUser: React.Dispatch<React.SetStateAction<any[]>>;
  currentStorage: any[];
  setCurrentStorage: React.Dispatch<React.SetStateAction<any[]>>;
}

const ProjectStorageLeftComponent = ({
  leftStorages,
  setLeftStorages,
  leftWorkstations,
  setLeftWorkstations,
  handlePublic,
  project,
  getList,
  assignedWorkstations,
  assignedStorages,
  setWs,
  ws,
  setUser,
  user,
  setCurrentStorage,
  currentStorage,
}: Props) => {
  const t = useT();

  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownIndex, setDropdownIndex] = useState<number | null>(null);
  const [popUpdateUser, setPopUpdateUser] = useState(false);
  const [popUpdateWS, setPopUpdateWS] = useState(false);
  // const [currentStorage, setCurrentStorage] = useState<any>();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (showDropdown && !(event.target as HTMLElement).closest(".dropdown-menu")) {
        setShowDropdown(false);
        setDropdownIndex(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);

  const handleContextMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    storage: any,
    index: number
  ) => {
    event.preventDefault();
    setCurrentStorage(storage);
    setShowDropdown(true);
    setDropdownIndex(index);
  };

  const handleCheckbox = (storage: ProjectStorageUiData) => () => {
    const newStorages = leftStorages.map(_storage => {
      if (_storage.storageId === storage.storageId) {
        return {
          ...storage,
          isChecked: !_storage.isChecked,
        };
      }
      return _storage;
    });
    setLeftStorages(newStorages);
  };

  const getStorageName = (storage: ProjectStorageUiData) => {
    let result = (
      <>
        {storage.shareWith &&
          storage.shareWith.map((stData: any) => {
            return <p key={stData.userGuid}>{stData.fullName}</p>;
          })}
      </>
    );
    return result;
  };

  const getWorkstationName = (storage: ProjectStorageUiData) => {
    let result = (
      <>
        {storage.workstations &&
          storage.workstations.map((WSData: any) => {
            return <p key={WSData.workstationId}>{WSData.name}</p>;
          })}
      </>
    );
    return result;
  };

  return (
    <Wrapper>
      <ProjectListForm>
        {leftStorages.map(
          (storage: ProjectStorageUiData, index: number) =>
            !storage.deleted && (
              <NameWithCheckBoxWrapper key={storage.storageId}>
                <NameWithCheckBox
                  key={storage.storageId}
                  title={ProjectStorageUiData.fullName(storage)}
                  isChecked={storage.isChecked}
                  handleCheckbox={handleCheckbox(storage)}
                  handleContextMenu={e => handleContextMenu(e, storage, index)}
                  isPublic={storage.isPublic}
                />
                {((storage.workstations && storage.workstations.length > 0) ||
                  (storage.shareWith && storage.shareWith.length > 0)) && (
                  <WSInfoWapper>
                    (
                    {storage.shareWith.length > 0 && (
                      <WSInfoContent>
                        {t("project.user", "사용자")} :
                        <Tooltip title={getStorageName(storage)}>
                          <BoldUnderline>{storage.shareWith.length}</BoldUnderline>
                        </Tooltip>
                      </WSInfoContent>
                    )}
                    {storage.workstations.length > 0 && (
                      <WSInfoContent>
                        {t("project.mount", "마운트")} :
                        <Tooltip title={getWorkstationName(storage)}>
                          <BoldUnderline>{storage.workstations.length}</BoldUnderline>
                        </Tooltip>
                      </WSInfoContent>
                    )}
                    )
                  </WSInfoWapper>
                )}

                {showDropdown && dropdownIndex === index && (
                  <DropdownMenu className="dropdown-menu">
                    <DropdownContent onClick={() => setPopUpdateUser(true)}>
                      {t("project.user_setting", "사용자 설정")}
                    </DropdownContent>
                    <DropdownContent onClick={() => setPopUpdateWS(true)}>
                      {t("project.mount_setting", "마운트 설정")}
                    </DropdownContent>
                  </DropdownMenu>
                )}
              </NameWithCheckBoxWrapper>
            )
        )}
      </ProjectListForm>

      <Text>(* Public)</Text>
      <ButtonWrap>
        <Button onClick={handlePublic} variant="outlined" color="info" size="small">
          {t("project.designate_release_public", "Public 지정/해제")}
        </Button>
      </ButtonWrap>

      {/* {popUpdateUser && (
        <StorageUpdateUser
          isOpen={popUpdateUser}
          storage={currentStorage}
          setOpen={setPopUpdateUser}
          projectId={project && project.projectId}
          projectName={project && project.name}
          getList={getList}
        />
      )} */}

      {popUpdateUser && (
        <ShareStorageUser
          isOpen={popUpdateUser}
          storage={currentStorage}
          setOpen={setPopUpdateUser}
          projectId={project.projectId}
          projectName={project.name}
          getList={getList}
          user={user}
          setUser={setUser}
          setWs={setWs}
        />
      )}

      {popUpdateWS && (
        <ShareWorkstation
          isOpen={popUpdateWS}
          setOpen={setPopUpdateWS}
          assignedWorkstations={assignedWorkstations}
          assignedStorages={assignedStorages}
          currentStorage={currentStorage}
          ws={ws}
          setWs={setWs}
          setUser={setUser}
        />
      )}
    </Wrapper>
  );
};
const Wrapper = styled.div``;

const NameWithCheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  &:hover {
    background-color: #f8f8f8;
  }
`;

const WSInfoWapper = styled.div`
  width: 100%;
  font-size: 11px;
  margin-right: 3px;
`;

const WSInfoContent = styled.span`
  margin-right: 6px;
`;

const BoldUnderline = styled.span`
  font-weight: bold;
  text-decoration: underline;
  margin-left: 5px;
`;

const dropdownAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 2000;
  width: 120px;
  top: 80%;
  left: 11%;
  transform: translateY(5px);
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  animation: ${dropdownAnimation} 0.3s ease-out forwards;
`;

const DropdownContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f8f8f8;
  }

  &:last-child {
    border-bottom: none;
  }
`;

const Text = styled.div`
  padding: 10px;
  font-size: 12px;
`;

const ButtonWrap = styled.div`
  padding: 10px;
`;

export default ProjectStorageLeftComponent;
