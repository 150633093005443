import React, { useEffect } from "react";
import styled from "styled-components";

import { useT } from "../../../constants/i18n/useT";
import InputLabel from "../../../components/input/input-label/input";
import { MAX_LENGTH } from "../../../constants/max-length";
import LoginFailed from "../../../components/input/signup/text-alert";
import RoundButton from "../../../components/button/round-button";
import { ValidType } from "../../../@types/type/valid";
import { InputService } from "../../../service/common/input.service";

interface Props {
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  validPassword: ValidType;
  password2: string;
  setPassword2: React.Dispatch<React.SetStateAction<string>>;
  validPassword2: ValidType;
  s3Message: string;
  checkPassword: () => void;
  checkPassword2: () => void;
  onSubmit: () => void;
}
const FindPassword4 = ({
  password,
  setPassword,
  validPassword,
  password2,
  setPassword2,
  validPassword2,
  s3Message,
  checkPassword,
  checkPassword2,
  onSubmit,
}: Props) => {
  const t = useT();

  useEffect(() => checkPassword(), [password]);
  useEffect(() => checkPassword2(), [password2]);
  useEffect(() => {
    console.log(validPassword, validPassword2);
  }, [validPassword]);
  return (
    <Wrapper>
      <Body>
        <InputLabel
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          label={t("user.password", "비밀번호")}
          placeholder={
            t(
              "user.please_enter_a_password_with_letters",
              "대문자, 소문자, 숫자, 특수문자를 포함해 8~16자리 이내로 입력해주세요."
            ) || ""
          }
          maxLength={MAX_LENGTH.PASSWORD}
          type="password"
        />
        <LoginFailed>
          {(validPassword !== ValidType.VALID &&
            validPassword !== ValidType.EMPTY &&
            t(
              "user.please_enter_a_password_with_letters",
              "대문자, 소문자, 숫자, 특수문자(@$!%*#?&) 를 포함해 8~16자리 이내로 입력해주세요."
            )) ||
            ""}
        </LoginFailed>

        <InputLabel
          value={password2}
          onChange={(e) => setPassword2(e.target.value)}
          label={t("user.password", "비밀번호")}
          placeholder={
            t(
              "user.please_verify_the_password_you_want_to_change",
              "바꾸실 패스워드를 확인해 주세요"
            ) || ""
          }
          maxLength={MAX_LENGTH.PASSWORD}
          type="password"
          onKeyDown={(e) => InputService.pressEnter(e, onSubmit)}
        />
        {validPassword2 == ValidType.INVALID && (
          <LoginFailed>
            {(validPassword2 == ValidType.INVALID &&
              t(
                "user.the_password_entered_does_not_match",
                "비밀번호가 일치하지 않습니다."
              )) ||
              ""}
          </LoginFailed>
        )}
        {validPassword2 != ValidType.INVALID && !!s3Message.length && (
          <LoginFailed>{s3Message}</LoginFailed>
        )}
      </Body>
      <div className="login_btn">
        <RoundButton onClick={() => onSubmit()} color="blue" size="full_lg">
          {t("user.reset_password", "패스워드 변경")}
        </RoundButton>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  .login_btn {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
`;
const Body = styled.div`
  height: 170px;
`;
export default FindPassword4;
