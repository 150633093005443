import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useT } from "../../../../../constants/i18n/useT";
import CircleProgress from "../../../../../components/loading/CircleProgress";
import ModalPopup from "../../../../../components/modal/popup";
import { StorageService } from "../../../../../service/storage/storage.service";
import { WorkstationService } from "../../../../../service/workstation/workstation.service";
import { useAlert } from "../../../../../components/alert/alert.service";

interface Props {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  storage: any;
  getList: () => void;
}

const MountWorkstation = ({ isOpen, setOpen, storage, getList }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [workstations, setWorkstations] = useState<any[]>([]);
  const [selectedWorkstations, setSelectedWorkstations] = useState<string[]>([]);
  const [driveLetters, setDriveLetters] = useState<{ [key: string]: string }>({});
  const [availableLetters, setAvailableLetters] = useState<{ [key: string]: string[] }>({});
  const [initialState, setInitialState] = useState<{
    workstations: string[];
    driveLetters: { [key: string]: string };
  }>({ workstations: [], driveLetters: {} });

  const DriveLetter = ["Z", "Y", "X", "W", "V", "U", "T", "S", "R", "Q"];

  const { setAlert, setSuccess } = useAlert();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const mountedRes = await StorageService.getMountedWorkstations(storage.storageId);
        const mountableRes = await StorageService.getMountableWorkstations(storage.storageId);

        const mountedWorkstations = mountedRes.map((ws: any) => ({
          ...ws,
          isChecked: true,
        }));
        const mountableWorkstations = mountableRes.map((ws: any) => ({
          ...ws,
          isChecked: false,
        }));

        const allWorkstations = [...mountedWorkstations, ...mountableWorkstations];

        setWorkstations(allWorkstations);
        setSelectedWorkstations(mountedWorkstations.map((ws: any) => ws.workstationId));

        const initialDriveLetters = mountedWorkstations.reduce((acc: any, ws: any) => {
          acc[ws.workstationId] = ws.driveLetter;
          return acc;
        }, {});

        setDriveLetters(initialDriveLetters);

        setInitialState({
          workstations: mountedWorkstations.map((ws: any) => ws.workstationId),
          driveLetters: initialDriveLetters,
        });

        for (const ws of mountedWorkstations) {
          await handleDriveLetterFocus(ws.workstationId, allWorkstations, initialDriveLetters);
        }

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching workstations:", error);
      }
    };

    fetchData();
  }, [storage.storageId]);

  const t = useT();

  const handleCheckboxChange = async (id: string) => {
    setSelectedWorkstations(prev => {
      if (prev.includes(id)) {
        return prev.filter(wsId => wsId !== id);
      } else {
        const newSelection = [...prev, id];
        handleDriveLetterFocus(id, workstations, driveLetters);
        return newSelection;
      }
    });
  };

  const handleDriveLetterChange = (id: string, letter: string) => {
    setDriveLetters(prev => ({
      ...prev,
      [id]: letter,
    }));
  };

  const handleDriveLetterFocus = async (
    id: string,
    allWorkstations: any[],
    driveLettersState: { [key: string]: string }
  ) => {
    try {
      const mountedStorage = await WorkstationService.mountWorkstation(id);
      const availableLetters = getAvailableLetters(
        mountedStorage,
        id,
        allWorkstations,
        driveLettersState
      );

      setAvailableLetters(prev => ({
        ...prev,
        [id]: availableLetters,
      }));

      setDriveLetters(prev => ({
        ...prev,
        [id]: driveLettersState[id] || availableLetters[0],
      }));
    } catch (error) {
      console.error("Error mounting workstation:", error);
    }
  };

  const getAvailableLetters = (
    mountedStorage: any[],
    currentId: string,
    allWorkstations: any[],
    driveLettersState: { [key: string]: string }
  ) => {
    const usedLetters = mountedStorage.map(storage => storage.driveLetter);
    const currentLetter = driveLettersState[currentId];

    let availableLetters = DriveLetter.filter(letter => !usedLetters.includes(letter));

    if (currentLetter && !availableLetters.includes(currentLetter)) {
      availableLetters = [currentLetter, ...availableLetters];
    }

    return availableLetters;
  };

  const onSubmit = async () => {
    const newSelections = selectedWorkstations
      .filter(wsId => !initialState.workstations.includes(wsId))
      .map(wsId => ({
        workstationId: wsId,
        driveLetter: driveLetters[wsId],
      }));

    const unmountedWorkstations = initialState.workstations.filter(
      wsId => !selectedWorkstations.includes(wsId)
    );

    const driveLetterChanges = selectedWorkstations
      .filter(
        wsId =>
          initialState.workstations.includes(wsId) &&
          driveLetters[wsId] !== initialState.driveLetters[wsId]
      )
      .map(wsId => ({
        workstationId: wsId,
        driveLetter: driveLetters[wsId],
      }));

    try {
      if (unmountedWorkstations.length > 0) {
        for (const wsId of unmountedWorkstations) {
          await StorageService.unmountWorkstations(storage.storageId, wsId);
        }
      }

      if (newSelections.length > 0) {
        await StorageService.mountWorkstations(storage.storageId, newSelections);
      }

      if (driveLetterChanges.length > 0) {
        for (const change of driveLetterChanges) {
          await StorageService.updateDriveLetter(
            storage.storageId,
            change.workstationId,
            change.driveLetter
          );
        }
      }

      setSuccess(storage.name + t("storage.has_been_modified", "이(가) 수정되었습니다."));
    } catch (error) {
      console.error("Error updating workstations:", error);
    } finally {
      getList();
      setOpen(false);
    }
  };

  return (
    <ModalPopup
      size="md"
      isOpen={isOpen}
      setOpen={setOpen}
      onSubmit={onSubmit}
      buttonText={t("common.save", "저장")}
    >
      <Wrapper>
        <Title>{storage.name}</Title>

        <div className="label_box">
          <label>{t("workstation.workstation", "워크스테이션")}</label>
          <label>{t("storage.drive", "드라이브")}</label>
        </div>
        <div className="storage_box">
          {isLoading ? (
            <div className="loading">
              <CircleProgress />
            </div>
          ) : (
            workstations.map((ws: any) => (
              <EachWorkstation key={ws.workstationId}>
                <div className="checkbox">
                  <input
                    type="checkbox"
                    checked={selectedWorkstations.includes(ws.workstationId)}
                    onChange={() => handleCheckboxChange(ws.workstationId)}
                  />
                  {ws.name}
                </div>
                {selectedWorkstations.includes(ws.workstationId) && (
                  <select
                    value={driveLetters[ws.workstationId] || ""}
                    onFocus={() =>
                      handleDriveLetterFocus(ws.workstationId, workstations, driveLetters)
                    }
                    onChange={e => handleDriveLetterChange(ws.workstationId, e.target.value)}
                  >
                    {(availableLetters[ws.workstationId] || []).map(letter => (
                      <option key={letter} value={letter}>
                        {letter}
                      </option>
                    ))}
                  </select>
                )}
              </EachWorkstation>
            ))
          )}
        </div>
      </Wrapper>
    </ModalPopup>
  );
};

const Wrapper = styled.div`
  margin: auto;
  margin-top: 10px;
  width: 100%;
  text-align: left;
  .label_box {
    display: flex;
    justify-content: space-between;
    .description {
      font-size: 12px;
      color: #979797;
    }
    label {
      display: block;
      font-size: 14px;
      font-weight: bold;
      color: #979797;
      margin-left: 5px;
    }
  }

  .storage_box {
    width: 100%;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    margin-top: 10px;
    height: 200px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #d4d4d4;
      border-radius: 5px;
    }
    .loading {
      margin-top: 70px;
    }
  }
  .no_storage {
    padding: 10px;
    color: gray;
    font-size: 13px;
  }
`;

const EachWorkstation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  height: 30px;
  .checkbox {
    display: flex;
    align-items: center;
    font-size: 12px;
    cursor: pointer;
    input {
      margin-right: 10px;
    }
  }
  select {
    width: 100px;
    height: 25px;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
  }

  .shared_icon {
    margin-left: 5px;
  }
`;

const Title = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
`;

export default MountWorkstation;
