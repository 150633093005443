import React from "react";
import { ChevronRight } from "react-feather";
import styled from "styled-components";

interface Props {
  children: any;
  color?: "dark";
  big?: true;
  onClick: () => void;
}

const MainBlueButton = ({ children, color, big, onClick }: Props) => {
  const buttonColor = (() => {
    if (color == "dark") return { color: "#004996", hoverColor: "#0051a8" };
    return { color: "var(--GridaBlue)", hoverColor: "#006de2" };
  })();

  return (
    <Wrapper
      onClick={() => onClick()}
      color={buttonColor.color}
      hoverColor={buttonColor.hoverColor}
      big={big || false}
    >
      {children}
      <ChevronRight />
    </Wrapper>
  );
};

interface StyleProps {
  color: string;
  hoverColor: string;
  big: boolean;
}

const Wrapper = styled.button<StyleProps>`
  cursor: pointer;
  position: relative;
  text-align: start;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  height: 50px;
  width: 250px;
  font-size: 18px;
  font-weight: 500;

  border-radius: 5px;
  background: ${(p) => p.color};
  color: #fff;
  border: none;

  &:hover {
    background: ${(p) => p.hoverColor};
  }
  &:focus {
    color: #fff;
  }
  svg {
    /* position: absolute;
    right: 2rem; */
    margin-left: 1rem;
    margin-top: 0.1rem;
  }
  @media (min-width: 1024px) {
    width: ${(props) => (props.big ? 300 : 250)}px;
  }
`;

export default MainBlueButton;
