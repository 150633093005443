import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

interface Props {
  check: boolean;
  onClick: () => void;
  title?: string | null;
  name: string;
  addText?: string | null;
}

const CheckBoxCircle = ({ check, onClick, title, name, addText }: Props) => {
  const navigate = useNavigate();

  const handleLabelClick = () => {
    navigate("/policy");
  };

  return (
    <Wrapper>
      <input
        onClick={() => onClick()}
        checked={check}
        readOnly
        type="checkbox"
        id={name}
      />

      <label htmlFor={name}>
        {title && <span onClick={() => handleLabelClick()}>{title}</span>}
      </label>

      <label className="addText">{addText}</label>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  input {
    display: none;
    & + label {
      cursor: pointer;
      span {
        vertical-align: middle;
        padding-left: 5px;
        font-size: 13px;
      }
    }
    & + label:before {
      content: "";
      display: inline-block;
      width: 18px;
      height: 18px;
      border: 0.5px solid #c3c3c3;
      border-radius: 50%;
      vertical-align: middle;
    }
    &:checked + label:before {
      content: "";
      background-color: #1a86df;
      border-color: #1a86df;
      background-image: url("/assets/sub/checkbox.png");
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: 50%;
    }
  }

  .addText {
    font-size: 13px;
  }

  @media screen and (min-width: 1024px) {
    label {
      font-size: 15px;
      &::before {
        width: 25px;
        height: 25px;
        top: -5px;
      }
    }
  }
`;

export default CheckBoxCircle;
