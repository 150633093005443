import React, { useEffect, useState } from "react";
import { Code } from "../../../../../../@types/infra/code";
import { Image } from "../../../../../../@types/infra/image";
import { useT } from "../../../../../../constants/i18n/useT";

interface Props {
  setBootDisk: React.Dispatch<React.SetStateAction<string>>;
  bootDisk: string;
  images: Image[];
  selectImages2: Image[];
  os: string;
  sw: string;
  isLoading: boolean;
  setImageId: React.Dispatch<React.SetStateAction<string>>;
}

const WSCreateBoot = ({
  os,
  sw,
  setBootDisk,
  selectImages2,
  bootDisk,
  images,
  setImageId,
}: Props) => {
  const [showBoot, setShowBoot] = useState<Code[]>([]);

  const filterByBoot = (images: Image[]) => {
    if (images.length > 0) {
      let tmpImages = images.filter(
        (image: Image) =>
          image.os &&
          image.installedSw &&
          image.bootingSize &&
          image.os.cd === os &&
          image.installedSw.cd === sw &&
          image.bootingSize.cd === bootDisk
      );
      if (tmpImages.length > 0 && tmpImages[0].imageId) {
        return tmpImages[0].imageId;
      }
    }
    return "";
  };

  const finalSelectImages = (selectImages2: Image[]) => {
    let myBoot: Code[] = [];
    selectImages2.map((image) => {
      if (image.bootingSize) {
        myBoot.push({
          ...image.bootingSize,
          value: Number(image.bootingSize.value) || 0,
        });
      }
    });
    sortBootingDisk(myBoot);
    return dedupCode(myBoot);
  };

  const sortBootingDisk = (boots: Code[]) => {
    boots.sort((a, b) => {
      if (
        a.value &&
        b.value &&
        typeof a.value === "number" &&
        typeof b.value === "number"
      )
        return a.value - b.value;
      return 0;
    });
  };

  const dedupCode = (boots: Code[]) => {
    return boots.filter(
      (v, i) => boots.findIndex((boot) => boot.cd === v.cd) === i
    );
  };

  useEffect(() => setShowBoot(finalSelectImages(selectImages2)), [
    selectImages2,
    os,
    sw,
    bootDisk,
  ]);

  useEffect(() => {
    if (bootDisk !== "" && os !== "" && sw !== "")
      setImageId(filterByBoot(images));
  }, [os, sw, bootDisk]);

  const t = useT();

  useEffect(() => {
    if (showBoot.length > 0 && showBoot[0].cd && bootDisk === "")
      setBootDisk(showBoot[0].cd);
  }, [showBoot]);
  return (
    <dd className="radio_middle">
      {showBoot.length > 0 ? (
        <select
          tabIndex={2}
          onChange={(e) => setBootDisk(e.target.value)}
          id="number"
        >
          {showBoot.map((bootDisk: Code, idx) => (
            <option key={idx} value={bootDisk.cd} id={bootDisk.cd}>
              {bootDisk.name}
            </option>
          ))}
        </select>
      ) : (
        <p id="red">
          {t(
            "workstation.there_is_no_available_software_to_use",
            "사용 가능한 sw가 없습니다."
          )}
        </p>
      )}
    </dd>
  );
};

export default WSCreateBoot;
