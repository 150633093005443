import React from "react";
import { Workstation } from "../../../../../../@types/workstation/workstation";

import styled from "styled-components";

export const CardBody2 = ({ workstation }: { workstation: Workstation }) => {
  const tableList = [
    { name: "Boot Disk", cd: "bootingSize" },
    { name: "OS", cd: "os" },
    { name: "SW", cd: "installedSw" },
  ];
  const getDetail = (item: "bootingSize" | "os" | "installedSw" | string) => {
    if (!workstation.imageInfo) return;
    if (!workstation.imageInfo.baseImage) return;

    if (item === "bootingSize") {
      return (
        workstation.imageInfo.baseImage.bootingSize &&
        workstation.imageInfo.baseImage.bootingSize.name
      );
    }
    if (item === "os") {
      return workstation.imageInfo.baseImage.os && workstation.imageInfo.baseImage.os.name;
    }
    if (item === "installedSw") {
      return (
        workstation.imageInfo.baseImage.installedSw &&
        workstation.imageInfo.baseImage.installedSw.name
      );
    }
  };
  return (
    <Wrapper>
      {tableList.map(item => (
        <div className="item" key={item.cd}>
          <span className="title">{item.name}</span>
          <span className="content">{getDetail(item.cd)}</span>
        </div>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.article`
  border-bottom: 1px solid #e5e5e5;
  margin-top: 5px;

  .title {
    font-weight: 600;
    color: #5e5e5e;
  }
`;
