import React, { useState } from "react";
import { Dropdown } from "../../dropdown/Dropdown";
import { userState } from "../../../constants/recoil/user";
import { useRecoilState } from "recoil";
import { useT } from "../../../constants/i18n/useT";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import LogoutModal from "../../../pages/login/logout/modal";
import { CgProfile } from "@react-icons/all-files/cg/CgProfile";

const ProfileDropdown = () => {
  const [popLogout, setPopLogout] = useState(false);

  const [user] = useRecoilState(userState);

  const navigate = useNavigate();
  const goto = (path: string) => {
    navigate(path);
  };

  const t = useT();
  return (
    <Dropdown
      arrow={false}
      title={
        <UserTitle>
          <StyledProfileIcon />
          <a>{user.fullName}</a>
        </UserTitle>
      }
    >
      <li onClick={() => goto("/mypage/info")}>{t("common.my_information", "내 정보")}</li>
      <li onClick={() => goto("/mypage/credit")}>{t("common.credit_history", "Credit 내역")}</li>
      <li onClick={() => goto("/mypage/user-alarms")}>{t("common.noti_log", "알림 및 로그")}</li>
      <li onClick={() => goto("/mypage/contact")}>{t("common.customer_service", "고객센터")}</li>
      <li onClick={() => setPopLogout(true)}>{t("common.sign_out", "로그아웃")}</li>
      {popLogout && <LogoutModal setOpen={setPopLogout} isOpen={popLogout} />}
    </Dropdown>
  );
};

const UserTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: var(--GridaBlue);
  img {
    width: 30px;
    height: 30px;
    margin-right: 12px;
  }
  padding: 15px;
`;

const StyledProfileIcon = styled(CgProfile)`
  width: 30px;
  height: 30px;
  background-color: var(--GridaBlue);
  // border: 0.5px solid white;
  border-radius: 50%;
  color: white;

  margin-right: 12px;
`;

export default ProfileDropdown;
