import React from "react";
import styled from "styled-components";
import Star from "../essential-star";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  essential?: boolean;
  label: string;
  type?: "text" | "password" | "mail";
  description?: string | null;
  isValid?: boolean;
  inline?: boolean;
}

const InputLabel = (props: Props) => {
  const { essential, label, type, description, inline, isValid } = props;
  const DEFAULT_MAX_LENGTH = 50;

  const isError = () => {
    if (props.value === "") return "";
    if (isValid === undefined) return "";
    if (isValid) return "";
    return "error";
  };

  if (props.disabled) {
    return (
      <Wrapper className="flex_box" id={inline ? "inline" : ""}>
        <label>
          {props.label}
          {essential && <Star />}
        </label>
        <p className="value2">{props.value}</p>
      </Wrapper>
    );
  }
  return (
    <Wrapper id={inline ? "inline" : ""}>
      <label>
        {label}
        {essential && <Star />}
      </label>
      <input
        className={isError()}
        type={type || "text"}
        {...props}
        maxLength={props.maxLength || DEFAULT_MAX_LENGTH}
      />
      {description && (
        <div className="description">
          {!isValid && <span className={isError()}>{description}</span>}
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  &#inline {
    display: flex;
    align-items: center;
  }
  margin: auto;
  margin-top: 10px;
  width: 100%;
  text-align: left;
  label {
    font-size: 14px;
    font-weight: bold;
    color: #979797;
    margin-left: 5px;
    min-width: 50px;
  }
  .flex_box {
    display: flex;
    align-items: center;
  }
  .value {
    color: #979797;
    margin-left: 5px;
    margin-top: 10px;
  }
  .value2 {
    color: #979797;
    margin-left: 15px;
  }
  input {
    display: block;
    width: 100%;
    height: 35px;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    &.error {
      border: 1px solid #ff2c2c !important;
    }
    &:focus {
      border: 1px solid #50a0fa;
    }
    text-indent: 10px;
    &::placeholder {
      font-size: 10px;
    }
  }
  .description {
    font-size: 10px;
    color: #979797;
    margin-top: 5px;
    margin-left: 10px;
    min-height: 12px;
    .error {
      color: #ff2c2c;
    }
  }
  @media (min-width: 768px) {
    .description {
      font-size: 10px;
    }
  }
`;

export default InputLabel;
