import React from "react";
import styled from "styled-components";
import { IoMdSearch } from "@react-icons/all-files/io/IoMdSearch";

const SearchInput = (props: React.InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <Wrapper>
      <input {...props} />
      <button type="button">
        {/* <img src="/assets/user/search.png" /> */}
        <IoMdSearch size={24} />
      </button>
    </Wrapper>
  );
};
const Wrapper = styled.article`
  height: 30px;
  width: 100%;
  margin-top: 20px;
  padding-left: 5px;
  border-bottom: 1px solid #aaaaaa;
  box-sizing: border-box;
  position: relative;
  input {
    width: 100%;
    border: 0;
    line-height: 30px;
    background-color: transparent;
  }
  button {
    img {
      width: 15px;
      height: 15px;
    }
    border: 0;
    background: transparent;
    position: absolute;
    top: 9px;
    right: 5px;
  }
  @media (min-width: 1100px) {
    height: 40px;
    width: 300px;
    margin-top: 0;
    input {
      line-height: 40px;
    }
    button {
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
`;
export default SearchInput;
