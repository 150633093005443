import { DefaultUserUiData, ProjectUserUiData } from "../../@types/project/ui/user";
import { ProjectService } from "../project/project.service";
import { CompanyService } from "../user/company.service";
import { MemberService } from "../common/member.service";
import { ProjectCompanyUserResponse } from "../../@types/project/response";

interface Props {
  projectId?: number;
  companyGuid?: string;
  ownerUserGuid?: string;
  leftUsers: DefaultUserUiData[];
}

export class WorkstationUpdateService {
  static getMembers = async ({
    projectId,
    companyGuid,
    leftUsers,
    ownerUserGuid = "",
  }: Props): Promise<DefaultUserUiData[]> => {
    let users = [] as DefaultUserUiData[];
    if (projectId) {
      users = await this.getUsersByProject(projectId, leftUsers);

      return users;
    }
    if (companyGuid) {
      users = await this.getUsersByCompany(companyGuid, leftUsers, ownerUserGuid);

      return users;
    }
    return users;
  };

  private static getUsersByProject = async (projectId: number, leftUsers: DefaultUserUiData[]) => {
    let users: DefaultUserUiData[] = [];
    await ProjectService.getUserList(projectId).then(res => {
      if (res.state === "success") {
        res.data.map((_user: ProjectUser) => {
          if (MemberService.isDuplicateInTarget(_user.user, leftUsers)) {
            return;
          }
          let newUser = new ProjectUserUiData(_user.user);
          if (_user.projectRole) {
            newUser.projectRole = _user.projectRole;
          }
          if (_user.projectUserId) {
            newUser.projectUserId = _user.projectUserId;
          }
          if (_user.workstationUserId) {
            newUser.workstationUserId = _user.workstationUserId;
          }
          if (_user.storageUserId) {
            newUser.storageUserId = _user.storageUserId;
          }
          users.push(newUser);
        });
      }
    });
    return users;
  };

  private static getUsersByCompany = async (
    companyGuid: string,
    leftUsers: DefaultUserUiData[],
    ownerUserGuid: string
  ) => {
    const users: DefaultUserUiData[] = [];
    await CompanyService.getUserList(companyGuid).then(res => {
      if (res.state === "success") {
        res.data.forEach((usr: ProjectCompanyUserResponse) => {
          if (usr.userGuid === ownerUserGuid) {
            return;
          }
          if (MemberService.isDuplicateInTarget(usr, leftUsers)) return;
          users.push(new ProjectUserUiData(usr));
        });
      }
    });
    return users;
  };
}

interface ProjectUser {
  userGuid: string;
  fullName: string;
  userId: string;
  projectUserId?: number;
  workstationUserId?: number;
  storageUserId?: number;
  projectRole?: { cd: string; name?: string };
  user: { userGuid: string; fullName: string; userId: string };
}
