import React from "react";
import styled from "styled-components";
import { Storage } from "../../../../@types/storage/storage";
import CardHeader from "./components/header/card-top";
import CardBody from "./components/body/card-body";

interface Props {
  storage: Storage;
  getList: () => void;
  own: boolean;
}

const StorageCard = (props: Props) => {
  return (
    <Wrapper>
      <CardHeader {...props} />
      <CardBody {...props} />
    </Wrapper>
  );
};
const Wrapper = styled.li`
  #own {
    border-radius: 5px 5px 0 0;
    background: var(--PowerOff);
    color: #fff;
    img {
      filter: brightness(500%);
    }
  }
  .card_btn {
    margin-right: 20px;
  }
  &:hover {
    box-shadow: 2px 2px 7px #e1e1e1;
    transition: 0.3s;
  }
  border: 1px solid #ddd;
  margin: 22px;
  margin-bottom: 25px;
  box-sizing: border-box;
  background: #fbfbfb;

  border-radius: 5px;
  width: 280px;
  @media screen and (min-width: 768px) {
    width: 320px;
  }
`;

export default StorageCard;
