import React, { useEffect, useState } from "react";
import styled from "styled-components";
import LoginForm from "../components/login-wrapper";
import { UpdateUser } from "../../../service/user/user.update.service";
import FindId1 from "./chapter1";
import FindId2 from "./chapter2";
import FindId3 from "./chapter3";
import { useT } from "../../../constants/i18n/useT";
import { REGEX } from "../../../constants/regex";

const FindIdPage = () => {
  const [session, setSession] = useState(1);
  const [id, setId] = useState("");
  const [newUser, setNewUser] = useState(new UpdateUser());
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [loginFailed, setLoginFailed] = useState("");
  const [emailNumber, setEmailNumber] = useState("");
  const [verified, setVerified] = useState(false);
  const [failed, setFailed] = useState("");

  const [createDate, setCreateDate] = useState("");
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const t = useT();
  const findId = () => {
    // session 1
    if (name === "")
      return setLoginFailed(
        t("user.please_enter_your_name", "이름을 입력하세요") || ""
      );

    if (!REGEX.EMAIL.test(mail))
      return setLoginFailed(
        t(
          "user.please_enter_a_valid_email_address",
          "올바른 이메일 주소를 입력해 주세요"
        ) || ""
      );

    newUser.mail = mail;
    newUser.fullName = name;
    setNewUser(newUser);
    newUser.sendEmailToFindId().then((res) => {
      console.log(res);
      if (res.state === "success") {
        setSession(2);
        setMinutes(10);
        setSeconds(0);
      } else
        setLoginFailed(
          res.message ||
            t(
              "common.error",
              "현재 서버가 원활하지 않습니다. 잠시 후 시도해주세요."
            )
        );
    });
  };

  const checkEmailNum = () => {
    // session 2
    newUser.validEmailNumber(emailNumber).then((res) => {
      if (res.state === "success") setVerified(true);
      else
        setFailed(
          res.message ||
            t(
              "common.error",
              "현재 서버가 원활하지 않습니다. 잠시 후 시도해주세요."
            )
        );
    });
  };

  const onSubmit = () => {
    // session 3
    newUser.certificationNumber = emailNumber;
    newUser.finduserId().then((res) => {
      if (res.state === "success") {
        setId(res.data.userId);
        setCreateDate(res.data.createdDt);
        setSession(3);
      } else
        setFailed(
          res.message ||
            t(
              "common.error",
              "현재 서버가 원활하지 않습니다. 잠시 후 시도해주세요."
            )
        );
    });
  };

  useEffect(() => {
    if (emailNumber.length === 6) checkEmailNum();
  }, [emailNumber]);

  const props = {
    name,
    setName,
    mail,
    setMail,
    loginFailed,
    findId,
    emailNumber,
    setEmailNumber,
    verified,
    failed,
    minutes,
    setMinutes,
    seconds,
    setSeconds,
    onSubmit,
    id,
    createDate,
  };

  return (
    <LoginForm>
      <Wrapper>
        {session === 1 && <FindId1 {...props} />}
        {session === 2 && <FindId2 {...props} />}
        {session === 3 && <FindId3 {...props} />}
      </Wrapper>
    </LoginForm>
  );
};

const Wrapper = styled.div``;

export default FindIdPage;
