import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useT } from "../../../../../../constants/i18n/useT";
import CircleProgress from "../../../../../../components/loading/CircleProgress";
import ModalPopup from "../../../../../../components/modal/popup";

interface Props {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentWorkstation: any;
  assignedWorkstations?: any[] | [];
  assignedStorages?: any[] | [];
  st: any[];
  setSt: React.Dispatch<React.SetStateAction<any[]>>;
  setWsUser: React.Dispatch<React.SetStateAction<any[]>>;
}

const shareStorage = ({
  isOpen,
  setOpen,
  assignedWorkstations,
  assignedStorages,
  currentWorkstation,
  st,
  setSt,
  setWsUser,
}: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedWorkstations, setSelectedWorkstations] = useState<string[]>([]);
  const [driveLetters, setDriveLetters] = useState<{ [key: string]: string }>({});

  const DriveLetter = ["Z", "Y", "X", "W", "V", "U", "T", "S", "R", "Q"];

  useEffect(() => {
    setWsUser([]);
    setSt([]);
  }, []);

  useEffect(() => {
    setIsLoading(false);

    if (currentWorkstation && currentWorkstation.storages) {
      setSelectedWorkstations(currentWorkstation.storages.map((ws: any) => ws.storageId));
      const initialDriveLetters = currentWorkstation.storages.reduce(
        (DriveLetter: any, ws: any) => {
          DriveLetter[ws.storageId] =
            ws.driveLetter || getNextAvailableDriveLetter(Object.values(DriveLetter));
          return DriveLetter;
        },
        {}
      );
      setDriveLetters(initialDriveLetters);
    }
  }, [currentWorkstation]);

  const t = useT();

  const getNextAvailableDriveLetter = (usedLetters: string[] = []) => {
    for (let letter of DriveLetter) {
      if (!usedLetters.includes(letter)) {
        return letter;
      }
    }
    return "Z";
  };

  const handleCheckboxChange = (storageId: string) => {
    setSelectedWorkstations(prevSelected => {
      if (prevSelected.includes(storageId)) {
        const updatedDriveLetters = { ...driveLetters };
        delete updatedDriveLetters[storageId];
        setDriveLetters(updatedDriveLetters);
        return prevSelected.filter(id => id !== storageId);
      } else {
        const nextDriveLetter = getNextAvailableDriveLetter(Object.values(driveLetters));
        setDriveLetters(prevDriveLetters => ({
          ...prevDriveLetters,
          [storageId]: nextDriveLetter,
        }));
        return [...prevSelected, storageId];
      }
    });
  };

  const handleDriveLetterChange = (storageId: string, driveLetter: string) => {
    setDriveLetters(prevDriveLetters => {
      const updatedDriveLetters = {
        ...prevDriveLetters,
        [storageId]: driveLetter,
      };

      return updatedDriveLetters;
    });
  };

  const onSubmit = () => {
    const updatedWorkstations = selectedWorkstations.map(storageId => ({
      storageId,
      driveLetter: driveLetters[storageId] || "Z",
    }));

    const existingWorkstationIds = currentWorkstation.storages.map((st: any) => st.storageId);

    const removedWorkstations = existingWorkstationIds
      .filter((id: any) => !selectedWorkstations.includes(id))
      .map((storageId: any) => ({
        storageId,
        driveLetter: driveLetters[storageId] || "Z",
        useStateCd: "UseState.Deleted",
      }));

    const finalWorkstations = [
      ...updatedWorkstations.filter(ws => !existingWorkstationIds.includes(ws.storageId)),
      ...removedWorkstations,
    ];

    setSt(finalWorkstations);
    setOpen(false);
  };

  return (
    <ModalPopup
      size="md"
      isOpen={isOpen}
      setOpen={setOpen}
      buttonText={t("common.save", "저장")}
      onSubmit={onSubmit}
    >
      <Wrapper>
        <div className="workstation-name">{currentWorkstation.name}</div>
        <div className="label_box">
          <label>{t("storage.storage", "스토리지")}</label>
          <label>{t("storage.drive", "드라이브")}</label>
        </div>
        <div className="storage_box">
          {isLoading && (
            <div className="loading">
              <CircleProgress />
            </div>
          )}
          {!isLoading && (
            <>
              {assignedStorages && assignedStorages.length > 0 ? (
                assignedStorages.map(st => (
                  <div key={st.storage.storageId}>
                    <EachStorage>
                      <div className="checkbox">
                        <input
                          type="checkbox"
                          checked={selectedWorkstations.includes(st.storage.storageId)}
                          onChange={() => handleCheckboxChange(st.storage.storageId)}
                        />
                        <span>{st.storage.name}</span>
                      </div>
                      {selectedWorkstations.includes(st.storage.storageId) && (
                        <select
                          className="select_drive"
                          value={
                            driveLetters[st.storage.storageId] ||
                            getNextAvailableDriveLetter(Object.values(driveLetters))
                          }
                          onChange={e => {
                            handleDriveLetterChange(st.storage.storageId, e.target.value);
                          }}
                        >
                          {DriveLetter.map(letter => (
                            <option key={letter} value={letter}>
                              {letter}
                            </option>
                          ))}
                        </select>
                      )}
                    </EachStorage>
                  </div>
                ))
              ) : (
                <div className="no_storage">
                  {t("storage.noStoragesAssigned", "할당된 스토리지가 없습니다")}
                </div>
              )}
            </>
          )}
        </div>
      </Wrapper>
    </ModalPopup>
  );
};

const Wrapper = styled.div`
  margin: auto;
  margin-top: 10px;
  width: 100%;
  text-align: left;
  .workstation-name {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .label_box {
    display: flex;
    justify-content: space-between;
    .description {
      font-size: 12px;
      color: #979797;
    }
    label {
      display: block;
      font-size: 14px;
      font-weight: bold;
      color: #979797;
      margin-left: 5px;
    }
  }

  .storage_box {
    width: 100%;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    margin-top: 10px;
    height: 200px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #d4d4d4;
      border-radius: 5px;
    }
    .loading {
      margin-top: 70px;
    }
  }
  .no_storage {
    padding: 10px;
    color: gray;
    font-size: 13px;
  }
`;

const EachStorage = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  height: 30px;
  .checkbox {
    display: flex;
    align-items: center;
    font-size: 12px;
    cursor: pointer;
    input {
      margin-right: 10px;
    }
  }
  select {
    width: 100px;
    height: 25px;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
  }

  .shared_icon {
    margin-left: 5px;
  }
`;

export default shareStorage;
