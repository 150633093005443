import React from "react";
import styled, { FlattenSimpleInterpolation } from "styled-components";
import { Workstation } from "../../../../../../@types/workstation/workstation";
import { useT } from "../../../../../../constants/i18n/useT";
import { Link } from "react-router-dom";
import { CardService, StatusType } from "../card-setting";
import { CardEditType } from "../../../../../../@types/type/card-edit";
import { Tooltip } from "@mui/material";
import { DateService } from "../../../../../../service/common/date.service";

interface Props {
  workstation: Workstation;
  status: StatusType;
  visualPercent: number;
  usedTime: number;
  editType?: CardEditType;
}

// interface Storage {
//   storageId: string;
//   name: string;
// }

export const CardBody3 = ({ workstation, status, visualPercent, usedTime, editType }: Props) => {
  const t = useT();
  const timezone = navigator.language;

  const getShareUsers = () => {
    let result = (
      <>
        {workstation.shareWith &&
          workstation.shareWith.map((userData, idx) => {
            return <p key={userData.userGuid}>{userData.fullName}</p>;
          })}
      </>
    );
    return result;
  };

  const getStorages = () => {
    let result = (
      <>
        {workstation.storages &&
          workstation.storages.map(storage => {
            return <p key={storage.storageId}>{storage.name}</p>;
          })}
      </>
    );
    return result;
  };

  return (
    <Wrapper>
      <div className="item">
        <div className="title">{t("workstation.status", "상태")}</div>
        <StatusName colorStyle={CardService.getColorStyle(status)}>
          <div className={status !== "PowerOff" ? "blink" : "unblinck"}>
            {workstation.status && workstation.status.name}
          </div>
        </StatusName>
      </div>

      <div className="item">
        <div className="title">{t("workstation.usage_time", "사용 시간")}</div>
        <div className="content" id={usedTime !== 0 ? "pay" : "nonpay"}>
          {usedTime !== 0 ? (
            Math.floor(usedTime / 60) > 0 ? (
              <>
                <span> {Math.floor(usedTime / 60)}</span>
                {t("workstation.hour", "시간")}
                &nbsp;
                <span> {usedTime - Math.floor(usedTime / 60) * 60}</span>{" "}
                {t("workstation.min", "분")}
              </>
            ) : (
              <>
                <span> {usedTime}</span>
                {t("workstation.min", "분")}
              </>
            )
          ) : (
            "-"
          )}
        </div>
      </div>

      <div className="item">
        <div className="title">{t("workstation.start_time", "시작 시간")}</div>
        <div className="content">
          {status === "Running" && workstation.runningTime && workstation.runningTime.startDt
            ? DateService.formatDate(workstation.runningTime.startDt, timezone)
            : "-"}
        </div>
      </div>

      {/* {!workstation.project && (  */}
      {/* {workstation && !workstation.shareWith && !workstation.loginUser && ( */}
      <div className="item">
        <div className="title">{t("common.shared_user", "사용자")}</div>
        <div className="content">
          <p>
            {workstation.loginUser ? workstation.loginUser.fullName : "-"}{" "}
            <span className="share">
              {workstation.shareWith && workstation.shareWith.length ? (
                <Tooltip title={getShareUsers()} className="hover">
                  <span>
                    {"( "}
                    {t("common.share", "공유")}
                    {": "}
                    <span className="constent">
                      {workstation.shareWith && workstation.shareWith.length}
                    </span>{" "}
                    {t("common.person", "명")}
                    {" )"}
                  </span>
                </Tooltip>
              ) : (
                <span></span>
              )}
            </span>
          </p>
        </div>
      </div>
      {/* )} */}

      {workstation.storages && workstation.storages.length > 0 && (
        <div className="item">
          <div className="title">{t("billing.storage", "스토리지")}</div>
          <div className="content">
            <p>
              <span className="share">
                {workstation.storages && workstation.storages.length ? (
                  <Tooltip title={getStorages()} className="hover">
                    <span>
                      <span className="constent">
                        {workstation.storages && workstation.storages.length}
                      </span>
                    </span>
                  </Tooltip>
                ) : (
                  <span></span>
                )}
              </span>
            </p>
          </div>
        </div>
      )}

      {workstation.project && workstation.project.projectId && (
        <div className="item">
          <div className="title">{t("workstation.project", "프로젝트")}</div>
          <div className="content" id="bold">
            {editType === CardEditType.ALL || editType === CardEditType.UPDATE ? (
              <Link
                to="/workstation#project"
                state={{
                  projectId: workstation.project && workstation.project.projectId,
                }}
                className="hover"
              >
                {workstation.project.name}
              </Link>
            ) : (
              workstation.project.name
            )}
          </div>
        </div>
      )}

      {/* {workstation.users && workstation.users.length > 0 && (
        <div className="item">
          <div className="title">{t("common.shared_user", "사용자")}</div>
          <div className="content" id="bold">
            <p>
              {workstation.users.length} {t("common.person", "명")}
            </p>
          </div>
        </div>
      )} */}

      {/* {visualPercent > -1 && (
        <>
          <ProgressBar>
            <progress className="progress" value={visualPercent} max={1} />
            <p id={visualPercent >= 0.5 ? "white" : ""} className="percent">
              {Math.floor(visualPercent * 100)}%
            </p>
          </ProgressBar>
        </>
      )} */}
    </Wrapper>
  );
};

const Wrapper = styled.article`
  height: 250px;
  border-bottom: 1px solid #e5e5e5;
  margin-top: 5px;
  .hover {
    color: #6f6f6f;
    text-decoration: underline;
    font-weight: 500;
    cursor: pointer;
    &:hover {
      color: #3b3b3b;
      font-weight: 600;
    }
  }
  #pay {
    font-weight: 600;
    span {
      margin-right: 3px;
    }
  }
  #bold {
    font-weight: 400;
    color: #333;
  }
  #nonpay {
    // visibility: hidden;
    span {
      margin-right: 3px;
    }
  }
  .item {
    margin-top: 5px;
  }
  .title {
    color: #000000 !important;
    font-size: 10x !important; // => 12px
    font-weight: 400 !important;
  }
  @media screen and (max-width: 768px) {
    height: 250px;
    .title {
      font-size: 12px !important;
    }
  }
`;

interface StyleProps {
  colorStyle: FlattenSimpleInterpolation;
}
const StatusName = styled.div<StyleProps>`
  ${p => p.colorStyle};
  font-weight: 700;
  font-size: 13px;
  width: 60%;
`;
const ProgressBox = styled.div`
  height: 23px;
`;
const ProgressBar = styled.div`
  margin: auto;
  width: 75%;
  .percent {
    position: relative;
    left: 45%;
    top: -16px;
    font-weight: 500;
    font-size: 11px;
    z-index: 10;
  }
  #white {
    color: white;
  }
  .progress {
    appearance: none;
    width: 100%;
    margin: auto;
    margin-top: 10px;
    height: 17px;
    position: relative;
    &::-webkit-progress-bar {
      background: #f0f0f0;
      border-radius: 5px;
      box-shadow: inset 3px 3px 10px #ccc;
      transition: 0.2s ease;
    }
    &::-webkit-progress-value {
      border-radius: 5px;
      background: var(--GridaBlue);
      transition: 0.5s ease;
      background: -webkit-linear-gradient(to right, #95c3f4, #0061c9);
      background: linear-gradient(to right, #95c3f4, #0061c9);
    }
  }
`;
