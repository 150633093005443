import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ProjectMemberLeftComponent from "./left/index";
import ProjectMemberRightComponent from "./right/index";
import ProjectComponentContainer from "../components/form.container";
import ProjectCenterButton from "../components/button/center.button";
import { Code } from "../../../../../../../@types/infra/code";
import { useRecoilState } from "recoil";
import { projectUiDataState } from "../../../../../../../constants/recoil/project";
import { Project } from "../../../../../../../@types/project/project";
import { ProjectUserUiData } from "../../../../../../../@types/project/ui/user";
import { _projectMember } from "../../../../../script/setting/project.member";
import { userState } from "../../../../../../../constants/recoil/user";
import { useT } from "../../../../../../../constants/i18n/useT";
import { ProjectUserMode } from "../../../../../../../@types/project/ui/user-search-mode";

interface Props {
  project?: Project;
  leftUsers: ProjectUserUiData[];
  setLeftUsers: React.Dispatch<React.SetStateAction<ProjectUserUiData[]>>;
}

const ProjectMemberComponent = ({ project, leftUsers, setLeftUsers }: Props) => {
  const [rightUsers, setRightUsers] = useState<ProjectUserUiData[]>([]);
  const [searchUsers, setSearchUsers] = useState<ProjectUserUiData[]>([]);
  const [projectUiData] = useRecoilState(projectUiDataState);
  const [types, setTypes] = useState<Code[]>([]);
  const [user] = useRecoilState(userState);
  const [mode, setMode] = useState<ProjectUserMode>(
    user.company ? ProjectUserMode.COMPANY : ProjectUserMode.SEARCH
  );
  const t = useT();

  const { toLeft, toRight, hadlerUserToUiData, setRightNoProjectUserByCompany } = _projectMember({
    leftUsers,
    setLeftUsers,
    rightUsers,
    setRightUsers,
    searchUsers,
    setSearchUsers,
    sUser: user,
  });

  useEffect(() => setTypes(projectUiData.codes as Code[]), [projectUiData]);

  useEffect(() => {
    if (project) {
      setLeftUsers(hadlerUserToUiData(project));
    } else {
      setRightNoProjectUserByCompany(user.company);
    }
  }, [project]);

  return (
    <ProjectComponentContainer title={t("workstation.member_assignment", "Member 할당") || ""}>
      <Wrapper>
        <ProjectMemberLeftComponent
          userList={leftUsers}
          setLeftUsers={setLeftUsers}
          types={types}
        />
        <div className="center_btn">
          {mode === ProjectUserMode.COMPANY && (
            <ProjectCenterButton toLeft={() => toLeft(ProjectUserMode.COMPANY)} toRight={toRight} />
          )}
          {mode === ProjectUserMode.SEARCH && (
            <ProjectCenterButton toLeft={() => toLeft(ProjectUserMode.SEARCH)} toRight={toRight} />
          )}
        </div>
        <ProjectMemberRightComponent
          mode={mode}
          setMode={setMode}
          rightUsers={rightUsers}
          setRightUsers={setRightUsers}
          searchUsers={searchUsers}
          setSearchUsers={setSearchUsers}
          ownerGuid={project ? project.user && project.user.userGuid : user.userGuid}
        />
      </Wrapper>
    </ProjectComponentContainer>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: end;
  .center_btn {
    margin-bottom: 70px;
  }
`;

export default ProjectMemberComponent;
