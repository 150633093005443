import React, { useState } from "react";
import { Workstation } from "../../../../../@types/workstation/workstation";

import { useT } from "../../../../../constants/i18n/useT";
import ModalPopup from "../../../../../components/modal/popup";
import { ModalProps } from "../../../../../components/modal";
import { WorkstationService } from "../../../../../service/workstation/workstation.service";
import { useAlert } from "../../../../../components/alert/alert.service";

interface Props extends ModalProps {
  workstation: Workstation;
  getList: () => void;
}
const WSDelete = ({ isOpen, setOpen, workstation, getList }: Props) => {
  const [isResponse, setIsResponse] = useState(false);
  const { setSuccess, setAlert } = useAlert();

  const onSubmit = () => {
    setIsResponse(true);
    WorkstationService.delete(workstation.workstationId!)
      .then(res => {
        setSuccess(workstation.name + t("workstation.has_been_deleted", "이 삭제되었습니다."));
      })
      .catch(error => {
        if (error.response && error.response.status === 400) {
          setAlert(
            t(
              "workstation.workstation_aleady_start_cant_delete",
              "이미 실행중인 워크스테이션이 있습니다."
            )
          );

          return;
        }

        if (error.response && error.response.status === 404) {
          setAlert(t("workstation.not_found_ws", "워크스테이션 조회에 실패했습니다."));

          return;
        }

        if (error.response && error.response.status === 500) {
          setAlert(t("common.error", "현재 서버가 원활하지 않습니다. 잠시 후 시도해주세요."));

          return;
        }
      })
      .finally(() => {
        setIsResponse(false);
        setOpen(false);
        getList();
      });
  };
  const t = useT();
  return (
    <ModalPopup
      isResponse={isResponse}
      isOpen={isOpen}
      setOpen={setOpen}
      buttonText={t("common.delete", "삭제")}
      type="delete"
      onSubmit={onSubmit}
    >
      <h1>
        {t(
          "workstation.would_you_like_to_proceed_with_deleting_the_following_workstation",
          "다음 Workstation을 삭제하시겠습니까?"
        )}
      </h1>
      <p>
        {t(
          "workstation.all_data_saved_on_the_workstation_will_be_permanently_deleted",
          "Workstation에 저장된 모든 데이터가 영구적으로 삭제됩니다."
        )}
      </p>
      <p id="blue">{workstation.name}</p>
    </ModalPopup>
  );
};

export default WSDelete;
