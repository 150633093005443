import React from "react";
import styled, { FlattenSimpleInterpolation } from "styled-components";
import { ButtonProps, ButtonStyle } from "./setting";

const Button = (props: ButtonProps) => {
  const sizeStyle = ButtonStyle.SIZES[props.size || "md"];
  const colorStyle = ButtonStyle.COLOR[props.color || "skyblue"];

  return (
    <StyledButton {...props} sizeStyle={sizeStyle} colorStyle={colorStyle}>
      {props.children}
    </StyledButton>
  );
};

interface StyleProps {
  sizeStyle: FlattenSimpleInterpolation;
  colorStyle: FlattenSimpleInterpolation;
  disabled?: boolean;
}

const StyledButton = styled.button<StyleProps>`
  border: none;
  cursor: pointer;
  font-weight: 600;
  ${(p) => p.sizeStyle}
  ${(p) => p.colorStyle}
  ${(p) => p.disabled && ButtonStyle.COLOR.disabled}
`;

export default Button;
