import React from "react";
import { Price } from "../../@types/billing/price";
import { useT } from "../../constants/i18n/useT";
import ContactAndCalculateButtonBox from "./components/button/contact-calculate-button";
import MainPriceCard from "./components/card/price-card";
import styled from "styled-components";

interface Props {
  cpuPrice: Price;
  gpuPrice: Price;
  storagePrice: Price;
}
const Main3 = ({ cpuPrice, gpuPrice, storagePrice }: Props) => {
  const t = useT();
  return (
    <Wrapper>
      <Title data-testid="title">
        <strong id="blue">Fee Guide</strong>
        <br />
        <h3>{t("billing.m2m_pricing", "요금 안내")}</h3>
        <p className="content">
          {t(
            "main.we_provide_pricing_information",
            "클라우드 사용에 대해 요금을 안내드립니다."
          )}
          <br />
          {t(
            "main.you_can_efficiently_manage_your_it_costs",
            "사용한 만큼 요금을 지불하여 IT 비용을 효율적으로 처리할 수 있습니다."
          )}
          <br />
          {t(
            "main.if_needed_you_can_lower_your_costs",
            "필요 시, 장기 계약을 통하여 비용을 낮출 수 있습니다."
          )}
        </p>
      </Title>
      <PriceCardList>
        <MainPriceCard price={cpuPrice} type="CPU" />
        <MainPriceCard price={gpuPrice} type="GPU" />
        <MainPriceCard price={storagePrice} type="STORAGE" />
      </PriceCardList>
      <div className="btn_wrap">
        <ContactAndCalculateButtonBox type="row" />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-image: url("/assets/main/price_bak.png");
  background-attachment: fixed;
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 100px;
`;
const Title = styled.div`
  padding-top: 100px;
  padding-left: 20px;
  strong {
    font-size: 18px;
    font-weight: 900;
  }
  h3 {
    font-size: 28px;
    font-weight: 700;
    margin-top: 20px;
  }
  @media (min-width: 768px) {
    text-align: center;
    padding: 100px;
    strong {
      font-size: 18px;
    }
    h3 {
      font-size: 40px;
    }
  }
  @media (min-width: 1024px) {
    h3 {
      font-size: 45px;
    }
  }
`;

const PriceCardList = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .btn_wrap {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
      margin-bottom: 10px;
    }
  }
  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-around;
  }
`;
export default Main3;
