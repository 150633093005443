import React, { useEffect, useState } from "react";
import styled from "styled-components";
import StorageCreatePage from "../../pages/storage/components/modal/create/modal";
import { useRecoilState } from "recoil";
import { userState } from "../../constants/recoil/user";
import InfraCreateButton from "../button/infra/create-button";
import SearchInput from "../input/search/infra-input";
import TabWrapper from "./tab-wrapper";
import { CreditService } from "../../service/billing/credit.service";
import { StorageList } from "../../@types/storage/response";
import { Storage } from "../../@types/storage/storage";
import { useAlert } from "../alert/alert.service";
import { MAX_LENGTH } from "../../constants/max-length";
import { FaChartPie } from "@react-icons/all-files/fa/FaChartPie";
import StorageStatusModal from "../../pages/storage/components/modal/chart/modal";

interface Props {
  rawStorages: StorageList;
  getList: () => void;
  myStorages: Storage[];
  sharedAsDirectorStorages: Storage[];
  sharedAsMemberStorages: Storage[];
  sharedAsNoProjectStorages: Storage[];
  setMyStorages: React.Dispatch<React.SetStateAction<Storage[]>>;
  setSharedAsDirectorStorages: React.Dispatch<React.SetStateAction<Storage[]>>;
  setSharedAsMemberStorages: React.Dispatch<React.SetStateAction<Storage[]>>;
  setSharedAsNoProjectStorages: React.Dispatch<React.SetStateAction<Storage[]>>;
}

const StorageNavbar = ({
  getList,
  rawStorages,
  myStorages,
  sharedAsDirectorStorages,
  sharedAsMemberStorages,
  sharedAsNoProjectStorages,
  setMyStorages,
  setSharedAsDirectorStorages,
  setSharedAsMemberStorages,
  setSharedAsNoProjectStorages,
}: Props) => {
  const [popCreate, setPopCreate] = useState(false);
  const [showCharts, setShowCharts] = useState(false);
  const [value, setValue] = useState("");
  const [user] = useRecoilState(userState);

  const { setCreditAlert } = useAlert();

  const openCreate = () => {
    CreditService.hasEnoughCredit(user.userGuid).then(res => {
      if (res.state === "success") {
        if (res.data) return setPopCreate(true);
        setCreditAlert(true);
      }
    });
  };

  const searchStorage = () => {
    const getFilter = (storages: Storage[]) => {
      return storages.filter(storage => storage.name.toLowerCase().includes(value.toLowerCase()));
    };

    setMyStorages(getFilter(rawStorages.myStorages));
    setSharedAsDirectorStorages(getFilter(rawStorages.sharedAsDirectorStorages));
    setSharedAsMemberStorages(getFilter(rawStorages.sharedAsMemberStorages));
    setSharedAsNoProjectStorages(getFilter(rawStorages.sharedAsNoProjectStorages));
  };

  useEffect(() => {
    searchStorage();
  }, [value]);

  return (
    <TabWrapper>
      <ButtonAndIconWrapper>
        <InfraCreateButton type="storage" onClick={() => openCreate()} />

        <IconWrapper onClick={() => setShowCharts(true)}>
          <FaChartPie size={25} />
        </IconWrapper>
      </ButtonAndIconWrapper>
      <SearchInput
        placeholder="search storage"
        value={value}
        onChange={e => setValue(e.target.value)}
        maxLength={MAX_LENGTH.PROJECT_NAME}
      />
      {popCreate && (
        <StorageCreatePage getList={getList} setOpen={setPopCreate} isOpen={popCreate} />
      )}
      {showCharts && (
        <StorageStatusModal
          myStorages={myStorages}
          sharedAsDirectorStorages={sharedAsDirectorStorages}
          sharedAsMemberStorages={sharedAsMemberStorages}
          sharedAsNoProjectStorages={sharedAsNoProjectStorages}
          setShowCharts={setShowCharts}
        />
      )}
    </TabWrapper>
  );
};

const IconWrapper = styled.div`
  background-color: #017bfb;
  color: white;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;

  &:hover {
    background-color: #006ee4;
    cursor: pointer;
  }

  @media (max-width: 1024px) {
    width: 30px;
    height: 30px;
  }
`;

const ButtonAndIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export default StorageNavbar;
