import { useEffect, useState } from "react";
import { Workstationlist } from "../../../@types/workstation/response";
import { WorkstationService } from "../../../service/workstation/workstation.service";
import { useRecoilState } from "recoil";
import { userState } from "../../../constants/recoil/user";

const FILTER = {
  MY_WORKSTATION: "MY_WORKSTATION",
  SHARED_BY_OTHERS: "SHARED_BY_OTHERS",
};

const PROJECT_ASSIGNED = {
  TRUE: "TRUE",
  FALSE: "FALSE",
};

const PROJECT_ROLES = {
  ARTIST: "ARTIST",
  DIRECTOR: "DIRECTOR",
};

const PROGRESS_RAGE = {
  TRUE: "TRUE",
  FALSE: "FALSE",
};

const useWorkstation = () => {
  const [everyWorkstations, setEveryWorkstations] = useState<Workstationlist>(
    new Workstationlist()
  );

  const [showWorkstations, setShowWorkstations] = useState<Workstationlist>(new Workstationlist());

  const [user] = useRecoilState(userState);

  const [usingWS, setUsingWS] = useState<string[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  const [createWS, setCreateWs] = useState(false);

  useEffect(() => {
    if (usingWS.length === 0) return;
    const interval = setInterval(() => getList(), 10000);
    return () => clearInterval(interval);
  }, [usingWS]);

  useEffect(() => getList(), []);

  const getList = () => {
    WorkstationService.getList().then(res => {
      // if (res.state === "success") {
      const data = res.data as Workstationlist;

      //   setEveryWorkstations(data);
      //   setUsingWS(WorkstationService.getUsingList(data));
      // } else setEveryWorkstations(new Workstationlist());
    });
    //   .finally(() => setIsLoading(false));

    Promise.all([
      WorkstationService.getMyWorkstations(
        user.userGuid!,
        FILTER.MY_WORKSTATION,
        PROGRESS_RAGE.TRUE
      ),
      WorkstationService.getProjectSharedWorkstationsForDirector(
        user.userGuid!,
        FILTER.SHARED_BY_OTHERS,
        PROJECT_ASSIGNED.TRUE,
        PROJECT_ROLES.DIRECTOR,
        PROGRESS_RAGE.TRUE
      ),
      WorkstationService.getProjectSharedWorkstationsForArtist(
        user.userGuid!,
        FILTER.SHARED_BY_OTHERS,
        PROJECT_ASSIGNED.TRUE,
        PROJECT_ROLES.ARTIST,
        PROGRESS_RAGE.TRUE
      ),
      WorkstationService.getUnassignedSharedWorkstations(
        user.userGuid!,
        FILTER.SHARED_BY_OTHERS,
        PROJECT_ASSIGNED.FALSE,
        PROGRESS_RAGE.TRUE
      ),
    ])
      .then(
        ([
          myWorkstations,
          projectSharedWorkstationsForDirector,
          projectSharedWorkstationsForArtist,
          unassignedSharedWorkstations,
        ]) => {
          const allWorkstation = {
            myWorkstations: myWorkstations,
            sharedAsDirectorWorkstations: projectSharedWorkstationsForDirector,
            sharedAsMemberWorkstations: projectSharedWorkstationsForArtist,
            sharedAsNoProjectWorkstations: unassignedSharedWorkstations,
          };

          setEveryWorkstations(allWorkstation);
          setUsingWS(WorkstationService.getUsingList(allWorkstation));
        }
      )
      .finally(() => setIsLoading(false));
  };

  const reset = (workstationList?: Workstationlist) => {
    if (workstationList) {
      setShowWorkstations(workstationList);
    } else {
      setShowWorkstations(everyWorkstations);
    }
  };

  const checkNoData = () => {
    if (isLoading) return false;
    if (
      showWorkstations.myWorkstations.length === 0 &&
      showWorkstations.sharedAsDirectorWorkstations.length === 0 &&
      showWorkstations.sharedAsMemberWorkstations.length === 0 &&
      showWorkstations.sharedAsNoProjectWorkstations.length === 0
    )
      return true;
  };

  return {
    usingWS,
    getList,
    everyWorkstations,
    isLoading,
    showWorkstations,
    reset,
    checkNoData,
    setCreateWs,
  };
};

export default useWorkstation;
