import React from "react";
import styled from "styled-components";
import Star from "../../../../../../components/input/essential-star";

interface Props {
  label: string;
  essential?: boolean;
  children?: React.ReactNode;
}

const SmallSelectLabel = ({ label, essential, children }: Props) => {
  return (
    <Wrapper>
      <label>
        {label}
        {essential && <Star />}
      </label>

      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 10px;
  min-width: 150px;
  max-width: 180px;
  label {
    display: block;
    font-size: 14px;
    font-weight: bold;
    color: #979797;
    margin-left: 5px;
    text-align: left;
  }
  select {
    display: block;
    min-width: 150px;
    height: 30px;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    margin-top: 10px;
    text-indent: 10px;
    font-size: 11px;
  }
  #red {
    color: red;
    font-size: 11px;
    margin-left: 5px;
    margin-top: 13px;
    text-align: left;
  }
`;

export default SmallSelectLabel;
