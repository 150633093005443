import React, { useEffect, useState } from "react";
import { useT } from "../../../../../constants/i18n/useT";
import ModalForm from "../../../../../components/modal/modal-form";
import styled from "styled-components";
import { ProjectUserUiData } from "../../../../../@types/project/ui/user";
import LeftUserList from "./left";
import ProjectCenterButton from "../../../../project/components/modal/create/setting-create-update/components/button/center.button";
import { _workstationMember } from "../../../script/ws-update-user";
import { useRecoilState } from "recoil";
import { userState } from "../../../../../constants/recoil/user";
import RightUserListWithCompany from "./right/right.user.company";
import RightUserListWithProject from "./right/right.user.project";
import { Workstation } from "../../../../../@types/workstation/workstation";
import { ProjectUserMode } from "../../../../../@types/project/ui/user-search-mode";

interface Props {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  projectId?: number;
  workstation: any;
  getList: () => void;
  projectName?: string;
}
const WSUpdateUserPage = ({
  isOpen,
  setOpen,
  projectId,
  workstation,
  getList,
  projectName,
}: Props) => {
  const t = useT();
  const [isLoading, setIsLoading] = useState(true);
  const [leftUsers, setLeftUsers] = useState<ProjectUserUiData[]>([]);
  const [rightUsers, setRightUsers] = useState<ProjectUserUiData[]>([]);
  const [searchUsers, setSearchUsers] = useState<ProjectUserUiData[]>([]);

  const [uwHostname, setUwHostname] = useState("");
  const [user] = useRecoilState(userState);

  const [mode, setMode] = useState<ProjectUserMode>(ProjectUserMode.COMPANY);

  const { toLeft, toRight, onSubmit, getWorkstation } = _workstationMember({
    projectId,
    setRightUsers,
    setIsLoading,
    setLeftUsers,
    leftUsers,
    rightUsers,
    workstationId: workstation.workstationId || "",
    getList,
    uwHostname,
    setUwHostname,
    searchUsers,
    setSearchUsers,
  });

  useEffect(() => {
    getWorkstation();
  }, []);

  const getTitle = () => {
    if (workstation.name) {
      return (
        t("workstation.workstation_share_setting", "Workstation 사용자 설정") +
        ` (${workstation.name})`
      );
    } else {
      return t("workstation.workstation_share_setting", "Workstation 사용자 설정");
    }
  };
  return (
    <ModalForm title={getTitle()} size="xxl" isOpen={isOpen} setOpen={setOpen}>
      {projectName && (
        <ProjectTitle>
          Project : <ProjectName>{projectName}</ProjectName>
        </ProjectTitle>
      )}
      <Wrapper>
        {!isLoading && (
          <PrivBox>
            <LeftUserList setLeftUsers={setLeftUsers} leftUsers={leftUsers} />
            <div className="center_btn">
              {mode === ProjectUserMode.COMPANY && (
                <ProjectCenterButton
                  toLeft={() => toLeft(ProjectUserMode.COMPANY)}
                  toRight={toRight}
                />
              )}
              {mode === ProjectUserMode.SEARCH && (
                <ProjectCenterButton
                  toLeft={() => toLeft(ProjectUserMode.SEARCH)}
                  toRight={toRight}
                />
              )}
            </div>
            {projectId && (
              <RightUserListWithProject rightUsers={rightUsers} setRightUsers={setRightUsers} />
            )}
            {!projectId && user.company && (
              <RightUserListWithCompany
                mode={mode}
                setMode={setMode}
                searchUsers={searchUsers}
                setSearchUsers={setSearchUsers}
                rightUsers={rightUsers}
                setRightUsers={setRightUsers}
                ownerGuid={user.userGuid}
              />
            )}
            {!projectId && !user.company && (
              <RightUserListWithCompany
                mode={mode}
                company={false}
                setMode={setMode}
                searchUsers={searchUsers}
                setSearchUsers={setSearchUsers}
                rightUsers={rightUsers}
                setRightUsers={setRightUsers}
                ownerGuid={user.userGuid}
              />
            )}
          </PrivBox>
        )}
        <ModalForm.ButtonBox setOpen={setOpen} onSubmit={onSubmit} />
      </Wrapper>
    </ModalForm>
  );
};
const Wrapper = styled.div`
  width: 1100px;
  // height: 650px;
  height: 700px;
  background-color: #ffffff;
  overflow-y: hidden;
  padding: 0 20px;
`;
const PrivBox = styled.article`
  height: 550px;
  width: 900px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
`;

const ProjectTitle = styled.div`
  font-size: 20px;
  margin-top: 20px;
  margin-left: 11%;
`;

const ProjectName = styled.span``;

export default WSUpdateUserPage;
