export const ConvertService = {
  setRound: (price: number, unit: number) => {
    // 반올림
    return Math.round(price / unit) * unit;
  },

  setPrice: (price: number) => {
    // 가격 10의 자리에서 반올림 , 한국식으로 변경 (10,000원)
    return ConvertService.setRound(price, 1).toLocaleString("ko-KR");
  },

  /**
   * code.cd 의 형태가 'OS.Windows10','OS.BootingSize.50GB','OS.InstalledSw.Basic' 인 codes를 넣으면
   * group이라는 변수에 적힌 값으로 시작하는 code들만 반납하는 함수
   * @param codes
   * @param prefix 원하는 그룹의 형태, 접두사 ex) OS , OS.BootingSize
   * @returns
   */
  getCodes: (codes: any, prefix: string) => {
    return codes.filter(
      (code: any) =>
        code.cd.indexOf(prefix) === 0 &&
        code.cd.split(".").length === prefix.split(".").length + 1
    );
  },
};
