import React, { useEffect } from "react";
import i18n from "../../constants/i18n";
import { atom, useRecoilState } from "recoil";
import styled from "styled-components";

// alert state
export const alertState = atom<AlertService>({
  key: "alert",
  default: {
    open: false,
    message: i18n.t("common.there_is_a_server_error", "서버 에러입니다."),
    type: "alert",
  },
});

export const noCreditState = atom<boolean>({
  key: "noCredit",
  default: false,
});

export const useAlert = () => {
  const [open, setOpen] = useRecoilState(alertState);

  const [popNoCredit, setPopNoCredit] = useRecoilState(noCreditState);

  const setAlert = (message: string) => {
    return setOpen(new AlertService(message, "alert"));
  };

  const setSuccess = (message: string) => {
    return setOpen(new AlertService(message, "success"));
  };

  const handleClose = () => setOpen({ ...open, open: false });

  useEffect(() => {
    let time = 2500;
    if (open.type === "success") time = 3500;
    if (open.open === true)
      setTimeout(() => setOpen({ ...open, open: false }), time);
  }, [open.open]);

  const isOpen = () => {
    if (open.open === true) return "openAnimation";
    if (open.open === false) return "closeAnimation";
  };

  const alertUi = () => {
    return (
      <>
        {open.open && (
          <Wrapper onClick={() => handleClose()} id={isOpen()}>
            <div>
              {open.type === "alert" && (
                <img
                  className="alert_img"
                  src="/assets/sub/alert.png"
                  alt="alert"
                />
              )}
              {open.type === "success" && (
                <img src="/assets/sub/check.png" alt="check" />
              )}
            </div>
            <div className="message_box">
              <h3>{open.message}</h3>
            </div>
          </Wrapper>
        )}
      </>
    );
  };

  const setCreditAlert = (bool: boolean) => {
    return setPopNoCredit(bool);
  };

  return { setAlert, setSuccess, alertUi, setCreditAlert };
};

class AlertService {
  open: boolean = true;
  message: string;
  type?: "alert" | "success";

  constructor(message: string, type?: "alert" | "success") {
    this.type = type || "alert";
    this.message = message;
  }
}

const Wrapper = styled.article`
  position: fixed;
  margin: auto;
  z-index: 1301;
  left: 0;
  right: 0;
  top: 20vh;
  text-align: center;
  background-color: #ffffff;
  border: 0.3px solid #dfdfdf;
  width: 300px;
  border-radius: 10px;
  color: #333;
  img {
    height: 60px;
    margin-top: 80px;
  }
  .alert_img {
    filter: brightness(150%);
  }

  .message_box {
    margin-top: 20px;
    padding: 0 30px;
    padding-bottom: 30px;
    min-height: 50px;
    max-height: 500px;
    h3 {
    }
  }
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  &#openAnimation {
    opacity: 1;
  }
  &#closeAnimation {
    animation: fadeOut 1s ease-in-out;
  }
`;
