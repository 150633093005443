import React, { useState } from "react";
import { Workstation } from "../../../../../@types/workstation/workstation";

import { useAlert } from "../../../../../components/alert/alert.service";
import { useT } from "../../../../../constants/i18n/useT";
import ModalPopup from "../../../../../components/modal/popup";
import { WorkstationService } from "../../../../../service/workstation/workstation.service";
import { ModalProps } from "../../../../../components/modal";

interface Props extends ModalProps {
  workstation: Workstation;
  getList: () => void;
}
const WSRestart = ({ isOpen, setOpen, workstation, getList }: Props) => {
  const [isResponse, setIsResponse] = useState(false);
  const { setSuccess, setAlert } = useAlert();

  const onSubmit = () => {
    setIsResponse(true);
    WorkstationService.restart(workstation.workstationId!)
      .then(res => {
        setSuccess(workstation.name + t("workstation.has_been_restarted", "이 재시작되었습니다."));
      })
      .catch(error => {
        if (error.response && error.response.status === 400) {
          setAlert(
            t("workstation.workstation_is_not_running.", "워크스테이션이 실행중이 아닙니다.")
          );

          return;
        }

        if (error.response && error.response.status === 404) {
          setAlert(t("workstation.not_found_ws", "워크스테이션 조회에 실패했습니다."));

          return;
        }

        if (error.response && error.response.status === 500) {
          setAlert(t("common.error", "현재 서버가 원활하지 않습니다. 잠시 후 시도해주세요."));

          return;
        }
      })
      .finally(() => {
        setIsResponse(false);
        setOpen(false);
        getList();
      });
  };
  const t = useT();

  return (
    <ModalPopup
      isResponse={isResponse}
      isOpen={isOpen}
      setOpen={setOpen}
      buttonText={t("common.restart", "재시작")}
      onSubmit={onSubmit}
    >
      <h1>
        {t("workstation.restarting_the_next_workstation", "Workstation을 재시작 하시겠습니까?")}
      </h1>
    </ModalPopup>
  );
};

export default WSRestart;
