import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { Storage } from "../../../../../@types/storage/storage";
import { useAlert } from "../../../../../components/alert/alert.service";

import { useT } from "../../../../../constants/i18n/useT";
import ModalPopup from "../../../../../components/modal/popup";
import { ModalProps } from "../../../../../components/modal";
import { StorageService } from "../../../../../service/storage/storage.service";

interface Props extends ModalProps {
  storage: Storage;
  getList: () => void;
}
const StorageDelete = (props: Props) => {
  const [isResponse, setIsResponse] = useState(false);

  const { setAlert, setSuccess } = useAlert();

  const t = useT();

  const onSubmit = () => {
    setIsResponse(true);
    StorageService.delete(props.storage.storageId)
      .then(res => {
        if (res.state === "success") {
          setSuccess(props.storage.name + t("storage.has_been_deleted", "를 삭제했습니다."));
          props.getList();
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 400) {
          setAlert(
            t(
              "storage.please_delete_shared_user",
              "공유 사용자가 존재합니다. 공유 사용자가를 모두 해제 후 삭제하여 주십시요."
            )
          );
          // setAlert(
          //   `${props.storage} 공유 사용자가 존재합니다. 공유 사용자가를 모두 해제 후 삭제하여 주십시요.`
          // );
        }
      })
      .finally(() => {
        setIsResponse(false);
        props.setOpen(false);
      });
  };

  return (
    <ModalPopup
      {...props}
      buttonText={t("common.delete", "삭제")}
      type="delete"
      onSubmit={onSubmit}
      isResponse={isResponse}
    >
      <h1>
        {t(
          "storage.do_you_want_to_delete_the_following_storage",
          "다음 스토리지를 삭제하시겠습니까?"
        )}
      </h1>
      <p>
        {t(
          "storage.the_data_stored_in_the_storage",
          "Storage에 저장된 데이터들은 영구히 삭제됩니다."
        )}
      </p>
      <p id="blue">{props.storage.name}</p>
    </ModalPopup>
  );
};

export default StorageDelete;
