import React, { useState } from "react";
import { Dropdown } from "../../dropdown/Dropdown";
import { useT } from "../../../constants/i18n/useT";
import { useNavigate } from "react-router-dom";
import CalculateModal from "../../../pages/billing/calculate/modal";

const BillingButton = () => {
  const navigate = useNavigate();
  const t = useT();
  const [popCalculate, setPopCalculate] = useState(false);

  return (
    <Dropdown title={t("billing.pricing", "요금")}>
      <li onClick={() => navigate("/billing/policy")}>
        {t("billing.m2m_pricing", "요금 안내")}
      </li>
      <li onClick={() => setPopCalculate(true)}>
        {t("billing.pricing_calculator", "요금 계산기")}
      </li>
      {popCalculate && (
        <CalculateModal setOpen={setPopCalculate} isOpen={popCalculate} />
      )}
    </Dropdown>
  );
};

export default BillingButton;
