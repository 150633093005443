import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

const Sidebar = () => {
  const { pathname } = useLocation();

  const isActive = (path: string) => {
    const currentPath = pathname.split("/")[1];
    return currentPath === path ? "active" : "inactive";
  };

  return (
    <Wrapper>
      <Link className={isActive("project")} to="/project">
        Project
      </Link>
      <Link to="/workstation" className={isActive("workstation")}>
        Workstation
      </Link>

      <Link to="/storage" className={isActive("storage")}>
        Storage
      </Link>

      <Link className={isActive("mypage")} to="/mypage/info">
        My Page
      </Link>
    </Wrapper>
  );
};

const Wrapper = styled.nav`
  display: none;
  width: 245px;
  background: #fff;
  border-right: 1px solid #ddd;
  padding: 0 20px;
  z-index: 200;
  padding-top: 120px;
  font-weight: 600;
  font-size: 17px;
  margin-bottom: 5px;
  @media (min-width: 1024px) {
    display: block;
  }
  a {
    line-height: 50px;
    padding: 0 20px;
    color: #333;
    width: 80%;
    display: inline-block;
    margin-bottom: 20px;

    &:hover {
      background: #f2f2f2;
      border-radius: 5px;
    }
    &.active {
      background: var(--GridaBlue);
      color: #fff;
      border-radius: 5px;
      background-image: url("/assets/user/menu_arrow.png");
      background-repeat: no-repeat;
      background-position: right 20px center;
    }
  }
`;

export default Sidebar;
