import React, { useRef, useEffect } from "react";
import * as d3 from "d3";
import { Storage } from "../../../../../@types/storage/storage";

interface DonutChartProps {
  myStorages: Storage[];
  sharedAsDirectorStorages: Storage[];
  sharedAsMemberStorages: Storage[];
  sharedAsNoProjectStorages: Storage[];
}

const StorageCapacityDonutChart: React.FC<DonutChartProps> = ({
  myStorages,
  sharedAsDirectorStorages,
  sharedAsMemberStorages,
  sharedAsNoProjectStorages,
}) => {
  const ref = useRef<SVGSVGElement | null>(null);

  const allStorages = [
    ...myStorages,
    ...sharedAsDirectorStorages,
    ...sharedAsMemberStorages,
    ...sharedAsNoProjectStorages,
  ];

  const capacityCounts = allStorages.reduce((acc, storage) => {
    if (storage.capacity !== undefined) {
      const capacity = String(storage.capacity);
      acc[capacity] = (acc[capacity] || 0) + 1;
    }
    return acc;
  }, {} as Record<string, number>);

  const storageData = allStorages.reduce((acc, storage) => {
    if (storage.capacity !== undefined) {
      const capacity = String(storage.capacity);
      if (!acc[capacity]) {
        acc[capacity] = [];
      }
      acc[capacity].push(storage.name);
    }
    return acc;
  }, {} as Record<string, string[]>);

  const chartData = Object.entries(capacityCounts).map(([capacity, count]) => ({
    label: capacity,
    value: count,
    storages: storageData[capacity],
  }));

  useEffect(() => {
    const svg = d3.select(ref.current);
    const width = 300;
    const height = 300;
    const margin = { top: 10, right: 10, bottom: 10, left: 50 };
    const radius = Math.min(width, height) / 2 - margin.top;

    svg.selectAll("*").remove();

    svg
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom);

    const g = svg
      .append("g")
      .attr("transform", `translate(${width / 2 + margin.left}, ${height / 2 + margin.top})`);

    const color = d3
      .scaleOrdinal<string>()
      .domain(chartData.map(d => d.label))
      .range([
        "#4285F4",
        "#34A853",
        "#FBBC05",
        "#EA4335",
        "#FF6347",
        "#8A2BE2",
        "#FFD700",
        "#00FA9A",
        "#FF4500",
        "#1E90FF",
      ]);

    const pie = d3
      .pie<any>()
      .value(d => d.value)
      .sort(null);

    const path = d3
      .arc<d3.PieArcDatum<any>>()
      .outerRadius(radius)
      .innerRadius(radius - 100);

    const arcs = g
      .selectAll("arc")
      .data(pie(chartData.filter(d => d.value > 0)))
      .enter()
      .append("g");

    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("position", "absolute")
      .style("background-color", "#333")
      .style("color", "#fff")
      .style("border-radius", "8px")
      .style("padding", "10px")
      .style("box-shadow", "0 4px 8px rgba(0, 0, 0, 0.2)")
      .style("opacity", 0)
      .style("pointer-events", "none")
      .style("z-index", 1001);

    const showTooltip = (event: any, d: any) => {
      tooltip
        .style("opacity", 1)
        .html(
          `
          <div style="font-weight: bold; font-size: 14px; margin-bottom: 5px;">${d.data.label}</div>
          <div style="font-size: 12px; line-height: 1.5;">
            ${d.data.storages
              .map(
                (storage: any) => `
                <div style="margin-bottom: 5px;">
                  ${storage}
                </div>`
              )
              .join("")}
          </div>
        `
        )
        .style("left", `${event.pageX + 10}px`)
        .style("top", `${event.pageY + 10}px`);
    };

    const moveTooltip = (event: any) => {
      tooltip.style("left", `${event.pageX + 10}px`).style("top", `${event.pageY + 10}px`);
    };

    const hideTooltip = () => {
      tooltip.style("opacity", 0);
    };

    arcs
      .append("path")
      .attr("fill", d => color(d.data.label))
      .transition()
      .duration(1000)
      .attrTween("d", function(d) {
        const i = d3.interpolate({ startAngle: 0, endAngle: 0 }, d);
        return function(t) {
          return path(i(t)) || "";
        };
      });

    arcs
      .append("text")
      .attr("transform", d => `translate(${path.centroid(d)})`)
      .attr("dy", "0.35em")
      .style("text-anchor", "middle")
      .style("font-size", "12px")
      .style("fill", "#000")
      .style("font-weight", "bold")
      .each(function(d) {
        const group = d3.select(this);
        const label = `${d.data.label}: `;
        const value = d.data.value;

        group
          .append("tspan")
          .text(label)
          .style("font-weight", "bold");

        group
          .append("tspan")
          .text(value)
          .style("text-decoration", "underline")
          .style("font-weight", "bold");
      })
      .style("opacity", 0)
      .on("mouseover", showTooltip)
      .on("mousemove", moveTooltip)
      .on("mouseout", hideTooltip)
      .transition()
      .delay(1000)
      .duration(500)
      .style("opacity", 1);

    return () => {
      tooltip.remove();
    };
  }, [chartData]);

  return <svg ref={ref}></svg>;
};

export default StorageCapacityDonutChart;
