import { StorageType } from "./storage-type";
import { Storage } from "../storage";

export class RefindedStorage {
  type: StorageType;
  storageId?: string;
  letter: string = "";
  name?: string;
  maapingWSId?: string;
  // used: boolean = false;
  using: boolean = false;
  user?: { fullName?: string };
  shareWith?: any[];

  constructor(type: StorageType, storage: Storage) {
    this.type = type;

    {
      this.storageId = storage.storageId;
      this.name = storage.name;
      this.user = storage.user;
      this.shareWith = storage.shareWith;
    }
  }
  setName = (name: string) => {
    this.name = name;
  };
  checkPrivilType = () => {
    return this.type === StorageType.PRIVIL;
  };
}
