import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Code } from "../../../../../@types/infra/code";
import { useT } from "../../../../../constants/i18n/useT";
import PrivUserEach from "./user/each-user";
import { StorageUser } from "../../../../../@types/storage/response";
import { SelectChangeEvent } from "@mui/material";
import { StorageService } from "../../../../../service/storage/storage.service";
import { ConvertService } from "../../../../../service/common/convert.service";

interface Props {
  LUsers: StorageUser[];
  leftCart: StorageUser[];
  originUsers: any[];
  mode: "COMPANY" | "SEARCH";
  setLeftCart: React.Dispatch<React.SetStateAction<StorageUser[]>>;
  setLUsers: React.Dispatch<React.SetStateAction<StorageUser[]>>;
  readOnly?: boolean;
}

const SelectUserList = ({
  LUsers,
  setLeftCart,
  mode,
  leftCart,
  setLUsers,
  originUsers,
  readOnly,
}: Props) => {
  const [privs, setPrivs] = useState<Code[]>([]);

  const handleRadio = (usr: StorageUser) => {
    if (isChecked(usr)) setLeftCart(leftCart.filter((user) => user !== usr));
    else setLeftCart([...leftCart, usr]);
  };

  const isChecked = (usr: StorageUser) => {
    return leftCart.includes(usr);
  };

  const updatePriv = (user: StorageUser, e: SelectChangeEvent<any>) => {
    const selected = privs.find((priv) => priv.cd === e.target.value) || {
      cd: "Storage.Privilege.RO",
      name: "읽기전용",
    };

    const newUsers: StorageUser[] = [];

    LUsers.map((usr) => {
      if (user.userGuid === usr.userGuid)
        newUsers.push({
          ...usr,
          privilege: selected,
        });
      else newUsers.push(usr);
    });

    setLUsers(newUsers);
  };

  const getUiStorage = () => {
    // 읽기, 읽고쓰기,삭제
    StorageService.getUi().then((res) => {
      if (res.state === "success")
        setPrivs(ConvertService.getCodes(res.data.codes, "Storage.Privilege"));
    });
  };

  const setUserToLeft = () => {
    const newList: StorageUser[] = [];
    originUsers.map((_user) => {
      try {
        const newUser = new StorageUser(_user.user);
        if (_user.storageUserId) newUser.storageUserId = _user.storageUserId;
        newList.push(newUser);
      } catch {
        console.log(_user);
      }
    });
    setLUsers(newList);
  };

  useEffect(() => getUiStorage(), []);

  useEffect(() => setUserToLeft(), [originUsers]);
  const t = useT();
  return (
    <Wrapper>
      <Title>
        <h1>
          <span id="blue">{t("storage.collaborator", "공유 사용자")}</span>
        </h1>
      </Title>
      <Content>
        {LUsers.map((usr) => (
          <PrivUserEach
            readOnly={readOnly}
            handleRadio={handleRadio}
            isChecked={isChecked}
            updatePriv={updatePriv}
            user={usr}
            privs={privs}
            key={usr.userGuid}
          />
        ))}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.section``;
const Title = styled.article`
  height: 50px;
  display: flex;
  justify-content: space-between;
  width: 75%;
  margin: auto;
  text-align: center;
  align-items: center;
  h1 {
    font-size: 15px;
  }
`;
const Content = styled.div`
  border: 0.5px solid #9c9c9c;
  height: 470px;
  width: 400px;
  margin: 10px 50px;
  background-color: #fff;
  border-radius: 5px;
  overflow-y: auto;
  padding-top: 20px;
`;

export default SelectUserList;
