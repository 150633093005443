import React, { useEffect } from "react";
import styled from "styled-components";
import { Storage } from "../../../../../../@types/storage/storage";
import UsageBar from "./usage-bar";
import { DateService } from "../../../../../../service/common/date.service";
import { Link } from "react-router-dom";
import { useT } from "../../../../../../constants/i18n/useT";
import { Tooltip } from "@mui/material";

interface Props {
  storage: Storage;
  own: boolean;
}
const CardBody = ({ storage, own }: Props) => {
  const t = useT();
  const timezone = navigator.language;

  const getMyPrivilege = () => {
    return (
      !own &&
      storage.users &&
      storage.users[0] &&
      storage.users[0].privilege &&
      storage.users[0].privilege.name
    );
  };

  const getUserName = () => {
    let result = (
      <>
        {storage.shareWith &&
          storage.shareWith.map((userData, idx) => {
            return <p key={userData.userGuid}>{userData.fullName}</p>;
          })}
      </>
    );
    return result;
  };

  const getWorkstationName = () => {
    let result = (
      <>
        {storage.workstations &&
          storage.workstations.map((workstation, idx) => {
            return <p key={workstation.workstationId}>{workstation.name}</p>;
          })}
      </>
    );
    return result;
  };

  return (
    <Wrapper>
      <div>
        <h4>{storage.capacity}</h4>
        <MyPrivilege>{getMyPrivilege()}</MyPrivilege>
        <UsageBar storage={storage} />
      </div>
      <CardFooter>
        {storage.createdAt && (
          <p>Created : {DateService.formatDate(storage.createdAt, timezone)}</p>
        )}
        <p>FTP : {storage.isFtp ? "Enabled" : "Disabled"}</p>

        {storage.shareWith && storage.shareWith.length > 0 && (
          <p>
            {t("common.shared_user", "공유 사용자")} :
            <Tooltip title={getUserName()}>
              <span>
                <Counts>{storage.shareWith.length}</Counts>

                {t("common.person", "명")}
              </span>
            </Tooltip>
          </p>
        )}

        {storage.projectId && (
          <p>
            Project :
            {own ? (
              <Link
                to="/storage#project"
                state={{
                  projectId: storage.projectId,
                }}
                className="project_a"
              >
                {storage.projectName}
              </Link>
            ) : (
              storage.projectName
            )}
          </p>
        )}

        {storage.workstations && storage.workstations.length > 0 && (
          <p>
            Mount :
            <Tooltip title={getWorkstationName()}>
              <span>
                <Counts>{storage.workstations.length}</Counts>
              </span>
            </Tooltip>
          </p>
        )}
      </CardFooter>
    </Wrapper>
  );
};

const Wrapper = styled.article`
  padding: 7px 15px 15px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  .project_a {
    color: #989898;
    text-decoration: underline;
    font-weight: 500;
    cursor: pointer;
    &:hover {
      color: #3b3b3b;
      font-weight: 600;
    }
  }
  h4 {
    height: 100px;
    font-size: 40px;
    font-weight: 600;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const CardFooter = styled.div`
  height: 100px;
  padding: 5px 0;
  margin-top: 15px;
  border-top: 0.5px solid #cacaca;
  font-size: 13px;
  color: #828282;
  p {
    margin-top: 5px;
  }
`;

const MyPrivilege = styled.p`
  height: 17px;
  font-size: 14px !important;
  font-weight: 600;
  color: var(--GridaGray) !important;
`;

const Counts = styled.span`
  text-decoration: underline;
  margin-right: 5px;
  font-weight: 1000;
  margin-left: 5px;
`;

export default CardBody;
