export enum DEV_LOCATION { // DEV 환경일 경우 domain, brokerIp 변경
  IP = "192.168.0.12",
  LOCAL_DOMAIN = "m2m.local",
  LOCALHOST = "localhost",
  TOBE_DOMAIN = "m2m-human.local",
}

export enum DEV_SETTING {
  DOMAIN = "m2m-tobe.local",
  BROKER_IP = "192.168.0.64",
  VERSION = 3,
}
export enum PROD_SETTING {
  DOMAIN = "m2m-grida.com",
  BROKER_IP = "m2m.grida.dev:64433",
  VERSION = 3,
}
export enum STAGE_LOCATION {
  DOMAIN = "m2m.grida.dev",
  CLIENT = "https://m2m.grida.dev",
}
export enum CLIENT {
  STAGE = "https://m2m.grida.dev",
  DEV = "http://192.168.0.58:31001",
  // PROD = "http://10.10.1.165:31001",
  PROD = "http://10.10.1.168:31001",
}
export enum EXE {
  // WINDOW = "/resource/client/windows/M2MClient_1.0.3-1.exe",
  WINDOW = "/resource/client/windows/M2MClient_1.3.0-1.exe",
  LINUX = "/resource/client/linux/m2m-client_1.2.0.tar.gz",
  MAC = "/resource/client/macos/M2MClient-1.2.0.dmg",
}
