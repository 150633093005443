import React, { useEffect, useState } from "react";
import styled, { FlattenSimpleInterpolation } from "styled-components";
import { Workstation } from "../../../../@types/workstation/workstation";
import CardTitle from "./components/header/title";
import OptionIcon from "./components/button/options";
import WSQuit from "../modal/quit/modal";
import WSRestart from "../modal/restart/modal";
import WSSetCompleted from "../modal/ready/modal";
import { CardBody1 } from "./components/body/card-body-1";
import { CardBody2 } from "./components/body/card-body-2";
import { CardBody3 } from "./components/body/card-body-3";
import { CardBody4 } from "./components/body/card-body-4";
import { WorkstationService } from "../../../../service/workstation/workstation.service";
import { CardEditType } from "../../../../@types/type/card-edit";
import { CardService, StatusType } from "./components/card-setting";

interface Props {
  workstation: Workstation;
  getList: () => void;
  editType?: CardEditType;
}

const WSCard = ({ workstation, getList, editType }: Props) => {
  // popup 창
  const [popQuit, setPopQuit] = useState(false);
  const [popRestart, setPopRestart] = useState(false);
  const [popOpenPC, setPopOpenPC] = useState(false);

  // 사용시간
  const [usedTime, setUsedTime] = useState(0);

  // 현재상태

  const [status, setStatus] = useState(CardService.getStatus(workstation));

  // 직전의 상태
  const [tmpWS, setTmpWS] = useState<Workstation>(new WorkstationService());
  const [powerOn, setPowerOn] = useState(true);

  const [visualPercent, setVisualPercent] = useState(Number(workstation.progressRate) || -1);
  // 시각적 data

  const 이전WS와같은지 = () => {
    return tmpWS.workstationId === workstation.workstationId;
  };

  // OFF 일때

  const 상태 = {
    준비다되었을때: () =>
      CardService.getStatus(tmpWS) !== "Running" &&
      CardService.getStatus(workstation) === "Running",

    전원끌때: () =>
      CardService.getStatus(tmpWS) === "Running" &&
      CardService.getStatus(workstation) === "ShuttingDown",

    완전히꺼졌을때: () =>
      CardService.getStatus(tmpWS) === "ShuttingDown" &&
      (CardService.getStatus(workstation) === "PowerOff" ||
        CardService.getStatus(workstation) === "NotPowerOff"),

    방금전원을껐을때: () => !powerOn,
  };

  const openPCStart = () => {
    setTimeout(() => setPopOpenPC(true), 1000);
  };

  const 전WS와비교후상태변경 = () => {
    setVisualPercent(Number(workstation.progressRate) || -1);
    if (이전WS와같은지()) {
      if (상태.준비다되었을때()) {
        openPCStart();
      }
      if (상태.전원끌때()) {
        setPowerOn(true);
      }
      if (상태.완전히꺼졌을때()) {
        setVisualPercent(1);
      }
      if (상태.방금전원을껐을때()) {
        setStatus("ShuttingDown");
      }
    }
    setTmpWS(workstation); // 1번
  };

  const 사용시간측정 = () => {
    if (
      CardService.getStatus(workstation) === "Running" &&
      workstation.runningTime &&
      workstation.runningTime.serverDt &&
      workstation.runningTime.startDt
    ) {
      let gap =
        (new Date(workstation.runningTime.serverDt).getTime() -
          new Date(workstation.runningTime.startDt).getTime()) /
        1000 /
        60;

      setUsedTime(Math.ceil(gap));
    }
  };

  const 백넘는경우상태값없애기 = () => {
    if (visualPercent >= 1) setTimeout(() => setVisualPercent(-1), 3000);
  };

  const 상태에따라Progress올리기 = () => {
    if (!workstation.progressRate) setVisualPercent(-1);
  };

  useEffect(() => {
    setStatus(CardService.getStatus(workstation));
    전WS와비교후상태변경();
    사용시간측정();
  }, [workstation]);

  useEffect(() => {
    상태에따라Progress올리기();
    백넘는경우상태값없애기();
  }, [visualPercent]);

  return (
    <CardWrapper>
      <CardTopWrapper colorStyle={CardService.getHeaderColor(status)}>
        <CardTitle
          workstation={workstation}
          setPopQuit={setPopQuit}
          setPopRestart={setPopRestart}
          status={status}
          powerOn={powerOn}
          getList={getList}
          name={workstation.name}
        />

        <OptionIcon
          editType={editType}
          status={status}
          workstation={workstation}
          getList={getList}
        />
      </CardTopWrapper>

      <CardBodyWrapper>
        <CardBody1 workstation={workstation} />
        <CardBody2 workstation={workstation} />
        <CardBody3
          usedTime={usedTime}
          status={status}
          workstation={workstation}
          visualPercent={visualPercent}
          editType={editType}
        />
        <CardBody4 workstation={workstation} />
      </CardBodyWrapper>

      {popQuit && (
        <WSQuit
          setPowerOn={setPowerOn}
          setVisualPercent={setVisualPercent}
          setStatus={setStatus}
          setOpen={setPopQuit}
          isOpen={popQuit}
          workstation={workstation}
        />
      )}

      {popOpenPC && (
        <WSSetCompleted setOpen={setPopOpenPC} isOpen={popOpenPC} workstation={workstation} />
      )}

      {popRestart && (
        <WSRestart
          getList={getList}
          setOpen={setPopRestart}
          isOpen={popRestart}
          workstation={workstation}
        />
      )}
    </CardWrapper>
  );
};

interface StyleProps {
  colorStyle: FlattenSimpleInterpolation;
}

const CardWrapper = styled.div`
  width: 270px; // => 320px
  margin: 15px; // => 30px 20px
  background-color: #f1f1f1;
  border-radius: 5px;
  border: 1px solid #ddd;
  display: inline-block;
  vertical-align: top;
  position: relative;

  &:hover {
    box-shadow: 2px 2px 7px #e1e1e1;
    transition: 0.3s;
  }

  .time {
    font-size: 12px;
    color: #666;
    line-height: 18px;
    display: flex;
    span {
      height: 18px;
      width: 70px;
    }
  }
  @media (min-width: 768px) {
    width: 320px;
    margin: 30px 20px;
  }
`;

const CardTopWrapper = styled.section<StyleProps>`
  ${p => p.colorStyle}
  padding: 8px 10px;
  font-weight: 500;
  transition: 1.5s ease-out;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 768px) {
    padding: 10px 10px;
  }
`;
const CardBodyWrapper = styled.article`
  padding-left: 20px;
  padding-right: 15px;
  .item {
    padding: 3px 0;
    margin-bottom: 9px;
    margin-top: 3px;
    display: flex;
    justify-content: space-between;
  }
  font-size: 11px; // => 13px
  .title {
    display: flex;
    align-items: center;
    font-weight: 700;
    color: #000;
    width: 25%;
    img {
      width: 18px; // => 22
      height: 16px; // => 20
      margin-right: 10px;
    }
  }
  .content {
    width: 60%;

    color: #a5a5a5;
  }
  @media (min-width: 768px) {
    font-size: 13px;
    .title {
      img {
        width: 22px;
        height: 20px;
      }
    }
  }
`;

export default WSCard;
