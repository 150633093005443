import React from "react";
import { Code } from "../../../../../../@types/infra/code";
import UserListForm from "../../../../../workstation/components/modal/update-user/components/user.list.wapper";
import StorageCheckBox from "../../../../../storage/components/modal/share/left/storage-checkbox";
import { useT } from "../../../../../../constants/i18n/useT";
import { StorageUserUiData } from "../../../../../../@types/storage/ui/storage.user";
import { MemberService } from "../../../../../../service/common/member.service";

interface Props {
  leftUsers: StorageUserUiData[];
  setLeftUsers: React.Dispatch<React.SetStateAction<StorageUserUiData[]>>;
  types?: Code[];
}

const ProjectStorageLeftUserList = ({ leftUsers, setLeftUsers, types }: Props) => {
  const handleCheckbox = (user: StorageUserUiData) => {
    const index = leftUsers.findIndex(u => u.userGuid === user.userGuid);
    const newUserList = [...leftUsers];
    newUserList[index].isChecked = !newUserList[index].isChecked;
    setLeftUsers(newUserList);
  };
  const handleType = (user: StorageUserUiData) => (type: string) => {
    const index = MemberService.findIndex(user, leftUsers);
    const newUserList = [...leftUsers];
    newUserList[index].privilege = { cd: type };

    setLeftUsers(newUserList);
  };

  const t = useT();
  return (
    <UserListForm title={t("workstation.user", "사용자")}>
      {leftUsers.map(
        user =>
          !user.deleted && (
            <StorageCheckBox
              key={user.userGuid}
              title={user.fullName}
              isChecked={user.isChecked}
              typeList={types}
              handleCheckbox={() => handleCheckbox(user)}
              handleType={handleType(user)}
              user={user}
            />
          )
      )}
    </UserListForm>
  );
};

export default ProjectStorageLeftUserList;
