import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { noCreditState } from "../alert/alert.service";
import UserContactModal from "../../pages/mypage/contact/create/modal";
import { WindowService } from "../../service/common/window.service";
import NoCreditAlertPage from "../alert/no-credit.page";

const PrivateRoute = ({ element }: { element: React.ReactElement }) => {
  // 여기서 토큰 없으면 로그인 페이지로 이동 🔥
  let TOKEN = sessionStorage.getItem("x-access-token");

  const [popNoCredit, setPopNoCredit] = useRecoilState(noCreditState);
  const [popChargeCredit, setPopChargeCredit] = useState(false);

  useEffect(() => { // 🍋 토큰 관리
    if (!TOKEN) WindowService.goLogin();
  }, []);

  return (
    <div>
      {element}
      {popNoCredit && (
        <NoCreditAlertPage
          isOpen={popNoCredit}
          setOpen={setPopNoCredit}
          setPopContact={setPopChargeCredit}
        />
      )}
      {popChargeCredit && (
        <UserContactModal
          isOpen={popChargeCredit}
          setOpen={setPopChargeCredit}
        />
      )}
    </div>
  );
};
export default PrivateRoute;
