import React from "react";
import styled from "styled-components";
import { Workstation } from "../../../../../../@types/workstation/workstation";
import { FiCpu } from "@react-icons/all-files/fi/FiCpu";
import { CgSmartphoneRam } from "@react-icons/all-files/cg/CgSmartphoneRam";
import { BsGpuCard } from "react-icons/bs";

export const CardBody1 = ({ workstation }: { workstation: Workstation }) => {
  const tableList = [
    { name: "CPU", cd: "cpu", icon: <FiCpu size={20} /> },
    { name: "RAM", cd: "ram", icon: <CgSmartphoneRam size={20} /> },
    { name: "GPU", cd: "gpu", icon: <BsGpuCard size={20} /> },
  ];

  const getDetail = (item: "cpu" | "ram" | "gpu" | string) => {
    if (item === "cpu")
      return (
        workstation.workstationSpec &&
        workstation.workstationSpec.cpu &&
        workstation.workstationSpec.cpu.name
      );
    if (item === "ram")
      return (
        workstation.workstationSpec &&
        workstation.workstationSpec.ram &&
        workstation.workstationSpec.ram.name
      );
    if (item === "gpu")
      return (
        workstation.workstationSpec &&
        (workstation.workstationSpec.gpu ? workstation.workstationSpec.gpu.name : "-")
      );
  };

  return (
    <Wrapper className="user_computer">
      {tableList.map(item => (
        <div className="item" key={item.cd}>
          <div className="title">
            {item.icon} <span>{item.name}</span>
          </div>
          <div className="content">{getDetail(item.cd)}</div>
        </div>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.article`
  border-bottom: 1px solid #e5e5e5;
  margin-top: 5px;

  span {
    margin-left: 10px;
  }
`;
