export class DateService {
  static getTimeExceptSecond = (time: String) => {
    return time
      .split(":")
      .slice(0, 2)
      .join(":");
  };
  static UI_YYYY_MM_DD = (date: Date) => {
    return `${date.getFullYear()}-${this.getMonthData(date)}-${this.getDayData(date)}`;
  };
  static API_YYYY_MM_DD = (date: Date) => {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  };

  static getMonthData = (date: Date) => {
    let data = date.getMonth() + 1;
    if (data < 10) return `0${data}`;
    return data;
  };

  static getDayData = (date: Date) => {
    let data = date.getDate();
    if (data < 10) return `0${data}`;
    return data;
  };

  static getPrev6Month = () => {
    const now = new Date();
    function prevMonth(month: number) {
      const result = new Date(now.getTime());
      const thisMonth = result.getMonth();
      result.setMonth(thisMonth - month);
      return getDateStr(result);
    }
    function getDateStr(myDate: Date) {
      var year = myDate.getFullYear();
      var month = ("0" + (myDate.getMonth() + 1)).slice(-2);
      return year + month;
    }
    let result = [];
    for (let i = 0; i < 6; i++) {
      result.push(prevMonth(i));
    }
    return result;
  };

  static formatDate(dateString: string, locale: string) {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat(locale, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      hour12: false,
    })
      .format(date)
      .replace(",", "");
  }
}
