import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Calender from "../../../../../../../components/calendar/calendar";
import { Project } from "../../../../../../../@types/project/project";
import { DateService } from "../../../../../../../service/common/date.service";
import { _projectInfoDate } from "../../../../../script/project.info";
import { useT } from "../../../../../../../constants/i18n/useT";
interface Props {
  project?: Project;
  startDate: Date;
  endDate: Date;
  setStartDate: React.Dispatch<React.SetStateAction<Date>>;
  setEndDate: React.Dispatch<React.SetStateAction<Date>>;
}

const DateSelectButton = ({
  project,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: Props) => {
  const [popStartDate, setPopStartDate] = useState(false);
  const [popEndDate, setPopEndDate] = useState(false);

  const { setProjectDate } = _projectInfoDate({
    setStartDate,
    setEndDate,
  });

  useEffect(() => {
    if (project) setProjectDate(project);
  }, [project]);

  const t = useT();
  return (
    <Wrapper>
      <div className="flex_box">
        <label className="title_label">{t("common.period", "기간")}{": "}</label>
        {popStartDate && (
          <div className="calender_box_1">
            <Calender
              isOpen={popStartDate}
              setOpen={setPopStartDate}
              dt={startDate}
              setDt={setStartDate}
            />
          </div>
        )}
        {popEndDate && (
          <div className="calender_box_2">
            <Calender
              isOpen={popEndDate}
              setOpen={setPopEndDate}
              dt={endDate}
              setDt={setEndDate}
            />
          </div>
        )}
        <div className="date_select">
          <label>{DateService.UI_YYYY_MM_DD(startDate)}</label>
          <button onClick={() => setPopStartDate(!popStartDate)}>
            <img src={"/assets/sub/calendar.png"} />
          </button>
        </div>
        <p className="date_to">~</p>
        <div className="date_select">
          <label>{DateService.UI_YYYY_MM_DD(endDate)}</label>
          <button onClick={() => setPopEndDate(!popEndDate)}>
            <img src={"/assets/sub/calendar.png"} />
          </button>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 40px;
  .title_label {
    display: block;
    font-size: 14px;
    font-weight: bold;
    color: #979797;
    margin-left: 5px;
    width: 50px;
  }
  .date_to {
    width: 30px;
    text-align: center;
  }
  .date_select {
    display: flex;
    align-items: center;
    height: 40px;
    label {
      font-size: 11px;
      color: #828282;
    }
    button {
      width: 30px;
      height: 30px;
      margin-left: 10px;
      border: none;
      border-radius: 2px;
      background-color: #fff;
      img {
        width: 20px;
        filter: sepia(100%) hue-rotate(185deg) saturate(300%);
        border: 0.3px solid #828282;
        border-top: none;
        border-radius: 2px;
      }
      &:hover {
        cursor: pointer;
        background-color: #ededed6b;
      }
    }
  }
  .flex_box {
    display: flex;
    align-items: center;
    margin-top: 5px;
    position: relative;
    .calender_box_1 {
      position: absolute;
      top: 40px;
      left: 0px;
      z-index: 10;
    }
    .calender_box_2 {
      position: absolute;
      right: 0;
      top: 40px;
      z-index: 10;
    }
  }
`;
export default DateSelectButton;
