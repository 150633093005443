import React, { useState, useEffect } from "react";
import { Workstation } from "../../../../../@types/workstation/workstation";
import { useAlert } from "../../../../../components/alert/alert.service";
import styled from "styled-components";
import { useT } from "../../../../../constants/i18n/useT";
import ModalPopup from "../../../../../components/modal/popup";
import { ModalProps } from "../../../../../components/modal";
import { WorkstationService } from "../../../../../service/workstation/workstation.service";
import WSCantStart from "./components/ws-cant-start";

interface Props extends ModalProps {
  workstation: Workstation;
  getList: () => void;
}
const WSActive = ({ isOpen, setOpen, workstation, getList }: Props) => {
  const [isResponse, setIsResponse] = useState(false);

  const [cantStart, setCantStart] = useState(false);
  const [WSActive, setWSActive] = useState(true);

  const t = useT();

  const { setSuccess, setAlert } = useAlert();

  // useEffect(() => {
  //   setCantStart(true);
  // }, [cantStart]);

  const onSubmit = () => {
    setIsResponse(true);
    if (workstation.workstationId)
      WorkstationService.start(workstation.workstationId)
        .then(res => {
          setSuccess(
            t(
              "workstation.workstation_has_been_launched",
              "Workstation을 실행했습니다. 실행하는데 2분에서 최대 3분이 소요될 수 있습니다."
            )
          );

          setOpen(false);
        })
        .catch(error => {
          if (error.response && error.response.status === 400) {
            setAlert(
              t("workstation.workstation_aleady_start", "이미 사용 중인 워크스테이션 입니다.")
            );
            setOpen(false);

            return;
          }

          if (error.response && error.response.status === 402) {
            setAlert(t("workstation.insufficient_credit", "크레딧이 부족합니다."));
            setOpen(false);
            return;
          }

          if (error.response && error.response.status === 403) {
            setAlert(t("workstation.not_have_permission", "워크스테이션 실행 권한이 없습니다.."));
            setOpen(false);
            return;
          }

          if (error.response && error.response.status === 404) {
            setAlert(t("workstation.not_found_ws", "워크스테이션 조회에 실패했습니다."));
            setOpen(false);
            return;
          }

          if (error.response && error.response.status === 500) {
            setAlert(t("common.error", "현재 서버가 원활하지 않습니다. 잠시 후 시도해주세요."));

            return;
          }

          if (error.response && error.response.status === 503) {
            setWSActive(false);
            setCantStart(true);
          }
        })
        .finally(() => {
          setIsResponse(false);
          // setOpen(false);
          getList();
        });
  };

  return (
    <>
      {WSActive && (
        <ModalPopup
          isOpen={isOpen}
          setOpen={setOpen}
          buttonText={WSActive ? t("common.run", "실행") : null}
          onSubmit={onSubmit}
          isResponse={isResponse}
        >
          <>
            <h1>
              {t(
                "workstation.running_the_following_workstation",
                "아래의 Workstation을 실행합니다."
              )}
            </h1>
            <p id="blue">{workstation.name}</p>
          </>
        </ModalPopup>
      )}
      {cantStart && <WSCantStart isOpen={isOpen} setOpen={setOpen} getList={getList} />}
    </>
  );
};

export default WSActive;
