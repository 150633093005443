import { APICLIENT } from "../api";
import { Code } from "../../@types/infra/code";
import { StorageForm } from "../../@types/storage/form";
export const STORAGE_API = "/api/v1/infra-web-user/storages";
export const INFRA_WEB_USER = "/api/v1/infra-web-user";

export const StorageService = {
  create: async (storage: StorageForm) => {
    const response = await APICLIENT.AUTH.post(`${STORAGE_API}/create`, {
      data: storage,
    });

    return response.data;
  },
  update: async (storage: StorageForm) => {
    const response = await APICLIENT.AUTH.post(`${STORAGE_API}/update`, {
      data: storage,
    });

    return response.data;
  },

  getUi: async () => {
    const response = await APICLIENT.AUTH_NOALERT.get(`${STORAGE_API}/getUiData`);
    return response.data;
  },
  getByProjectId: async (projectId: number) => {
    const response = await APICLIENT.AUTH.get(
      // `${STORAGE_API}/getList/project/${projectId}`
      `${STORAGE_API}/${projectId}/getList`
    );
    return response.data;
  },
  getByNoProject: async () => {
    const response = await APICLIENT.AUTH.get(`${STORAGE_API}/no-project/getList`);
    return response.data;
  },
  getStorage: async (userGuid: string, filter: string, storageUsage: string) => {
    const response = await APICLIENT.AUTH.get(
      `${INFRA_WEB_USER}/${userGuid}/storage?filter=${filter}&storageUsage=${storageUsage}`
    );
    return response.data;
  },
  // getStorageByWorkstationId: async (
  //   userGuid: string,
  //   filter: string,
  //   workstationId: string,
  //   sotrageUsage: string
  // ) => {
  //   const response = await APICLIENT.AUTH.get(
  //     `${INFRA_WEB_USER}/${userGuid}/storage?filter=${filter}&workstationId=${workstationId}&storageUsage=${sotrageUsage}`
  //   );
  //   return response.data;
  // },

  getStorageByWorkstationId: async (
    userGuid: string,
    filter: string,
    workstationId: string,
    storageUsage: string
  ) => {
    const response = await APICLIENT.AUTH.get(
      `${INFRA_WEB_USER}/${userGuid}/storage?filter=${filter}&workstationId=${workstationId}&storageUsage=${storageUsage}`
    );
    return response.data;
  },

  getMyStorage: async (userGuid: string, filter: string) => {
    const response = await APICLIENT.AUTH.get(
      `${INFRA_WEB_USER}/${userGuid}/storage?filter=${filter}`
    );
    return response.data;
  },
  getSharedAsDirectorStorages: async (
    userGuid: string,
    filter: string,
    projectAssigned: string,
    projectRole: string
  ) => {
    const response = await APICLIENT.AUTH.get(
      `${INFRA_WEB_USER}/${userGuid}/storage?filter=${filter}&projectAssigned=${projectAssigned}&projectRole=${projectRole}`
    );
    return response.data;
  },
  getSharedAsArtistStorages: async (
    userGuid: string,
    filter: string,
    projectAssigned: string,
    projectRole: string
  ) => {
    const response = await APICLIENT.AUTH.get(
      `${INFRA_WEB_USER}/${userGuid}/storage?filter=${filter}&projectAssigned=${projectAssigned}&projectRole=${projectRole}`
    );
    return response.data;
  },
  getSharedAsNoProjectStorages: async (
    userGuid: string,
    filter: string,
    projectAssigned: string
  ) => {
    const response = await APICLIENT.AUTH.get(
      `${INFRA_WEB_USER}/${userGuid}/storage?filter=${filter}&projectAssigned=${projectAssigned}`
    );
    return response.data;
  },

  delete: async (storageId?: string) => {
    const response = await APICLIENT.AUTH.get(`${STORAGE_API}/delete/${storageId}`);
    return response.data;
  },
  getList: async () => {
    const response = await APICLIENT.AUTH.get(`${STORAGE_API}/getList`);
    return response.data;
  },
  getById: async (storageId: string) => {
    const response = await APICLIENT.AUTH.get(`${STORAGE_API}/get/${storageId}`);
    return response.data;
  },

  getStorageById: async (storageId: string) => {
    const response = await APICLIENT.AUTH.get(`${STORAGE_API}/${storageId}`);
    return response.data;
  },

  updateUser: async (data: any) => {
    const response = await APICLIENT.AUTH.post(`${STORAGE_API}/users/update`, { data });
    return response.data;
  },
  getDefualtDrives: () => new Drives(),
  filterBiggerSize: (sizes: Code[], size: number) => {
    return sizes.filter(siz => {
      let tmpValue = 0;
      if (!siz.value) return;
      try {
        tmpValue = Number(siz.value);
      } catch {
        return;
      }

      return tmpValue >= size;
    });
  },

  getMountedWorkstations: async (storageId: string) => {
    const response = await APICLIENT.AUTH.get(`${STORAGE_API}/${storageId}/mount`);

    return response.data;
  },

  getMountableWorkstations: async (storageId: string) => {
    const response = await APICLIENT.AUTH.get(`${STORAGE_API}/${storageId}/mountable`);

    return response.data;
  },

  mountWorkstations: async (storageId: string, workstations: any) => {
    const response = await APICLIENT.AUTH.post(`${STORAGE_API}/${storageId}/mount`, workstations);
    return response.data;
  },

  unmountWorkstations: async (storageId: string, workstation: string) => {
    const response = await APICLIENT.AUTH.delete(
      `${STORAGE_API}/${storageId}/mount?workstationId=${workstation}`
    );

    return response.data;
  },

  updateDriveLetter: async (storageId: string, workstationId: string, driveLetter: string) => {
    const response = await APICLIENT.AUTH.patch(
      `${STORAGE_API}/${storageId}/mount?workstationId=${workstationId}&driveLetter=${driveLetter}`
    );

    return response.data;
  },
};

export class Drives {
  Z: boolean = false;
  Y: boolean = false;
  X: boolean = false;
  W: boolean = false;
  V: boolean = false;
  U: boolean = false;
  T: boolean = false;
  S: boolean = false;
  R: boolean = false;
  Q: boolean = false;
}
