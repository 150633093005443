import React from "react";
import styled, { FlattenSimpleInterpolation } from "styled-components";
import { ButtonProps, ButtonStyle } from "./setting";

const RoundButton = (props: ButtonProps) => {
  const sizeStyle = ButtonStyle.SIZES[props.size || "md"];
  const colorStyle = ButtonStyle.COLOR[props.color || "skyblue"];
  const roundStyle = ButtonStyle.ROUND["round"];

  return (
    <StyledButton
      sizeStyle={sizeStyle}
      colorStyle={colorStyle}
      roundStyle={roundStyle}
      {...props}
    >
      {props.children}
    </StyledButton>
  );
};

interface StyleProps {
  sizeStyle: FlattenSimpleInterpolation;
  colorStyle: FlattenSimpleInterpolation;
  roundStyle: FlattenSimpleInterpolation;
}

const StyledButton = styled.button<StyleProps>`
  border: none;
  /* margin: 0; */
  cursor: pointer;
  font-weight: 600;
  ${(p) => p.sizeStyle}
  ${(p) => p.colorStyle}
  ${(p) => p.roundStyle}
`;

export default RoundButton;
