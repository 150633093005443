import React, { useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { userState } from "../../../../../constants/recoil/user";
import { useAlert } from "../../../../../components/alert/alert.service";

import CompanyInput from "../../../../signup/components/signup2/company-input";

import { useT } from "../../../../../constants/i18n/useT";
import { RegisterCompany } from "../../../../../@types/user/company";
import { ModalProps } from "../../../../../components/modal";
import ModalPopup from "../../../../../components/modal/popup";
interface Props extends ModalProps {
  getUser: () => void;
}
const UpdateCompany = ({ isOpen, setOpen, getUser }: Props) => {
  const [user] = useRecoilState(userState);
  const [isResponse, setIsResponse] = useState(false);

  const [company, setCompany] = useState(new RegisterCompany());
  const t = useT();

  const { setAlert, setSuccess } = useAlert();

  const onSubmit = () => {
    company.userGuid = user.userGuid;

    if (company.name === "") {
      return setAlert(
        t("user.please_enter_the_company_name", "회사명을 입력하세요")
      );
    }
    if (company.name === user.individualCompanyName) {
      return setAlert(
        t(
          "user.the_company_information_entered",
          "입력한 회사 정보가 이전과 동일합니다."
        )
      );
    }
    if (user.company && company.id === user.company.companyGuid) {
      return setAlert(
        t(
          "user.the_company_information_entered",
          "입력한 회사 정보가 이전과 동일합니다."
        )
      );
    }
    setIsResponse(true);
    company
      .update()
      .then((res) => {
        if (res.state === "success") {
          setSuccess(
            t(
              "user.the_company_information_has_updated",
              "회사 정보가 수정되었습니다."
            )
          );
        }
      })
      .finally(() => {
        setOpen(false);
        getUser();
        setIsResponse(false);
      });
    return;
  };

  const getCompanyName = () => {
    if (user.company)
      return `${user.company.name} (${t(
        "user.affiliate_company",
        "제휴 회사"
      )})`;
    return user.individualCompanyName;
  };

  return (
    <ModalPopup
      buttonText={t("user.update_company", "회사 수정")}
      size="md"
      isOpen={isOpen}
      isResponse={isResponse}
      setOpen={setOpen}
      onSubmit={onSubmit}
    >
      <Wrapper>
        {getCompanyName() && (
          <div className="original">
            <label>{t("user.existing_company", "기존 회사")} :</label>
            <span>{getCompanyName()}</span>
          </div>
        )}
        <CompanyInput company={company} setCompany={setCompany} />
      </Wrapper>
    </ModalPopup>
  );
};
const Wrapper = styled.div`
  height: 370px;
  .title {
    height: 50px;
  }
  .original {
    display: flex;
    justify-content: start;
    height: 50px;
  }
`;

export default UpdateCompany;
