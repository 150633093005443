import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { userState } from "../../../../../../constants/recoil/user";
import styled, { keyframes } from "styled-components";
import { WorkstationService } from "../../../../../../service/workstation/workstation.service";
import { DropDownSetting } from "../../../../../../components/dropdown/setting";
import { Workstation } from "../../../../../../@types/workstation/workstation";
import { useT } from "../../../../../../constants/i18n/useT";
import WSRestart from "../../../modal/restart/modal";
import CircleProgress from "../../../../../../components/loading/CircleProgress";
import { Dialog, DialogContent, Typography } from "@mui/material";
import { useAlert } from "../../../../../../components/alert/alert.service";

// Icons
import { ImExit } from "@react-icons/all-files/im/ImExit";
import { VscDebugRestart } from "@react-icons/all-files/vsc/VscDebugRestart";
import { GrPower } from "@react-icons/all-files/gr/GrPower";

interface Props {
  setPopQuit: React.Dispatch<React.SetStateAction<boolean>>;
  setPopRestart: React.Dispatch<React.SetStateAction<boolean>>;
  workstation: Workstation;
  getList: () => void;
}

const PowerOffButton = ({ setPopQuit, workstation, getList, setPopRestart }: Props) => {
  const t = useT();

  const { setAlert } = useAlert();

  const [user] = useRecoilState(userState);

  const [isLoading, setIsLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const logout = () => {
    if (workstation.workstationId) {
      WorkstationService.logout(workstation.workstationId)
        .then(res => {
          setIsLoading(true);
          setShowDialog(true);

          setTimeout(() => {
            setShowDialog(false);
          }, 2500);
        })
        .catch(error => {
          if (error.response && error.response.status === 403) {
            setAlert(
              t(
                "workstation.requested_user_and_workstation_user_are_different.",
                "요청한 사용자와 워크스테이션 사용자가 다릅니다."
              )
            );

            return;
          }

          if (error.response && error.response.status === 404) {
            setAlert(t("workstation.not_found_ws", "워크스테이션 조회에 실패했습니다."));

            return;
          }

          if (error.response && error.response.status === 500) {
            setAlert(t("common.error", "현재 서버가 원활하지 않습니다. 잠시 후 시도해주세요."));

            return;
          }
        })
        .finally(() => {});
    }
  };

  useEffect(() => {
    if (!workstation.loginUser) {
      setIsLoading(false);
    }
  }, [workstation]);

  const powerOff = () => {
    setPopQuit(true);
  };

  const reStart = () => {
    setPopRestart(true);
  };

  return (
    <Wrapper>
      <StyledDialog open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogContent>
          <Typography variant="h6" align="center">
            로그아웃 중입니다. 잠시만 기다려주세요.
          </Typography>
        </DialogContent>
      </StyledDialog>

      <DropDownSetting.Wrapper>
        <DropDownSetting.Button>
          <img alt="" src="/assets/user/power_red.png" />
        </DropDownSetting.Button>
        <DropDownSetting.CenterContent>
          {workstation.loginUser &&
            workstation.loginUser.userGuid === user.userGuid &&
            (isLoading ? (
              <li>
                <CircleProgress size="small" />
              </li>
            ) : (
              <li onClick={logout}>
                <ImExit size={22} />
                {t("common.sign_out", "로그아웃")}
              </li>
            ))}

          <li onClick={reStart}>
            <VscDebugRestart size={22} />
            {t("common.restart", "재시작")}
          </li>

          <li onClick={powerOff}>
            <GrPower size={22} />
            {t("workstation.power_off", "시스템 종료")}
          </li>
        </DropDownSetting.CenterContent>
      </DropDownSetting.Wrapper>
    </Wrapper>
  );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.8);
  }
`;

const Wrapper = styled.span`
  margin-right: 15px;
  img {
    margin-right: 10px;
    animation: fadeIn 2s;
    cursor: pointer;
    transition: 1.5s ease-out;
    width: 23px;
    height: 23px;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media (min-width: 768px) {
    img {
      width: 25px;
      height: 25px;
    }
  }
`;

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    animation: ${fadeIn} 0.5s, ${fadeOut} 0.5s 4.5s;
  }
`;

export default PowerOffButton;
