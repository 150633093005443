import React, { useEffect } from "react";
import styled from "styled-components";
import { useT } from "../../../../../constants/i18n/useT";
import CircleProgress from "../../../../../components/loading/CircleProgress";
import { RefindedStorage } from "../../../../../@types/storage/form/refined-storage";
import { MappingStorage } from "../../../../../@types/workstation/form/storage";

interface Props {
  refinedStorage: RefindedStorage[];
  setRefinedStorages: React.Dispatch<React.SetStateAction<RefindedStorage[]>>;
  drives: any;
  setDrives: React.Dispatch<React.SetStateAction<any>>;
  mappingWSList?: MappingStorage[];
  isLoading: boolean;
}

const UpdateWSStorage = ({
  refinedStorage,
  setRefinedStorages,
  mappingWSList,
  setDrives,
  drives,
  isLoading,
}: Props) => {
  const editStorage = (storage: RefindedStorage) => {
    // storage 사용 하겠다 / storage 사용 안하겠다
    console.log("storage:", storage);
    if (!storage.using) {
      // 빈칸일경우
      let newLetter = findNotUse();
      let newStorage: RefindedStorage[] = [];
      refinedStorage.map(rStorage => {
        if (rStorage.storageId !== storage.storageId) {
          newStorage.push(rStorage);
        } else {
          newStorage.push({ ...rStorage, using: true, letter: newLetter });
        }
      });
      setRefinedStorages(newStorage);
      setDrives({ ...drives, [newLetter]: true });
    } else {
      // 클릭되어있을때
      let newStorage: RefindedStorage[] = [];
      refinedStorage.map(rStorage => {
        if (rStorage.storageId !== storage.storageId) {
          newStorage.push(rStorage);
        } else {
          newStorage.push({ ...rStorage, using: false, letter: "" });
        }
      });
      setRefinedStorages(newStorage);
      setDrives({ ...drives, [storage.letter]: false });
    }
  };
  const findNotUse = () => {
    let result = "";
    let array = Object.keys(drives).reverse();
    array.forEach(letter => {
      if (!drives[letter]) {
        result = letter;
      }
    });
    return result;
  };

  const useStorageThisLetter = (storage: RefindedStorage, letter: string) => {
    let newStorage: RefindedStorage[] = [];
    refinedStorage.map(rStorage => {
      if (rStorage.storageId === storage.storageId) {
        newStorage.push({ ...rStorage, letter: letter });
      } else {
        newStorage.push(rStorage);
      }
    });
    setDrives({ ...drives, [letter]: true, [storage.letter]: false });
    setRefinedStorages(newStorage);
  };
  useEffect(() => {
    if (mappingWSList && !isLoading) {
      let newStorage: RefindedStorage[] = refinedStorage;
      let newDrives = drives;
      mappingWSList.map(storage => {
        let newStorage2: RefindedStorage[] = [];
        newStorage.map(rStorage => {
          if (rStorage.storageId === storage.storage.storageId) {
            newStorage2.push({
              ...rStorage,
              using: true,
              letter: storage.driveLetter,
              maapingWSId: storage.mappingWSId,
              user: { fullName: storage.user ? storage.user.fullName : "" },
            });
          } else {
            newStorage2.push(rStorage);
          }
        });
        newDrives = { ...newDrives, [storage.driveLetter]: true };
        newStorage = newStorage2;
      });
      setRefinedStorages(newStorage);
      setDrives(newDrives);
    }

    console.log(refinedStorage);
  }, [isLoading]);

  const t = useT();
  return (
    <Wrapper>
      <div className="label_box">
        <label>{t("storage.storage", "스토리지")}</label>
        <div className="description">
          <span className="shared_icon">
            <img src="/assets/sub/shared.png" />
          </span>
          : Shared by Other
        </div>
      </div>
      <div className="storage_box">
        {isLoading && (
          <div className="loading">
            <CircleProgress />
          </div>
        )}
        {!isLoading && (
          <>
            {refinedStorage.map(storage => (
              <EachStorage key={storage.storageId}>
                <div onClick={() => editStorage(storage)} className="checkbox">
                  <input readOnly type="checkbox" checked={storage.using} />
                  <span>
                    {storage.name}
                    {storage.checkPrivilType() && (
                      <span className="shared_icon">
                        <img src="/assets/sub/shared.png" />
                      </span>
                    )}
                    {/* {storage.checkPrivilType() && storage.user && `(${storage.user.fullName})`} */}
                  </span>
                </div>
                {storage.using && (
                  <select
                    className="select_drive"
                    onChange={e => useStorageThisLetter(storage, e.target.value)}
                    defaultValue={storage.letter}
                    id="number"
                  >
                    <option value={storage.letter} id={storage.letter}>
                      {storage.letter}
                    </option>
                    {Object.keys(drives).map(
                      letter =>
                        !drives[letter] && (
                          <option key={letter} value={letter} id={letter}>
                            {letter}
                          </option>
                        )
                    )}
                  </select>
                )}
              </EachStorage>
            ))}
            {refinedStorage.length === 0 && (
              <p className="no_storage">
                {t(
                  "workstation.there_is_no_available_storage_to_use",
                  "사용 가능한 storage가 없습니다."
                )}
              </p>
            )}
          </>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: auto;
  margin-top: 10px;
  width: 100%;
  text-align: left;
  .label_box {
    display: flex;
    justify-content: space-between;
    .description {
      font-size: 12px;
      color: #979797;
    }
    label {
      display: block;
      font-size: 14px;
      font-weight: bold;
      color: #979797;
      margin-left: 5px;
    }
  }

  .storage_box {
    width: 100%;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    margin-top: 10px;
    height: 200px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #d4d4d4;
      border-radius: 5px;
    }
    .loading {
      margin-top: 70px;
    }
  }
  .no_storage {
    padding: 10px;
    color: gray;
    font-size: 13px;
  }
`;
const EachStorage = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  height: 30px;
  .checkbox {
    display: flex;
    align-items: center;
    font-size: 12px;
    cursor: pointer;
    input {
      margin-right: 10px;
    }
  }
  select {
    width: 100px;
    height: 25px;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
  }

  .shared_icon {
    margin-left: 5px;
  }
`;
export default UpdateWSStorage;
