import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { useNavigate } from "react-router-dom";
import { useT } from "../../../constants/i18n/useT";
import { FaRegQuestionCircle } from "@react-icons/all-files/fa/FaRegQuestionCircle";
import ContactModal from "../../../pages/contact/non-member/modal";
import UserContactModal from "../../../pages/mypage/contact/create/modal";

interface Props {
  isLogin: boolean;
}

const HelpButton = ({ isLogin }: Props) => {
  const [popContact, setPopContact] = useState(false);
  const [popLoginContact, setPopLoginContact] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const t = useT();
  const navigate = useNavigate();

  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };

  const inquire = () => {
    if (isLogin) {
      setPopLoginContact(true);
    } else {
      setPopContact(true);
    }
  };

  return (
    <>
      <ButtonWrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <ButtonContent>
          <StyledFaRegQuestionCircle />
          <ButtonLabel>Help</ButtonLabel>
        </ButtonContent>
        {dropdownVisible && (
          <DropdownMenu>
            <MenuItem onClick={() => navigate("/guide")}>
              {t("common.user_guide", "사용가이드")}
            </MenuItem>

            <MenuItem onClick={() => inquire()}>{t("user.request", "문의하기")}</MenuItem>
          </DropdownMenu>
        )}
      </ButtonWrapper>

      {popContact && <ContactModal setOpen={setPopContact} isOpen={popContact} />}
      {popLoginContact && (
        <UserContactModal setOpen={setPopLoginContact} isOpen={popLoginContact} />
      )}
    </>
  );
};

const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 60px;
  margin-left: 40px;
  transition: color 0.3s ease;
  height: 80px;

  &:hover {
    color: var(--GridaBlue);
  }
`;

const StyledFaRegQuestionCircle = styled(FaRegQuestionCircle)`
  font-size: 24px;
  color: #333;
  margin-right: 5px;
  transition: color 0.3s ease, transform 0.3s ease;

  ${ButtonContent}:hover & {
    transform: rotate(360deg);
  }
`;

const ButtonLabel = styled.span`
  font-size: 20px;
  color: #333;
  transition: color 0.3s ease;
`;

const slideDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const DropdownMenu = styled.ul`
  position: absolute;
  top: 80%;
  left: 15%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  list-style: none;
  margin: 0;

  min-width: 130px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;

  ${ButtonWrapper}:hover & {
    opacity: 1;
    visibility: visible;
    animation: ${slideDown} 0.3s ease forwards;
  }
`;

const MenuItem = styled.li`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  transition: background-color 0.3s ease, color 0.3s ease, border-radius 0.3s ease;

  &:first-child:hover {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:last-child:hover {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &:hover {
    background-color: var(--GridaBlue);
    color: #ffffff;
  }
`;

export default HelpButton;
