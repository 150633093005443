import { CompanyService } from "../../service/user/company.service";

export class RegisterCompany extends CompanyService {
  name?: string = "";
  id?: string = "";
  constructor(name?: string, id?: string) {
    super();
    this.name = name;
    this.id = id;
  }
}
