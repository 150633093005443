import { Storage } from "../../storage/storage";

export class ProjectStorageUiData {
  storageId?: string;
  projectStorageId?: number;
  name?: string;
  deleted?: boolean;
  user?: { fullName?: string };
  isChecked: boolean = false;
  isPublic?: boolean = false;
  isFtp?: boolean = false;
  owner?: { fullName?: string };
  shareWith?: any;
  workstations?: any;

  constructor(
    storage: Storage,
    isPublic?: boolean,
    workstations?: Array<{
      storageId?: any;
      driveLetter?: any;
      useStateCd?: "UseState.Deleted" | undefined;
    }>,
    shareWith?: Array<{ userGuid: string; useStateCd?: "UseState.Deleted" | undefined }>
  ) {
    this.storageId = storage.storageId;

    this.name = storage.name;
    this.isFtp = storage.isFtp || false;
    if (storage.user) this.user = storage.user;

    this.shareWith = storage.shareWith;
    this.workstations = storage.workstations;

    this.isPublic = isPublic || false;
  }

  static fullName(storage: ProjectStorageUiData) {
    if (storage.user) {
      // return `${storage.name} (${storage.user.fullName})`;
      return `${storage.name} `;
    }
    return storage.name;
  }
}
