import React, { useEffect, useState } from "react";
import { useT } from "../../../../../constants/i18n/useT";
import { ProjectForm } from "../../../../../@types/project/form";
import styled from "styled-components";
import ProjectInfoComponent from "./setting-create-update/info";
import ProjectSettingComponent from "./setting-create-update";
import { useAlert } from "../../../../../components/alert/alert.service";
import { useRecoilState } from "recoil";
import { userState } from "../../../../../constants/recoil/user";
import { ProjectUiData, projectUiDataState } from "../../../../../constants/recoil/project";
import { ProjectService } from "../../../../../service/project/project.service";
import { Workstation } from "../../../../../@types/workstation/workstation";
import { Storage } from "../../../../../@types/storage/storage";
import ModalForm from "../../../../../components/modal/modal-form";
import { ModalProps } from "../../../../../components/modal";

interface Props extends ModalProps {
  getList: () => void;
}
const ProjectCreate = ({ isOpen, setOpen, getList }: Props) => {
  const [projectForm, setProjectForm] = useState<ProjectForm>(new ProjectForm());
  const [isResponse, setIsResponse] = useState(false);

  const [, setProjectUiData] = useRecoilState(projectUiDataState);
  const [user] = useRecoilState(userState);

  const PROJECT_ASSIGNABLE = "PROJECT_ASSIGNABLE";

  const STORAGE_USAGE = {
    TRUE: "true",
    FALSE: "false",
  };

  const t = useT();

  const { setSuccess, setAlert } = useAlert();

  const onSubmit = () => {
    if (!projectForm.name) {
      return setAlert(t("common.please_enter_a_name", "이름을 입력해주세요."));
    }

    const newProjectForm = {
      ...projectForm,
      user: { userGuid: user.userGuid },
    };

    if (!newProjectForm.description) {
      delete newProjectForm.description;
    }
    setIsResponse(true);
    ProjectService.create(newProjectForm)
      .then(res => {
        if (res.state === "success") {
          setSuccess(`${newProjectForm.name} ${t("storage.has_been_created", "를 생성했습니다.")}`);
        }
      })
      .finally(() => {
        setIsResponse(false);
        setOpen(false);
        getList();
      });
  };

  // const setUiData = () => {
  //   ProjectService.getUiData().then((res) => {
  //     if (res.state === "success") {
  //       const data = res.data as ProjectUiData;

  //       const newData = {
  //         ...data,
  //         unassignedWorkstations:
  //           ProjectService.getFilterByOwnerId<Workstation>(
  //             data.unassignedWorkstations,
  //             user.userGuid
  //           ),
  //         unassignedStorages: ProjectService.getFilterByOwnerId<Storage>(
  //           data.unassignedStorages,
  //           user.userGuid
  //         ),
  //       };

  //       setProjectUiData(newData);
  //       setProjectUiData(res.data);
  //     }
  //   });
  // };

  const setUiData = async (userGuid: string) => {
    try {
      const code = await ProjectService.getUiData();
      const workstation = await ProjectService.getWorkstation(userGuid, PROJECT_ASSIGNABLE);
      const stoarge = await ProjectService.getStorage(
        userGuid,
        PROJECT_ASSIGNABLE,
        STORAGE_USAGE.FALSE
      );

      console.log("Unassined Workstation : ", workstation);
      console.log("Unassined Storage", stoarge);
      const newData = {
        codes: code.data.codes,
        unassignedWorkstations: workstation,
        unassignedStorages: stoarge,
      };

      setProjectUiData(newData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    (async () => {
      await setUiData(user.userGuid!);
    })();
  }, []);

  return (
    <ModalForm
      title={t("project.create_project", "프로젝트 생성")}
      isOpen={isOpen}
      setOpen={setOpen}
      size="full"
    >
      <Wrapper>
        <div className="flex_box">
          <div className="left">
            <ProjectSettingComponent
              projectForm={projectForm}
              setProjectForm={setProjectForm}
              getList={getList}
              // assignedStorages={assignedStorages}
              // assignedWorkstations={assignedWorkstations}
            />
          </div>
          <div className="right">
            <ProjectInfoComponent projectForm={projectForm} setProjectForm={setProjectForm} />
          </div>
        </div>
        <ModalForm.ButtonBox setOpen={setOpen} onSubmit={onSubmit} />
      </Wrapper>
    </ModalForm>
  );
};
const Wrapper = styled.div`
  padding: 10px 50px;
  height: 90vh;
  max-height: 900px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .flex_box {
    display: flex;
  }
  .left {
    width: 70%;
  }
  .right {
    width: 30%;
  }
`;

export default ProjectCreate;
