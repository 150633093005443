export enum API {
  CREDIT = `/api/v1/billing-web/user/creditBalance`,
  BILLING = `/api/v1/billing-web`,
  ALARM_INFO = `/api/v1/billing-web/alarmInfo`,
  AUTH_DEFUALT = `/api/v1/account/auth`,
  AUTH_LOGIN = `/api/v1/account/auth/user`,
  AUTH_MAIL = `/api/v1/account/mail`,
  USER = `/api/v1/account/user`,
  CONTACT = `/api/v1/bbs/contact`,
  AUTH_FIND = `/api/v1/account/find`,
}
