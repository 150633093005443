// usePagination.ts
import { useState } from "react";

const usePagination = (initialPageSize: number) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pagenationSize, setPagenationSize] = useState(initialPageSize);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const pagenationSize = parseInt(event.target.value);
    setPagenationSize(pagenationSize);
    setCurrentPage(1);
  };

  const startIndex = (currentPage - 1) * pagenationSize;
  const endIndex = startIndex + pagenationSize;

  return {
    currentPage,
    handlePageChange,
    pagenationSize,
    handlePageSizeChange,
    startIndex,
    endIndex,
  };
};

export default usePagination;
