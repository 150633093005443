import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ProjectComponentContainer from "../components/form.container";
import ProjectCenterButton from "../components/button/center.button";
import ProjectStorageLeftComponent from "./left";
import ProjectStorageRightComponent from "./right";
import { Project } from "../../../../../../../@types/project/project";
import { useAlert } from "../../../../../../../components/alert/alert.service";
import { useT } from "../../../../../../../constants/i18n/useT";
import { ProjectStorageUiData } from "../../../../../../../@types/project/ui/storage";
import { ProjectWorkstationUiData } from "../../../../../../../@types/project/ui/workstation";
interface Props {
  project?: Project;
  leftStorages: ProjectStorageUiData[];
  setLeftStorages: React.Dispatch<React.SetStateAction<ProjectStorageUiData[]>>;
  leftWorkstations: ProjectWorkstationUiData[];
  setLeftWorkstations: React.Dispatch<React.SetStateAction<ProjectWorkstationUiData[]>>;
  assignedStorages?: any[] | [];
  assignedWorkstations?: any[] | [];
  getList: () => void;
  user: any[];
  setUser: React.Dispatch<React.SetStateAction<any[]>>;
  setWs: React.Dispatch<React.SetStateAction<any[]>>;
  ws: any[];
  setCurrentStorage: React.Dispatch<React.SetStateAction<any[]>>;
  currentStorage: any[];
}

const ProjectStorageComponent = ({
  project,
  leftStorages,
  setLeftStorages,
  leftWorkstations,
  setLeftWorkstations,
  assignedStorages,
  assignedWorkstations,
  getList,
  setWs,
  ws,
  setUser,
  user,
  setCurrentStorage,
  currentStorage,
}: Props) => {
  const [rightStorages, setRightStorages] = useState<ProjectStorageUiData[]>([]);

  const { setAlert } = useAlert();
  const t = useT();

  const isDuplicateInTarget = (target: { storageId?: string }, list: { storageId?: string }[]) => {
    const index = list.findIndex(u => u.storageId === target.storageId);
    if (index === -1) return false;
    return true;
  };

  const toLeft = () => {
    let newLeftStorages = [...leftStorages];
    let newRightStorages = [...rightStorages];
    rightStorages.forEach(storage => {
      if (storage.isChecked) {
        if (storage.deleted) {
          const index = newLeftStorages.findIndex(s => s.storageId === storage.storageId);
          newLeftStorages[index] = {
            ...storage,
            isChecked: false,
            deleted: false,
          };
        } else {
          if (!isDuplicateInTarget(storage, newLeftStorages)) {
            newLeftStorages.push({ ...storage, isChecked: false });
          } else {
            setAlert(t("project.", "이미 추가된 Storage입니다."));
          }
        }
        newRightStorages = newRightStorages.filter(s => s.storageId !== storage.storageId);
      }
    });
    setLeftStorages(newLeftStorages);
    setRightStorages(newRightStorages);
  };

  const toRight = () => {
    let newLeftStorages = [...leftStorages];
    let newRightStorages = [...rightStorages];

    leftStorages.forEach(storage => {
      if (storage.isChecked) {
        if (storage.projectStorageId) {
          newRightStorages.push({
            ...storage,
            isChecked: false,
            deleted: true,
            isPublic: false,
          });
          const index = newLeftStorages.findIndex(s => s.storageId === storage.storageId);
          newLeftStorages[index] = {
            ...newLeftStorages[index],
            isChecked: false,
            isPublic: false,
            deleted: true,
          };
        } else {
          if (!isDuplicateInTarget(storage, newRightStorages)) {
            newRightStorages.push({ ...storage, isChecked: false });
          } else {
            setAlert(t("project.", "이미 추가된 Storage입니다."));
          }
          newLeftStorages = newLeftStorages.filter(s => s.storageId !== storage.storageId);
        }
      }
    });
    setLeftStorages(newLeftStorages);
    setRightStorages(newRightStorages);
  };

  const handlePublic = () => {
    let count = 0;
    leftStorages.forEach(storage => {
      if (storage.isChecked) count++;
    });
    if (count === 0) {
      return setAlert(t("project.please_select_storage", "Storage를 선택해주세요."));
    }
    if (count > 1) {
      return setAlert(
        t("project.please_select_only_one_storage", "하나의 Storage만 선택해주세요.")
      );
    }

    const findIndex = leftStorages.findIndex(s => s.isChecked);

    if (!leftStorages[findIndex].isFtp) {
      return setAlert(
        t("project.only_ftp_storage_can_be_designated", "FTP Storage만 Public 지정이 가능합니다.")
      );
    }

    const newLeftStorages = leftStorages.map(storage => {
      if (storage.isChecked) {
        return {
          ...storage,
          isPublic: !storage.isPublic,
        };
      }
      return {
        ...storage,
        isPublic: false,
      };
    });

    setLeftStorages(newLeftStorages);
  };

  useEffect(() => {
    if (project && project.storages) {
      setLeftStorages(
        assignedStorages!.map(storage => {
          const newStorage = new ProjectStorageUiData(storage.storage, storage.isPublic);
          if (storage.projectStorageId) {
            newStorage.projectStorageId = storage.projectStorageId;
          }
          return newStorage;
        })
      );
    }
  }, [assignedStorages]);

  return (
    <ProjectComponentContainer
      title={t("project.storage_allocation", "Storage 할당 및 설정") || ""}
      tooltip={
        t(
          "project.except_if_is_already_assigned_to_project",
          "이미 project에 할당된 경우, 사용자들이 공유된 경우 제외"
        ) || ""
      }
    >
      <ProjectStorageLeftComponent
        leftStorages={leftStorages}
        project={project}
        setLeftStorages={setLeftStorages}
        leftWorkstations={leftWorkstations}
        setLeftWorkstations={setLeftWorkstations}
        assignedWorkstations={assignedWorkstations}
        assignedStorages={assignedStorages}
        handlePublic={handlePublic}
        getList={getList}
        ws={ws}
        setWs={setWs}
        setUser={setUser}
        user={user}
        currentStorage={currentStorage}
        setCurrentStorage={setCurrentStorage}
      />

      <ProjectCenterButton toLeft={toLeft} toRight={toRight} />
      <ProjectStorageRightComponent
        project={project}
        rightStorages={rightStorages}
        setRightStorages={setRightStorages}
      />
    </ProjectComponentContainer>
  );
};

const Wrapper = styled.div``;

export default ProjectStorageComponent;
