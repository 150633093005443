import React from "react";
import styled from "styled-components";
import { Workstation } from "../../../../../../@types/workstation/workstation";
import { DateService } from "../../../../../../service/common/date.service";

export const CardBody4 = ({ workstation }: { workstation: Workstation }) => {
  const timezone = navigator.language;

  return (
    <Wrapper>
      <div className="item">
        <div className="title">Created</div>

        <div className="content">
          {workstation.createdAt ? DateService.formatDate(workstation.createdAt, timezone) : "-"}
        </div>
      </div>
      <div className="item">
        {workstation.runningTime && workstation.runningTime.endDt && (
          <>
            <div className="title">Latest Used</div>
            <div className="content">
              {DateService.formatDate(workstation.runningTime.endDt, timezone)}
            </div>
          </>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.article`
  .item {
    margin-top: 10px;
    margin-bottom: 2px;
  }
  .title {
    color: #5e5e5e !important;
    font-weight: 400 !important;
    font-size: 9px !important;
  }
  .content {
    color: #5e5e5e !important;
    font-weight: 400 !important;
    font-size: 9px !important;
  }

  @media (min-width: 768px) {
    .title {
      font-size: 11px !important;
    }
    .content {
      font-size: 11px !important;
    }
  }
`;
