import React, { useEffect, useState } from "react";
import ModalPopup from "../../../../../components/modal/popup";
import { useT } from "../../../../../constants/i18n/useT";
import { Project } from "../../../../../@types/project/project";
import { ProjectService } from "../../../../../service/project/project.service";
import { ModalProps } from "../../../../../components/modal";
import { useAlert } from "../../../../../components/alert/alert.service";

interface Props extends ModalProps {
  projectId: number;
  getList: () => void;
}

const ProjectDeletePage = ({ isOpen, setOpen, projectId, getList }: Props) => {
  const t = useT();
  const [project, setProject] = useState<Project | null>();
  const [isResponse, setIsResponse] = useState(false);

  const { setSuccess } = useAlert();

  const onSubmit = () => {
    setIsResponse(true);
    if (projectId) {
      ProjectService.delete(projectId)
        .then((res) => {
          setSuccess(
            t("project.project_has_been_deleted", "프로젝트를 삭제했습니다.")
          );
        })
        .finally(() => {
          setOpen(false);
          setIsResponse(false);
          getList();
        });
    }
  };

  useEffect(() => {
    ProjectService.getById(projectId).then((res) => {
      if (res.state === "success") {
        setProject(res.data);
      }
    });
  }, []);

  return (
    <ModalPopup
      isOpen={isOpen}
      setOpen={setOpen}
      buttonText={t("common.delete", "삭제")}
      type="delete"
      onSubmit={onSubmit}
      isResponse={isResponse}
    >
      {project && (
        <>
          <h1>
            {t(
              "project.do_you_want_to_delete_the_following_project",
              "다음 프로젝트를 삭제하시겠습니까?"
            )}
          </h1>
          <p>{project.name}</p>
          <p>
            {t(
              "project.when_a_project_is_deleted",
              "프로젝트가 삭제되면 프로젝트 자원에 대한 다른 사용자의 접근이 불가능하게 됩니다."
            )}
          </p>
        </>
      )}
    </ModalPopup>
  );
};

export default ProjectDeletePage;
