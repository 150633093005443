import React from "react";
import styled from "styled-components";
import { Storage } from "../../../../../../@types/storage/storage";
import StorageMenu from "../menu/storage-card-menu";
import { FiHardDrive } from "@react-icons/all-files/fi/FiHardDrive";

interface Props {
  storage: Storage;
  own: boolean;
  getList: () => void;
}

const CardHeader = (props: Props) => {
  return (
    <Wrapper id={props.own ? "own" : "notOwn"}>
      <Title>
        {/* <img alt="storge_img" src="/assets/user/box_storage.png" /> */}
        <FiHardDrive size={25} />
        <p>{props.storage.name}</p>
      </Title>

      <StorageMenu {...props} />
    </Wrapper>
  );
};
const Wrapper = styled.div`
  padding: 10px 10px;
  font-weight: 500;
  transition: 1.5s ease-out;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: space-between;

  li {
    color: #333;
  }
`;
const Title = styled.span`
  display: flex;
  align-items: center;
  img {
    width: 25px;
    height: 20px;
  }
  p {
    padding-left: 5px;
  }
`;
export default CardHeader;
