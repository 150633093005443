import { APICLIENT, API } from "../api";
import { Code } from "../../@types/infra/code";
const COMPANY_API = "/api/v1/account/companies";
export interface Company {
  createdBy?: string;
  createdDt?: string;
  updatedBy?: string;
  updatedDt?: string;
  useState?: Code;
  companyGuid?: string;
  name?: string;
  userGuid?: string;
}

export class CompanyService {
  name?: string = "";
  id?: string = "";
  userGuid?: string = "";
  static getList = async (name: string) => {
    const response = await APICLIENT.NONAUTH.post(`${COMPANY_API}/getList`, {
      filter: { name: name },
      paginate: { offset: 0, limit: 100 },
    });
    return response.data;
  };
  static getUserList = async (companyGuid: string) => {
    const response = await APICLIENT.AUTH_NOALERT.get(
      `${COMPANY_API}/${companyGuid}/users`
    );
    return response.data;
  };

  update = async () => {
    let response;
    if (this.id)
      response = await APICLIENT.AUTH.post(`${API.USER}/update/company`, {
        data: { companyGuid: this.id, userGuid: this.userGuid },
      });
    else
      response = await APICLIENT.AUTH.post(
        `${API.USER}/update/individual-company`,
        { data: { individualCompanyName: this.name, userGuid: this.userGuid } }
      );

    return response.data;
  };
}
