import React, { useCallback } from "react";
import styled from "styled-components";
import ProjectListForm from "../../components/form.container.list.inner";
import NameWithCheckBox from "../../components/item/name-with-checkbox";
import { Code } from "../../../../../../../../@types/infra/code";
import { ProjectUserUiData } from "../../../../../../../../@types/project/ui/user";
import { _projectMemberLeft } from "../../../../../../script/setting/project.member";

interface Props {
  userList: ProjectUserUiData[];
  setLeftUsers: React.Dispatch<React.SetStateAction<ProjectUserUiData[]>>;
  types: Code[];
}

const ProjectMemberLeftComponent = ({
  userList,
  setLeftUsers,
  types,
}: Props) => {
  const { handleCheckbox, handleRole } = _projectMemberLeft({
    userList,
    setLeftUsers,
  });

  const getTitle = useCallback(
    (user: ProjectUserUiData) => {
      if (user.userId) {
        return `${user.fullName} (${user.userId})`;
      }
      return user.fullName;
    },
    [userList]
  );

  return (
    <ProjectListForm size="lg">
      {userList.map(
        (user) =>
          !user.deleted && (
            <NameWithCheckBox
              key={user.userGuid}
              title={getTitle(user)}
              isChecked={user.isChecked}
              handleCheckbox={() => handleCheckbox(user)}
              typeList={types}
              handleType={handleRole(user)}
              user={user}
            />
          )
      )}
    </ProjectListForm>
  );
};

const Wrapper = styled.div``;
export default ProjectMemberLeftComponent;
