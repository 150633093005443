export const INCLUDE_FOOTER_PATH = ["/", "/billing/policy", "/signup"];

export enum PATH_NAME {
  MAIN = "/",
  SIGNUP = "/signup",
  LOGIN = "/login",
  FIND_ID = "/find/id",
  FIND_PASSWORD = "/find/password",
  POLICY = "/policy",
  BILLING_POLICY = "/billing/policy",
  ADMIN_LOGIN = "/admin/login",
  DWONLOAD = "/download",
  GUIDE = "/guide",
  NOT_FOUND = "*",
  SERVICE_ALL_DOWN = "/error",
}

export enum AUTH_PATH_NAME {
  WORKSTATION = "/workstation",
  STORAGE = "/storage",
  PROJECT = "/project",
  MYPAGE = "/mypage/:location",
}
