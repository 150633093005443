// PaginationWrapper.tsx
import React from "react";
import styled from "styled-components";
import PaginationComponent from "./pagenation";
import PaginationSelectBox from "./selectBox";

interface Props {
  totalItems: number;
  pagenationSize: number;
  currentPage: number;
  onPageChange: (newPage: number) => void;
  onPageSizeChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const PaginationWrapper: React.FC<Props> = ({
  totalItems,
  pagenationSize,
  currentPage,
  onPageChange,
  onPageSizeChange,
}) => {
  return (
    <StyledPaginationWrapper>
      <PaginationComponent
        totalItems={totalItems}
        pagenationSize={pagenationSize}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
      <PaginationSelectBox value={pagenationSize} onChange={onPageSizeChange} />
    </StyledPaginationWrapper>
  );
};

const StyledPaginationWrapper = styled.div`
  margin: 20px auto;
  display: flex;
  justify-content: center;
`;

export default PaginationWrapper;
