export class SignupForm {
  userId: string = "";
  password: string = "";
  password2?: string = "";

  fullName: string = "";
  mail: string = "";
  mobile: string = "";

  individualCompanyName?: string;
  company?: { companyGuid?: string };

  agreed: boolean = true;

  termsOfUseVersion: string = "1.0.0";
  personalInfoVersion: string = "1.0.0";
}
