import React from "react";
import styled from "styled-components";
import RoundButton from "../../../components/button/round-button";
import InputLabel from "../../../components/input/input-label/input";
import { MAX_LENGTH } from "../../../constants/max-length";
import LoginFailed from "../../../components/input/signup/text-alert";
import { useT } from "../../../constants/i18n/useT";
import { InputService } from "../../../service/common/input.service";

interface Props {
  id: string;
  setId: React.Dispatch<React.SetStateAction<string>>;
  s1Message: string;
  checkId: () => void;
}
const FindPassword1 = ({ id, setId, s1Message, checkId }: Props) => {
  const t = useT();
  return (
    <Wrapper>
      <Body>
        <InputLabel
          value={id}
          onChange={(e) => setId(e.target.value)}
          label={t("user.id", "아이디")}
          placeholder={t("user.please_enter_id", "아이디를 입력하세요") || ""}
          maxLength={MAX_LENGTH.ID}
          onKeyDown={(e) => InputService.pressEnter(e, checkId)}
        />
        <LoginFailed>{s1Message}</LoginFailed>
      </Body>
      <div className="login_btn">
        <RoundButton onClick={() => checkId()} color="blue" size="full_lg">
          {t("user.find_password", "비밀번호 찾기")}
        </RoundButton>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  .login_btn {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
`;
const Body = styled.div`
  height: 120px;
  padding-top: 20px;
`;

export default FindPassword1;
