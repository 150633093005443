import React, { useState } from "react";
import styled from "styled-components";
import { useT } from "../../../constants/i18n/useT";
import { useNavigate } from "react-router-dom";
import LocaleUpdate from "../button/locale-change";
import LogoutModal from "../../../pages/login/logout/modal";
import CalculateModal from "../../../pages/billing/calculate/modal";
import ContactModal from "../../../pages/contact/non-member/modal";

interface Props {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isLogin: boolean;
}

const MobileSidebar = ({ setOpen, isLogin }: Props) => {
  const [popCalculate, setPopCalculate] = useState(false);
  const [popContact, setPopContact] = useState(false);
  const [popLogout, setPopLogout] = useState(false);

  const navigate = useNavigate();

  const t = useT();

  const goto = (address: string) => {
    setOpen(false);
    navigate(address);
  };

  return (
    <Wrapper id="mobile_sidebar">
      <BackDrop onClick={() => setOpen(false)} />
      <NavWrapper>
        <div className="close_btn">
          <img
            onClick={() => setOpen(false)}
            alt="close"
            src={"/assets/sub/close.png"}
          />
        </div>
        <Ul>
          <li>
            <a onClick={() => goto("/")}>Main</a>
          </li>
          {isLogin && (
            <>
              <li>
                <a onClick={() => goto("/workstation")}>Workstation</a>
              </li>
              <li>
                <a onClick={() => goto("/storage")}>Storage</a>
              </li>
            </>
          )}

          {!isLogin && (
            <>
              <li>
                <a onClick={() => goto("/login")}>
                  {t("common.sign_in", "로그인")}
                </a>
              </li>
              <li>
                <a onClick={() => goto("/signup")}>
                  {t("common.sign_up", "회원가입")}
                </a>
              </li>
            </>
          )}
        </Ul>

        <Ul>
          <li>
            <a onClick={() => goto("/billing/policy")}>
              {t("billing.m2m_pricing", "요금 안내")}
            </a>
          </li>
          <li>
            <a onClick={() => setPopCalculate(true)}>
              {t("billing.pricing_calculator", "요금 계산기")}
            </a>
          </li>
        </Ul>
        <Ul>
          <LocaleUpdate type="mobile" isLogin={isLogin} />
        </Ul>

        <Ul>
          {!isLogin && (
            <li>
              <a onClick={() => setPopContact(true)}>
                {t("main.contact", "Contact")}
              </a>
            </li>
          )}
          <li>
            <a onClick={() => goto("/policy")}>
              {t("common.terms_of_service", "이용약관")} |{" "}
              {t("common.privacy_policy", "개인정보 처리방침")}{" "}
            </a>
          </li>
        </Ul>

        {isLogin && (
          <Ul>
            <li>
              <a onClick={() => goto("/mypage/info")}>
                {t("common.my_information", "내 정보")}
              </a>
            </li>
            <li>
              <a onClick={() => goto("/mypage/credit")}>
                {t("common.credit_history", "Credit 내역")}
              </a>
            </li>
            <li>
              <a onClick={() => goto("/mypage/user-alarms")}>
                {t("common.noti_log", "알림 및 로그")}
              </a>
            </li>
            <li>
              <a onClick={() => goto("/mypage/contact")}>
                {t("common.customer_service", "고객센터")}
              </a>
            </li>

            <li>
              <a
                className="gray"
                onClick={() => {
                  setPopLogout(true);
                }}
              >
                {t("common.sign_out", "로그아웃")}
              </a>
            </li>
          </Ul>
        )}
      </NavWrapper>
      {popCalculate && (
        <CalculateModal setOpen={setPopCalculate} isOpen={popCalculate} />
      )}
      {popContact && (
        <ContactModal setOpen={setPopContact} isOpen={popContact} />
      )}
      {popLogout && <LogoutModal setOpen={setPopLogout} isOpen={popLogout} />}
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  height: 100vh;
`;

const BackDrop = styled.div`
  width: 20%;
  background-color: #c6c6c6a7;
  @media screen and (min-width: 768px) {
    width: 50%;
  }
`;
const NavWrapper = styled.div`
  width: 80%;
  background-color: #fff;
  align-items: left;
  overflow-y: auto;
  .close_btn {
    display: block;
    margin: 25px;
    img {
      height: 20px;
      cursor: pointer;
      filter: brightness(0);
      transition: 0.7s;
    }
    img:hover {
      transform: rotate(90deg);
      transition: 0.7s;
    }
  }
  &::-webkit-scrollbar {
    background-color: rgb(255, 255, 255);
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(219, 219, 219, 0.514);
    border-radius: 10px;
    height: 10px;
  }
  @media screen and (min-width: 768px) {
    width: 50%;
  }
`;

const Ul = styled.ul`
  padding: 15px;
  padding-left: 25px;
  border-top: 0.5px solid #8e8e8e;
  li {
    padding: 12px 0;
    a {
      font-size: 17px;
      font-weight: 600;
      cursor: pointer;
      &:hover {
        color: var(--GridaBlue);
      }
    }
    .gray {
      color: #8e8e8e;
    }
  }
`;

export default MobileSidebar;
