import { Code } from "../../infra/code";

export class StorageUser {
  storageUserId?: number;
  fullName?: string;
  userGuid?: string;
  companyGuid?: string;
  privilege?: Code;
  useState?: Code;
  constructor(user: any) { // TODO : user : User
    this.userGuid = user.userGuid;
    this.fullName = user.fullName;
    this.companyGuid = user.companyGuid;
    this.privilege = user.privilege;
  }
}
