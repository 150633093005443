import React from "react";
import styled from "styled-components";
import WSCard from "./card";
import { Workstation } from "../../../../@types/workstation/workstation";
import { CardEditType } from "../../../../@types/type/card-edit";

interface Props {
  title: string;
  workstations: Workstation[];
  getList: () => void;
  editType?: CardEditType;
  isLoading: boolean;
}

const WorkstationCardList = ({
  title,
  workstations,
  getList,
  editType = CardEditType.ALL,
  isLoading,
}: Props) => {
  return (
    <Wrapper>
      {!isLoading && workstations.length > 0 && (
        <>
          <h4 className="ws_title">{title}</h4>
          <WorkstationWrapper>
            {workstations.map((workstation) => {
              return (
                <WSCard
                  editType={editType}
                  getList={getList}
                  key={workstation.workstationId}
                  workstation={workstation}
                />
              );
            })}
          </WorkstationWrapper>
        </>
      )}
    </Wrapper>
  );
};
const Wrapper = styled.section`
  .ws_title {
    margin-left: 30px;
    margin-top: 30px;
  }
`;
const WorkstationWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 30px;
  list-style: none;
  @media screen and (max-width: 1024px) {
    justify-content: center;
  }
`;
export default WorkstationCardList;
