export enum MAX_LENGTH {
  DEFAULT = 100,
  WORKSTATION = 15,
  STROAGE = 20,
  PASSWORD = 16,
  ID = 20,
  NAME = 30,
  MOBILE = 15,
  COMPANY = 30,
  MAIL = 40,
  DESCRIPTION = 100,
  PROJECT_NAME = 30,
  PROJECT_DESCRIPTION = 500,
  CONTACT_TITLE = 30,
  CONTACT_CONTENT = 500,
}
