import React, { useEffect } from "react";
import styled from "styled-components";
import RoundButton from "../../../components/button/round-button";
import InputLabel from "../../../components/input/input-label/input";
import LoginFailed from "../../../components/input/signup/text-alert";
import Button from "../../../components/button/Button";
import Timer from "../../../components/timer/Timer";
import { useT } from "../../../constants/i18n/useT";

interface Props {
  emailNumber: string;
  setEmailNumber: React.Dispatch<React.SetStateAction<string>>;
  verified: boolean;
  failed: string;
  minutes: number;
  setMinutes: React.Dispatch<React.SetStateAction<number>>;
  seconds: number;
  setSeconds: React.Dispatch<React.SetStateAction<number>>;
  getCertNumber: () => void;
  checkEmailNum: () => void;
}
const FindPassword3 = ({
  emailNumber,
  setEmailNumber,
  verified,
  failed,
  minutes,
  setMinutes,
  seconds,
  setSeconds,
  getCertNumber,
  checkEmailNum
}: Props) => {
  const t = useT();

  useEffect(() => {
    if (emailNumber.length === 6) checkEmailNum();
  }, [emailNumber]);
  return (
    <Wrapper>
      <Body>
        <InputLabel
          value={emailNumber}
          onChange={(e) => setEmailNumber(e.target.value)}
          label={t("user.verification_code", "인증번호")}
          placeholder={"000000"}
          maxLength={6}
        />
        {!verified && emailNumber.length > 5 && (
          <LoginFailed>{failed}</LoginFailed>
        )}
        {!verified && emailNumber.length < 6 && (
          <>
            <Timer
              minutes={minutes}
              setMinutes={setMinutes}
              seconds={seconds}
              setSeconds={setSeconds}
            />
            {minutes === 0 && seconds === 0 && (
              <Button size="xs" onClick={() => getCertNumber()}>
                {t("common.resend", "재전송")}
              </Button>
            )}
          </>
        )}
        {verified && (
          <LoginFailed success>
            {t(
              "user.the_verification_code_is_correct",
              "인증번호가 일치합니다."
            )}
          </LoginFailed>
        )}
      </Body>
      <div className="login_btn">
        <RoundButton color="disabled" size="full_lg">
          {t("user.reset_password", "패스워드 변경")}
        </RoundButton>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  .login_btn {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
`;
const Body = styled.div`
  height: 120px;
`;
export default FindPassword3;
