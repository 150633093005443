import React from "react";
import styled from "styled-components";
import RoundButton from "../../../components/button/round-button";
import InputLabel from "../../../components/input/input-label/input";
import LoginFailed from "../../../components/input/signup/text-alert";
import Button from "../../../components/button/Button";
import Timer from "../../../components/timer/Timer";
import { useT } from "../../../constants/i18n/useT";

interface Props {
  mail: string;
  emailNumber: string;
  setEmailNumber: React.Dispatch<React.SetStateAction<string>>;
  verified: boolean;
  failed: string;
  minutes: number;
  setMinutes: React.Dispatch<React.SetStateAction<number>>;
  seconds: number;
  setSeconds: React.Dispatch<React.SetStateAction<number>>;
  onSubmit: () => void;
  findId: () => void;
}
const FindId2 = ({
  mail,
  emailNumber,
  setEmailNumber,
  verified,
  failed,
  minutes,
  setMinutes,
  seconds,
  setSeconds,
  onSubmit,
  findId,
}: Props) => {
  const t = useT();

  return (
    <Wrapper>
      <div className="message">
        <p>{t("user.we_send_a_verification_code", "인증번호를 보냈습니다.")}</p>
        <p>
          {t(
            "user.please_enter_the_verification_code",
            "인증 번호를 입력해주세요"
          )}
        </p>
        <p>
          <span id="blue">mail : {mail}</span>
        </p>
      </div>
      <InputLabel
        value={emailNumber}
        onChange={(e) => setEmailNumber(e.target.value)}
        label={t("user.verification_code", "인증번호")}
        placeholder={"000000"}
        maxLength={6}
      />
      {!verified && emailNumber.length > 5 && (
        <LoginFailed>{failed}</LoginFailed>
      )}
      {!verified && emailNumber.length < 6 && (
        <>
          <Timer
            minutes={minutes}
            setMinutes={setMinutes}
            seconds={seconds}
            setSeconds={setSeconds}
          />
          {minutes === 0 && seconds === 0 && (
            <Button size="xs" onClick={() => findId()}>
              {t("common.resend", "재전송")}
            </Button>
          )}
        </>
      )}
      {verified && (
        <LoginFailed success>
          {t("user.the_verification_code_is_correct", "인증번호가 일치합니다.")}
        </LoginFailed>
      )}

      <div className="login_btn">
        <RoundButton onClick={() => onSubmit()} color="blue" size="full_lg">
          {t("user.find_id", "아이디 찾기")}
        </RoundButton>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  .login_btn {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
  .message {
    font-size: 12px;
    color: #666666;
  }
`;
export default FindId2;
