import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { CreditBook, CreditType } from "../../../../@types/billing/credit";
import { userState } from "../../../../constants/recoil/user";

interface Props {
  setSelectedType: React.Dispatch<React.SetStateAction<CreditType>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setBillings: React.Dispatch<React.SetStateAction<CreditBook[]>>;
}

const SelectType = ({ setSelectedType, setIsLoading, setBillings }: Props) => {
  const [selectedType, setSelectedTypeState] = useState<CreditType>(CreditType.ALL);
  const [user] = useRecoilState(userState);

  useEffect(() => {}, []);

  useEffect(() => {}, [selectedType]);

  const handleTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selected = e.target.value as CreditType;
    setSelectedTypeState(selected);
    setSelectedType(selected);
  };

  return (
    <Wrapper className="user_combo_wrap">
      <div className="combo_left">
        <div className="user_search_wrap">
          <select onChange={handleTypeChange} value={selectedType} className="history_data">
            {Object.values(CreditType).map((type, idx) => (
              <option value={type} key={idx}>
                {type}
              </option>
            ))}
          </select>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.article``;

export default SelectType;
