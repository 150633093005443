import React from "react";
import { Modal } from "@mui/material";
import styled, { FlattenSimpleInterpolation } from "styled-components";
import { useT } from "../../constants/i18n/useT";
import Button from "../button/Button";
import { ModalSize, MODALSIZES, ModalType } from ".";

interface Props {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: any;
  title?: string | any;
  buttonText?: string | null;
  onSubmit?: () => void;
  type?: ModalType;
  size?: ModalSize;
  isResponse?: boolean;
  disabled?: boolean;
}

const ModalPopup = ({
  isOpen,
  setOpen,
  children,
  title,
  buttonText,
  onSubmit,
  type = "confirm",
  size,
  isResponse,
  disabled,
}: Props) => {
  const sizeStyle = MODALSIZES[size || "sm"];
  const t = useT();
  return (
    <Modal
      className="modal_background"
      open={isOpen}
      onClose={() => setOpen(false)}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
      hideBackdrop
    >
      <Wrapper sizeStyle={sizeStyle}>
        <CloseButton onClick={() => setOpen(false)}>
          <button type="button">
            {title ? (
              <img
                src="/assets/sub/close.png"
                style={{
                  filter: "brightness(0)",
                }}
              />
            ) : (
              <img src="/assets/sub/close.png" />
            )}
          </button>
        </CloseButton>
        {title && (
          <TitleBox>
            <h3>{title}</h3>
          </TitleBox>
        )}

        <ContentBox>
          <Content>{children}</Content>
          {!isResponse && buttonText && onSubmit && (
            <ButtonBox>
              <Button size="full" onClick={() => setOpen(false)}>
                {t("common.close", "닫기")}
              </Button>
              {type === "delete" && (
                <Button size="full" disabled={disabled} color="warning" onClick={() => onSubmit()}>
                  {buttonText}
                </Button>
              )}
              {type === "confirm" && (
                <Button size="full" disabled={disabled} color="primary" onClick={() => onSubmit()}>
                  {buttonText}
                </Button>
              )}
              {type === "disable" && (
                <Button size="full" disabled={disabled} color="disabled">
                  {buttonText}
                </Button>
              )}
            </ButtonBox>
          )}
          {isResponse && buttonText && onSubmit && (
            <ButtonBox>
              <Button size="full" disabled onClick={() => setOpen(false)}>
                {t("common.close", "닫기")}
              </Button>
              {type === "delete" && (
                <Button size="full" disabled color="disabled" onClick={() => onSubmit()}>
                  {buttonText}
                </Button>
              )}
              {type === "confirm" && (
                <Button size="full" disabled color="disabled" onClick={() => onSubmit()}>
                  {buttonText}
                </Button>
              )}
              {type === "disable" && (
                <Button size="full" disabled color="disabled">
                  {buttonText}
                </Button>
              )}
            </ButtonBox>
          )}
        </ContentBox>
      </Wrapper>
    </Modal>
  );
};

interface StyleProps {
  sizeStyle: FlattenSimpleInterpolation;
}

const Wrapper = styled.div<StyleProps>`
  z-index: 11;
  overflow: hidden;
  position: fixed;
  margin: 0 auto;
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
  right: 0;
  background: rgb(255, 255, 255);
  border: 0.5px solid rgba(181, 180, 180, 0.406);
  max-height: 100%;
  overflow-y: auto;
  border-radius: 5px;
  ${(p) => p.sizeStyle}

  &:focus {
    outline: none;
  }
  .loading {
    margin-top: 70px;
  }
  @media screen and (max-width: 768px) {
    border-radius: 10px;
  }
`;
const CloseButton = styled.div`
  position: absolute;
  top: 12px;
  right: 13px;
  z-index: 10;
  button {
    border: 0;
    background: transparent;
    width: 17px;
    img {
      width: 100%;
    }
  }
  filter: brightness(0.5);
  @media screen and (max-width: 768px) {
  }
`;
const TitleBox = styled.div`
  width: 100%;
  padding: 15px 0;
  background: var(--Active);
  color: #fff;
  text-align: center;
  h3 {
    font-size: 22px;
  }
`;
const ContentBox = styled.div`
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    margin-top: 25px;
  }
`;
const Content = styled.div`
  text-align: center;
  min-height: 80px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h1 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  p {
    font-size: 16px;
    margin-bottom: 10px;
  }
`;

const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  margin: auto;
  margin-top: 20px;
  align-items: center;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;

    button {
      width: 48%;
      height: 40px !important;
    }
  }
  button {
    margin-bottom: 10px;
    height: 35px;
  }
`;

export default ModalPopup;
