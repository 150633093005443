import {
  ProjectForm,
  ProjectFormStorage,
  ProjectFormUser,
  ProjectFormWorkstation,
} from "../../../../@types/project/form";
import { ProjectUserUiData } from "../../../../@types/project/ui/user";
import { Project } from "../../../../@types/project/project";
import { ProjectStorageUiData } from "../../../../@types/project/ui/storage";
import { ProjectWorkstationUiData } from "../../../../@types/project/ui/workstation";

interface Props {
  project: Project | undefined;
  leftUsers: ProjectUserUiData[];
  leftWorkstations: ProjectWorkstationUiData[];
  leftStorages: ProjectStorageUiData[];
  projectForm: ProjectForm;
  setProjectForm: React.Dispatch<React.SetStateAction<ProjectForm>>;
  ws: any[];
  currentStorage: any;
  st: any[];
  currentWorkstation: any;
  user: any[];
  wsUser: any[];
}

export const _projectSetting = ({
  project,
  leftUsers,
  leftWorkstations,
  leftStorages,
  projectForm,
  setProjectForm,
  ws,
  currentStorage,
  st,
  currentWorkstation,
  user,
  wsUser,
}: Props) => {
  const checkSameUserRoleInProject = (user: ProjectUserUiData, project: Project) => {
    let result = false;
    project.users &&
      project.users.forEach(projectUser => {
        if (user.projectRole && user.projectRole.cd && projectUser.projectRole.cd) {
          if (
            projectUser.projectUserId === user.projectUserId &&
            projectUser.projectRole.cd === user.projectRole.cd
          ) {
            result = true;
          }
        }
      });
    return result;
  };

  const handleUserForm = (users: ProjectUserUiData[]): ProjectFormUser[] => {
    return users.map(user => {
      const newUser = new ProjectFormUser(user.userGuid, user.projectRole && user.projectRole.cd);
      if (user.projectUserId) {
        newUser.projectUserId = user.projectUserId;
        if (user.deleted) {
          newUser.useState = { cd: "UseState.Deleted" };
        } else if (project && !checkSameUserRoleInProject(user, project)) {
          newUser.useState = undefined;
        }
      }
      return newUser;
    });
  };

  const handleWorkstationForm = (
    workstations: ProjectWorkstationUiData[],
    st: any[],
    currentWorkstation: any,
    user: any[]
  ): ProjectFormWorkstation[] => {
    return workstations.map(workstation => {
      const newWorkstation = new ProjectFormWorkstation(workstation.workstationId);

      if (workstation.projectWorkstationId) {
        newWorkstation.projectWorkstationId = workstation.projectWorkstationId;
        if (workstation.deleted) {
          newWorkstation.useState = { cd: "UseState.Deleted" };
        }

        const isCurrentWorkstation =
          currentWorkstation && workstation.workstationId === currentWorkstation.workstationId;

        if (isCurrentWorkstation) {
          newWorkstation.storages = st.map(storage => ({
            storageId: storage.storageId,
            driveLetter: storage.driveLetter,
            useStateCd: storage.useStateCd === "UseState.Deleted" ? storage.useStateCd : undefined,
          }));

          newWorkstation.shareWith = wsUser.map(userItem => {
            return {
              userGuid: userItem.user.userGuid,
              useStateCd:
                userItem.useStateCd === "UseState.Deleted" ? userItem.useStateCd : undefined,
            };
          });
        } else {
          newWorkstation.storages = workstation.storages || [];
          newWorkstation.shareWith = [];
        }
      }

      return newWorkstation;
    });
  };

  const handleStorageForm = (
    storages: ProjectStorageUiData[],
    ws: any[],
    currentStorage: any
  ): ProjectFormStorage[] => {
    return storages.map(storage => {
      const newStorage = new ProjectFormStorage(storage.storageId);

      if (storage.projectStorageId) {
        newStorage.projectStorageId = storage.projectStorageId;
        if (storage.deleted) {
          newStorage.useState = { cd: "UseState.Deleted" };
        }
        if (storage.isPublic) {
          newStorage.isPublic = storage.isPublic;
        }

        const isCurrentStorage = currentStorage && storage.storageId === currentStorage.storageId;

        if (isCurrentStorage) {
          newStorage.workstations = ws.map(workstation => ({
            workstationId: workstation.workstationId,
            driveLetter: workstation.driveLetter,
            useStateCd:
              workstation.useStateCd === "UseState.Deleted" ? workstation.useStateCd : undefined,
          }));

          newStorage.shareWith = user.map(userItem => {
            return {
              userGuid: userItem.user.userGuid,
              useStateCd:
                userItem.useStateCd === "UseState.Deleted" ? userItem.useStateCd : undefined,
              privilegeCd: userItem.privilege ? userItem.privilege.cd : "Storage.Privilege.RW",
            };
          });
        } else {
          newStorage.workstations = storage.workstations || [];
        }
      }

      return newStorage;
    });
  };

  const handleProjectForm = () => {
    const newUsers: ProjectFormUser[] = handleUserForm(leftUsers);
    const newWorkstations: ProjectFormWorkstation[] = handleWorkstationForm(
      leftWorkstations,
      st,
      currentWorkstation,
      user
    );
    const newStorages: ProjectFormStorage[] = handleStorageForm(leftStorages, ws, currentStorage);

    setProjectForm(projectForm => ({
      ...projectForm,
      users: newUsers,
      workstations: newWorkstations,
      storages: newStorages,
    }));
  };

  return { handleProjectForm };
};
