import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { useT } from "../../../constants/i18n/useT";

const fadeIn = keyframes`
from {
  opacity: 0;
}
to {
  opacity: 1;
}
`;

const Page4 = () => {
  const t = useT();

  const [isOpen, setIsOpen] = useState(false);
  const [img, setImg] = useState("");

  const openModal = (imgSrc: string) => {
    setImg(imgSrc);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <>
      <div>
        <p>
          {t(
            "workstation.mac_client_download_message8",
            "M2MClientLauncher가 성공적으로 실행되면, 다음과 같이 Dock에 표시되는 것을 확인할 수 있습니다."
          )}
        </p>
        <Image
          src="/assets/user/download/download5.png"
          alt="Download"
          onClick={() => openModal("/assets/user/download/download5.png")}
        />
      </div>
      {isOpen && (
        <Modal onClick={closeModal}>
          <ModalContent src={img} onClick={(e) => e.stopPropagation()} />
          <CloseButton onClick={closeModal}>&times;</CloseButton>
        </Modal>
      )}
    </>
  );
};
const Image = styled.img`
  border-radius: 10px;
  margin-top: 30px;
  width: 100%;
`;

const Modal = styled.div`
  position: fixed;
  z-index: 1050;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  animation: ${fadeIn} 0.5s ease-out;
`;

const ModalContent = styled.img`
  margin: auto;
  display: block;
  max-width: 90%;
  max-height: 80%;
  margin-top: 10%;
  animation: ${fadeIn} 0.5s ease-out;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
`;
export default Page4;
