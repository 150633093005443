import React from "react";
import { DropDownSetting } from "./setting";
import { HiDotsVertical } from "@react-icons/all-files/hi/HiDotsVertical";

interface Props {
  children: React.ReactNode;

  type: "workstation" | "storage" | "project";
  status?: "Error" | "PowerOff" | "notMine" | string;
}

export const CardDropdown = ({ children, type, status }: Props) => {
  const getIcon = (type?: "Error" | "PowerOff" | string) => {
    if (!type) return <HiDotsVertical size={24} />;
    if (type === "Error") return <HiDotsVertical size={24} />;
    if (type === "PowerOff") return <HiDotsVertical size={24} />;
    return <HiDotsVertical size={24} />;
  };

  return (
    <DropDownSetting.Wrapper>
      {type === "workstation" && status && (
        <DropDownSetting.Button>{getIcon(status)}</DropDownSetting.Button>
      )}
      {type === "storage" && <DropDownSetting.Button>{getIcon(status)}</DropDownSetting.Button>}
      {type === "project" && <DropDownSetting.Button>{getIcon()}</DropDownSetting.Button>}

      <DropDownSetting.Content>{children}</DropDownSetting.Content>
    </DropDownSetting.Wrapper>
  );
};

interface MProps {
  title: string;
  onClick?: () => void;
  // setState: React.Dispatch<React.SetStateAction<boolean>>;
}
CardDropdown.MenuElement = ({ title, onClick }: MProps) => {
  return (
    <li onClick={onClick}>
      <a>{title}</a>
    </li>
  );
};
