import React, { useState } from "react";
import {
  DefaultUserUiData,
  ProjectUserUiData,
} from "../../../../../../@types/project/ui/user";
import UserListForm from "../components/user.list.wapper";
import NameWithCheckBox from "../../../../../project/components/modal/create/setting-create-update/components/item/name-with-checkbox";
import styled from "styled-components";
import CircleProgress from "../../../../../../components/loading/CircleProgress";

interface Props {
  rightUsers: ProjectUserUiData[];
  setRightUsers: React.Dispatch<React.SetStateAction<ProjectUserUiData[]>>;
}

const RightUserListWithProject = ({ rightUsers, setRightUsers }: Props) => {
  const handleCheckbox = (usr: DefaultUserUiData) => () => {
    const newUsers = rightUsers.map((u) =>
      u.userGuid === usr.userGuid ? { ...usr, isChecked: !usr.isChecked } : u
    );
    setRightUsers(newUsers);
  };

  const [isLoading] = useState(false);

  return (
    <Wrapper>
      <UserListForm title="Project Member">
        {isLoading && <CircleProgress />}
        {!isLoading &&
          rightUsers.map((usr) => (
            <NameWithCheckBox
              key={usr.userGuid}
              title={usr.fullName}
              isChecked={usr.isChecked}
              handleCheckbox={handleCheckbox(usr)}
              user={usr}
            />
          ))}
      </UserListForm>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export default RightUserListWithProject;
