import React, { useEffect, useState } from "react";
import TabWrapper from "./tab-wrapper";
import InfraCreateButton from "../button/infra/create-button";
import SearchInput from "../input/search/infra-input";
import ProjectCreate from "../../pages/project/components/modal/create/modal";
import { MAX_LENGTH } from "../../constants/max-length";
import { ProjectList } from "../../@types/project/response/project-list";
import { Project } from "../../@types/project/project";

interface Props {
  rawProjects: ProjectList;
  setProjects: React.Dispatch<React.SetStateAction<ProjectList>>;
  getList: () => void;
}

const ProjectNavbar = ({ getList, rawProjects, setProjects }: Props) => {
  const [popCreate, setPopCreate] = useState(false);
  const [value, setValue] = useState("");
  const openCreateProject = () => {
    setPopCreate(true);
  };

  const searchProject = () => {
    let myProjects: Project[] = [];
    let sharedAsDirectorProjects: Project[] = [];
    let sharedAsMemberProjects: Project[] = [];

    const getFilter = (projects: Project[]) => {
      return projects.filter(
        (project) =>
          project.name &&
          project.name.toLowerCase().includes(value.toLowerCase())
      );
    };

    myProjects = getFilter(rawProjects.myProjects);

    sharedAsDirectorProjects = getFilter(rawProjects.sharedAsDirectorProjects);

    sharedAsMemberProjects = getFilter(rawProjects.sharedAsMemberProjects);

    setProjects({
      myProjects,
      sharedAsDirectorProjects,
      sharedAsMemberProjects,
    });
  };

  useEffect(() => searchProject(), [rawProjects, value]);

  return (
    <TabWrapper>
      <InfraCreateButton type="project" onClick={() => openCreateProject()} />
      <SearchInput
        placeholder="search project"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        maxLength={MAX_LENGTH.STROAGE}
      />
      {popCreate && (
        <ProjectCreate
          getList={getList}
          isOpen={popCreate}
          setOpen={setPopCreate}
        />
      )}
    </TabWrapper>
  );
};

export default ProjectNavbar;
