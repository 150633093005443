import React, { useState, useEffect } from "react";

import styled from "styled-components";
import { useT } from "../../constants/i18n/useT";

interface Props {
  minutes: number;
  setMinutes: React.Dispatch<React.SetStateAction<number>>;
  seconds: number;
  setSeconds: React.Dispatch<React.SetStateAction<number>>;
}

const Timer = ({ minutes, setMinutes, seconds, setSeconds }: Props) => {
  // const [minutes, setMinutes] = useState(0);
  const t = useT();

  useEffect(() => {
    const countdown = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(countdown);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => clearInterval(countdown);
  }, [minutes, seconds]);

  return (
    <Wrapper>
      {(minutes > 0 || seconds > 0) && (
        <p className="time">
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </p>
      )}
      {minutes === 0 && seconds === 0 && (
        <div className="time_over">
          <p>
            {t(
              "user.the_validity_period_has_expired",
              "유효 시간이 지났습니다."
            )}{" "}
          </p>
        </div>
      )}
    </Wrapper>
  );
};
const Wrapper = styled.article`
  color: #535353;
  .time {
    padding: 5px;
  }
  .time_over {
    margin-top: 5px;
    height: 20px;
    font-size: 11px;
    color: #fa3f3f;
  }
`;

export default Timer;
