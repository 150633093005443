import React, { useEffect, useState } from "react";
import ModalPopup from "../../../../../components/modal/popup";
import { useT } from "../../../../../constants/i18n/useT";
import { Spec } from "../../../../../@types/infra/spec";
import { Image } from "../../../../../@types/infra/image";
import { Code } from "../../../../../@types/infra/code";
import { Workstation } from "../../../../../@types/workstation/workstation";
import { Workstationlist } from "../../../../../@types/workstation/response";
import { handleCreateWS } from "../../../script/ws-create";
import CircleProgress from "../../../../../components/loading/CircleProgress";
import InputLabel from "../../../../../components/input/input-label/input";
import { MAX_LENGTH } from "../../../../../constants/max-length";
import WSCreateSpec from "./components/spec";
import WSCreateOS from "./components/os";
import WSCreateSW from "./components/sw";
import WSCreateBoot from "./components/boot-disk";
import UpdateWSStorage from "../update/storage";
import WSAutoStart from "./components/auto-start";
import WSCreateSuccess from "./components/ws-create-success";
import styled from "styled-components";
import SmallSelectLabel from "./components/select-label";
import SelectLabel from "../../../../../components/input/input-label/select";
import { Drives } from "../../../../../service/storage/storage.service";
import { RefindedStorage } from "../../../../../@types/storage/form/refined-storage";
import { ModalProps } from "../../../../../components/modal";

interface Props extends ModalProps {
  getList: () => void;
  workstations: Workstation[];
  setCreateWs: React.Dispatch<React.SetStateAction<boolean>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  everyWorkstations: Workstationlist;
}

const WSCreatePage = ({
  everyWorkstations,
  setCreateWs,
  workstations,
  isOpen,
  setOpen,
  getList,
}: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isResponse, setIsResponse] = useState(false);
  const [isCompleteResponse, setIsCompleteResponse] = useState(false);
  // UI DATA
  const [specs, setSpecs] = useState<Spec[]>([]);

  const [images, setImages] = useState<Image[]>([]);
  const [selectImages, setSelectImages] = useState<Image[]>([]);
  const [selectImages2, setSelectImages2] = useState<Image[]>([]);
  const [oss, setOss] = useState<Code[]>([]);
  const [SWs, setSWs] = useState<Code[]>([]);
  const [description, setDescription] = useState("");
  const [autoStart, setAutoStart] = useState(false);

  // MY DATA
  const [refinedStorages, setRefinedStorages] = useState<RefindedStorage[]>([]);
  const [drives, setDrives] = useState(new Drives()); // {'x':true,'y':true...}
  const [showSpecSelect, setShowSpecSelect] = useState(false);

  const [name, setName] = useState("");
  const [validName, setValidName] = useState(false);

  const [spec, setSpec] = useState({});
  const [bootDisk, setBootDisk] = useState("");
  const [imageId, setImageId] = useState("");
  const [os, setOs] = useState("");
  const [sw, setSw] = useState("");

  const [complete, setComplete] = useState(false);
  const [createWS, setCreateWS] = useState(false);

  const { onSubmit, getUiData, checkName } = handleCreateWS({
    name,
    spec,
    os,
    sw,
    bootDisk,
    imageId,
    refinedStorages,
    workstations,
    description,
    autoStart,
    complete,
    createWS,
    setCreateWS,
    setIsResponse,
    setIsCompleteResponse,
    setOpen,
    setIsLoading,
    getList,
    setOss,
    setSWs,
    setBootDisk,
    setSpecs,
    setImages,
    setRefinedStorages,
    setDescription,
    setAutoStart,
    validName,
    setValidName,
    setCreateWs,
    setComplete,
    everyWorkstations,
  });

  useEffect(() => {
    const fetchData = async () => {
      await getUiData();
    };

    fetchData();
  }, []);
  useEffect(() => checkName(), [name]);
  const t = useT();

  if (isResponse)
    return (
      <ModalPopup
        isOpen={isOpen}
        setOpen={setOpen}
        buttonText={t("common.add", "추가")}
        type="disable"
        onSubmit={onSubmit}
        isResponse={isResponse}
        size="lg"
      >
        <Wrapper>
          <div className="loading_box">
            <div className="progress">
              <CircleProgress size="big" />
            </div>
            <h1>{t("workstation.creating_workstation", "Workstation 생성중입니다...")}</h1>
            <h3>{t("workstation.please_wait_a_moment", "잠시만 기다려주세요.")}</h3>
            <h3>{t("workstation.it_may_take_a_few_minutes", "몇 분 정도 소요될 수 있습니다.")}</h3>
            <br />
            <h4>
              {t(
                "workstation.after_the_workstation_is_created",
                "(Workstation 생성 후엔 사용여부와 상관없이"
              )}
            </h4>
            <h4>
              {t(
                "workstation.you_will_be_charged_for_the_bootdisk",
                "부팅디스크에 대한 요금이 부과됩니다.)"
              )}
            </h4>
          </div>
        </Wrapper>
      </ModalPopup>
    );

  if (isCompleteResponse)
    return (
      <WSCreateSuccess
        getList={getList}
        isOpen={isOpen}
        setOpen={setOpen}
        workstations={workstations}
        autoStart={autoStart}
        setAutoStart={setAutoStart}
      />
    );

  return (
    <ModalPopup
      isOpen={isOpen}
      setOpen={setOpen}
      buttonText={t("common.add", "추가")}
      onSubmit={onSubmit}
      size="lg"
    >
      <Wrapper>
        {isLoading && <CircleProgress size="big" />}
        {!isLoading && (
          <>
            <InputLabel
              value={name}
              onChange={e => setName(e.target.value)}
              label={t("workstation.name", "이름")}
              // isValid={validName}
              description={t(
                "workstation.enter_a_4_15_character_name",
                "한글 영문 포함 4~15자 이내로 입력해주세요."
              )}
              maxLength={MAX_LENGTH.WORKSTATION}
              placeholder="Workstation name"
            />
            <SelectLabel label="Spec">
              <WSCreateSpec
                showSpecSelect={showSpecSelect}
                setShowSpecSelect={setShowSpecSelect}
                spec={spec}
                specs={specs}
                setSpec={setSpec}
              />
            </SelectLabel>
            <div className="flex_box">
              <SmallSelectLabel label="OS">
                <WSCreateOS
                  setImageId={setImageId}
                  images={images}
                  bootDisk={bootDisk}
                  setBootDisk={setBootDisk}
                  oss={oss}
                  os={os}
                  setOs={setOs}
                  setSelectImages={setSelectImages}
                  setSw={setSw}
                />
              </SmallSelectLabel>
              <SmallSelectLabel label="SW">
                <WSCreateSW
                  SWs={SWs}
                  setSw={setSw}
                  sw={sw}
                  selectImages={selectImages}
                  setSelectImages2={setSelectImages2}
                  os={os}
                  images={images}
                />
              </SmallSelectLabel>
              <SmallSelectLabel label={t("workstation.boot_disk_size", "부트 디스크 크기")}>
                <WSCreateBoot
                  setImageId={setImageId}
                  isLoading={isLoading}
                  setBootDisk={setBootDisk}
                  bootDisk={bootDisk}
                  images={images}
                  os={os}
                  sw={sw}
                  selectImages2={selectImages2}
                />
              </SmallSelectLabel>
            </div>
            <UpdateWSStorage
              refinedStorage={refinedStorages}
              setRefinedStorages={setRefinedStorages}
              drives={drives}
              setDrives={setDrives}
              isLoading={isLoading}
            />
            <InputLabel
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              label={t("common.description", "비고")}
              placeholder=""
            />
            <WSAutoStart autoStart={autoStart} setAutoStart={setAutoStart} />
          </>
        )}
        {!isLoading && !images.length && (
          <NoImage>
            <h3>{t("workstation.we_are_unable_to_create_a_workstation")}</h3>
            <h3>{t("workstation.please_contact_the_administrator")}</h3>
          </NoImage>
        )}
      </Wrapper>
    </ModalPopup>
  );
};

const Wrapper = styled.div`
  width: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .flex_box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
  }

  .loading_box {
    margin-top: 50px;

    .progress {
      margin-bottom: 40px;
    }
  }
`;

const NoImage = styled.article`
  padding-top: 50px;
  align-items: center;
`;

export default WSCreatePage;
