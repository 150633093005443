import React from "react";
import styled from "styled-components";
import { Element as ScrollElement } from "react-scroll";
import { useT, useCurrentLanguage } from "../../../constants/i18n/useT";

const Project = () => {
  const t = useT();
  const currentLanguage = useCurrentLanguage();

  return (
    <Wrapper>
      <ScrollElement name="project1">
        <Title>1. {t("common.project_creation_and_configuration", "프로젝트 생성 및 변경")}</Title>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_project1",
            `M2M 서비스는 프로젝트 개념을 지원합니다. 사용자는 프로젝트를 생성하여 워크스테이션, 스토리지를 프로젝트 단위로 관리하고 프로젝트에 참여자를 할당할 수 있습니다. 이는 실제 CG/VFX/편집 등의 작업에 유용하게 사용될 수 있습니다.`
          )}
        </Paragraph>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_project2",
            `프로젝트의 멤버의 권한은 Director와 Artist로 구분이 됩니다. 프로젝트를 생성한 사람은 자동으로 해당 프로젝트의 Director가 되며 프로젝트의 소유자(owner) 가 됩니다. 프로젝트의 Director는 프로젝트를 관리하는 멤버로서 프로젝트 설정을 변경할 수 있습니다. 프로젝트의 Artist는 프로젝트내의 컴퓨팅 자원 중 공유 설정된 자원에 대한 접근 및 사용이 가능하나 프로젝트의 설정 변경은 불가능합니다.`
          )}
        </Paragraph>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_project3",
            `프로젝트 생성을 위하여 페이지 왼쪽 컬럼의 ‘Project’ 메뉴를 클릭하며 프로젝트 페이지로 이동합니다. 이제, “Project 생성”을 클릭하여 다음의 UI를 이용하여 프로젝트를 생성할 수 있습니다. 프로젝트의 이름, 기간, 공개여부, 멤버/워크스테이션/스토리지 할당 설정을 통해 프로젝트를 생성할 수 있습니다. 프로젝트 이름만 먼저 설정하여 생성한 후에 다른 설정을 수정할 수도 있습니다. 프로젝트가 성공적으로 생성되면 워크스테이션 경우와 비슷하게 프로젝트별로 카드 형식으로 표시됩니다.`
          )}
        </Paragraph>

        <Spacer />

        <ImageWrapper>
          <ResponsiveImage
            src={`/assets/guide/${
              currentLanguage === "ko_KR" ? "create-project.png" : "en/en_create-project.png"
            }`}
            alt="create-project"
          />
        </ImageWrapper>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_project4",
            ` 프로젝트 생성 후에 프로젝트 수정 메뉴를 통해 멤버/워크스테이션/스토리지 할당 등을 변경할 수 있습니다.`
          )}
        </Paragraph>

        <Spacer />

        <ImageWrapper>
          <ResponsiveImage
            src={`/assets/guide/${
              currentLanguage === "ko_KR" ? "edit-project.png" : "en/en_edit-project.png"
            }`}
            alt="edit-project"
          />
        </ImageWrapper>
        <ImageWrapper>
          <ResponsiveImage
            src={`/assets/guide/${
              currentLanguage === "ko_KR" ? "edit-project2.png" : "en/en_edit-project2.png"
            }`}
            alt="edit-project2"
          />
        </ImageWrapper>
      </ScrollElement>

      <Spacer />

      <ScrollElement name="project2">
        <Title>2. {t("common.project_editing", "프로젝트 변경")}</Title>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_project5",
            `프로젝트의 소유자와 Director는 프로젝트의 리소스(사용자, 워크스테이션, 스토리지) 할당 및 사용 권한, 마운트 등의 설정을 변경할 수 있습니다.`
          )}
        </Paragraph>

        <Spacer />

        <SmallTitle>1-1 {t("common.guide_project6", `멤버(Member) 할당`)}</SmallTitle>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_project7",
            `프로젝트 수정 UI의 Member 할당 섹션에서 프로젝트에 참여하는 사용자를 변경할 수 있습니다. 참여할 사용자의 ID를 유저검색창에 입력하고 검색하면 ID가 일치하는 사용자가 검색 결과에 표시됩니다. 이제, 해당 사용자를 선택하고 화살표를 클릭하여 프로젝트 멤버로 등록/제외할 수 있습니다. 멤버할당 후에 제외된 사용자는 “이전 member” 리스트에 포함되어 추가 검색 없이 멤버로 재등록이 가능합니다. 멤버 할당을 수정한 후에는 저장을 통해 변경한 설정을 적용해야 합니다. 이때 “닫기”로 프로젝트 수정 UI를 닫으면 변경한 설정이 적용되지 않으니 주의해야 합니다.`
          )}
        </Paragraph>

        <Spacer />

        <ImageWrapper>
          <ResponsiveImage
            src={`/assets/guide/${
              currentLanguage === "ko_KR" ? "project-member.png" : "en/en_project-member.png"
            }`}
            alt="project-member"
          />
        </ImageWrapper>

        <Spacer />

        <SmallTitle>
          1-2 {t("common.workstation_assignment_and_settings", "워크스테이션 할당")}
        </SmallTitle>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_project8",
            `“Workstation 할당” 섹션에서 프로젝트에서 사용할 워크스테이션을 변경할 수 있습니다. 현재 할당가능한 워크스테이션 리스트가 오른쪽 박스 안에 나타납니다. 프로젝트 소유자가 생성한 워크스테이션만 할당 가능 리스트에 들어갈 수 있습니다. 워크스테이션에 공유 사용자가 설정되어 있는 경우는 할당 가능 리스트에 나타나지 않으며 공유 설정을 해제한 후에 프로젝트에 할당할 수 있습니다. 워크스테이션에 공유 스토리지가 마운트 되어 있는 경우도 프로젝트에 할당이 불가능하며 공유 스토리지를 언마운트 한 후에 프로젝트에 할당할 수 있습니다. 프로젝트에 할당된 워크스테이션은 프로젝트 멤버만 공유 설정이 가능하게 되며 프로젝트 스토리지만 마운트가 가능하게 됩니다.`
          )}
        </Paragraph>

        <Spacer />

        <ImageWrapper>
          <ResponsiveImage
            src={`/assets/guide/${
              currentLanguage === "ko_KR" ? "project-ws.png" : "en/en_project-ws.png"
            }`}
            alt="project-ws"
          />
        </ImageWrapper>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_project9",
            `프로젝트에 할당된 워크스테이션을 할당 해제할 경우 워크스테이션에 설정된 공유 사용자와 스토리지가 자동으로 설정 해제됩니다. 이 경우 공유자로서 워크스테이션을 사용하는 사용자가 더 이상 워크스테이션에 접근하지 못하게 되니 주의하여야 합니다.`
          )}
        </Paragraph>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_project10",
            `실제로 할당된 워크스테이션을 프로젝트에서 사용하기 위해선 사용자 및 스토리지 마운트 설정이 필요합니다. 워크스테이션에 마우스 포인터를 위치하고 오른쪽 버튼을 클릭하면 “사용자 설정”, “스토리지 설정” 메뉴가 나타납니다.`
          )}
        </Paragraph>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_project11",
            `이때, “사용자설정”을 클릭하면 “Workstation 사용자설정” 윈도우가 팝업되며 현재 프로젝트 멤버 중에 해당 워크스테이션 사용자를 설정할 수 있습니다.`
          )}
        </Paragraph>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_project12",
            `“스토리지 설정”을 클릭하면 스토리지 마운트 설정 윈도우가 팝업되며 원하는 스토리지와 드라이브 레터를 선택하고 “저장” 버튼을 누르면 스토리지가 워크스테이션에 마운트 됩니다.`
          )}
        </Paragraph>

        <Spacer />

        <ImageWrapper>
          <ResponsiveImage
            src={`/assets/guide/${
              currentLanguage === "ko_KR" ? "project-ws-member.png" : "en/en_project-ws-member.png"
            }`}
            alt="project-ws-member"
          />
        </ImageWrapper>

        <Spacer />

        <ImageWrapper>
          <ResponsiveImage
            src={`/assets/guide/${
              currentLanguage === "ko_KR" ? "project-ws-st.png" : "en/en_project-ws-st.png"
            }`}
            alt="project-ws-st"
          />
        </ImageWrapper>

        <Spacer />

        <SmallTitle>1-3 {t("common.storage_assignment_and_settings", `스토리지 할당`)}</SmallTitle>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_project13",
            `“Storage 할당” 섹션에서 프로젝트에서 사용할 스토리지를 변경할 수 있습니다. 현재 할당 가능한 스토리지 리스트가 오른쪽 박스안에 나타납니다. 스토리지를 왼쪽 박스로 이동시키고 저장하면 스토리지가 프로젝트에 할당됩니다. 프로젝트 소유자가 생성한 스토리지만 프로젝트에 할당 가능합니다. 프로젝트 소유자가 생성한 스토리지도 다른 사용자와 공유 설정되어 있는 경우에는 프로젝트에 할당 리스트에 보여지기 않습니다.`
          )}
        </Paragraph>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_project14",
            `스토리지가 프로젝트에 할당되면 프로젝트 멤버에게만 공유 설정이 가능합니다. 프로젝트 할당에서 제외되면 스토리지에 설정된 모든 공유 설정은 해제되어 이전 공유 사용자의 접근이 불가능하게 됩니다.`
          )}
        </Paragraph>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_project15",
            `워크스테이션과 마찬가지로 사용자 및 마운트 설정을 하여야 실제로 프로젝트에서 스토리지 사용이 가능합니다. 프로젝트에 할당된 스토리지를 오른쪽 마우스 클릭하면 “사용자설정” 및 “마운트설정” 메뉴가 나타난다. 이때, “사용자설정”을 클릭하면 스토리지의 사용자 설정 윈도우가 팝업되며 현재 할당된 프로젝트 멤버 중에 스토리지 사용자 선택하고 적당한 접근 권한(“Read/Write”, “Read”)을 설정한다.`
          )}
        </Paragraph>

        <Spacer />

        <ImageWrapper>
          <ResponsiveImage
            src={`/assets/guide/${
              currentLanguage === "ko_KR" ? "project-st-member.png" : "en/en_project-st-member.png"
            }`}
            alt="project-st-member"
          />
        </ImageWrapper>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_project16",
            `“마운트설정”을 클릭하면 해당 스토리지를 프로젝트 워크스테이션에 마운트할 수 있습니다. 팝업된 마운트 설정 윈도우에 프로젝트에 할당된 워크스테이션 리스트가 나타나며 마운트할 워크스테이션과 드라이브 레터를 선택하여 “저장” 버튼을 클릭하면 스토리지의 마운트 설정이 완료됩니다.`
          )}
        </Paragraph>

        <Spacer />

        <ImageWrapper>
          <ResponsiveImage
            src={`/assets/guide/${
              currentLanguage === "ko_KR" ? "project-st-ws.png" : "en/en_project-st-ws.png"
            }`}
            alt="project-st-ws"
          />
        </ImageWrapper>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_project17",
            `“마운트설정”을 클릭하면 해당 스토리지를 프로젝트 워크스테이션에 마운트할 수 있습니다. 팝업된 마운트 설정 윈도우에 프로젝트에 할당된 워크스테이션 리스트가 나타나며 마운트할 워크스테이션과 드라이브 레터를 선택하여 “저장” 버튼을 클릭하면 스토리지의 마운트 설정이 완료됩니다.`
          )}
        </Paragraph>

        <Spacer />

        <ImageWrapper>
          <ResponsiveImage
            src={`/assets/guide/${
              currentLanguage === "ko_KR" ? "project-st-public.png" : "en/en_project-st-public.png"
            }`}
            alt="project-st-public"
          />
        </ImageWrapper>
      </ScrollElement>
    </Wrapper>
  );
};

export default Project;

const Wrapper = styled.div`
  padding: 25px;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 20px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

const SmallTitle = styled.h3`
  font-size: 20px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 18px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

const Paragraph = styled.p`
  font-size: 16px;
  line-height: 1.5;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 13px;
  }
`;

const Spacer = styled.div`
  height: 20px;

  @media (max-width: 768px) {
    height: 15px;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    margin-top: 30px;
    margin-bottom: 40px;
  }

  @media (max-width: 480px) {
    margin-top: 20px;
    margin-bottom: 30px;
  }
`;

const ResponsiveImage = styled.img`
  max-width: 100%;
  height: auto;
`;
