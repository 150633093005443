import { LinearProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useT } from "../../../../constants/i18n/useT";
import { UserAlarms } from "../../../../service/userAlarms/userAlarms";
import { userAlarmData } from "../../../../@types/\balarm/userAlarmData";

interface Props {
  // isLoading: boolean;
  headers: { title: string; width: number }[];
  onUserAlarmsUpdate: (data: userAlarmData[]) => void;
  startIndex: number;
  endIndex: number;
}

const TableBody = ({ headers, onUserAlarmsUpdate, startIndex, endIndex }: Props) => {
  const t = useT();
  const [userAlarms, setUserAlarms] = useState([]);
  const [isLoading, setIsLoading] = useState<Boolean>(true);

  useEffect(() => {
    getAlarmList();
  }, []);

  const getAlarmList = async () => {
    try {
      const res = await UserAlarms.getList();
      if (res.state === "success") {
        let data = res.data;

        setUserAlarms(data);
        onUserAlarmsUpdate(data);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <Wrapper className="history_td_wrap">
      {isLoading && <LinearProgress />}
      <Table>
        <TableSize>
          {headers.map((content, idx) => (
            <col key={idx} width={`${content.width}%`} />
          ))}
        </TableSize>
        <tbody>
          {userAlarms.slice(startIndex, endIndex).map((data: userAlarmData, index) => (
            <ContentWrapper key={index}>
              <td className="content">{startIndex + index + 1}</td>
              <td className="content">{data.createdDt}</td>
              <td className="content">{data.project ? data.project.name : "-"}</td>
              <td>{data.message}</td>
            </ContentWrapper>
          ))}
        </tbody>
      </Table>

      {!isLoading && userAlarms.length === 0 && (
        <NoData>
          <h1>No data found</h1>
        </NoData>
      )}
    </Wrapper>
  );
};
const ContentWrapper = styled.tr`
  #left {
    text-align: start;
    padding-left: 10px;
  }
  #right {
    text-align: end;
    padding-right: 20px;
  }
  
  .content {
    font-size: 30
    font-weight: 700;
    text-align: center;
  }
`;

const Wrapper = styled.article`
  overflow: hidden;
  overflow-y: auto;
  max-height: 55vh;
  margin-top: 10px;
  font-size: 11px;
  tr {
    border-bottom: 1px solid #ddd;
    &:hover {
      background: #f8f8f8;
    }
  }
`;

const Table = styled.table``;
const TableSize = styled.colgroup``;

const NoData = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 30px;
  h1 {
    font-size: 20px;
  }
`;

export default TableBody;
