import React from "react";
import styled from "styled-components";
import RoundButton from "../../../components/button/round-button";
import InputLabel from "../../../components/input/input-label/input";
import { MAX_LENGTH } from "../../../constants/max-length";
import LoginFailed from "../../../components/input/signup/text-alert";
import { InputService } from "../../../service/common/input.service";
import { useT } from "../../../constants/i18n/useT";

interface Props {
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  mail: string;
  setMail: React.Dispatch<React.SetStateAction<string>>;
  loginFailed: string;
  findId: () => void;
}
const FindId1 = ({
  name,
  setName,
  mail,
  setMail,
  loginFailed,
  findId,
}: Props) => {
  const t = useT();

  return (
    <Wrapper>
      <InputLabel
        value={name}
        onChange={(e) => setName(e.target.value)}
        label={t("user.name", "이름")}
        placeholder={
          t("user.please_enter_your_name", "이름을 입력하세요") || ""
        }
        maxLength={MAX_LENGTH.NAME}
      />
      <InputLabel
        value={mail}
        onChange={(e) => setMail(e.target.value)}
        type="mail"
        label={t("user.email", "이메일")}
        placeholder={
          t(
            "user.please_enter_the_email_you_used_to_sign_up",
            "가입하신 메일을 입력하세요."
          ) || ""
        }
        onKeyDown={(e) => InputService.pressEnter(e, findId)}
        maxLength={MAX_LENGTH.MAIL}
      />

      <LoginFailed>{loginFailed}</LoginFailed>

      <div className="login_btn">
        <RoundButton onClick={() => findId()} color="blue" size="full_lg">
          {t("user.find_id", "아이디 찾기")}
        </RoundButton>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  .login_btn {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
`;
export default FindId1;
