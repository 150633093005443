import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useT } from "../../constants/i18n/useT";

// Importing icons
import {
  FaUserAlt,
  FaDesktop,
  FaHdd,
  FaProjectDiagram,
  FaInfoCircle,
  FaTabletAlt,
} from "react-icons/fa";

import SystemRequirement from "./contents/system-requirement";
import Service from "./contents/service";
import Workstation from "./contents/workstation";
import Storage from "./contents/storage";
import Project from "./contents/project";
import UserInfo from "./contents/userInfo";
import Ect from "./contents/etc";

import ServiceLeft from "./contents/serviceLeft";
import WorkstationLeft from "./contents/workstationLeft";
import StorageLeft from "./contents/storageLeft";
import ProjectLeft from "./contents/projectLeft";
import UserInfoLeft from "./contents/userInfoLeft";
import EtcLeft from "./contents/etcLeft";

import ToScrollTop from "../../components/button/to-scroll-top";

const Guide = () => {
  const [activeSection, setActiveSection] = useState("service");
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [showLeft, setShowLeft] = useState(false);

  const t = useT();

  const handleSectionClick = (section: string) => {
    setActiveSection(section);
  };

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }

    if (window.scrollY > 650) {
      setShowLeft(true);
    } else {
      setShowLeft(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Wrapper>
      <div className="sub_page">
        <div className="content">
          <div className="g_sub_top">
            <div className="g_sub_top_text">
              <h3>{t("common.user_guide", "사용가이드")}</h3>
              <div className="site_step">
                <div>
                  <Link to="/">
                    <img alt="img" src="/assets/sub/home_icon.png" />
                    Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="main_section">
            <div className="sidebar">
              <ul>
                <NavItem
                  className={activeSection === "service" ? "active" : ""}
                  onClick={() => handleSectionClick("service")}
                >
                  <FaUserAlt />
                  {t("common.accessing_and_logging_in_services", `서비스 접속 및 로그인`)}
                </NavItem>
                <NavItem
                  className={activeSection === "workstation" ? "active" : ""}
                  onClick={() => handleSectionClick("workstation")}
                >
                  <FaDesktop />
                  {t("common.using_workstations", `워크스테이션 사용`)}
                </NavItem>
                <NavItem
                  className={activeSection === "storage" ? "active" : ""}
                  onClick={() => handleSectionClick("storage")}
                >
                  <FaHdd />
                  {t("common.using_storage", `스토리지 사용`)}
                </NavItem>
                <NavItem
                  className={activeSection === "project" ? "active" : ""}
                  onClick={() => handleSectionClick("project")}
                >
                  <FaProjectDiagram />
                  {t("common.project_setting", `프로젝트 세팅`)}
                </NavItem>
                <NavItem
                  className={activeSection === "user" ? "active" : ""}
                  onClick={() => handleSectionClick("user")}
                >
                  <FaInfoCircle />
                  {t("common.user_info", `사용자 정보`)}
                </NavItem>
                <NavItem
                  className={activeSection === "ect" ? "active" : ""}
                  onClick={() => handleSectionClick("ect")}
                >
                  <FaTabletAlt />
                  {t("common.etc", `주변기기(모니터, 태블릿, etc)`)}
                </NavItem>
              </ul>
            </div>
            <div className="content_area">
              {activeSection === "service" && <Service />}
              {activeSection === "service" && showLeft && <ServiceLeft />}

              {activeSection === "workstation" && <Workstation />}
              {activeSection === "workstation" && showLeft && <WorkstationLeft />}

              {activeSection === "storage" && <Storage />}
              {activeSection === "storage" && showLeft && <StorageLeft />}

              {activeSection === "project" && <Project />}
              {activeSection === "project" && showLeft && <ProjectLeft />}

              {activeSection === "user" && <UserInfo />}
              {activeSection === "user" && showLeft && <UserInfoLeft />}

              {activeSection === "ect" && <Ect />}
              {activeSection === "ect" && showLeft && <EtcLeft />}
            </div>
          </div>
        </div>
        {showScrollButton && <ToScrollTop />}
      </div>
    </Wrapper>
  );
};

export default Guide;

const Wrapper = styled.div`
  .site_step {
    display: flex;
    align-items: center;
  }

  .main_section {
    display: flex;
  }

  .sidebar {
    width: 250px;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    position: absolute;
  }

  .sidebar ul {
    list-style: none;
    padding: 0;
    width: 100%;
  }

  .sidebar li {
    margin-bottom: 10px;
  }

  .sidebar li a {
    text-decoration: none;
    color: #333;
  }

  .content_area {
    flex-grow: 1;
    padding: 20px;
    margin-left: 270px;
  }
`;

const NavItem = styled.li`
  display: flex;
  align-items: center;
  line-height: 50px;
  padding: 0 20px;
  color: #333;
  width: 90%;
  display: inline-block;
  margin-bottom: 20px;
  font-weight: bold;
  cursor: pointer;

  svg {
    margin-right: 10px;
  }

  &:hover {
    background: var(--GridaBlue);
    border-radius: 5px;
    color: #ffffff;
  }

  &.active {
    background: var(--GridaBlue);
    color: #fff;
    border-radius: 5px;
    background-image: url("/assets/user/menu_arrow.png");
    background-repeat: no-repeat;
    background-position: right 20px center;
  }
`;
