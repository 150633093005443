import { APICLIENT } from "../api";

const PROJECT_API = "/api/v1/infra-web-user/projects";
const INFRA_WEB_USER = "/api/v1/infra-web-user";

export const ProjectService = {
  getById: async (id: number) => {
    const response = await APICLIENT.AUTH.get(`${PROJECT_API}/get/${id}`);
    return response.data;
  },
  getList: async () => {
    const response = await APICLIENT.AUTH.get(`${PROJECT_API}/getList`);
    return response.data;
  },

  getProjectList: async (userGuid: string) => {
    const response = await APICLIENT.AUTH.get(`${INFRA_WEB_USER}/users/${userGuid}/projects`);
    return response.data;
  },

  create: async (data: any) => {
    const response = await APICLIENT.AUTH.post(`${PROJECT_API}/create`, {
      data: data,
    });
    return response.data;
  },
  getUiData: async () => {
    const response = await APICLIENT.AUTH.get(`${PROJECT_API}/getUiData`);
    return response.data;
  },
  getWorkstation: async (userGuid: string, filter: string) => {
    const response = await APICLIENT.AUTH.get(
      `${INFRA_WEB_USER}/${userGuid}/workstation?filter=${filter}`
    );
    return response.data;
  },
  getAssignedWorkstations: async (userGuid: string, filter: string, projectId: number) => {
    const response = await APICLIENT.AUTH.get(
      `${PROJECT_API}/${projectId}/workstations?filter=${filter}`
      // `${INFRA_WEB_USER}/${userGuid}/workstation?filter=${filter}&projectId=${projectId}`
    );
    return response.data;
  },

  getAssignedStorages: async (userGuid: string, filter: string, projectId: number) => {
    const response = await APICLIENT.AUTH.get(
      `${PROJECT_API}/${projectId}/storages?filter=${filter}`
      // `${INFRA_WEB_USER}/${userGuid}/storage?filter=${filter}&projectId=${projectId}`
    );
    return response.data;
  },

  getStorage: async (userGuid: string, filter: string, storageUsage: string) => {
    const response = await APICLIENT.AUTH.get(
      `${INFRA_WEB_USER}/${userGuid}/storage?filter=${filter}&storageUsage=${storageUsage}`
    );
    return response.data;
  },
  delete: async (id: number) => {
    const response = await APICLIENT.AUTH.get(`${PROJECT_API}/delete/${id}`);
    return response.data;
  },
  update: async (data: any) => {
    const response = await APICLIENT.AUTH.post(`${PROJECT_API}/update`, {
      data: data,
    });
    return response.data;
  },
  getUserList: async (projectId: number) => {
    const response = await APICLIENT.AUTH.get(`${PROJECT_API}/users/getList/${projectId}`);
    return response.data;
  },

  // OwnerId 가 같은 것만 필터링
  // create 는 내 자신, update 는 Project 의 owner 가 같은 것만 필터링
  getFilterByOwnerId: <T extends { user?: { userGuid?: string } }>(
    list: T[],
    ownerId?: string
  ): T[] => {
    return list.filter(item => {
      if (item.user && item.user.userGuid === ownerId) {
        return item;
      }
    });
  },
};
