import React from "react";
import styled from "styled-components";
import { useT } from "../../../../constants/i18n/useT";
import Button from "../../../../components/button/Button";
import ModalForm from "../../../../components/modal/modal-form";
import { Contact } from "../../../../@types/user/contact";
import { ModalProps } from "../../../../components/modal";

interface Props extends ModalProps {
  contact: Contact;
}

const UserContactDetail = ({ isOpen, setOpen, contact }: Props) => {
  const t = useT();
  return (
    <ModalForm isOpen={isOpen} setOpen={setOpen} size="xl">
      <Wrapper>
        <div className="titlebox">
          <p>{t("user.view_inquiries", "문의 조회")}</p>
        </div>

        <QABox>
          <QuestionBox>
            <div className="title">
              <h2>
                {t("user.title", "제목")} : {contact.title}
              </h2>
            </div>
            <hr />
            <div className="content">
              <div className="label">{t("user.content", "내용")} : </div>
              <div className="contact_content">
                <p>{contact.content}</p>
              </div>
            </div>
            <div className="description">
              {t("user.created_date", "날짜")}: {contact.createdDt}
            </div>
          </QuestionBox>
          {contact.answers && (
            <AnswerBox>
              {!!contact.answers.length && (
                <div className="content">
                  <div className="label">{t("user.answer", "답변")} : </div>
                  <div className="contact_content">
                    {contact.answers.map((answer) => (
                      <div key={answer.id} className="answer">
                        <p>{answer.content}</p>
                        <div className="description">
                          {contact.createdDt} : {answer.createdDt}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {!contact.answers.length && (
                <div className="no_ans">
                  {t("user.there_is_no_answer_yet", "아직 답변이 없습니다.")}
                </div>
              )}
            </AnswerBox>
          )}
        </QABox>

        <div className="close_button">
          <Button onClick={() => setOpen(false)} size="full">
            {t("common.close", "닫기")}
          </Button>
        </div>
      </Wrapper>
    </ModalForm>
  );
};
const Wrapper = styled.section`
  min-height: 300px;
  max-height: 80vh;
  width: 95%;
  margin: auto;
  .close_button {
    width: 60%;
    text-align: center;
    margin: 10px auto;
    margin-bottom: 30px;
    button {
      margin: auto;
    }
  }
  .titlebox {
    padding-top: 30px;
    width: 100%;
    margin: auto;
    text-align: center;
    border-bottom: 7px solid #385fd0;
    margin-bottom: 10px;
    p {
      color: #385fd0;
      font-size: 25px;
      font-weight: 600;
      padding-bottom: 15px;
    }
  }
`;
const QABox = styled.article`
  padding: 20px;

  .no_ans {
    padding: 20px;
    color: gray;
  }
`;
const QuestionBox = styled.article`
  padding-bottom: 10px;
  border-bottom: 2px solid #333;
  .title {
    padding-bottom: 20px;
  }
  .content {
    display: flex;
    padding: 20px 0;

    .label {
      flex: 1.5;
      text-align: left;
      font-weight: 600;
      font-size: 1.2rem;
    }
    .contact_content {
      flex: 8.5;
      font-size: 1.1rem;
    }
  }
  .description {
    padding: 3px;
    text-align: end;
    font-size: 0.9rem;
    color: #8d8d8d;
  }
`;
const AnswerBox = styled.article`
  .content {
    display: flex;
    padding: 20px 0;
    min-height: 50px;
    .label {
      flex: 1.5;
      text-align: left;
      font-weight: 600;
      font-size: 1.2rem;
    }
    .contact_content {
      flex: 8.5;
      font-size: 1.1rem;
      .answer {
        border-bottom: 0.1px solid #cbcbcb;
        margin-bottom: 15px;
        padding-bottom: 5px;
      }
      .description {
        padding: 3px;
        text-align: end;
        font-size: 0.9rem;
        color: #8d8d8d;
      }
    }
  }
`;

export default UserContactDetail;
