import React from "react";
import { Workstation } from "../../../../../@types/workstation/workstation";
import { useRecoilState } from "recoil";
import { userState } from "../../../../../constants/recoil/user";
import { useT } from "../../../../../constants/i18n/useT";
import { WorkstationClientService } from "../../../../../service/workstation/client.service";
import { ModalProps } from "../../../../../components/modal";
import ModalPopup from "../../../../../components/modal/popup";

interface Props extends ModalProps {
  workstation: Workstation;
}
const WSSetCompleted = ({ isOpen, setOpen, workstation }: Props) => {
  const [user] = useRecoilState(userState);

  const onSubmit = () => {
    const client = new WorkstationClientService(workstation, user);
    WorkstationClientService.run(client);
    setOpen(false);
  };

  const t = useT();
  return (
    <ModalPopup
      isOpen={isOpen}
      setOpen={setOpen}
      buttonText={t("common.run", "실행")}
      onSubmit={onSubmit}
    >
      <h5 className="pop_logout_title">
        {t(
          "workstation.the_following_workstation_is_now_ready",
          "다음 Workstation이 준비되었습니다."
        )}
        <br />
        <br />
        <span className="g_point_a">{workstation.name}</span>
        <br />
        {t("workstation.would_you_like_to_launch_it", "실행하시겠습니까?")}
      </h5>
    </ModalPopup>
  );
};

export default WSSetCompleted;
