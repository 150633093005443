import styled from "styled-components";
import React from "react";
import { ChevronDown } from "react-feather";
import { DropDownSetting } from "./setting";

interface Props {
  title?: string | any;
  arrow?: boolean;
  children: React.ReactNode;
}

export const Dropdown = ({ title, children, arrow }: Props) => {
  if (arrow === undefined) arrow = true;

  return (
    <DropDownSetting.Wrapper>
      {title && (
        <DropDownSetting.Title>
          <span>{title}</span>
          {arrow && <ChevronDown size={18} />}
        </DropDownSetting.Title>
      )}

      <DropDownSetting.Content>{children}</DropDownSetting.Content>
    </DropDownSetting.Wrapper>
  );
};
