import React from "react";
import styled from "styled-components";
import { Storage } from "../../../../../@types/storage/storage";
import StorageCard from "../card";

interface Props {
  storages: Storage[];
  getList: () => void;
  title: string;
  id: string;
}

const EachStoargeList = ({ storages, getList, title, id }: Props) => {
  const isOwned = () => id === "owned";
  return (
    <Wrapper id={id}>
      {storages.length > 0 && (
        <>
          <h4>{title}</h4>
          <StorageList className="user_box">
            {storages.map((storage, idx) => (
              <StorageCard
                getList={getList}
                storage={storage}
                key={idx}
                own={isOwned()}
              />
            ))}
          </StorageList>
        </>
      )}
    </Wrapper>
  );
};
const Wrapper = styled.article``;
const StorageList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media screen and (min-width: 1024px) {
    justify-content: flex-start;
  }
`;
export default EachStoargeList;
