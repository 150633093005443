import React, { useState } from "react";
import styled from "styled-components";
import { MAX_LENGTH } from "../../../../constants/max-length";
import { useAlert } from "../../../../components/alert/alert.service";
import Button from "../../../../components/button/Button";
import Star from "../../../../components/input/essential-star";
import ModalForm from "../../../../components/modal/modal-form";
import { useT } from "../../../../constants/i18n/useT";
import { ContactService } from "../../../../service/contact/contact.service";

interface Props {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getList?: () => void;
}

const UserContactModal = ({ isOpen, setOpen, getList }: Props) => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const { setAlert, setSuccess } = useAlert();
  const t = useT();

  const onSubmit = () => {
    if (title === "") {
      return setAlert(t("main.please_enter_a_title", "제목을 입력해주세요"));
    }
    if (content === "") {
      return setAlert(t("main.please_enter_a_content", "내용을 입력해주세요"));
    }

    let newContact = new ContactService({
      title: title,
      content: content,
    });

    newContact
      .submit()
      .then(res => {
        if (res.state === "success") {
          setSuccess(t("main.your_inquiry_has_been_submitted"));
        }
      })
      .finally(() => {
        getList && getList();
        setOpen(false);
      });
  };

  return (
    <ModalForm isOpen={isOpen} setOpen={setOpen}>
      <Wrapper>
        <button className="close_btn" onClick={() => setOpen(false)}>
          X
        </button>
        <PopTopText>
          <h1> {t("user.request", "문의하기")}</h1>
          <p>
            {t(
              "user.if_you_have_any_inquiries_about_service",
              "서비스 상담 및 기술 지원, 제휴에 대해 문의하시면 빠르게 답변 드리겠습니다."
            )}
          </p>
        </PopTopText>
        <Divider />
        <div className="pop_con_wrap">
          <form className="g_input_a">
            <div className="input_wrap">
              <label htmlFor="title" className="input_label">
                {t("user.title", "제목")} <Star />
              </label>
              <input
                maxLength={MAX_LENGTH.CONTACT_TITLE}
                value={title}
                onChange={e => setTitle(e.target.value)}
                type="text"
                id="title"
                placeholder={t("user.please_enter_the_title", "제목을 입력하세요") || ""}
              />
            </div>
            <div className="input_wrap">
              <label htmlFor="contents" className="input_label">
                {t("user.content", "내용")}
                <span className="small_gray">({content.length}/500)</span>
                <Star />
              </label>
              <textarea
                cols={30}
                rows={8}
                value={content}
                onChange={e => setContent(e.target.value)}
                maxLength={500}
                id="contents"
              ></textarea>
            </div>
          </form>
          <ButtonBox>
            <Button onClick={() => onSubmit()} size="full" color="primary">
              {t("user.request", "문의하기")}
            </Button>
          </ButtonBox>
        </div>
      </Wrapper>
    </ModalForm>
  );
};

const Wrapper = styled.div`
  max-height: 97vh;
  overflow-y: auto;
  .pop_con_wrap {
    padding: 30px;

    .input_wrap {
      margin-top: 0px;
    }
  }
  .small_gray {
    font-size: 12px;
    color: #c2c2c2;
  }
  input,
  textarea {
    &:focus {
      outline: none;
      border-color: #007bff;
      box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    }
  }
  textarea {
    height: 160px;
    width: 470px !important;
    border-radius: 0 !important;
    resize: none;
    padding: 10px;
  }
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`;

const PopTopText = styled.div`
  padding: 20px 0;
  text-align: center;
  h1 {
    padding: 15px;
  }
  p {
    padding: 0 20px;
  }
`;

const Divider = styled.div`
  width: 90%;
  height: 1.5px;
  background-color: #333;
  margin: auto;
`;

const ButtonBox = styled.div`
  width: 100%;
  margin: auto;
  text-align: center;
  margin-top: 40px;
`;

export default UserContactModal;
