import { useRecoilState } from "recoil";

import { Storage } from "../../../../../../@types/storage/storage";
import { StorageService } from "../../../../../../service/storage/storage.service";
import { userState } from "../../../../../../constants/recoil/user";
import { useAlert } from "../../../../../../components/alert/alert.service";
import { Code } from "../../../../../../@types/infra/code";
import { ConvertService } from "../../../../../../service/common/convert.service";
import { useT } from "../../../../../../constants/i18n/useT";
import { StorageUserUiData } from "../../../../../../@types/storage/ui/storage.user";
import { StorageUpdateService } from "../../../../../../service/storage/update.service";
import { ProjectUserMode } from "../../../../../../@types/project/ui/user-search-mode";
import {
  StorageUpdateUser,
  StorageUpdateUserForm,
} from "../../../../../../@types/storage/form/user";
import { StorageMemberResponse } from "../../../../../../@types/storage/response";

interface Props {
  storage: Storage;
  setRightUsers: React.Dispatch<React.SetStateAction<StorageUserUiData[]>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setLeftUsers: React.Dispatch<React.SetStateAction<StorageUserUiData[]>>;
  leftUsers: StorageUserUiData[];
  rightUsers: StorageUserUiData[];
  getList: () => void;
  searchUsers: StorageUserUiData[];
  setSearchUsers: React.Dispatch<React.SetStateAction<StorageUserUiData[]>>;
  setTypes: React.Dispatch<React.SetStateAction<Code[]>>;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

export const _storageUpdateUser = ({
  setRightUsers,
  setIsLoading,
  setLeftUsers,
  leftUsers,
  rightUsers,
  getList,
  searchUsers,
  setSearchUsers,
  storage,
  setTypes,
  setDisabled,
}: Props) => {
  const [myUser] = useRecoilState(userState);

  const t = useT();
  const { setAlert, setSuccess } = useAlert();

  const getStorage = () => {
    if (storage.storageId) {
      let projectId: number;

      StorageService.getStorageById(storage.storageId).then(async res => {
        projectId = res.data.projectId;
      });

      StorageService.getById(storage.storageId)
        .then(async res => {
          if (res.state === "success") {
            if (res.data) {
              const NewleftUsers = hadleLeftUserToUiData(res.data.users);
              setLeftUsers(NewleftUsers);

              setRightUsers(
                await StorageUpdateService.getMembers({
                  projectId: projectId,
                  companyGuid: myUser.company && myUser.company.companyGuid,
                  ownerUserGuid: myUser.userGuid,
                  leftUsers: NewleftUsers,
                })
              );
            }
          }
        })
        .finally(() => setIsLoading(false));
    }
  };
  const getUiStorage = () => {
    StorageService.getUi().then(res => {
      if (res.state === "success") {
        setTypes(ConvertService.getCodes(res.data.codes, "Storage.Privilege"));
        getStorage();
      }
    });
  };

  const hadleLeftUserToUiData = (users: StorageMemberResponse[]): StorageUserUiData[] => {
    const newUsers = users.map(usr => {
      let newUser = new StorageUserUiData(usr.user.userGuid, usr.user.fullName);
      newUser.storageUserId = usr.storageUserId;
      newUser.privilege = usr.privilege;
      return newUser;
    });
    return newUsers;
  };
  const isDuplicateInTarget = (
    user: { userGuid?: string },
    targetUsers: { userGuid?: string }[]
  ) => {
    const index = targetUsers.findIndex(u => u.userGuid === user.userGuid);
    if (index === -1) return false;
    return true;
  };
  const toLeft = (mode: ProjectUserMode): void => {
    if (mode === ProjectUserMode.COMPANY) {
      let newLeftUsers = [...leftUsers];
      let newRightUsers = [...rightUsers];
      rightUsers.forEach(user => {
        if (!user.isChecked) return;
        if (user.deleted) {
          const index = newLeftUsers.findIndex(u => u.storageUserId === user.storageUserId);
          newLeftUsers[index] = {
            ...user,
            isChecked: false,
            deleted: false,
          };
        } else {
          if (!isDuplicateInTarget(user, newLeftUsers)) {
            newLeftUsers.push({ ...user, isChecked: false });
          } else {
            setAlert(t("project.", "이미 추가된 Workstation입니다."));
          }
        }
        newRightUsers = newRightUsers.filter(u => u.userGuid !== user.userGuid);
      });
      setLeftUsers(newLeftUsers);
      setRightUsers(newRightUsers);
    } else {
      let newLeftUsers = [...leftUsers];
      searchUsers.forEach(user => {
        if (!user.isChecked) return;
        if (isDuplicateInTarget(user, newLeftUsers)) return;
        newLeftUsers.push({ ...user, isChecked: false });
      });
      setLeftUsers(newLeftUsers);
      setSearchUsers([]);
    }
  };

  const toRight = () => {
    let newLeftUsers = [...leftUsers];
    let newRightUsers = [...rightUsers];

    leftUsers.forEach(user => {
      if (!user.isChecked) return;
      if (user.storageUserId) {
        newRightUsers.push({ ...user, isChecked: false, deleted: true });
        const index = newLeftUsers.findIndex(u => u.storageUserId === user.storageUserId);
        newLeftUsers[index] = {
          ...user,
          isChecked: false,
          deleted: true,
        };
      } else {
        newLeftUsers = newLeftUsers.filter(u => u.userGuid !== user.userGuid);
        if (!isDuplicateInTarget(user, newRightUsers)) {
          newRightUsers.push({ ...user, isChecked: false });
        }
      }
      setLeftUsers(newLeftUsers);
      setRightUsers(newRightUsers);
    });

    setLeftUsers(newLeftUsers);
  };

  const checkSameUserPrivilInStorage = (
    user: StorageUserUiData,
    users: StorageMemberResponse[] = []
  ) => {
    let result = false;
    users.map(storageUser => {
      if (storageUser.storageUserId === user.storageUserId) {
        if (user.privilege && storageUser.privilege) {
          if (storageUser.privilege.cd === user.privilege.cd) {
            result = true;
          }
        }
      }
    });
    return result;
  };

  const onSubmit = () => {
    setDisabled(true);

    let newUsers: StorageUpdateUser[] = [];
    leftUsers.map(user => {
      const newUser = new StorageUpdateUser(user.userGuid, user.privilege && user.privilege.cd);
      if (user.storageUserId) {
        newUser.storageUserId = user.storageUserId;
        if (user.deleted) {
          newUser.useState = { cd: "UseState.Deleted" };
          newUsers.push(newUser);
          return;
        }
        if (!checkSameUserPrivilInStorage(user, storage.users)) {
          newUsers.push(newUser);
          return;
        }
        return;
      }
      newUsers.push(newUser);
    });
    if (newUsers.length === 0) {
      setSuccess(
        t("storage.storage_user_has_been_modified", "Storage 사용자가 성공적으로 수정되었습니다.")
      );

      return;
    }
    let newForm = new StorageUpdateUserForm(storage.storageId);
    newForm.users = newUsers;

    StorageService.updateUser(newForm)
      .then(res => {
        if (res.state === "success") {
          setSuccess(
            t(
              "storage.storage_user_has_been_modified",
              "Storage 사용자가 성공적으로 수정되었습니다."
            )
          );
        }

        getStorage();
      })
      .finally(() => {
        setDisabled(false);
        getList();
      });
  };
  return {
    getStorage,
    toRight,
    toLeft,
    onSubmit,
    getUiStorage,
  };
};
