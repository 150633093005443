import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import { Price } from "../../@types/billing/price";
const { persistAtom } = recoilPersist();

export const cpuPriceState = atom<Price>({
  key: "cpuPrice",
  default: new Price(),
  effects_UNSTABLE: [persistAtom],
});

export const gpuPriceState = atom<Price>({
  key: "gpuPrice",
  default: new Price(),
  effects_UNSTABLE: [persistAtom],
});

export const storagePriceState = atom<Price>({
  key: "storagePrice",
  default: new Price(),
  effects_UNSTABLE: [persistAtom],
});
