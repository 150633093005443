import React from "react";

import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useT } from "../../../../constants/i18n/useT";
import Button from "../../../../components/button/Button";
import { ModalProps } from "../../../../components/modal";
import ModalPopup from "../../../../components/modal/popup";

interface Props extends ModalProps {
  name: string;
}

const SignupAlert = (props: Props) => {
  const navigate = useNavigate();
  const goHome = () => {
    navigate("/login");
    props.setOpen(false);
  };
  const t = useT();

  return (
    <ModalPopup onSubmit={goHome} {...props}>
      <Wrapper>
        <div className="sub_title">
          <span id="blue">{props.name}</span>
          {t("signup.you_have_been_signed_up", "님 회원가입이 완료되었습니다.")}
        </div>
        <Button size="lg" onClick={goHome} color="primary">
          {t("signup.go_login_page", "로그인 페이지로 이동")}
        </Button>
      </Wrapper>
    </ModalPopup>
  );
};

const Wrapper = styled.div`
  text-align: center;
  .sub_title {
    margin: 30px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }
`;
export default SignupAlert;
