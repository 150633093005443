import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Price } from "../../../@types/billing/price";
import { useT } from "../../../constants/i18n/useT";
import { CreditService } from "../../../service/billing/credit.service";
import ContactAndCalculateButtonBox from "../../main/components/button/contact-calculate-button";
import { PriceService } from "../../../service/billing/price.service";
import { PriceList } from "../../../@types/billing/price-list";

const BillingPolicy = () => {
  const [priceData, setPriceData] = useState(new PriceList());
  const [cpuList, setCpuList] = useState<Price[]>([]);
  const [gpuList, setGpuList] = useState<Price[]>([]);
  const [storageList, setStorageList] = useState<Price[]>([]);
  const t = useT();

  const [periods, setPeriods] = useState([10, 30, 60]);
  const [ways, setWays] = useState(["Email, Workstation"]);

  const getPriceList = () => {
    PriceService.getList().then((res) => {
      if (res.state === "success") {
        setPriceData(res.data);
      }
    });
  };

  const setPriceList = (priceData: PriceList) => {
    setCpuList(priceData.cpuPriceList || []);
    setGpuList(priceData.gpuPriceList || []);
    setStorageList(priceData.storagePriceList || []);
  };

  useEffect(() => {
    CreditService.getAlarmWaysPeriods().then((res) => {
      if (res.state === "success") {
        setPeriods(res.data.periods);
        setWays(res.data.ways);
      }
    });
  }, []);

  useEffect(() => getPriceList(), []);
  useEffect(() => setPriceList(priceData), [priceData]);

  return (
    <div className="sub_page">
      <div className="content">
        <div className="g_sub_top">
          <div className="g_sub_top_text">
            <h3>{t("billing.m2m_pricing", "요금 안내")}</h3>
            <div className="site_step">
              <div>
                <Link to="/">
                  <img alt="img" src="/assets/sub/home_icon.png" />
                  Home
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="content_wrap">
          <div className="price_info">
            <div className="g_title_b w02">
              <h5>{t("billing.workstation_service", "Workstation Service")}</h5>
              <div className="line_bar"></div>
            </div>
            <div className="price_table_wrap">
              <h3 className="g_title_c">
                {t("billing.high_performance_cpu_servers", "고성능 CPU 서버")}
              </h3>
              <table className="g_table_a">
                <caption>
                  {t("billing.high_performance_cpu_servers", "고성능 CPU 서버")}
                </caption>
                <colgroup>
                  <col width="14%" />
                  <col width="14%" />
                  <col width="9%" />
                  <col width="9%" />
                  <col width="18%" />
                  <col width="18%" />
                  <col width="18%" />
                </colgroup>
                <thead>
                  <tr>
                    <th colSpan={4}>{t("billing.spec", "사양")}</th>
                    <th colSpan={3}>
                      {t(
                        "billing.pricing_per_krw",
                        "요금 (원/분, 부가세 별도)"
                      )}
                    </th>
                  </tr>
                </thead>
              </table>
              <table className="g_table_a">
                <caption>
                  {t("billing.high_performance_cpu_servers", "고성능 CPU 서버")}
                </caption>
                <colgroup>
                  <col width="14%" />
                  <col width="14%" />
                  <col width="9%" />
                  <col width="9%" />
                  <col width="18%" />
                  <col width="18%" />
                  <col width="18%" />
                </colgroup>
                <thead></thead>
                <tbody>
                  <tr className="td_title">
                    <td>CPU</td>
                    <td>RAM</td>
                    <td colSpan={2}>{t("billing.disk", "디스크")}</td>
                    <td>{t("billing.time_min", "시간(/min)")}</td>
                    <td>1 {t("billing.year_contract", "년 계약")}</td>
                    <td>2 {t("billing.year_contract", "년 계약")}</td>
                  </tr>
                  {cpuList.map((code: Price, idx) => (
                    <tr key={idx}>
                      {code.spec && (
                        <>
                          <td>{code.spec.cpu && code.spec.cpu.name}</td>
                          <td>{code.spec.ram && code.spec.ram.name}</td>
                        </>
                      )}
                      <td colSpan={2}>
                        {t("billing.user_selection", "사용자 선택")}
                      </td>
                      <td>
                        {code.unitPrice}
                        {t("billing.krw", "원")}
                      </td>
                      <td>
                        {code.reserved1YearPrice}
                        {t("billing.krw", "원")}
                      </td>
                      <td>
                        {code.reserved2YearPrice}
                        {t("billing.krw", "원")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <h3 className="g_title_c">
                {t("billing.high_performance_gpu_servers", "고성능 GPU 서버")}
              </h3>
              <table className="g_table_a">
                <caption>
                  {t("billing.high_performance_gpu_servers", "고성능 GPU 서버")}
                </caption>
                <colgroup>
                  <col width="14%" />
                  <col width="14%" />
                  <col width="9%" />
                  <col width="9%" />
                  <col width="18%" />
                  <col width="18%" />
                  <col width="18%" />
                </colgroup>
                <thead>
                  <tr>
                    <th colSpan={4}>{t("billing.spec", "사양")}</th>
                    <th colSpan={3}>
                      {t(
                        "billing.pricing_per_krw",
                        "요금 (원/분, 부가세 별도)"
                      )}
                    </th>
                  </tr>
                </thead>
              </table>
              <table className="g_table_a">
                <caption>
                  {t("billing.high_performance_gpu_servers", "고성능 GPU 서버")}
                </caption>
                <colgroup>
                  <col width="14%" />
                  <col width="14%" />
                  <col width="9%" />
                  <col width="9%" />
                  <col width="18%" />
                  <col width="18%" />
                  <col width="18%" />
                </colgroup>
                <thead></thead>
                <tbody>
                  <tr className="td_title">
                    <td>CPU</td>
                    <td>RAM</td>
                    <td>GPU</td>
                    <td>{t("billing.storage", "스토리지")}</td>
                    <td>{t("billing.time_min", "시간(/min)")}</td>
                    <td>1 {t("billing.year_contract", "년 계약")}</td>
                    <td>2 {t("billing.year_contract", "년 계약")}</td>
                  </tr>
                  {gpuList.map((code: Price, idx) => (
                    <tr key={idx}>
                      {code.spec && (
                        <>
                          <td>{code.spec.cpu && code.spec.cpu.name}</td>
                          <td>{code.spec.ram && code.spec.ram.name}</td>
                          <td>{code.spec.gpu && code.spec.gpu.name}</td>
                        </>
                      )}
                      <td>{t("billing.user_selection", "사용자 선택")}</td>
                      <td>
                        {code.unitPrice}
                        {t("billing.krw", "원")}
                      </td>
                      <td>
                        {code.reserved1YearPrice} {t("billing.krw", "원")}
                      </td>
                      <td>
                        {code.reserved2YearPrice} {t("billing.krw", "원")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <ul className="g_list_a">
              <li>
                ※{" "}
                {t(
                  "billing.we_provide_windows10_pro_as",
                  "기본 OS로 Windows 10 Pro를 제공합니다."
                )}
              </li>
              <li>
                ※{" "}
                {t(
                  "billing.we_provide_pcoip_server_and_client_connections",
                  "고화질 서버 접속을 위해 PCoIP server 및 client 접속을 제공합니다."
                )}
              </li>
            </ul>
          </div>
          <div className="sign_info_two">
            <div className="g_title_b">
              <h5>{t("billing.storage_service", "Storage Service")}</h5>
              <div className="line_bar"></div>
            </div>
            <div className="price_table_wrap">
              <h3 className="g_title_c">
                {t("billing.storage", "스토리지")}{" "}
                {t("billing.pricing", "요금")}
              </h3>
              <table className="g_table_a">
                <caption>
                  {t("billing.storage", "스토리지")}{" "}
                  {t("billing.pricing", "요금")}
                </caption>
                <colgroup>
                  <col width="14%" />
                  <col width="14%" />
                  <col width="9%" />
                  <col width="9%" />
                  <col width="18%" />
                  <col width="18%" />
                  <col width="18%" />
                </colgroup>
                <thead>
                  <tr>
                    <th colSpan={2}>{t("billing.type", "타입")}</th>
                    <th colSpan={2}>{t("billing.billing_rate", "과금기준")}</th>
                    <th colSpan={3}>
                      {t(
                        "billing.pricing_per_krw",
                        "요금 (원/분, 부가세 별도)"
                      )}
                    </th>
                  </tr>
                </thead>
              </table>
              <table className="g_table_a">
                <caption>
                  {t("billing.high_performance_cpu_servers", "고성능 CPU 서버")}{" "}
                  {t("billing.pricing", "요금")}
                </caption>
                <colgroup>
                  <col width="14%" />
                  <col width="14%" />
                  <col width="9%" />
                  <col width="9%" />
                  <col width="18%" />
                  <col width="18%" />
                  <col width="18%" />
                </colgroup>
                <thead></thead>
                <tbody>
                  {storageList.map((code, idx) => (
                    <React.Fragment key={idx}>
                      <tr className="td_title">
                        <td className="g_point_c" colSpan={2} rowSpan={2}>
                          Premium(SSD)
                        </td>
                        <td className="g_point_c" colSpan={2} rowSpan={2}>
                          {code.size}
                        </td>
                        <td>{t("billing.time_min", "시간(/min)")}</td>
                        <td>1 {t("billing.year_contract", "년 계약")}</td>
                        <td>2 {t("billing.year_contract", "년 계약")}</td>
                      </tr>
                      <tr>
                        <td>
                          {code.unitPrice}
                          {t("billing.krw", "원")}
                        </td>
                        <td>
                          {code.reserved1YearPrice}
                          {t("billing.krw", "원")}
                        </td>
                        <td>
                          {code.reserved2YearPrice}
                          {t("billing.krw", "원")}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
            <ul className="g_list_a">
              <li>
                ※{" "}
                {t(
                  "billing.the_volume_for_the_root_disk",
                  "Workstation의 루트 디스크로 이용되는 볼륨은 Workstation 사용에 관계없이 부과됩니다"
                )}
              </li>
              <li>
                ※{" "}
                {t(
                  "billing.the_volume_for_the_shared_storage",
                  "공유 스토리지로 이용되는 볼륨은 workstation 사용에 관계 없이 부과됩니다."
                )}
              </li>
              <li>
                ※ {t("billing.for_the_seamless_service")} (
                {periods.map((period) => period + t("billing.min")).join(", ")})
              </li>
              <li>
                ※ {t("billing.the_need_of_prepaid_recharge")} ({ways.join(", ")}
                )
              </li>
            </ul>
          </div>
          <ContactAndCalculateButtonBox type="row" />
        </div>
      </div>
    </div>
  );
};

export default BillingPolicy;
