import React, { useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { Workstation } from "../../../../../../@types/workstation/workstation";
import { userState } from "../../../../../../constants/recoil/user";
import { WorkstationClientService } from "../../../../../../service/workstation/client.service";
import { Tooltip } from "@mui/material";
import { DEV_LOCATION, STAGE_LOCATION } from "../../../../../../constants/m2m-client";

interface Props {
  workstation: Workstation;
  getList: () => void;
}

export const RunClientButton = ({ workstation, getList }: Props) => {
  const [user] = useRecoilState(userState);
  const [isDisabled, setIsDisabled] = useState(false);

  const workstationRun = () => {
    const client = new WorkstationClientService(workstation, user);
    // WorkstationClientService.run(client);
    console.log(client);
  };

  function isProtocolHandlerInstalled(
    protocol: string,
    callback: (isInstalled: boolean) => void
  ): void {
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = protocol;

    let detected = false;
    let timeout: number;

    function onBlur(): void {
      detected = true;
    }

    function onFocus(): void {
      clearTimeout(timeout);
      window.removeEventListener("blur", onBlur);
      window.removeEventListener("focus", onFocus);
      callback(detected);
    }

    window.addEventListener("blur", onBlur);
    window.addEventListener("focus", onFocus);

    document.body.appendChild(iframe);

    timeout = window.setTimeout(() => {
      document.body.removeChild(iframe);
      window.removeEventListener("blur", onBlur);
      window.removeEventListener("focus", onFocus);
      callback(detected);
    }, 1000);
  }

  const handleButtonClick = () => {
    if (isDisabled) return;

    setIsDisabled(true);

    const client = new WorkstationClientService(workstation, user);

    const HOST_NAME = window.location.hostname;
    const PORT_NAME = window.location.port;

    const TOKEN = sessionStorage.getItem("x-access-token");

    const protocol = `m2m-run://${client.domain}/connect?version=${client.version}&name=${client.workstationName}&data=${client.encodeData}&token=${TOKEN}`;

    isProtocolHandlerInstalled(protocol, isInstalled => {
      if (isInstalled) {
        workstationRun();
      } else {
        alert("프로그램이 설치되어 있지 않습니다. 다운로드 페이지로 이동합니다.");

        const redirectToDownload = () => {
          if (HOST_NAME === STAGE_LOCATION.DOMAIN) {
            window.location.href = "http://m2m.grida.dev/download";
          } else if (HOST_NAME === DEV_LOCATION.IP) {
            window.location.href = `http://${DEV_LOCATION.IP}:${PORT_NAME}/download`;
          } else if (HOST_NAME === DEV_LOCATION.LOCAL_DOMAIN) {
            window.location.href = `http://${DEV_LOCATION.LOCAL_DOMAIN}:${PORT_NAME}/download`;
          } else {
            window.location.href = "http://m2m.grida.dev/download";
          }
        };

        setTimeout(() => {
          redirectToDownload();
          setIsDisabled(false);
        }, 100);
      }
    });
  };

  return (
    <Tooltip title="Connect">
      <Wrapper onClick={handleButtonClick} disabled={isDisabled}>
        Connect
      </Wrapper>
    </Tooltip>
  );
};

const Wrapper = styled.button`
  background-color: var(--Running);
  border: none;
  background: rgb(243, 243, 243);
  color: var(--Running);
  padding: 0px 10px;
  margin-right: 3px;
  border-radius: 20px;
  width: 70px;
  height: 23px;
  font-size: 11px;
  font-weight: 600;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};

  &:hover {
    background: rgb(255, 254, 232);
  }

  @media (min-width: 768px) {
    width: 70px;
    height: 27px;
    font-size: 13px;
  }
`;
