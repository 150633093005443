import React, { useState } from "react";
import styled from "styled-components";
import { User } from "../../../../../@types/user/user";
import { useAlert } from "../../../../../components/alert/alert.service";
import { MAX_LENGTH } from "../../../../../constants/max-length";
import UpdateSession from "./update-session";
import { useT } from "../../../../../constants/i18n/useT";
import InputLabel from "../../../../../components/input/input-label/input";
import TextAreaLabel from "../../../../../components/input/input-label/textarea";
import { InputService } from "../../../../../service/common/input.service";
import ModalPopup from "../../../../../components/modal/popup";
import { ModalProps } from "../../../../../components/modal";
import { UpdateUser } from "../../../../../service/user/user.update.service";

interface Props extends ModalProps {
  user: User;
  setPopWithdraw: React.Dispatch<React.SetStateAction<boolean>>;
  getUser: () => void;
}
const UserUpdate = (props: Props) => {
  const { user, setPopWithdraw, getUser } = props;

  const [name, setName] = useState(user.fullName || "");
  const [mobile, setMobile] = useState(user.mobile || "");
  const [description, setDescription] = useState(user.description || "");
  const [sessionPolicy, setSessionPolicy] = useState(
    user.sessionPolicy || { cd: "Session.Policy.NoLimit" }
  );

  const [isResponse, setIsResponse] = useState(false);

  const { setAlert, setSuccess } = useAlert();

  const onSubmit = () => {
    setIsResponse(true);
    if (name === "") {
      return setAlert(t("user.please_enter_your_name", "이름을 입력하세요"));
    }
    if (mobile.length < 8) {
      return setAlert(
        t(
          "user.please_check_the_format_of_your_contact_information",
          "연락처의 형식을 확인해주세요."
        )
      );
    }
    const updateUser = new UpdateUser();
    updateUser.fullName = name;
    updateUser.sessionPolicy = { cd: sessionPolicy.cd };
    updateUser.mobile = mobile;
    updateUser.description = description;

    updateUser
      .update()
      .then(res => {
        if (res.state === "success") {
          setSuccess(t("user.your_membership_information_has_updated"));
        }
      })
      .finally(() => {
        setIsResponse(false);
        props.setOpen(false);
        getUser();
      });
  };
  const goWithDraw = () => {
    props.setOpen(false);
    setPopWithdraw(true);
  };
  const t = useT();
  return (
    <ModalPopup
      size="lg"
      buttonText={t("common.update", "수정")}
      onSubmit={onSubmit}
      isResponse={isResponse}
      {...props}
    >
      <Wrapper>
        <InputLabel
          value={name}
          onChange={e => setName(e.target.value)}
          label={t("user.name", "이름")}
          placeholder={t("user.please_enter_your_name", "이름을 입력하세요") || ""}
          maxLength={MAX_LENGTH.NAME}
        />
        <InputLabel
          value={mobile}
          onChange={e => setMobile(InputService.handleMobileNumber(e))}
          label={t("user.phone_number", "전화번호")}
          placeholder={
            t("signup.please_enter_your_phone_number", "휴대전화번호를 '-' 없이 입력해주세요.") ||
            ""
          }
          maxLength={MAX_LENGTH.MOBILE}
        />

        <TextAreaLabel
          value={description}
          onChange={e => setDescription(e.target.value)}
          label={t("common.description", "비고")}
          placeholder={t("common.please_enter_the_description", "비고를 입력하십시오")}
          maxLength={MAX_LENGTH.DESCRIPTION}
        />
        <div className="session_withdraw_box">
          <UpdateSession sessionPolicy={sessionPolicy} setSessionPolicy={setSessionPolicy} />
          <div onClick={() => goWithDraw()} className="withdraw">
            {t("user.withdraw", "회원 탈퇴")}
          </div>
        </div>
      </Wrapper>
    </ModalPopup>
  );
};
const Wrapper = styled.div`
  width: 90%;
  margin: auto;
  min-height: 400px;
  .session_withdraw_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .withdraw {
      margin-top: 20px;
      font-size: 12px;
      color: #979797;

      cursor: pointer;
      width: 100%;
      text-align: right;
    }
  }
  @media only screen and (min-width: 768px) {
    .session_withdraw_box {
      .withdraw {
        width: auto;
      }
    }
  }
`;
export default UserUpdate;
