import React, { useState } from "react";
import styled from "styled-components";
import SelectUserList from "./selected-users";
import { Storage } from "../../../../../@types/storage/storage";
import { StorageUser } from "../../../../../@types/storage/response";
import ModalPopup from "../../../../../components/modal/popup";

interface Props {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  storage: Storage;
}
const ShowPrivilege = ({ isOpen, setOpen, storage }: Props) => {
  const [leftCart, setLeftCart] = useState<StorageUser[]>([]);
  const [LUsers, setLUsers] = useState<StorageUser[]>([]);
  const [originUsers] = useState<any[]>(storage.users || []);
  const mode = "COMPANY";

  return (
    <ModalPopup size="lg" isOpen={isOpen} setOpen={setOpen}>
      <Wrapper>
        <PrivBox>
          <SelectUserList
            mode={mode}
            originUsers={originUsers}
            LUsers={LUsers}
            setLUsers={setLUsers}
            leftCart={leftCart}
            setLeftCart={setLeftCart}
            readOnly={true}
          />
        </PrivBox>
      </Wrapper>
    </ModalPopup>
  );
};
const Wrapper = styled.section`
  width: 100%;

  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    margin-bottom: 30px;
  }
`;
const PrivBox = styled.article`
  height: 600px;
  display: flex;
  padding: 20px;
  justify-content: center;
`;

export default ShowPrivilege;
