import { LinearProgress, Tooltip } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { CreditBook } from "../../../../@types/billing/credit";
import { useT } from "../../../../constants/i18n/useT";
import { CreditService } from "../../../../service/billing/credit.service";
import { ConvertService } from "../../../../service/common/convert.service";

interface Props {
  isLoading: boolean;
  billings: CreditBook[];
  headers: { title: string; width: number }[];
  startIndex?: number;
  endIndex?: number;
}

const TableBody = ({ isLoading, billings, headers, startIndex, endIndex }: Props) => {
  const t = useT();

  let latestTime = "";
  const getDate = (billing: CreditBook) => {
    if (latestTime === billing.startDt!.date) {
      return "";
    } else {
      latestTime = billing.startDt!.date;
      return latestTime;
    }
  };
  const getTime = (time: { date: string; time: string }) => {
    if (latestTime === time.date) return ` ~ ${time.time}`;
    else {
      return `~ ${time.date} ${time.time}`;
    }
  };

  const getName = (billing: CreditBook) => {
    if (billing.creditBookType === "Payment") {
      return "Recharge";
    }
    return billing.name;
  };

  const getSplitDate = (datetime: string) => datetime.split(" ")[0];
  const getSplitTime = (datetime: string) => datetime.split(" ")[1];

  return (
    <Wrapper className="history_td_wrap">
      {isLoading && <LinearProgress />}

      {billings.length > 0 && (
        <Table>
          <TableSize>
            {headers.map((content, index) => (
              <col key={index} width={`${content.width}%`} />
            ))}
          </TableSize>
          <tbody>
            {billings.map((billing, index) => {
              let time = CreditService.getConvertTime(billing.duration!);

              return (
                <ContentWrapper key={index}>
                  <td>{index + 1}</td>
                  <td className="credit_date">
                    {billing.chargingStartDt ? getSplitDate(billing.chargingStartDt) : ""}
                  </td>
                  <Tooltip title={billing.resourceId || ""} arrow>
                    <td id="left">{getName(billing)}</td>
                  </Tooltip>
                  <td>
                    {/* {billing.startDt && billing.startDt.time} */}
                    <td>
                      {billing.chargingStartDt ? getSplitTime(billing.chargingStartDt) : ""} ~
                      {billing.chargingEndDt ? getSplitTime(billing.chargingEndDt) : ""}
                    </td>
                  </td>
                  <td className="time">
                    {time.hour || time.minute || time.second ? (
                      <>
                        <div className="hour">
                          {time.hour}
                          {t("billing.h", "시간")}
                        </div>
                        <div>
                          {time.minute}
                          {t("billing.m", "분")}
                        </div>
                        <div>
                          {time.second}
                          {t("billing.s", "초")}
                        </div>
                      </>
                    ) : (
                      <>-</>
                    )}
                  </td>
                  <td>{billing.creditBookType || "-"}</td>
                  {billing.resourceSize ? <td id="left">{billing.resourceSize}</td> : <td>-</td>}
                  {billing.project && Object.keys(billing.project).length > 0 ? (
                    <td id="left">{billing.project.name}</td>
                  ) : (
                    <td>-</td>
                  )}
                  <td id="right">{billing.credit}</td>
                  {/* <td id="right">{billing.balance && ConvertService.setPrice(billing.balance)}</td> */}
                  <td id="right">{billing.balance}</td>
                </ContentWrapper>
              );
            })}
          </tbody>
        </Table>
      )}
      {!isLoading && billings.length === 0 && (
        <NoData>
          <h1>No data found</h1>
        </NoData>
      )}
    </Wrapper>
  );
};

const ContentWrapper = styled.tr`
  #left {
    text-align: start;
    padding-left: 10px;
  }
  #right {
    text-align: end;
    padding-right: 20px;
  }
  td {
    text-align: center;
  }
  .time {
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      width: 28px;
      height: 15px;
      &.hour {
        width: 32px;
      }
    }
  }
`;

const Wrapper = styled.article`
  &::-webkit-scrollbar {
    background-color: rgb(255, 255, 255);
    width: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(219, 219, 219, 0.514);
    border-radius: 10px;
    height: 10px;
  }

  overflow: hidden;
  overflow-y: auto;
  max-height: 55vh;
  margin-top: 10px;
  font-size: 11px;
  tr {
    border-bottom: 1px solid #ddd;
    &:hover {
      background: #f8f8f8;
    }
  }

  @media (max-width: 1480px) {
    max-height: 275px;
  }

  @media (min-width: 1481px) {
    max-height: 650px;
  }
`;

const Table = styled.table``;
const TableSize = styled.colgroup``;

const NoData = styled.div`
  width: 100%;
  text-align: center;
  h1 {
    font-size: 20px;
  }
`;

export default TableBody;
