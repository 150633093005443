import { ConvertService } from "../../../service/common/convert.service";
import { useT } from "../../../constants/i18n/useT";
import { useAlert } from "../../../components/alert/alert.service";
import { Code } from "../../../@types/infra/code";
import { Image } from "../../../@types/infra/image";
import { Spec } from "../../../@types/infra/spec";
import { Storage } from "../../../@types/storage/storage";
import { WorkstationService } from "../../../service/workstation/workstation.service";
import { StorageService } from "../../../service/storage/storage.service";
import { WorkstationCreateForm } from "../../../@types/workstation/form/create-form";
import { StorageType } from "../../../@types/storage/form/storage-type";
import { MappingWorkstation } from "../../../@types/workstation/form/storage";
import { RefindedStorage } from "../../../@types/storage/form/refined-storage";
import { Workstation } from "../../../@types/workstation/workstation";
import { Workstationlist } from "../../../@types/workstation/response";
import { ProjectService } from "../../../service/project/project.service";
import { useRecoilState } from "recoil";
import { userState } from "../../../constants/recoil/user";
import { useEffect, useState } from "react";
import { Description } from "@mui/icons-material";

interface Props {
  name: string;
  spec: Spec;
  os: string;
  sw: string;
  bootDisk: string;
  imageId: string;
  description: string;
  autoStart: boolean;
  refinedStorages: RefindedStorage[];
  workstations: Workstation[];
  setIsResponse: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCompleteResponse: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  setAutoStart: React.Dispatch<React.SetStateAction<boolean>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getList: () => void;
  setOss: React.Dispatch<React.SetStateAction<Code[]>>;
  setSWs: React.Dispatch<React.SetStateAction<Code[]>>;
  setBootDisk: React.Dispatch<React.SetStateAction<string>>;
  setSpecs: React.Dispatch<React.SetStateAction<Spec[]>>;
  setImages: React.Dispatch<React.SetStateAction<Image[]>>;
  setRefinedStorages: React.Dispatch<React.SetStateAction<RefindedStorage[]>>;
  validName: boolean;
  complete: boolean;
  createWS: boolean;
  setValidName: React.Dispatch<React.SetStateAction<boolean>>;
  setCreateWs: React.Dispatch<React.SetStateAction<boolean>>;
  setComplete: React.Dispatch<React.SetStateAction<boolean>>;
  setCreateWS: React.Dispatch<React.SetStateAction<boolean>>;

  everyWorkstations: Workstationlist;
}
export const handleCreateWS = ({
  name,
  spec,
  os,
  sw,
  bootDisk,
  imageId,
  refinedStorages,
  workstations,
  description,
  autoStart,
  complete,
  createWS,
  setIsResponse,
  setIsCompleteResponse,
  setOpen,
  setIsLoading,
  getList,
  setOss,
  setSWs,
  setBootDisk,
  setCreateWS,
  setSpecs,
  setImages,
  setDescription,
  setAutoStart,
  setRefinedStorages,
  validName,
  setValidName,
  setCreateWs,
  setComplete,
  everyWorkstations,
}: Props) => {
  const t = useT();
  const { setAlert, setSuccess } = useAlert();
  const [user] = useRecoilState(userState);

  const MOUNTABLE = {
    MOUNTABLE_MY_STORAGE: "MOUNTABLE_MY_STORAGE",
    MOUNTABLE_SHARED_BY_OTHERS: "MOUNTABLE_SHARED_BY_OTHERS",
  };

  const STORAGE_USAGE = {
    TRUE: "true",
    FALSE: "false",
  };

  const onSubmit = () => {
    console.log(name, spec, os, sw, bootDisk, description, autoStart);
    if (!validName)
      return setAlert(
        t("workstation.enter_a_4_15_character_name", "한글 영문 포함 4~15자 이내로 입력해주세요.")
      );
    if (spec.specId === "" || !spec.specId)
      return setAlert(t("workstation.please_select_the_specs", "스펙을 선택해주세요."));
    if (imageId === "" || !imageId)
      return setAlert(
        t("workstation.please_check_the_required_items", "필수 항목을 확인해주세요.")
      );

    setIsResponse(true);
    // setIsCompleteResponse(true);

    const newWorkstation = new WorkstationCreateForm(name, spec.specId, imageId, description);

    const myStorages: MappingWorkstation[] = [];

    refinedStorages.map(storage => {
      if (storage.using) {
        let storagetmp = new MappingWorkstation(storage.storageId || "", storage.letter);
        myStorages.push(storagetmp);
      }
    });

    if (myStorages.length > 0) newWorkstation.setStorages(myStorages);

    newWorkstation
      .create()
      .then(res => {
        setComplete(true);
        // setSuccess(
        //   newWorkstation.name +
        //     t("workstation.has_been_created", "이 생성되었습니다.")
        // );
      })
      .catch(error => {
        if (error.response && error.response.status === 400) {
          setAlert(t("workstation.base_image_is_required.", "Base Image가 필요합니다."));

          return;
        }

        if (error.response && error.response.status === 402) {
          setAlert(t("workstation.insufficient_credit", "크레딧이 부족합니다."));

          return;
        }

        if (error.response && error.response.status === 404) {
          setAlert(t("workstation.base_image_lookup_failed", "Base Image 조회에 실패했습니다."));

          return;
        }

        if (error.response && error.response.status === 409) {
          setAlert(
            t("workstation.same_name_workstation", "이미 같은 이름의 워크스테이션이 존재합니다.")
          );

          return;
        }

        if (error.response && error.response.status === 500) {
          setAlert(t("common.error", "현재 서버가 원활하지 않습니다. 잠시 후 시도해주세요."));

          return;
        }

        if (error.response && error.response.status === 503) {
          setAlert(
            t(
              "workstation.external_service_connection_and_resource_creation_failed",
              "외부서비스(AD, CAS, SMB) 연결 및 자원생성에 실패했습니다."
            )
          );

          return;
        }
      })
      .finally(() => {
        getList();
        setIsCompleteResponse(true);
        setIsResponse(false);
        setCreateWS(true);
      });
  };

  const codesToData = (codes: Code[]) => {
    setOss(ConvertService.getCodes(codes, "OS"));
    setSWs(ConvertService.getCodes(codes, "OS.InstalledSw"));
    setBootDisk("");
  };

  const filterStorage = (storages: {
    myStorages: Storage[];
    sharedAsDirectorStorages: Storage[];
    sharedAsMemberStorages: Storage[];
    sharedAsNoProjectStorages: Storage[];
  }) => {
    let storagelist: RefindedStorage[] = [];

    storages.myStorages.map(storage => {
      storagelist.push(new RefindedStorage(StorageType.OWNED, storage));
    });

    storages.sharedAsDirectorStorages.map(storage => {
      let newStorage = new RefindedStorage(StorageType.PRIVIL, storage);
      newStorage.setName(name);
      storagelist.push(newStorage);
    });
    storages.sharedAsMemberStorages.map(storage => {
      let newStorage = new RefindedStorage(StorageType.PRIVIL, storage);
      newStorage.setName(name);
      storagelist.push(newStorage);
    });

    storages.sharedAsNoProjectStorages.map(storage => {
      let newStorage = new RefindedStorage(StorageType.PRIVIL, storage);
      newStorage.setName(name);
      storagelist.push(newStorage);
    });
    return storagelist;
  };

  const getUiData = async () => {
    const storagelist: RefindedStorage[] = [];

    const myStorages = await StorageService.getStorage(
      user.userGuid!,
      MOUNTABLE.MOUNTABLE_MY_STORAGE,
      STORAGE_USAGE.FALSE
    );

    const sharedStorages = await StorageService.getStorage(
      user.userGuid!,
      MOUNTABLE.MOUNTABLE_SHARED_BY_OTHERS,
      STORAGE_USAGE.FALSE
    );

    myStorages.forEach((storage: Storage) => {
      storagelist.push(new RefindedStorage(StorageType.OWNED, storage));
    });

    sharedStorages.forEach((storage: Storage) => {
      storagelist.push(new RefindedStorage(StorageType.PRIVIL, storage));
    });

    setRefinedStorages(storagelist);

    // StorageService.getStorage(user.userGuid!, WORKSTATION_MOUNTABLE, STORAGE_USAGE.FALSE).then(
    //   res => {
    //     setRefinedStorages(res);
    //   }
    // );

    WorkstationService.getUi()
      .then(res => {
        console.log(res.data);
        if (res.state === "success") {
          setSpecs(res.data.specs);
          setImages(res.data.images);
          codesToData(res.data.codes);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const checkName = () => {
    if (name.match(/^[a-z|A-Z|가-힣|0-9-_]{4,15}$/)) setValidName(true);
    else setValidName(false);
  };

  return { onSubmit, getUiData, checkName };
};
