import { Checkbox } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Code } from "../../../../../../../../@types/infra/code";
import TypeSelectBox from "./select";
import { ProjectUserUiData } from "../../../../../../../../@types/project/ui/user";
import Star from "../../../../../../../../components/input/essential-star";

interface Props {
  id?: string;
  handleCheckbox: () => void;
  isChecked: boolean;
  title?: string;
  typeList?: Code[];
  handleType?: (type: string) => void;
  user?: ProjectUserUiData;
  isPublic?: boolean;
  handleContextMenu?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const NameWithCheckBox: React.FC<Props> = ({
  id,
  handleCheckbox,
  isChecked,
  title,
  typeList,
  handleType,
  user,
  isPublic,
  handleContextMenu,
}) => {
  const [type, setType] = useState((user && user.projectRole && user.projectRole.cd) || "");

  useEffect(() => {
    if (handleType) handleType(type);
  }, [type]);

  useEffect(() => {
    if (typeList && typeList[0]) {
      if (type === "") setType(typeList[0].cd || "");
    }
  }, [typeList]);

  return (
    <Wrapper onContextMenu={handleContextMenu} onClick={handleCheckbox} id={id}>
      <div className="name_title">
        <Checkbox checked={isChecked} />
        <p>{title || ""}</p>
        {isPublic && <Star />}
      </div>
      {typeList && <TypeSelectBox typeList={typeList} setType={setType} type={type} />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  padding: 3px 5px;
  height: 25px;
  width: 90%;
  font-size: 13px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    // background-color: #f8f8f8;
  }
  .name_title {
    display: flex;
    align-items: center;
  }
`;

export default NameWithCheckBox;
