import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Code } from "../../../../../@types/infra/code";
import { useT } from "../../../../../constants/i18n/useT";
import { AccountService } from "../../../../../service/user/account.service";

interface Props {
  sessionPolicy: Code;
  setSessionPolicy: React.Dispatch<React.SetStateAction<Code>>;
}

const UpdateSession = ({ sessionPolicy, setSessionPolicy }: Props) => {
  const [sessionPolicies, setSessionPolicies] = useState<Code[]>([]);
  const [value, setValue] = useState(sessionPolicy.cd);
  useEffect(() => {
    AccountService.getUi().then(res => {
      if (res.state === "success") {
        setSessionPolicies(res.data.sessionPolicies);
      }
    });
  }, []);
  const t = useT();
  useEffect(() => {
    setSessionPolicy(sessionPolicies.find(policy => policy.cd === value) || new Code());
  }, [value]);

  return (
    <Wrapper>
      <div className="label">{t("user.duplicate_login", "중복 로그인")}</div>
      <select onChange={e => setValue(e.target.value)} value={value}>
        {sessionPolicies.map((policy, idx) => (
          <option value={policy.cd} key={idx}>
            {policy.name}
          </option>
        ))}
      </select>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 25px;
  .label {
    display: block;
    font-size: 14px;
    font-weight: bold;
    color: #979797;
    margin-left: 5px;
  }
  select {
    width: 100px;
    padding: 5px 7px;
    margin-left: 20px;
  }
`;

export default UpdateSession;
