import React from "react";
import { useT } from "../../../../../constants/i18n/useT";
import styled from "styled-components";
import CheckBoxCircle from "../../../../../components/input/input-label/circle-checkbox";

interface Props {
  title: string;
  value: string;
  check: boolean;
  setCheck: React.Dispatch<React.SetStateAction<boolean>>;
  step: 1 | 2;
}

const PolicyTextArea = ({ title, value, check, setCheck, step }: Props) => {
  return (
    <Wrapper>
      <div className="title">
        <CheckBoxCircle
          check={check}
          onClick={() => setCheck(!check)}
          title={title}
          name={`terms_check_${step}`}
        />
      </div>
      <textarea cols={50} rows={13} readOnly value={value} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 20px;
  margin-top: 30px;
  background: #ffffff;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  .title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
  }
  textarea {
    width: 100%;
    resize: none;
    border: 0;
    font-size: 12px;
    padding: 5px;
    color: #888;
    background: #fff;
    &::-webkit-scrollbar {
      background-color: rgb(255, 255, 255);
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(219, 219, 219, 0.514);
      border-radius: 10px;
      height: 10px;
    }
  }
  @media (min-width: 1024px) {
    .title {
      font-size: 14px;
    }
    textarea {
      font-size: 15px;
      padding: 10px;
    }
  }
`;

export default PolicyTextArea;
