import React, { useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { GiTwoCoins } from "@react-icons/all-files/gi/GiTwoCoins";

import { cpuPriceState, gpuPriceState, storagePriceState } from "../../../constants/recoil/price";
import BillingPriceWorkstation from "./workstation-price";
import BillingPriceStorage from "./storage-price";
import Button from "../../../components/button/Button";
import ModalForm from "../../../components/modal/modal-form";
import { useT } from "../../../constants/i18n/useT";

interface Props {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CalculateModal = ({ isOpen, setOpen }: Props) => {
  const [cpuTotal, setCpuTotal] = useState(1);
  const [gpuTotal, setGpuTotal] = useState(0);
  const [storageTotal, setStorageTotal] = useState(0);
  const [cpuPrice] = useRecoilState(cpuPriceState);
  const [gpuPrice] = useRecoilState(gpuPriceState);
  const [storagePrice] = useRecoilState(storagePriceState);

  const t = useT();

  const totalPrice = () => {
    if (cpuPrice.monthBill && gpuPrice.monthBill && storagePrice.monthBill)
      return (
        cpuPrice.monthBill * cpuTotal +
        gpuPrice.monthBill * gpuTotal +
        (storagePrice.monthBill * (cpuTotal + gpuTotal)) / 2 +
        storagePrice.monthBill * (storageTotal / 100)
      ).toLocaleString("ko-KR");
  };

  const reset = () => {
    setCpuTotal(1);
    setGpuTotal(0);
    setStorageTotal(0);
  };

  const handle100Input = (
    value: string,
    setState: React.Dispatch<React.SetStateAction<number>>
  ) => {
    if (Number(value) % 100 === 0) setState(Number(value));
  };

  const handleNumberInput = (
    value: string,
    setState: React.Dispatch<React.SetStateAction<number>>
  ) => {
    if (value.length < 4) setState(Number(value));
  };

  return (
    <ModalForm isOpen={isOpen} setOpen={setOpen}>
      <Wrapper>
        <CloseButton onClick={() => setOpen(false)}>
          <button type="button">
            <img src="/assets/sub/close.png" />
          </button>
        </CloseButton>
        <Title>
          <GiTwoCoinsIcon />
          <h3>{t("billing.pricing_calculator", "요금 계산기")}</h3>
        </Title>
        <Content>
          <BillingPriceWorkstation
            cpuTotal={cpuTotal}
            setCpuTotal={setCpuTotal}
            gpuTotal={gpuTotal}
            setGpuTotal={setGpuTotal}
            handleNumberInput={handleNumberInput}
          />
          <BillingPriceStorage
            cpuTotal={cpuTotal}
            gpuTotal={gpuTotal}
            storageTotal={storageTotal}
            handle100Input={handle100Input}
            setStorageTotal={setStorageTotal}
          />
          <TotalPrice className="total_wrap">
            {t("billing.pricing", "요금")} : {t("billing.per_month", "월")}
            <span>{totalPrice()}</span> {t("billing.krw", "원")}(
            {t("billing.vat_not_included", "부가세 별도")})
          </TotalPrice>
          <ButtonBox>
            <Button onClick={() => reset()} size="lg">
              {t("billing.reset", "초기화")}
            </Button>
            {/* <Button size="lg" color="primary">
              {t("billing.save_estimation", "견적 저장")}
            </Button> */}
          </ButtonBox>
        </Content>
      </Wrapper>
    </ModalForm>
  );
};

const Wrapper = styled.section`
  max-height: 90vh;
  overflow-y: auto;
  padding: 10px 0;
  .input_wrap {
    label {
      width: 25%;
    }
    input {
      margin-bottom: 0;
      text-align: right;
      width: 20%;
      height: 40px;
    }
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
      margin-left: 10px;
      height: 20px;
    }
  }
`;
const Title = styled.section`
  text-align: center;
  border-radius: 5px 5px 0 0;
  padding: 10px 0;
  height: 60px;
  // background: url("/assets/sub/pop_top_img.png") center no-repeat;

  display: flex;
  align-items: center;
  justify-content: center;

  h3 {
    line-height: 60px;
    font-size: 40px;
    margin-left: 10px;
  }
`;
const GiTwoCoinsIcon = styled(GiTwoCoins)`
  font-size: 60px;
  color: #ffd700;
`;

const Content = styled.section`
  padding: 10px 20px;
`;

const ButtonBox = styled.article`
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
`;
const TotalPrice = styled.div`
  padding: 20px 0;
  background: #f1f1f1;
  text-align: center;
  margin-top: 10px;
  border-radius: 5px;

  span {
    font-size: 27px;
    color: var(--GridaBlue);
    font-weight: 500;
    padding: 0 5px;
  }
`;
const CloseButton = styled.div`
  position: absolute;
  top: 12px;
  right: 13px;
  z-index: 10;
  button {
    border: 0;
    background: transparent;
    width: 17px;
    img {
      width: 100%;
    }
  }
  filter: brightness(0.5);
  @media screen and (max-width: 768px) {
  }
`;
export default CalculateModal;
