import { css } from "styled-components";

export interface ModalProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// 기본값 sm
export const MODALSIZES = {
  sm: css`
    width: 80%;
    @media screen and (min-width: 768px) {
      width: 400px;
      min-height: 230px;
    }
  `,
  md: css`
    width: 80%;
    min-height: 220px;
    @media screen and (min-width: 768px) {
      width: 500px;
      min-height: 250px;
    }
  `,
  lg: css`
    width: 80%;
    min-height: 220px;
    @media screen and (min-width: 768px) {
      width: 600px;
      min-height: 250px;
    }
  `,
  xl: css`
    width: 1100px;
    min-height: 220px;
  `,
  xxl: css`
    width: 1500px;
    min-height: 220px;
  `,
};

export type ModalSize = keyof typeof MODALSIZES;

export type ModalType = "confirm" | "delete" | "disable";
