import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import MobileSidebar from "../sidebar/mobile-sidebar";

interface Props {
  isLogin: boolean;
}

const MobileHeader = ({ isLogin }: Props) => {
  const { pathname } = useLocation();
  const [active, setActive] = useState(pathname.split("/")[1]);

  const [popSideNav, setPopSideNav] = useState(false);

  useEffect(() => setActive(pathname.split("/")[1]), [pathname]);

  const isActive = (path: string) => {
    if (active === path) return "active";
    else return "inactive";
  };

  const handleSideNav = () => {
    setPopSideNav(!popSideNav);
  };

  return (
    <Wrapper>
      <div className="top">
        <div className="logo">
          <Link to="/">
            <img alt="img" src={"/assets/main/logo.png"} />
          </Link>
        </div>
        <div onClick={handleSideNav} className="hamburger">
          <img alt="hamburger" src={"/assets/sub/hamburger.png"} />
        </div>
      </div>
      {isLogin && (
        <LinkWrapper>
          <Link to="/workstation" className={isActive("workstation")}>
            Workstation
          </Link>
          <Link to="/storage" className={isActive("storage")}>
            Storage
          </Link>
          <Link to="/mypage/info" className={isActive("mypage")}>
            My Page
          </Link>
          <Link to="/project" className={isActive("project")}>
            Project
          </Link>
        </LinkWrapper>
      )}
      {popSideNav && (
        <MobileSidebar setOpen={setPopSideNav} isLogin={isLogin} />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.nav`
  width: 100%;
  min-height: 60px;
  background-color: #ffffffb9;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
  }
  border-color: #f1f1f1;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  align-items: center;
  .logo {
    display: block;
    margin-left: 25px;
    img {
      height: 40px;
      cursor: pointer;
    }
  }
  .hamburger {
    display: block;
    margin-right: 25px;
    img {
      height: 25px;
      cursor: pointer;
    }
  }
`;
const LinkWrapper = styled.div`
  height: 30px;
  min-width: 300px;
  width: 60%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  a {
    color: #000;
    font-size: 12px;
    font-weight: 700;
    text-decoration: none;
    padding: 5px 10px;
  }
  .active {
    border-bottom: 2px solid var(--GridaBlue);
  }
`;
export default MobileHeader;
