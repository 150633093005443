import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { DateService } from "../../../../service/common/date.service";
import { userState } from "../../../../constants/recoil/user";

interface Props {
  setSelectedDate: React.Dispatch<React.SetStateAction<string>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const SelectDate = ({ setIsLoading, setSelectedDate }: Props) => {
  const [isDateLoading, setIsDateLoading] = useState(true);
  const [chargingMonth, setChargingMonth] = useState("202211");
  const [chargingMonths, setChargingMonths] = useState(["202211"]);
  const [user] = useRecoilState(userState);

  useEffect(() => {
    const months = DateService.getPrev6Month();
    setChargingMonths(months);
    setChargingMonth(months[0]);
    setIsDateLoading(false);
  }, []);

  useEffect(() => {
    !isDateLoading && setSelectedDate(chargingMonth);
  }, [chargingMonth]);

  return (
    <Wrapper className="user_combo_wrap">
      <div className="combo_left">
        <div className="user_search_wrap">
          <select
            onChange={e => setChargingMonth(e.target.value)}
            value={chargingMonth}
            className="history_data"
          >
            {chargingMonths.map((date, idx) => (
              <option value={date} key={idx}>
                {date.slice(0, 4)}-{date.slice(4, 6)}
              </option>
            ))}
          </select>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.article``;

export default SelectDate;
