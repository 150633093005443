import styled from "styled-components";
import React, { useState } from "react";
import { useT } from "../../../../../../constants/i18n/useT";

interface Props {
  autoStart: boolean;
  setAutoStart: React.Dispatch<React.SetStateAction<boolean>>;
}

const WSAutoStart = ({ autoStart, setAutoStart }: Props) => {
  const t = useT();

  return (
    <CheckboxWrapper>
      <input
        type="checkbox"
        id="autoStart"
        checked={autoStart}
        onChange={e => setAutoStart(e.target.checked)}
      />
      <label htmlFor="autoStart">
        {t("workstation.create_and_start_automatically", "생성 후 자동 시작")}
      </label>
    </CheckboxWrapper>
  );
};

const CheckboxWrapper = styled.div`
  margin-right: auto;
  padding-top: 10px;
  label {
    margin-left: 5px;
  }
`;

export default WSAutoStart;
