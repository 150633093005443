// PaginationSelect.js
import React from "react";
import styled from "styled-components";

interface Props {
  itemsPerPage: number;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const NewSelectBox = ({ itemsPerPage, onChange }: Props) => {
  return (
    <Wrapper>
      <CustomSelectBox>
        <Select value={itemsPerPage} onChange={onChange}>
          <Option value={10}>10개</Option>
          <Option value={30}>30개</Option>
          <Option value={50}>50개</Option>
          <Option value={100}>100개</Option>
        </Select>
      </CustomSelectBox>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CustomSelectBox = styled.div`
  position: relative;
  width: 120px;
`;

const Select = styled.select`
  width: 70%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  color: #333;
  // appearance: none;

  &:hover {
    border-color: #888;
  }

  &:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    transition: box-shadow 0.2s ease-in-out;
  }
`;

const Option = styled.option`
  background-color: #fff; // 옵션의 배경색
  color: #333; // 옵션 텍스트 색상
  font-size: 14px;
`;

export default NewSelectBox;
