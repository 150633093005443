import { SetterOrUpdater } from "recoil";
import { User } from "../../../../@types/user/user";
import { AuthService } from "../../../../service/user/user.service";

interface Props {
  user: User;
  setUser: SetterOrUpdater<User>;
}

export const handleUserInfo = ({ user, setUser }: Props) => {
  const getUser = () => {
    AuthService.getUser("GUID", user.userGuid || "").then((res) => {
      if (res.state === "success") {
        console.log(res.data);
        setUser(res.data);
      }
    });
  };
  return { getUser };
};
