import React from "react";
import styled, { FlattenSimpleInterpolation, css } from "styled-components";

interface Props {
  children: React.ReactNode;
  size?: "md" | "lg";
}

const sizeStyles = {
  md: css`
    height: 140px;
  `,
  lg: css`
    height: 250px;
  `,
};

const ProjectListForm = ({ children, size }: Props) => {
  size = size || "md";
  return <Wrapper styleProps={sizeStyles[size || "md"]}>{children}</Wrapper>;
};

interface StyleProps {
  styleProps: FlattenSimpleInterpolation;
}

const Wrapper = styled.div<StyleProps>`
  background-color: #fff;
  border: 1px solid #cccccc;
  overflow-y: auto;
  padding: 10px;
  ${props => props.styleProps}
  width: 290px;
  &::-webkit-scrollbar {
    background-color: rgb(255, 255, 255);
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(112, 112, 112, 0.514);
    border-radius: 10px;
    height: 10px;
  }
`;

export default ProjectListForm;
