import { Tooltip } from "@mui/material";
import React from "react";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
  title?: string;
  tooltip?: string;
}

const ProjectComponentContainer = ({ children, title, tooltip }: Props) => {
  return (
    <Wrapper>
      {title && (
        <Title>
          <div className="title">
            <h2>{title}</h2>
            {tooltip && (
              <span className="tooltip">
                <Tooltip title={tooltip}>
                  <p>ⓘ</p>
                </Tooltip>
              </span>
            )}
          </div>
          <div className="line" />
        </Title>
      )}
      <div className="select_box">{children}</div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  .select_box {
    display: flex;
    justify-content: space-between;
  }
  padding-bottom: 10px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  height: 35px;
  padding-right: 20px;
  h2 {
    font-size: 18px;
  }
  .title {
    width: 350px;
    display: flex;
    align-items: center;
  }
  .tooltip {
    color: var(--GridaBlue);
    font-size: 16px;
    cursor: pointer;
    margin-left: 10px;
  }
  .line {
    width: 100%;
    height: 3px;
    background-color: #5975ef;
    margin: auto;
  }
`;

export default ProjectComponentContainer;
