import React, { useEffect, useState } from "react";

import { useAlert } from "../../../../../components/alert/alert.service";

import styled from "styled-components";
import { Checkbox } from "@mui/material";
import { useT } from "../../../../../constants/i18n/useT";
import InputLabel from "../../../../../components/input/input-label/input";
import { MAX_LENGTH } from "../../../../../constants/max-length";
import ModalPopup from "../../../../../components/modal/popup";
import { ModalProps } from "../../../../../components/modal";
import { REGEX } from "../../../../../constants/regex";
import { AuthService } from "../../../../../service/user/user.service";

interface Props extends ModalProps {
  getList: () => void;
}
const PasswordUpdate = (props: Props) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [validPassword2, setValidPassword2] = useState(false);
  const [seePw, setSeePw] = useState(false);

  const [isResponse, setIsResponse] = useState(false);

  const { setAlert, setSuccess } = useAlert();

  const checkPassword = () => {
    setValidPassword2(false);
    if (REGEX.PASSWORD.test(password)) {
      setValidPassword(true);
      if (password === password2) {
        setValidPassword2(true);
      }
    } else setValidPassword(false);
  };
  const checkPassword2 = () => {
    if (password === password2 && validPassword) {
      setValidPassword2(true);
    } else setValidPassword2(false);
  };

  const onSubmit = () => {
    if (currentPassword.length === 0)
      return setAlert(
        t(
          "user.the_existing_password_was_not_entered",
          "기존 비밀번호가 입력되지 않았습니다."
        )
      );

    if (!validPassword || !validPassword2)
      return setAlert(
        t(
          "user.please_check_the_format_of_the_new_password",
          "새 비밀번호의 형식을 확인해주세요,"
        )
      );

    if (password === currentPassword)
      return setAlert(
        t(
          "user.the_previous_and_new_passwords_are_the_same",
          "이전 비밀번호와 새 비밀번호가 같습니다."
        )
      );

    console.log(currentPassword, password);
    setIsResponse(true);
    AuthService.updatePW(currentPassword, password)
      .then((res) => {
        if (res.state === "success") {
          setSuccess(
            t(
              "user.your_password_has_been_reset",
              "비밀번호를 재설정 했습니다."
            )
          );
        }
      })
      .finally(() => {
        setIsResponse(false);
        props.setOpen(false);
        props.getList();
      });
  };

  useEffect(() => checkPassword(), [password]);
  useEffect(() => checkPassword2(), [password2]);
  const t = useT();
  const getType = () => {
    if (validPassword && validPassword2 && !!currentPassword.length) {
      return "confirm";
    }
    return "disable";
  };

  return (
    <ModalPopup
      onSubmit={onSubmit}
      buttonText={t("signup.update_password", "비밀번호 변경")}
      type={getType()}
      size="lg"
      isResponse={isResponse}
      {...props}
    >
      <Wrapper>
        <div className="user_pop_content">
          <form>
            <InputLabel
              label={t("signup.current_password", "기존 비밀번호")}
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              placeholder={t("signup.current_password", "기존 비밀번호") || ""}
              type={seePw ? "text" : "password"}
              maxLength={MAX_LENGTH.PASSWORD}
            />
            <InputLabel
              label={t("signup.new_password", "새 비밀번호")}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={t("signup.new_password", "새 비밀번호") || ""}
              type={seePw ? "text" : "password"}
              maxLength={MAX_LENGTH.PASSWORD}
              isValid={validPassword}
              description={t(
                "user.please_enter_a_password_with_letters",
                "대문자, 소문자, 숫자, 특수문자(@$!%*#?&) 를 포함해 8~16자리 이내로 입력해주세요."
              )}
            />
            <InputLabel
              label={t("signup.confirm_pw", "비밀번호 확인")}
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
              placeholder={t("signup.confirm_pw", "비밀번호 확인") || ""}
              type={seePw ? "text" : "password"}
              maxLength={MAX_LENGTH.PASSWORD}
              isValid={validPassword2}
              description={t(
                "signup.please_enter_your_password_again",
                "입력하신 비밀번호를 한번 더 입력해주세요"
              )}
            />

            <div className="left_flex">
              <Checkbox
                onClick={() => setSeePw(!seePw)}
                checked={seePw}
                name="see_pw"
              />
              <label
                onClick={() => setSeePw(!seePw)}
                className="see_pw"
                htmlFor="see_pw"
              >
                {t("signup.show_password", "비밀번호 표시")}
              </label>
            </div>
          </form>
        </div>
      </Wrapper>
    </ModalPopup>
  );
};

const Wrapper = styled.section`
  min-height: 370px;
  .user_pop_content {
    padding-top: 25px;
  }

  .see_pw {
    font-size: 0.9rem;
  }
  .left_flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;

export default PasswordUpdate;
