import React, { useEffect, useState } from "react";
import NameWithCheckBox from "../../../../../project/components/modal/create/setting-create-update/components/item/name-with-checkbox";
import styled from "styled-components";
import ProjectMemberTab from "../../../../../project/components/modal/create/setting-create-update/member/right/tab";
import ProjectSearchBar from "../../../../../project/components/modal/create/setting-create-update/components/search-bar";
import { MAX_LENGTH } from "../../../../../../constants/max-length";
import CircleProgress from "../../../../../../components/loading/CircleProgress";
import NotFoundData from "../../../../../project/components/modal/create/setting-create-update/components/item/notfound";

import { useT } from "../../../../../../constants/i18n/useT";
import UserListForm from "../../../../../workstation/components/modal/update-user/components/user.list.wapper";
import StorageCheckBox from "../left/storage-checkbox";
import { StorageUserUiData } from "../../../../../../@types/storage/ui/storage.user";
import { AuthService } from "../../../../../../service/user/user.service";
import { ProjectUserMode } from "../../../../../../@types/project/ui/user-search-mode";
import { useAlert } from "../../../../../../components/alert/alert.service";

interface Props {
  rightUsers: StorageUserUiData[];
  setRightUsers: React.Dispatch<React.SetStateAction<StorageUserUiData[]>>;
  ownerGuid?: string;
  searchUsers: StorageUserUiData[];
  setSearchUsers: React.Dispatch<React.SetStateAction<StorageUserUiData[]>>;
  mode: ProjectUserMode;
  setMode: React.Dispatch<React.SetStateAction<ProjectUserMode>>;
  company?: boolean;
}

const RightSTUserListWithCompany = ({
  rightUsers,
  setRightUsers,
  ownerGuid,
  searchUsers,
  setSearchUsers,
  mode,
  setMode,
  company = true,
}: Props) => {
  const [value, setValue] = useState("");
  const [notFound, setNotFound] = useState(false);

  const handleCheckbox = (
    mode: ProjectUserMode,
    usr: StorageUserUiData
  ) => () => {
    if (mode === ProjectUserMode.COMPANY) {
      const newUsers = rightUsers.map((u) =>
        u.userGuid === usr.userGuid ? { ...usr, isChecked: !usr.isChecked } : u
      );
      setRightUsers(newUsers);
    } else {
      const newUsers = searchUsers.map((u) =>
        u.userGuid === usr.userGuid ? { ...usr, isChecked: !usr.isChecked } : u
      );
      setSearchUsers(newUsers);
    }
  };
  const handleMode = (val: ProjectUserMode) => () => setMode(val);
  const checkMode = (val: ProjectUserMode) =>
    mode === val ? "selected" : "non_selected";
  const [isLoading, setIsLoading] = useState(false);
  const { setAlert } = useAlert();
  const t = useT();

  const getSearchUser = () => {
    setValue("");
    if (!value) return;
    setNotFound(false);
    AuthService.getUser("ID", value)
      .then((res) => {
        if (res.state === "success") {
          if (res.data.userGuid === ownerGuid) {
            setAlert(
              t(
                "project.you_cannot_add_yourself",
                "자신을 멤버로 추가할 수 없습니다."
              )
            );
            return setNotFound(true);
          }
          return setSearchUsers([
            new StorageUserUiData(res.data.userGuid, res.data.fullName),
          ]);
        }
        setNotFound(true);
        setSearchUsers([]);
      })
      .catch(() => setNotFound(true));
  };
  useEffect(() => {
    if (!company) setMode(ProjectUserMode.SEARCH);
  }, []);

  return (
    <Wrapper>
      <ProjectMemberTab
        company={company}
        handleMode={handleMode}
        checkMode={checkMode}
      />
      <UserListForm title="">
        {isLoading && <CircleProgress />}
        {!isLoading && (
          <>
            {mode === ProjectUserMode.SEARCH && (
              <>
                <ProjectSearchBar
                  placeholder={t("user.search_user", "유저 검색")}
                  value={value}
                  setValue={setValue}
                  maxLength={MAX_LENGTH.ID}
                  onSubmit={getSearchUser}
                />
                {searchUsers.map((usr) => (
                  <StorageCheckBox
                    key={usr.userGuid}
                    title={usr.fullName}
                    isChecked={usr.isChecked}
                    handleCheckbox={handleCheckbox(ProjectUserMode.SEARCH, usr)}
                    user={usr}
                  />
                ))}
                {notFound && (
                  <NotFoundData
                    title={t("user.user_not_found", "유저를 찾을 수 없습니다.")}
                  />
                )}
              </>
            )}

            {mode === ProjectUserMode.COMPANY &&
              rightUsers.map((usr) => (
                <NameWithCheckBox
                  key={usr.userGuid}
                  title={usr.fullName}
                  isChecked={usr.isChecked}
                  handleCheckbox={handleCheckbox(ProjectUserMode.COMPANY, usr)}
                  user={usr}
                />
              ))}
          </>
        )}
      </UserListForm>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export default RightSTUserListWithCompany;
