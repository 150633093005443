import React, { useEffect, useState } from "react";
import { Project } from "../../../../../@types/project/project";
import { ProjectForm } from "../../../../../@types/project/form";
import { useT } from "../../../../../constants/i18n/useT";
import styled from "styled-components";
import ModalForm from "../../../../../components/modal/modal-form";
import ProjectSettingComponent from "../create/setting-create-update";
import ProjectInfoComponent from "../create/setting-create-update/info";
import { LinearProgress } from "@mui/material";
import { useRecoilState } from "recoil";
import { ProjectUiData, projectUiDataState } from "../../../../../constants/recoil/project";
import { useAlert } from "../../../../../components/alert/alert.service";
import { ProjectService } from "../../../../../service/project/project.service";
import { Workstation } from "../../../../../@types/workstation/workstation";
import { Storage } from "../../../../../@types/storage/storage";
import { userState } from "../../../../../constants/recoil/user";
import { countBy } from "lodash";

interface Props {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getList: () => void;
  projectId: number;
}

const ProjectUpdate = ({ isOpen, setOpen, getList, projectId }: Props) => {
  const [projectForm, setProjectForm] = useState<ProjectForm>(new ProjectForm());
  const [project, setProject] = useState<Project | null>(null);

  const [assignedStorages, setAssignedStorages] = useState([]);
  const [assignedWorkstations, setAssignedWorkstations] = useState([]);

  const [user, setUser] = useRecoilState(userState);

  const t = useT();
  const UNASSIGNED = "PROJECT_ASSIGNABLE";
  const PROJECT_ASSIGNABLE = "PROJECT_ASSIGNABLE";
  const PROJECT_WORKSTATION = "PROJECT_WORKSTATION";
  const PROJECT_STORAGE = "PROJECT_STORAGE";

  const STORAGE_USAGE = {
    TRUE: "true",
    FALSE: "false",
  };

  const [, setProjectUiData] = useRecoilState(projectUiDataState);

  const { setAlert, setSuccess } = useAlert();

  const setProjectData = async () => {
    try {
      const res = await ProjectService.getById(projectId);
      if (res.state === "success") {
        const data = res.data as Project;

        setProjectForm(new ProjectForm());
        setProject(data);

        if (data.user) setUiData(data.user.userGuid!);
      }

      const assignedWorkstations = await ProjectService.getAssignedWorkstations(
        user.userGuid!,
        PROJECT_WORKSTATION,
        projectId
      );

      const assignedStorages = await ProjectService.getAssignedStorages(
        user.userGuid!,
        PROJECT_STORAGE,
        projectId
      );

      setAssignedWorkstations(assignedWorkstations);
      setAssignedStorages(assignedStorages);
    } catch (error) {
      console.error("Error fetching project data:", error);
      setAlert("Failed to fetch project data");
    }
  };

  // const setUiData = (ownerId?: string) => {
  //   ProjectService.getUiData().then((res) => {
  //     if (res.state === "success") {
  //       const data = res.data as ProjectUiData;

  //       const unassignedWorkstations = ProjectService.getFilterByOwnerId<
  //         Workstation
  //       >(data.unassignedWorkstations, ownerId);

  //       const unassignedStorages = ProjectService.getFilterByOwnerId<Storage>(
  //         data.unassignedStorages,
  //         ownerId
  //       );

  //       const newData = {
  //         ...data,
  //         unassignedWorkstations,
  //         unassignedStorages,
  //       };

  //       console.log(newData, "@@");
  //       setProjectUiData(newData);
  //     }
  //   });
  // };

  const setUiData = async (userGuid: string) => {
    try {
      const code = await ProjectService.getUiData();
      const workstation = await ProjectService.getWorkstation(userGuid, UNASSIGNED);
      const stoarge = await ProjectService.getStorage(
        userGuid,
        PROJECT_ASSIGNABLE,
        STORAGE_USAGE.FALSE
      );

      const newData = {
        codes: code.data.codes,
        unassignedWorkstations: workstation,
        unassignedStorages: stoarge,
      };

      setProjectUiData(newData);
    } catch (error) {
      console.error(error);
    }
  };
  const onSubmit = async () => {
    if (projectForm.name === "") {
      return setAlert("이름을 입력해주세요.");
    }

    const newProjectForm: ProjectForm = {
      ...projectForm,
      projectId: projectId,
      // storages: projectForm.storages.map(storage => ({
      //   storageId: storage.storageId,
      //   workstations:
      //     storage.workstations!.map(workstation => ({
      //       workstationId: workstation.workstationId,
      //       driveLetter: workstation.driveLetter || "Z",
      //     })),
      // })),
    };

    if (newProjectForm.description === "") {
      delete newProjectForm.description;
    }

    ProjectService.update(newProjectForm)
      .then(res => {
        setSuccess(`${newProjectForm.name} 프로젝트가 수정되었습니다.`);
        return setProjectData();
      })
      .catch(error => {
        if (error.response && error.response.status === 400) {
          setAlert("권한이 없습니다.");
        } else {
          console.error("An unexpected error occurred:", error);
        }
      })
      .finally(() => {
        getList();
      });
  };

  useEffect(() => {
    setProjectData();
  }, []);

  return (
    <ModalForm
      title={t("project.update_project", "프로젝트 수정")}
      isOpen={isOpen}
      setOpen={setOpen}
      size="full"
    >
      {!project && (
        <Wrapper>
          <LinearProgress />
        </Wrapper>
      )}
      {project && (
        <Wrapper>
          <div className="flex_box">
            <div className="left">
              <ProjectSettingComponent
                project={project}
                projectForm={projectForm}
                setProjectForm={setProjectForm}
                getList={getList}
                assignedStorages={assignedStorages}
                assignedWorkstations={assignedWorkstations}
              />
            </div>
            <div className="right">
              <ProjectInfoComponent
                projectForm={projectForm}
                setProjectForm={setProjectForm}
                project={project}
              />
            </div>
          </div>
          <ModalForm.ButtonBox setOpen={setOpen} onSubmit={onSubmit} />
        </Wrapper>
      )}
    </ModalForm>
  );
};
const Wrapper = styled.div`
  padding: 10px 50px;
  height: 90vh;
  max-height: 900px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .flex_box {
    display: flex;
  }
  .left {
    width: 70%;
  }
  .right {
    width: 30%;
  }
`;
export default ProjectUpdate;
