import { useTranslation } from "react-i18next";

export const useT = () => {
  const { t } = useTranslation(["page"]);
  return t;
};

export const useCurrentLanguage = () => {
  const { i18n } = useTranslation();
  return i18n.language;
};
