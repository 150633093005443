import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useT } from "../../../../../../../constants/i18n/useT";
import InputLabel from "../../../../../../../components/input/input-label/input";
import { MAX_LENGTH } from "../../../../../../../constants/max-length";
import TextAreaLabel from "../../../../../../../components/input/input-label/textarea";

import { ProjectForm } from "../../../../../../../@types/project/form";
import CheckboxLabel from "../../../../../../../components/input/input-label/checkbox";
import DateSelectButton from "./date.select";
import { Project } from "../../../../../../../@types/project/project";
import { DateService } from "../../../../../../../service/common/date.service";
import { _projectInfo } from "../../../../../script/project.info";
import { InputService } from "../../../../../../../service/common/input.service";

interface Props {
  projectForm: ProjectForm;
  setProjectForm: React.Dispatch<React.SetStateAction<ProjectForm>>;
  project?: Project;
}

const ProjectInfoComponent = ({ project, projectForm, setProjectForm }: Props) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [isPublic, setIsPublic] = useState(false);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());

  const { handleCheck, setProjectInfo } = _projectInfo({
    isPublic,
    setIsPublic,
    setName,
    setDescription,
  });
  const t = useT();

  useEffect(() => project && setProjectInfo(project), [project]);

  useEffect(() => {
    setProjectForm({
      ...projectForm,
      name: name,
      description: description,
      plannedStartDt: DateService.API_YYYY_MM_DD(startDate),
      plannedEndDt: DateService.API_YYYY_MM_DD(endDate),
    });
  }, [name, description, isPublic, startDate, endDate]);

  return (
    <Wrapper>
      <div>
        <div className="mt">
          <InputLabel
            value={name}
            onChange={e => {
              setName(InputService.onlyKorEng(e));
            }}
            label={`${t("common.name", "이름")} :`}
            placeholder={t("common.please_enter_a_name", "이름을 입력하십시오") || ""}
            maxLength={MAX_LENGTH.PROJECT_NAME}
            inline
          />
        </div>
        <div className="mt">
          <DateSelectButton
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            project={project}
          />
        </div>
        {/* <div className="mt">
          <CheckboxLabel
            label={t("common.public_or_private", "공개 여부")}
            onClick={handleCheck}
            checked={isPublic}
            value={isPublic}
          />
        </div> */}

        <div className="mt">
          <TextAreaLabel
            value={description}
            onChange={e => setDescription(e.target.value)}
            label={t("common.note", "노트")}
            placeholder={t("common.please_enter_the_note", "노트를 입력하십시오.")}
            maxLength={MAX_LENGTH.DESCRIPTION}
          />
        </div>
        {project && project.user && project.user.fullName && (
          <div className="mt">
            <InputLabel value={project.user.fullName} label={"Owner : "} disabled />
          </div>
        )}
        {project && project.createdDt && (
          <div className="mt">
            <InputLabel
              value={DateService.getTimeExceptSecond(project.createdDt)}
              label={"Created : "}
              disabled
            />
          </div>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 20px;
  height: 500px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .mt {
    margin-top: 40px;
  }
`;

export default ProjectInfoComponent;
