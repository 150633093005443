import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import SignupInput from "../../../../components/input/signup/signup-input";
import { SignupForm } from "../../../../@types/user/signup/signup.form";
import { useT } from "../../../../constants/i18n/useT";
import { REGEX } from "../../../../constants/regex";
import Button from "../../../../components/button/Button";
import { useAlert } from "../../../../components/alert/alert.service";
import { SignUpService } from "../../../../service/user/signup.service";
import SignupRestoreAlert from "../restore/modal";

interface Props {
  signupForm: SignupForm;
  setSignupForm: React.Dispatch<React.SetStateAction<SignupForm>>;

  canUseId: boolean;
  canUseEmail: boolean;

  setCanUseId: React.Dispatch<React.SetStateAction<boolean>>;
  setCanUseEmail: React.Dispatch<React.SetStateAction<boolean>>;

  setValidId: React.Dispatch<React.SetStateAction<boolean>>;
  setValidEmail: React.Dispatch<React.SetStateAction<boolean>>;
  setValidPassword: React.Dispatch<React.SetStateAction<boolean>>;
  setValidPassword2: React.Dispatch<React.SetStateAction<boolean>>;

  validId: boolean;
  validEmail: boolean;
  validPassword: boolean;
  validPassword2: boolean;
}

const SignupChapter1 = ({
  signupForm,
  setSignupForm,
  canUseId,
  canUseEmail,
  setCanUseId,
  setCanUseEmail,
  setValidId,
  setValidEmail,
  setValidPassword,
  setValidPassword2,
  validId,
  validEmail,
  validPassword,
  validPassword2,
}: Props) => {
  const t = useT();
  const { setAlert } = useAlert();

  const [popDuplicateAlert, setDuplicatePopAlert] = useState(false);
  const [restoreAlertMessage, setRestoreAlertMessage] = useState("");
  const idInputRef = useRef<HTMLInputElement | null>(null);
  const emailInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (idInputRef.current) {
      idInputRef.current.focus();
    }
  }, []);

  const handleId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCanUseId(false);
    setSignupForm(prev => ({ ...prev, userId: e.target.value }));
    if (REGEX.ID.test(e.target.value)) return setValidId(true);
    setValidId(false);
  };

  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCanUseEmail(false);
    setSignupForm(prev => ({ ...prev, mail: e.target.value }));
    if (REGEX.EMAIL.test(e.target.value)) return setValidEmail(true);
    setValidEmail(false);
  };

  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSignupForm(prev => ({ ...prev, password: value }));

    setValidPassword2(false);
    checkPassword(value);
  };

  const checkPassword = (value: string) => {
    if (REGEX.PASSWORD.test(value)) {
      setValidPassword(true);
      if (value === signupForm.password2) {
        setValidPassword2(true);
      }
    } else setValidPassword(false);
  };

  const handlePassword2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSignupForm(prev => ({ ...prev, password2: e.target.value }));
    checkPassword2(e.target.value);
  };
  const checkPassword2 = (value: string) => {
    // 2번이 내용이 있고, 1번과 동일하며 1번이 valid면
    if (signupForm.password === value && validPassword) {
      return setValidPassword2(true); // 2번 valid
    }
    setValidPassword2(false); // 내용이 다르거나 1번이 inValid면 2번 inValid
  };

  const getError = (valid: boolean, message: any) => {
    return valid ? null : message;
  };

  const checkCanUseId = async () => {
    if (!validId) {
      return setAlert(t("user.please_check_the_id", "ID를 확인해주세요"));
    }

    setCanUseId(false);

    SignUpService.checkId(signupForm.userId)
      .then(res => {
        if (res.state === "success") {
          if (idInputRef.current) {
            idInputRef.current.id = "";
          }
          return setCanUseId(true);
        }

        if (res.errorType === "DuplicatedException") {
          if (idInputRef.current) {
            idInputRef.current.focus();
            idInputRef.current.id = "error-input";
          }

          return setAlert(res.message || "DuplicatedException");
        }

        if (res.errorType === "AlreadyDeletedAccountException") {
          setDuplicatePopAlert(true);
          setRestoreAlertMessage(res.message);
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 409) {
          return setAlert(t("이미 사용중인 아이디입니다."));
        }
      });
  };

  const checkCanUseEmail = async () => {
    if (!validEmail) {
      return setAlert(t("user.please_format_the_email_properly", "메일 형식을 맞춰주세요"));
    }

    setCanUseEmail(false);
    SignUpService.checkEmail(signupForm.mail)
      .then(res => {
        if (res.state === "success") {
          if (emailInputRef.current) {
            emailInputRef.current.id = "";
          }
          return setCanUseEmail(true);
        }

        if (res.errorType === "DuplicatedException") {
          if (emailInputRef.current) {
            emailInputRef.current.focus();
            emailInputRef.current.id = "error-input";
          }
          return setAlert(res.message || "이미 사용중인 이메일입니다.");
        }
        if (res.errorType === "AlreadyDeletedAccountException") {
          setDuplicatePopAlert(true);
          setRestoreAlertMessage(res.message);
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 409) {
          return setAlert(t("이미 사용중인 이메일입니다."));
        }
      });
  };

  return (
    <div className="chapter_1">
      <SignupTitle title={t("signup.login_info", "로그인 정보")} />
      <SignupInput
        ref={idInputRef}
        value={signupForm.userId}
        essential
        onChange={handleId}
        title={t("signup.id", "아이디") || ""}
        placeholder={
          t(
            "signup.please_enter_the_username",
            "워크스테이션 접속에 사용될 사용자 이름을 입력하십시오."
          ) || ""
        }
        errorMessage={getError(
          validId,
          t(
            "signup.enter_an_ID_with_letters_numbers",
            "영어와 숫자가 포함된 8~20 글자수의 ID를 입력해주세요."
          )
        )}
      >
        <div className="btn_box">
          <Button onClick={checkCanUseId} disabled={canUseId} size="sm">
            {canUseId
              ? t("signup.id_email_available", "사용가능")
              : t("signup.duplicate_check", "중복확인")}
          </Button>
        </div>
      </SignupInput>
      <SignupInput
        ref={emailInputRef}
        value={signupForm.mail}
        type="mail"
        essential
        onChange={handleEmail}
        title={t("signup.email", "이메일") || ""}
        placeholder={
          t("signup.please_enter_the_email", "서비스 접속에 사용될 Email을 입력하십시오.") || ""
        }
        errorMessage={getError(validEmail, "이메일 형식에 맞게 입력해주세요.")}
      >
        <div className="btn_box">
          <Button onClick={checkCanUseEmail} disabled={canUseEmail} size="sm">
            {canUseEmail
              ? t("signup.id_email_available", "사용가능")
              : t("signup.duplicate_check", "중복확인")}
          </Button>
        </div>
      </SignupInput>
      <SignupInput
        value={signupForm.password}
        essential
        type="password"
        onChange={handlePassword}
        title={t("signup.password", "비밀번호") || ""}
        placeholder={t("signup.please_enter_your_password", "패스워드를 입력해주세요.") || ""}
        errorMessage={getError(
          validPassword,
          t(
            "user.please_enter_a_password_with_letters",
            "대문자, 소문자, 숫자, 특수문자(@$!%*#?&) 를 포함해 8~16자리 이내로 입력해주세요."
          )
        )}
      />
      <SignupInput
        value={signupForm.password2}
        essential
        type={"password"}
        onChange={handlePassword2}
        title={t("signup.confirm_pw", "비밀번호 확인") || ""}
        placeholder={
          t(
            "signup.please_enter_your_password_again",
            "입력하신 비밀번호를 한번 더 입력해주세요"
          ) || ""
        }
        errorMessage={getError(
          validPassword2,
          t("signup.please_enter_your_password_again", "입력하신 비밀번호를 한번 더 입력해주세요")
        )}
      />

      {popDuplicateAlert && (
        <SignupRestoreAlert
          message={restoreAlertMessage}
          isOpen={popDuplicateAlert}
          setOpen={setDuplicatePopAlert}
        />
      )}
    </div>
  );
};

const SignupTitle = ({ title }: { title: string }) => {
  return (
    <TitleWrap>
      <h5>{title}</h5>
      <div className="line_bar" />
    </TitleWrap>
  );
};
const TitleWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  h5 {
    font-size: 20px;
    font-weight: bold;
  }
  .line_bar {
    width: 60%;
    height: 3px;
    background-color: #333;
    margin-left: 20px;
  }
  @media only screen and (min-width: 768px) {
    h5 {
      font-size: 24px;
    }
    .line_bar {
      width: 60%;
    }
  }
`;

export default SignupChapter1;
