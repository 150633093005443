import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useT } from "../../../constants/i18n/useT";
import styled from "styled-components";
import LocaleUpdate from "../button/locale-change";
import Button from "../../button/Button";
import ProfileDropdown from "../button/profile-dropdown";
import ClientInstallButton from "../button/client-install";
import BillingButton from "../button/billing-dropdown";
import CalculateModal from "../../../pages/billing/calculate/modal";
import HelpButton from "../button/question";

interface Props {
  isLogin: boolean;
}

const DesktopHeader = ({ isLogin }: Props) => {
  const [popCalculate, setPopCalculate] = useState(false);
  const [popContact, setPopContact] = useState(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const t = useT();

  const servicePage = ["/workstation", "/storage", "/mypage/*", "/project"];
  const isServicePage = () => {
    if (servicePage.includes(pathname)) {
      return true;
    } else return false;
  };

  return (
    <Wrapper>
      <div className="logo">
        <Link to="/">
          <img alt="img" src={"/assets/main/logo.png"} />
        </Link>
      </div>
      <div className="nav_right">
        {/*  공용 */}
        <LocaleUpdate type="desktop" isLogin={isLogin} />

        <BillingButton />

        <ClientInstallButton />

        <HelpButton isLogin={isLogin} />

        {/*  로그인 전 */}
        {!isLogin && (
          <>
            <Link to="/login" className="nav_button">
              <Button>{t("common.sign_in", "로그인")}</Button>
            </Link>

            <Link to="/signup" className="nav_button">
              <Button color="blue">{t("common.sign_up", "회원가입")}</Button>
            </Link>
          </>
        )}

        {/*  로그인 후 */}
        {isLogin && (
          <>
            {!isServicePage() && (
              <Button onClick={() => navigate("/workstation")}>
                {t("common.service", "Service")}
              </Button>
            )}
            <ProfileDropdown />
          </>
        )}
      </div>
      {popCalculate && <CalculateModal setOpen={setPopCalculate} isOpen={popCalculate} />}
    </Wrapper>
  );
};

const Wrapper = styled.header`
  width: 100%;
  height: 80px;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  border-bottom: 1px solid #d4d4d4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo {
    display: block;
    margin-left: 25px;
    img {
      height: 50px;
      cursor: pointer;
    }
  }
  .nav_right {
    display: flex;
    align-items: center;
  }
  .nav_button {
    margin: 0 20px;
  }
`;
const NavLink = styled.li`
  display: flex;
  align-items: center;
  margin: 0 40px;
  padding: 10px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  &:hover {
    color: var(--GridaBlue);
  }
`;

export default DesktopHeader;
