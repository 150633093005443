import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import data from "./data.json";

i18n
  .use(detector) //사용자 언어 감지
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(
    {
      resources: {
        ko_KR: { page: data.ko_KR },
        en_US: { page: data.en_US },
        ja_JP: { page: data.ja_JP },
        zh_CN: { page: data.zh_CN },
      },
      fallbackLng: "ko", // 기본값?
      interpolation: {
        escapeValue: false,
      },
    },
    function(err) {
      if (err) console.error(err);
    }
  );

export default i18n;
