import React, { useEffect, useState } from "react";
import { Storage } from "../../../../../@types/storage/storage";
import { MAX_LENGTH } from "../../../../../constants/max-length";
import styled from "styled-components";
import { Code } from "../../../../../@types/infra/code";
import { useT } from "../../../../../constants/i18n/useT";
import ModalPopup from "../../../../../components/modal/popup";
import InputLabel from "../../../../../components/input/input-label/input";
import SelectLabel from "../../../../../components/input/input-label/select";
import TextAreaLabel from "../../../../../components/input/input-label/textarea";
import { ModalProps } from "../../../../../components/modal";
import { StorageForm } from "../../../../../@types/storage/form";
import { StorageService } from "../../../../../service/storage/storage.service";
import { ConvertService } from "../../../../../service/common/convert.service";
import { useAlert } from "../../../../../components/alert/alert.service";
import { InputService } from "../../../../../service/common/input.service";

interface Props extends ModalProps {
  getList: () => void;
  storage: Storage;
}
const StorageUpdateInfo = (props: Props) => {
  const [storage, setStorage] = useState(new StorageForm(props.storage));
  const [sizes, setSizes] = useState<Code[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isResponse, setIsResponse] = useState(false);

  useEffect(() => {
    getUIData();
  }, []);

  const { setAlert, setSuccess } = useAlert();

  const getUIData = () => {
    setIsLoading(true);
    StorageService.getUi()
      .then(res => {
        if (res.state === "success") {
          let codes = res.data.codes;
          let tmpCode = ConvertService.getCodes(codes, "Storage.SharedSize");
          setSizes(tmpCode);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStorage({ ...storage, name: InputService.onlyKorEng(e) });
  };
  const handleSize = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setStorage({ ...storage, size: { cd: e.target.value } });
  };

  const handleDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setStorage({ ...storage, description: e.target.value });
  };

  const onSubmit = () => {
    if (storage.name === "") {
      return setAlert(t("common.please_enter_a_name", "이름을 입력해주세요."));
    }
    if (storage.size.cd === "") {
      return setAlert(t("common.please_select_a_size", "용량을 선택해주세요."));
    }

    setIsResponse(true);

    if (storage.description === "") delete storage.description;

    console.log(storage);
    StorageService.update(storage)
      .then(res => {
        if (res.state === "success") {
          setSuccess(storage.name + t("storage.has_been_updated", "를 수정했습니다."));
        }
      })
      .finally(() => {
        setIsResponse(false);
        props.setOpen(false);
        props.getList();
      });
  };

  const t = useT();

  if (isLoading) return <></>;

  return (
    <ModalPopup
      buttonText={t("common.update", "수정")}
      onSubmit={onSubmit}
      size="md"
      isResponse={isResponse}
      {...props}
    >
      <InputLabel
        value={storage.name}
        onChange={handleName}
        label={t("common.name", "이름")}
        placeholder={t("common.please_enter_a_name", "이름을 입력하십시오") || ""}
        maxLength={MAX_LENGTH.STROAGE}
      />
      <Wrap>
        <SelectLabel label={t("storage.size", "용량")}>
          <select value={storage.size.cd} onChange={handleSize}>
            {sizes.map((size: Code, idx) => (
              <option key={idx} value={size.cd}>
                {size.name}
              </option>
            ))}
          </select>
        </SelectLabel>
        {
          <div className="disabled">
            FTP : <span>{storage.isFtp ? "Enabled" : "Disabled"}</span>
          </div>
        }
      </Wrap>
      <TextAreaLabel
        value={storage.description || ""}
        onChange={handleDescription}
        label={t("common.description", "비고")}
        placeholder={t("common.please_enter_the_description", "비고를 입력하십시오.")}
        maxLength={MAX_LENGTH.DESCRIPTION}
      />
    </ModalPopup>
  );
};
const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin: 10px 0;
  .disabled {
    padding-top: 10px;
    color: #979797;
    width: 300px;
    font-weight: 500;
    span {
      font-weight: 400;
    }
  }
`;

export default StorageUpdateInfo;
