import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ModalProps } from "../../../../components/modal";
import { useAlert } from "../../../../components/alert/alert.service";
import { ValidType } from "../../../../@types/type/valid";
import { useT } from "../../../../constants/i18n/useT";
import ModalPopup from "../../../../components/modal/popup";
import { AuthService } from "../../../../service/user/user.service";
import { MAX_LENGTH } from "../../../../constants/max-length";
import Button from "../../../../components/button/Button";
import { REGEX } from "../../../../constants/regex";

interface Props extends ModalProps {
  message: string;
  userId: string;
}

export const EmailAlertModal = (props: Props) => {
  const { message } = props;
  const [isResponse, setIsResponse] = useState(false);
  const [email, setEmail] = useState(message);
  const [validEmail, setValidEmail] = useState<ValidType>(ValidType.EMPTY);

  const { setAlert } = useAlert();
  const onSubmit = () => {
    if (validEmail === ValidType.VALID) {
      setIsResponse(true);
      AuthService.sendEmail(props.userId, email)
        .then((res) => {
          if (res.state === "success")
            return alert(
              t(
                "main.mail_account_has_sent_an_email",
                "메일 계정으로 이메일이 발송되었습니다.하루 이내에 인증을 완료해주세요"
              )
            );
          if (res.message) return setAlert(res.message);
          return setAlert(
            t(
              "common.error",
              "현재 서버가 원활하지 않습니다. 잠시 후 시도해주세요."
            )
          );
        })
        .finally(() => setIsResponse(false));
      return;
    }
    setAlert(
      t("user.please_format_the_email_properly", "메일 형식을 맞춰주세요")
    );
  };

  const checkEmail = () => {
    if (email === "") {
      return setValidEmail(ValidType.EMPTY);
    }
    if (email.match(REGEX.EMAIL)) {
      return setValidEmail(ValidType.VALID);
    }
    return setValidEmail(ValidType.INVALID);
  };

  const t = useT();

  useEffect(() => checkEmail(), [email]);
  return (
    <ModalPopup
      size="lg"
      isResponse={isResponse}
      onSubmit={onSubmit}
      buttonText={t("user.email_verification", "이메일 인증")}
      {...props}
    >
      <Wrapper>
        <div className="user_pop_content">
          <div className="content_box">
            <p>
              {t(
                "signup.please_complete_your_identity_verification_via_email",
                "이메일을 통해 본인 인증을 완료 후 다시 로그인해주세요"
              )}
              <br />
              {t(
                "signup.the_email_has_been_sent_to_the_following_address",
                "아래의 이메일 주소로 인증 메일이 발송되었습니다."
              )}
              <br />
              <span id="blue">({message})</span>
            </p>
            <br />
            <p>
              {t(
                "signup.click_the_resend_button_to_recover_the_verification_email",
                "재전송 버튼을 클릭하면 인증 메일이 재발송 됩니다."
              )}
            </p>
            <p>
              {t(
                "signup.email_can_be_corrected_if_there_is_an_error",
                "(이메일 주소 오류가 있는 경우 이메일 수정 가능)"
              )}
            </p>
            <div className="input_box">
              <input
                value={email}
                maxLength={MAX_LENGTH.MAIL}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button onClick={() => onSubmit()} size="xs" color="white">
                {t("common.resend", "재전송")}
              </Button>
            </div>
            {validEmail === ValidType.INVALID && (
              <p className="inValid">
                {t(
                  "user.please_enter_a_valid_email_address",
                  "유효한 이메일 주소를 입력해주세요."
                )}
              </p>
            )}
          </div>
        </div>
      </Wrapper>
    </ModalPopup>
  );
};

const Wrapper = styled.div`
  text-align: center;
  .content_box {
    height: 270px;
    text-align: center;
    .input_box {
      margin-top: 40px;
      input {
        width: 200px;
        height: 32px;
        margin-right: 30px;
        padding-left: 10px;
        border: 1px solid #d4d4d4;
        border-radius: 5px;
      }
      button {
        height: 30px;
      }
    }
    h3 {
      font-size: 15px;
      margin-bottom: 20px;
    }
  }
  .empty {
    font-size: 13px;
    color: #afafaf;
    margin-left: 125px;
    margin-top: 10px;
  }
  .inValid {
    font-size: 13px;
    color: #ff2c2c;
    margin-top: 4px;
    margin-left: 100px;
    text-align: start;
  }
  .button_box {
    width: 80%;
    margin: auto;
    margin-top: 50px;
  }
`;
