import React, { useEffect, useState } from "react";
import { useT } from "../../../../../constants/i18n/useT";
import { useRecoilState } from "recoil";
import { userState } from "../../../../../constants/recoil/user";
import { _storageUpdateUser } from "./script/storage.upate.user";
import { Storage } from "../../../../../@types/storage/storage";
import styled from "styled-components";

import ProjectCenterButton from "../../../../project/components/modal/create/setting-create-update/components/button/center.button";
import { Code } from "../../../../../@types/infra/code";
import StorageLeftUserList from "./left/left-user";
import RightSTUserListWithProject from "./right/right.user.project";
import RightSTUserListWithCompany from "./right/right.user.company";
import { StorageUserUiData } from "../../../../../@types/storage/ui/storage.user";
import { ProjectUserMode } from "../../../../../@types/project/ui/user-search-mode";
import { ModalProps } from "../../../../../components/modal";
import ModalPopup from "../../../../../components/modal/popup";

interface Props extends ModalProps {
  storage: any;
  projectId?: number;
  getList: () => void;
  projectName?: string;
}

// Storage 가 프로젝트에 소속되어 있을 때, 프로젝트에 소속된 유저들을 보여준다.

// Storage 가 프로젝트에 소속되어 있지 않을 때, 회사에 소속된 유저들을 보여준다.

// Storage 가 프로젝트에 소속되어 있지 않고, 회사에 소속되어 있지 않을 때, Prev Member 만 보여준다.

const STUpdateUserPage = ({ isOpen, setOpen, storage, getList, projectId, projectName }: Props) => {
  const t = useT();
  const [isLoading, setIsLoading] = useState(true);
  const [leftUsers, setLeftUsers] = useState<StorageUserUiData[]>([]);
  const [rightUsers, setRightUsers] = useState<StorageUserUiData[]>([]);
  const [searchUsers, setSearchUsers] = useState<StorageUserUiData[]>([]);

  const [user] = useRecoilState(userState);

  const [mode, setMode] = useState<ProjectUserMode>(ProjectUserMode.COMPANY);
  const [types, setTypes] = useState<Code[]>([]);
  const [disabled, setDisabled] = useState<boolean>(false);

  const { toRight, toLeft, onSubmit, getUiStorage } = _storageUpdateUser({
    setRightUsers,
    setIsLoading,
    setLeftUsers,
    leftUsers,
    rightUsers,
    getList,
    searchUsers,
    setSearchUsers,
    storage,
    setTypes,
    setDisabled,
  });

  useEffect(() => getUiStorage(), []);

  const getTitle = () => {
    if (storage) {
      return t("project.share_setting", "공유 설정") + ` (${storage.name})`;
    }
    return t("project.share_setting", "공유 설정");
  };
  return (
    <ModalPopup
      title={getTitle()}
      size="xl"
      isOpen={isOpen}
      setOpen={setOpen}
      buttonText={t("common.save", "저장")}
      onSubmit={onSubmit}
      disabled={disabled}
    >
      {projectName && (
        <ProjectTitle>
          Project : <ProjectName>{projectName}</ProjectName>
        </ProjectTitle>
      )}
      <Wrapper>
        {!isLoading && (
          <PrivBox>
            <StorageLeftUserList types={types} setLeftUsers={setLeftUsers} leftUsers={leftUsers} />

            <div className="center_btn">
              {mode === ProjectUserMode.COMPANY && (
                <ProjectCenterButton
                  toLeft={() => toLeft(ProjectUserMode.COMPANY)}
                  toRight={toRight}
                />
              )}
              {mode === ProjectUserMode.SEARCH && (
                <ProjectCenterButton
                  toLeft={() => toLeft(ProjectUserMode.SEARCH)}
                  toRight={toRight}
                />
              )}
            </div>

            {projectId && (
              <RightSTUserListWithProject rightUsers={rightUsers} setRightUsers={setRightUsers} />
            )}

            {!projectId && user.company && (
              <RightSTUserListWithCompany
                mode={mode}
                setMode={setMode}
                searchUsers={searchUsers}
                setSearchUsers={setSearchUsers}
                rightUsers={rightUsers}
                setRightUsers={setRightUsers}
                ownerGuid={user.userGuid}
              />
            )}

            {!projectId && !user.company && (
              <RightSTUserListWithCompany
                mode={mode}
                setMode={setMode}
                company={false}
                searchUsers={searchUsers}
                setSearchUsers={setSearchUsers}
                rightUsers={rightUsers}
                setRightUsers={setRightUsers}
                ownerGuid={user.userGuid}
              />
            )}
          </PrivBox>
        )}
      </Wrapper>
    </ModalPopup>
  );
};
const Wrapper = styled.div`
  width: 1100px;
  height: 650px;
  background-color: #ffffff;
  overflow-y: hidden;
  padding: 0 20px;
`;
const PrivBox = styled.article`
  height: 550px;
  width: 900px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
`;

const ProjectTitle = styled.div`
  font-size: 20px;
  margin-top: 20px;
  margin-left: 11%;
  margin-right: auto;
`;

const ProjectName = styled.span``;

export default STUpdateUserPage;
