import React, { useEffect, useState } from "react";
import { Code } from "../../../../../../@types/infra/code";
import { Image } from "../../../../../../@types/infra/image";
import { useT } from "../../../../../../constants/i18n/useT";

interface Iprops {
  oss: Code[];
  os: string;
  setOs: React.Dispatch<React.SetStateAction<string>>;
  images: Image[];
  setSelectImages: React.Dispatch<React.SetStateAction<Image[]>>;
  setSw: React.Dispatch<React.SetStateAction<string>>;
  bootDisk: string;
  setBootDisk: React.Dispatch<React.SetStateAction<string>>;
  setImageId: React.Dispatch<React.SetStateAction<string>>;
}

const WSCreateOS: React.FC<Iprops> = ({
  images,
  setSelectImages,
  setSw,
  oss,
  os,
  setOs,
  bootDisk,
  setBootDisk,
  setImageId,
}) => {
  const DEFAULT_OS = "OS.Windows10";
  const [showOs, setShowOs] = useState<Code[]>(oss);
  const filterBySW = () => {
    if (!!images.length) {
      let tmpImages = images.filter(
        (image: Image) => image.os && image.os.cd === os
      );
      setSelectImages(tmpImages);
    }
  };
  useEffect(() => setOs(DEFAULT_OS), [images]);
  useEffect(() => {
    if (os !== "") filterBySW();
    setImageId("");
  }, [os]);
  
  const t = useT();
  return (
    <dd className="radio_middle ">
      {showOs.length > 0 ? (
        <select
          tabIndex={3}
          onChange={(e) => setOs(e.target.value)}
          value={os}
          id="number"
        >
          {showOs.map((OS, index) => (
            <option key={index} value={OS.cd} id={OS.cd}>
              {OS.name}
            </option>
          ))}
        </select>
      ) : (
        <p id="red">
          {t(
            "workstation.there_is_no_available_os_to_use",
            "사용 가능한 os가 없습니다."
          )}
        </p>
      )}
    </dd>
  );
};

export default WSCreateOS;
