export class WindowService {
  static scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  static clearBrowser = () => {
    sessionStorage.removeItem("x-access-token");
    localStorage.clear();
  };
  static goMain = () => {
    window.location.replace("/");
  };
  static goWorkstation = () => {
    window.location.replace("/workstation");
  };
  static goLogin = () => {
    window.location.replace("/login");
  };
  static reload = () => {
    window.location.reload();
  };
}

// href 는 새로고침이 된다.