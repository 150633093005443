import React from "react";
import styled from "styled-components";

const TableHeader = ({ headers }: { headers: { title: string; width: number }[] }) => {
  return (
    <Wrapper>
      <TableSize>
        {headers.map(content => (
          <col key={content.title} width={`${content.width}%`} />
        ))}
      </TableSize>
      <TableTitle>
        <tr>
          {headers.map(content => (
            <th key={content.title}>{content.title}</th>
          ))}
        </tr>
      </TableTitle>
    </Wrapper>
  );
};
const Wrapper = styled.table`
  border-radius: 10px 10px 0 0;
  margin-top: 30px;
  background: var(--Active);
  border: 0.5px solid;
`;
const TableSize = styled.colgroup``;

const TableTitle = styled.thead`
  th {
    color: #ffffff;
    text-align: center;
    // border-left: 5px solid #dfdfdf;
    &:first-child {
      width: 50px;
      border-left: none;
    }
    border: 0.5px solid;
  }
`;
export default TableHeader;
