import React, { useCallback, useEffect, useState } from "react";
import MobileHeader from "./mobile-header";

import CalculateModal from "../../../pages/billing/calculate/modal";
import ContactModal from "../../../pages/contact/non-member/modal";
import LogoutModal from "../../../pages/login/logout/modal";
import DesktopHeader from "./desktop-header";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { userState } from "../../../constants/recoil/user";

const Header = () => {
  const [isLogin, setIsLogin] = useState(false);

  const [user] = useRecoilState(userState);

  // 🍋 토큰 체크
  useEffect(() => {
    setIsLogin(sessionStorage.getItem("x-access-token") ? true : false);
  }, [user]);

  const props = { isLogin };

  return (
    <>
      <Mobile>
        <MobileHeader {...props} />
      </Mobile>
      <Desktop>
        <DesktopHeader {...props} />
      </Desktop>
    </>
  );
};

const Mobile = styled.div`
  @media screen and (min-width: 1024px) {
    display: none;
  }
`;
const Desktop = styled.div`
  @media screen and (max-width: 1023px) {
    display: none;
  }
`;

export default Header;
