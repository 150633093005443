import React from "react";
import styled from "styled-components";
import { useT } from "../../../constants/i18n/useT";

const SignupHeader = ({ session }: { session: 1 | 2 }) => {
  const t = useT();
  return (
    <>
      <Header>
        <h1 className="title">{t("signup.sign_up", "회원가입")}</h1>
      </Header>
      <Step>
        <div className="step_wrap">
          <div id={session == 1 ? "on" : "off"} className="circle_wrap">
            <div className="circle">1</div>
            <div className="label">
              {t("user.agree_to_terms_of_use", "이용약관 동의")}
            </div>
          </div>
          <div id={session == 2 ? "on" : "off"} className="circle_wrap">
            <div className="circle">2</div>
            <div className="label">
              {t("user.enter_information", "정보입력")}
            </div>
          </div>
        </div>
      </Step>
    </>
  );
};

const Header = styled.header`
  width: 100%;
  height: 130px;
  background: url("/assets/sub/sub_top_img.jpg") center no-repeat;
  background-size: cover;
  padding-top: 50px;
  border-bottom: 1px solid #ddd;
  .title {
    padding-top: 50px;
    text-align: center;
    font-size: 30px;
    color: #333;
    font-weight: 700;
  }
  @media screen and (min-width: 1024px) {
    padding-top: 100px;
    height: 170px;
    .title {
      padding: 20px;
      font-size: 50px;
      text-align: start;
    }
  }
`;

const Step = styled.div`
  width: 100%;
  height: 100px;
  .step_wrap {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 70%;
    height: 100%;
    margin: auto;
    max-width: 700px;
  }
  .circle_wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &#on {
      .circle {
        background: var(--GridaBlue);
      }
      .label {
        color: var(--GridaBlue);
        font-weight: 500;
      }
    }
  }

  .label {
    margin-top: 7px;
    font-size: 12px;
    color: #7e7e7e;
  }
  .circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    height: 120px;
    .circle {
      width: 50px;
      height: 50px;
      font-size: 25px;
    }
    .label {
      font-size: 15px;
    }
  }

  @media screen and (min-width: 1024px) {
    height: 150px;
    .circle {
      width: 65px;
      height: 65px;
      font-size: 25px;
    }
    .label {
      font-size: 15px;
    }
  }
`;

export default SignupHeader;
