import React, { useState } from "react";
import styled from "styled-components";
import { User } from "../../../../@types/user/user";
import Button from "../../../../components/button/Button";
import UserUpdate from "./update/modal";
import Withdraw from "./withdraw/modal";
import UpdateEmail from "./update-email/modal";
import UserInfoElement from "./top-info-element";
import UpdateCompany from "./update-company/modal";
import { useT } from "../../../../constants/i18n/useT";

interface Props {
  user: User;
  getUser: () => void;
}

const UserInfoLeftBox = ({ user, getUser }: Props) => {
  const [popUserUpdate, setPopUserUpdate] = useState(false);
  const [popWithdraw, setPopWithdraw] = useState(false);
  const [popUpdateEmail, setPopUpdateEmail] = useState(false);
  const [popUpdateCompany, setPopUpdateCompany] = useState(false);
  const t = useT();
  return (
    <Wrapper>
      <UserInfoElement title={t("user.id", "아이디")} content={user.userId} />
      <UserInfoElement title={t("user.name", "이름")} content={user.fullName} />
      <UserInfoElement
        title={t("user.phone_number", "전화번호")}
        content={user.mobile}
      />

      {user.company && user.company.name && (
        <UserInfoElement
          title={t("user.company", "회사")}
          content={user.company.name}
          type="company"
          action={setPopUpdateCompany}
        />
      )}
      {user.individualCompanyName && (
        <UserInfoElement
          title={t("user.company", "회사")}
          content={user.individualCompanyName}
          type="company"
          action={setPopUpdateCompany}
        />
      )}

      {!user.individualCompanyName && !(user.company && user.company.name) && (
        <UserInfoElement
          title={t("user.company", "회사")}
          // content={user.individualCompanyName}
          type="company"
          action={setPopUpdateCompany}
        />
      )}

      <UserInfoElement
        title={t("user.email", "이메일")}
        content={user.mail}
        type="email"
        action={setPopUpdateEmail}
      />
      <UserInfoElement
        title={t("user.duplicate_login", "중복 로그인")}
        content={user.sessionPolicy && user.sessionPolicy.name}
      />
      <div className="update_btn">
        <Button size="sm" color="black" onClick={() => setPopUserUpdate(true)}>
          {t("user.update_info", "회원 정보 수정")}
        </Button>
      </div>
      {popUserUpdate && (
        <UserUpdate
          user={user}
          setOpen={setPopUserUpdate}
          isOpen={popUserUpdate}
          setPopWithdraw={setPopWithdraw}
          getUser={getUser}
        />
      )}
      {popWithdraw && (
        <Withdraw setOpen={setPopWithdraw} isOpen={popWithdraw} />
      )}
      {popUpdateEmail && (
        <UpdateEmail
          getUser={getUser}
          setOpen={setPopUpdateEmail}
          isOpen={popUpdateEmail}
        />
      )}
      {popUpdateCompany && (
        <UpdateCompany
          getUser={getUser}
          setOpen={setPopUpdateCompany}
          isOpen={popUpdateCompany}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.ul`
  margin: 10px;
  @media screen and (min-width: 768px) {
    margin: 20px;
    width: 50%;
    border-right: 1px solid #ddd;
  }
  .update_btn {
    margin-left: 15px;
  }
`;

export default UserInfoLeftBox;
