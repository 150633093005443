import React, { useEffect, useRef, useState } from "react";
import { Calendar } from "react-calendar";
import styled from "styled-components";
import "./calendar.css";
interface Props {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dt?: Date;
  setDt: any;
}

const Calender = ({ isOpen, setOpen, dt, setDt }: Props) => {
  let [isChange, setIsChange] = useState(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);
  const handleClickOutside = (event: MouseEvent) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target as Node)
    ) {
      setOpen(!isOpen);
    }
  };
  useEffect(() => {
    if (isChange) setOpen(false);
    setIsChange(true);
  }, [dt]);

  return (
    <Wrapper ref={wrapperRef}>
      <Calendar
        calendarType="US"
        formatDay={(locale, date) =>
          date.toLocaleString("kr", { day: "numeric" })
        }
        onChange={setDt}
        value={dt}
      />
    </Wrapper>
  );
};
const Wrapper = styled.article`
  z-index: 2;
`;
export default Calender;
