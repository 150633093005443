import React from "react";
import styled from "styled-components";
import Sidebar from "../nav/sidebar/desktop-sidebar";

interface Props {
  title?: string;
  children: React.ReactNode;
}

const ServicePageWrapper = ({ title, children }: Props) => {
  return (
    <Wrapper>
      <Sidebar />
      <FormWrapper>
        {title && <h3 className="form_title">{title}</h3>}
        {children}
      </FormWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  display: flex;
  height: 100vh;
`;

const FormWrapper = styled.section`
  width: 100%;
  background-color: #f8f9ff;
  overflow-y: scroll;
  padding-top: 80px;
  .form_title {
    display: none;
  }
  h3 {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
    margin: 30px;
  }
  @media screen and (min-width: 1024px) {
    padding-left: 0;
    .form_title {
      display: block;
    }
  }
`;

export default ServicePageWrapper;
