import { APICLIENT } from "../api";
const USERALARMS_API = "/api/v1/infra-web-user";

export const UserAlarms = {
  getList: async () => {
    const response = await APICLIENT.AUTH.post(`${USERALARMS_API}/userAlarms/getList`, {
      paginate: { limit: 10000 },
    });
    return response.data;
  },
};
