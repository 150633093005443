import React from "react";
import styled from "styled-components";
import RoundButton from "../../../components/button/round-button";
import InputLabel from "../../../components/input/input-label/input";
import { MAX_LENGTH } from "../../../constants/max-length";
import LoginFailed from "../../../components/input/signup/text-alert";
import { useT } from "../../../constants/i18n/useT";
import { InputService } from "../../../service/common/input.service";

interface Props {
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  mail: string;
  setMail: React.Dispatch<React.SetStateAction<string>>;
  s2Message: string;
  getCertNumber: () => void;
}
const FindPassword2 = ({
  name,
  setName,
  mail,
  setMail,
  s2Message,
  getCertNumber,
}: Props) => {
  const t = useT();
  return (
    <Wrapper>
      <Body>
        <InputLabel
          label={t("user.name", "이름")}
          maxLength={MAX_LENGTH.ID}
          value={name}
          onChange={(e) => setName(e.target.value)}
          type="text"
          placeholder={
            t("user.please_enter_your_name", "이름을 입력하세요") || ""
          }
        />
        <InputLabel
          label={t("user.email", "이메일")}
          maxLength={MAX_LENGTH.MAIL}
          onKeyDown={(e) => InputService.pressEnter(e, getCertNumber)}
          value={mail}
          onChange={(e) => setMail(e.target.value)}
          type="text"
          placeholder={
            t(
              "user.please_enter_the_email_you_used_to_sign_up",
              "가입하신 메일을 입력하세요."
            ) || ""
          }
        />
        <LoginFailed>{s2Message}</LoginFailed>
      </Body>
      <div className="login_btn">
        <RoundButton
          onClick={() => getCertNumber()}
          color="blue"
          size="full_lg"
        >
          {t("user.get_verification_code", "인증번호 받기")}
        </RoundButton>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  .login_btn {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
`;
const Body = styled.div``;

export default FindPassword2;
