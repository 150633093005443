export class ProjectFormWorkstation {
  workstation?: { workstationId?: string };
  projectWorkstationId?: number;
  useState?: { cd: "UseState.Deleted" };

  shareWith?: Array<{
    userGuid: string;
    privilegeCd?: string;
    useStateCd?: "UseState.Deleted";
  }>;

  workstations?: Array<{
    workstationId?: any;
    driveLetter?: any;
    useStateCd?: "UseState.Deleted" | undefined;
  }>;

  storages?: any[];

  constructor(
    workstationId?: string,
    workstations?: Array<{
      workstationId?: any;
      driveLetter?: any;
      useStateCd?: "UseState.Deleted" | undefined;
    }>,
    shareWith?: Array<{
      userGuid: string;
      privilegeCd?: string;
      useStateCd?: "UseState.Deleted" | undefined;
    }>
  ) {
    this.workstation = { workstationId };
    this.workstations = workstations;
    this.shareWith = shareWith;
  }
}
