import React from "react";
import { Dropdown } from "../../dropdown/Dropdown";
import { WorkstationClientService } from "../../../service/workstation/client.service";
import { useT } from "../../../constants/i18n/useT";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const ClientInstallButton = () => {
  const goSearchPage = (os: "window" | "linux" | "mac") => {
    const link = WorkstationClientService.getDownloadLink(os);
    window.location.replace(link);
  };

  const t = useT();
  const navgate = useNavigate();

  return (
    // <Dropdown arrow={false} title={t("common.client_install", "Client 설치")}>
    //   <li onClick={() => goSearchPage("linux")}>Linux</li>
    //   <li onClick={() => navgate("/download")}>Window</li>
    // </Dropdown>

    <Button onClick={() => navgate("/download")}>
      {t("common.client_install", "Client 설치")}
    </Button>
  );
};

const Button = styled.button`
  padding: 15px;
  font-size: 16px;
  border: none;
  padding-rigth: 30px;

  background: none;
  cursor: pointer;
  min-width: 140px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ClientInstallButton;
