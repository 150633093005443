import { APICLIENT } from "../api";
import { SignupForm } from "../../@types/user/signup/signup.form";
const USER_API = "/api/v1/account/user";
const MAIL_API = "/api/v1/account/mail";

export const SignUpService = {
  create: async (signupForm: SignupForm) => {
    if (signupForm.password2) delete signupForm.password2;
    const response = await APICLIENT.NONAUTH_NOALERT.post(
      `${USER_API}/create`,
      {
        data: signupForm,
      }
    );
    return response.data;
  },
  checkId: async (id: string) => {
    const response = await APICLIENT.NONAUTH_NOALERT.get(
      `${USER_API}/check/userid/${id}`
    );
    return response.data;
  },
  checkEmail: async (email: string) => {
    const response = await APICLIENT.NONAUTH_NOALERT.get(
      `${USER_API}/check/email/${email}`
    );
    return response.data;
  },
  sendRecoveryMail: async (email: string) => {
    const response = await APICLIENT.NONAUTH_NOALERT.post(
      `${MAIL_API}/sendRecoveryMail`,
      {
        mail: email,
      }
    );
    return response.data;
  },
};
