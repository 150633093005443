import React from "react";
import styled from "styled-components";
import { Project } from "../../../../../@types/project/project";
import { useT } from "../../../../../constants/i18n/useT";
import { CardDropdown } from "../../../../../components/dropdown/card-dropdown";

interface Props {
  project: Project;
  handlePopUpdate: (projectId?: number) => void;
  handlePopDelete: (projectId?: number) => void;
  editType?: "updateOnly" | "all" | "none";
}

const ProjectHeader = ({
  project,
  handlePopUpdate,
  handlePopDelete,
  editType = "all",
}: Props) => {
  const t = useT();

  return (
    <Wrapper>
      <h1>{project.name}</h1>
      {editType !== "none" && (
        <CardDropdown type="project">
          <CardDropdown.MenuElement
            title={t("common.update", "수정")}
            onClick={() => handlePopUpdate(project.projectId)}
          />
          {editType !== "updateOnly" && (
            <CardDropdown.MenuElement
              title={t("common.delete", "삭제")}
              onClick={() => handlePopDelete(project.projectId)}
            />
          )}
        </CardDropdown>
      )}
    </Wrapper>
  );
};
const Wrapper = styled.div`
  background: var(--PowerOff);
  padding: 20px 10px;
  border-bottom: 2px solid #c5c5c5;
  display: flex;
  justify-content: space-between;
  h1 {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    margin-left: 10px;
  }
`;

export default ProjectHeader;
