import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Spec } from "../../../../../../@types/infra/spec";
import { useT } from "../../../../../../constants/i18n/useT";

interface Props {
  specs: Spec[];
  setSpec: React.Dispatch<React.SetStateAction<Spec>>;
  spec: Spec;
  showSpecSelect: boolean;
  setShowSpecSelect: React.Dispatch<React.SetStateAction<boolean>>;
}

const WSCreateSpec = ({
  specs,
  setSpec,
  spec,
  showSpecSelect,
  setShowSpecSelect,
}: Props) => {
  const selectEl = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: MouseEvent) => {
    if (selectEl.current)
      if (!selectEl.current.contains(e.target as Node)) {
        setShowSpecSelect(false);
      }
  };
  useEffect(() => {
    if (showSpecSelect) {
      window.addEventListener("click", handleClickOutside);
      let El = document.getElementById("custom_select_box") as HTMLElement;
      El.style.display = "block";
    } else {
      let El = document.getElementById("custom_select_box") as HTMLElement;
      El.style.display = "none";
    }
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [showSpecSelect]);

  const select = (selectSpec: Spec) => {
    console.log(selectSpec);
    setSpec(selectSpec);
    setShowSpecSelect(false);
  };
  const t = useT();
  return (
    <Wrapper>
      <div ref={selectEl}>
        <div
          className="select_title"
          onClick={() => setShowSpecSelect(!showSpecSelect)}
          id={showSpecSelect ? "select_focus" : ""}
        >
          <span className="flex_1" id={!spec.specId ? "gray" : ""}>
            {!spec.specId ? (
              t("workstation.please_select_the_specs", "스펙을 선택해 주세요.")
            ) : (
              <li className="selected_li">
                <div>
                  <div>
                    <span id="blue">CPU</span> {spec.cpu && spec.cpu.name}
                  </div>
                  <div>
                    <span id="blue">RAM</span> {spec.ram && spec.ram.name}
                  </div>
                  {spec.gpu && (
                    <div>
                      <span id="blue">GPU</span> {spec.gpu.name}
                    </div>
                  )}
                </div>
              </li>
            )}
          </span>
          <span className="flex_2">▼</span>
        </div>
        <div className="container">
          <div id="custom_select_box">
            <ul className="custom_select_ul">
              {specs.map((spectmp, idx) => (
                <li
                  onClick={() => select(spectmp)}
                  key={idx}
                  className="select_li"
                  id={spec === spectmp ? "spec_select" : ""}
                >
                  <div>
                    <div>
                      <span id="blue">CPU</span>
                      {spectmp.cpu && spectmp.cpu.name}
                    </div>
                    <div>
                      <span id="blue">RAM</span>
                      {spectmp.ram && spectmp.ram.name}
                    </div>
                    <span id="blue">GPU</span>
                    {spectmp.gpu ? (
                      spectmp.gpu.name
                    ) : (
                      <span className="empty">-</span>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.dd`
  .container {
    position: absolute;
    z-index: 2;
  }
  .select_title {
    width: 200px;
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    text-align: center;
    padding: 0 10px;
    height: 60px;
    margin-top: 10px;
    cursor: pointer;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .flex_1 {
      width: 200px;
      text-align: start;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
    }
  }
  #gray {
    color: rgb(163, 163, 163);
  }
  #blue {
    margin-right: 5px;
  }
  .select_title .flex_2 {
    width: 20px;
    color: rgb(135, 135, 135);
  }

  #custom_select_box {
    background-color: #ffffff;
    border: 1.5px solid rgb(191, 191, 191);
    border-radius: 8px;
    padding: 10px;
    height: 220px;
    width: 200px;
    margin: auto;
    display: block;
  }
  .custom_select_ul {
    overflow-y: scroll;
    width: 200px;
    height: 220px;
    &::-webkit-scrollbar {
      background-color: rgb(255, 255, 255);
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(163, 163, 163, 0.514);
      border-radius: 10px;
      height: 10px;
    }
  }
  .select_li {
    background-color: rgb(235, 235, 235);
    width: 90%;
    font-size: 10px;
    border-radius: 5px;
    margin: 12px auto;
    padding: 5px 5px;
    height: 60px;
    text-align: left;
    cursor: pointer;
    div div {
      padding: 2px 0;
    }
    &:hover {
      background-color: rgba(196, 196, 196, 0.579);
      cursor: pointer;
    }
    .empty {
      padding-left: 30px;
      font-weight: 900;
    }
  }
  .selected_li {
    background-color: rgb(255, 255, 255);
    width: 100%;
    font-size: 10px;
    margin: auto;
    height: 50px;
    text-align: left;
    cursor: pointer;
    div div {
      padding: 2px 0;
    }
  }
  @media (min-width: 768px) {
    .select_title {
      width: 240px;
    }
    .flex_1 {
      width: 240px;
    }
    #custom_select_box {
      width: 240px;
    }
    .custom_select_ul {
      width: 240px;
    }
  }
`;

export default WSCreateSpec;
