import React from "react";
import styled from "styled-components";
import { Element as ScrollElement } from "react-scroll";
import { useT, useCurrentLanguage } from "../../../constants/i18n/useT";

const Workstation = () => {
  const t = useT();
  const currentLanguage = useCurrentLanguage();

  return (
    <Wrapper>
      <ScrollElement name="workstation1">
        <Title>1. {t("common.workstation_creation", "워크스테이션 생성")}</Title>
        <Spacer />
        <Paragraph>
          {t(
            "common.guide_workstation1",
            `로그인을 하면 워크스테이션(workstation) 페이지로 연결이 되며, 현재 생성된 워크스테이션을 조회하여 카드 형식으로 보여지게 됩니다. 최초 로그인 시에는 생성된 워크스테이션이 없기 때문에 워크스테이션 카드가 보이지 않습니다.`
          )}
        </Paragraph>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_workstation2",
            `워크스테이션 생성을 위해선 “Workstation 생성” 버튼을 클릭합니다. 버튼을 클릭하면 다음의 워크스테이션 생성 UI가 팝업됩니다. 워크스테이션의 이름, 스펙, OS, SW, 부팅디스크 크기, 스토리지(storage)를 입력 혹은 선택하고 “추가” 버튼을 클릭합니다. 사전에 생성된 스토리지가 없는 경우 “사용가능한 storage가 없습니다”라는 메세지가 나옵니다. 스토리지는 워크스테이션 생성 이후에도 생성 및 마운트가 가능합니다. (스토리지 생성 및 워크스테이션 수정 참고)`
          )}
        </Paragraph>

        <Spacer />

        <ImageWrapper>
          <HeightImage
            src={`/assets/guide/${
              currentLanguage === "ko_KR" ? "create-ws.png" : "en/en_create-ws.png"
            }`}
            alt="create-ws"
          />
        </ImageWrapper>

        <Paragraph>
          {t(
            "common.guide_workstation3",
            `워크스테이션 생성이 성공하면 아래의 윈도우가 팝업되며 “Workstation 시작” 을 클릭하면 즉시 워크스테이션이 부팅(부팅)됩니다. ‘닫기” 버튼을 클릭하면 생성된 워크스테이션에 대한 카드가 만들어 지고 원하는 때 워크스테이션을 시작할 수 있습니다.`
          )}
        </Paragraph>

        <ImageWrapper>
          <HeightImage
            src={`/assets/guide/${
              currentLanguage === "ko_KR" ? "create-ws2.png" : "en/en_create-ws2.png"
            }`}
            alt="create-ws2"
          />
        </ImageWrapper>
      </ScrollElement>

      <ScrollElement name="workstation2">
        <Title>
          2. {t("common.workstation_retrieval_and_execution", "워크스테이션 조회 및 실행")}
        </Title>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_workstation4",
            `워크스테이션 페이지에서는 사용자가 생성한 모든 워크스테이션이 카드 형태로 표시됩니다. 워크스테이션의 실행 상태를 기준으로 워크스테이션 표시가 가능하여 ‘모두’, ‘실행중’, ‘중지’, ‘준비중’의 옵션이 제공됩니다.`
          )}
        </Paragraph>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_workstation5",
            `워크스테이션 접속을 위해선 해당 워크스테이션이 카드에서 전원켜기 버튼을 클릭합니다. 해당 워크스테이션 실행 확인을 위한 UI가 팝업되며 “실행”을 클릭하면 워크스테이션이 구동을 시작합니다. ‘닫기’를 클릭하면 워크스테이션 실행이 취소됩니다.`
          )}
        </Paragraph>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_workstation6",
            `워크스테이션 카드의 헤더의 색깔이 연두색으로 변하고 워크스테이션의 상태가 ‘Booting’으로 바뀌게 됩니다.`
          )}
        </Paragraph>

        <Spacer />

        <ImageWrapper>
          <HeightImage
            src={`/assets/guide/${
              currentLanguage === "ko_KR" ? "ws-boot.png" : "en/en_ws-boot.png"
            }`}
            alt="ws-boot"
          />
        </ImageWrapper>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_workstation7",
            `‘Booting’은 약 2-3분 소요되며 ‘Booting’이 완료되면 워크스테이션의 상태는 ‘Running’으로 변경되고 워크스테이션 원격 접속 클라이언트 실행을 위한 UI가 팝업됩니다. 이때, ‘실행’을 클릭하면 클라이언트가 실행됩니다. ‘닫기’를 클릭하면 팝업 윈도우가 사라집니다. 팝업 윈도우가 사라지더라도 워크스테이션 카드의 ‘connect’ 버튼 클릭을 통해 클라이언트 실행이 가능합니다.`
          )}
        </Paragraph>

        <Spacer />

        <ImageWrapper>
          <HeightImage
            src={`/assets/guide/${
              currentLanguage === "ko_KR" ? "ws-boot2.png" : "en/en_ws-boot2.png"
            }`}
            alt="ws-boot2"
          />
        </ImageWrapper>
      </ScrollElement>

      <ScrollElement name="workstation3">
        <Title>3. {t("common.connection_to_workstation", "워크스테이션 원격 접속 및 해제")}</Title>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_workstation8",
            `워크스테이션에 원격으로 접속하기 위해선 사용자의 로컬 환경에 M2M 클라이언트이 먼저 설치되어 있어야 합니다. 클라이언트가 설치되지 않았다면 웹페이지 오른쪽 위의 ‘client 설치’ 버튼에 마우스를 가져가 클라이언트 인스톨 프로그램을 다운로드 받을 수 있습니다. 클라이언트는 윈도우즈와 맥 OS, 리눅스(Ubuntu) 환경에 설치가 가능합니다.`
          )}
        </Paragraph>

        <Spacer />

        <ImageWrapper>
          <HeightImage
            src={`/assets/guide/${
              currentLanguage === "ko_KR" ? "client-install.png" : "en/en_client-install.png"
            }`}
            alt="client-install"
          />
        </ImageWrapper>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_workstation9",
            `클라이언트가 설치되어 있고 워크스테이션 클라이언트 실행 UI에서 실행 버튼 혹은 워크스테이션 카드의 활성화된 ‘connect’ 버튼을 클릭하면 클라이언트를 실행하게 됩니다. 이때, 클라이언트 실행을 허용할 것인지 묻는 메세지가 브라우저에서 아래와 같이 팝업되고 ‘열기’ 버튼을 클릭하여 클라이언트를 실행합니다.`
          )}
        </Paragraph>

        <Spacer />

        <ImageWrapper>
          <OriginalImage
            src={`/assets/guide/${
              currentLanguage === "ko_KR" ? "client-open.png" : "en/en_client-open.png"
            }`}
            alt="client-open"
          />
        </ImageWrapper>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_workstation10",
            `M2M 클라이언트 실행 확인 윈도우클라이언트가 실행되면 워크스테이션 접속을 위하여 사용자의 비밀번호를 입력하게 됩니다(아래그림). M2M 서비스 로그인과 동일한 비밀번호를 입력하고 로그인 버튼을 클릭하면 워크스테이션에 접속하게 됩니다.`
          )}
        </Paragraph>

        <Spacer />

        <ImageWrapper>
          <OriginalImage
            src={`/assets/guide/${
              currentLanguage === "ko_KR" ? "client-login.png" : "en/en_client-login.png"
            }`}
            alt="client-login"
          />
        </ImageWrapper>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_workstation11",
            `아래는 워크스테이션에 접속한 클라이언트 윈도우의 캡쳐 이미지입니다. 윈도우의 사이즈를 조정함에 따라 워크스테이션 화면 해상도가 자동으로 변경되고 전체화면 모드로도 사용이 가능합니다(보기(View) > Show Full Screen One Monitor 선택)`
          )}
        </Paragraph>

        <Spacer />

        <ImageWrapper>
          <OriginalImage
            src={`/assets/guide/${
              currentLanguage === "ko_KR" ? "connected-ws.jpeg" : "en/en_connected-ws.jpeg"
            }`}
            alt="connected-ws"
          />
        </ImageWrapper>

        <Paragraph>
          {t(
            "common.guide_workstation12",
            `워크스테이션의 원격 접속을 해제하기 위해선 M2M 클라이언트 메뉴바에서 연결(Connection) > 연결해제(Disconnect)를 클릭합니다. 워크스테이션 원격 접속이 해제되었어도 워크스테이션은 계속하여 동작 중입니다. 워크스테이션을 완전히 종료하기 위해선 M2M 서비스 페이지에서 해당 워크스테이션 카드에서 접속 종료 버튼을 클릭해야 합니다 (워크스테이션 종료 참고).`
          )}
        </Paragraph>

        <Spacer />

        <ImageWrapper>
          <WidthImage
            src={`/assets/guide/${
              currentLanguage === "ko_KR" ? "disconnect-ws.png" : "en/en_disconnect-ws.png"
            }`}
            alt="disconnect-ws"
          />
        </ImageWrapper>
      </ScrollElement>

      <ScrollElement name="workstation4">
        <Title>4. {t("common.shut_down_workstation", `워크스테이션 종료`)}</Title>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_workstation13",
            ` 실행 중인 워크스테이션을 종료하기 위해선 M2M 서비스내의 워크스테이션 카드에서 종료버튼(아래 이미지 참고)을 클릭하여야 합니다. 워크스테이션을 장기간 사용할 경우 M2M 서비스의 로그인 세션이 만료가 될 수 있는데 이 경우 재로그인이 필요합니다. 사용자 이미지 보존을 위해 2-3분 정도의 시간 후에 워크스테이션이 완전 종료 됩니다. 완전 종료 시까지 해당 워크스테이션의 사용이 제한됩니다.`
          )}
        </Paragraph>

        <Spacer />

        <ImageWrapper>
          <WidthImage
            src={`/assets/guide/${currentLanguage === "ko_KR" ? "off-ws.png" : "en/en_off-ws.png"}`}
            alt="off-ws"
          />
        </ImageWrapper>
      </ScrollElement>

      <Spacer />

      <ScrollElement name="workstation5">
        <Title>5. {t("common.workstation_editing_and_sharing", `워크스테이션 수정 및 공유`)}</Title>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_workstation14",
            `워크스테이션 카드의 오른쪽 위의 곳에 마우스를 가져가고 ‘수정’을 클릭하면 워크스테이션의 이름과 스펙, 스토리지 마운트 설정을 변경할 수 있는 윈도우가 팝업됩니다.`
          )}
        </Paragraph>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_workstation15",
            `워크스테이션 이름은 기존 워크스테이션과 중복되지 않은 이름으로 재설정이 가능합니다. 워크스테이션 스펙도 필요에 따라 상향 혹은 하향 조정이 가능합니다. 워크스테이션 스펙이 변경되면 이에 따라 사용 요금도 자동으로 변경됩니다.`
          )}
        </Paragraph>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_workstation16",
            `스토리지 마운트를 위해선 리스트에서 스토리지를 선택하고 드라이브 레터를 설정합니다. 사용자 자신이 생성한 스토리지는 자동으로 리스트되고 다른 사용자가 현 사용자에게 공유 설정한 스토리지도 같이 보여 집니다.`
          )}
        </Paragraph>

        <ImageWrapper>
          <WidthImage
            src={`/assets/guide/${
              currentLanguage === "ko_KR" ? "ws-edit.png" : "en/en_ws-edit.png"
            }`}
            alt="ws-edit"
          />
        </ImageWrapper>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_workstation17",
            `워크스테이션을 구동하면 새로운 스토리지 마운트 설정이 즉시 반영되고 워크스테이션이 실행 중이라면 수 초이내에 선택한 스토리지가 마운트 됩니다. 스토리지 리스트에서 선택을 해지하면 스토리지는 워크스테이션에서 언마운트됩니다. 스토리지의 생성 및 권한 설정은 다음 절(스토리지 사용)을 참고하면 됩니다.`
          )}
        </Paragraph>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_workstation18",
            `자신이 만든 워크스테이션을 타인과 공유도 가능합니다. 이를 위해선 워크스테이션 카드에서 ‘공유’를 클릭합니다. 팝업된 Workstation 사용자 설정 윈도우에서 사용자를 검색하고 화살표를 이용하여 워크스테이션 공유 사용자 리스트에서 추가하고 저장을 클릭합니다. 워크스테이션의 공유 사용자로 설정된 사용자는 자신의 M2M 페이지에서 해당 워크스테이션 카드가 보여지며 해당 워크스테이션를 시작, 접속, 종료할 수 있게 됩니다. 워크스테이션을 공유 사용자가 사용할 경우 요금은 워크스테이션을 생성한 사용자에게 부과가 됩니다.`
          )}
        </Paragraph>

        <Spacer />

        <ImageWrapper>
          <WidthImage
            src={`/assets/guide/${
              currentLanguage === "ko_KR" ? "ws-share.png" : "en/en_ws-share.png"
            }`}
            alt="ws-share"
          />
        </ImageWrapper>
      </ScrollElement>

      <Spacer />

      <ScrollElement name="workstation6">
        <Title>6. {t("common.workstation_deletion", `워크스테이션 삭제`)}</Title>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_workstation19",
            `워크스테이션 카드의 오른쪽 위의 곳에 마우스를 가져가면 아래 그림과 같이 ‘수정’, ‘삭제’ 메뉴가 나오고 ‘삭제’를 클릭하면 삭제 의사를 재확인 하는 윈도우가 팝업되고 ‘삭제’를 클릭하면 워크스테이션이 최종 삭제됩니다. 이때, ‘닫기’를 클릭하면 워크스테이션 삭제가 취소 됩니다.`
          )}
        </Paragraph>

        <Spacer />

        <ImageWrapper>
          <WidthImage
            src={`/assets/guide/${
              currentLanguage === "ko_KR" ? "delete-ws.png" : "en/en_delete-ws.png"
            }`}
            alt="delete-ws"
          />
        </ImageWrapper>
      </ScrollElement>
    </Wrapper>
  );
};
export default Workstation;

const Wrapper = styled.div`
  padding: 25px;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 20px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

const Paragraph = styled.p`
  font-size: 16px;
  line-height: 1.5;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 13px;
  }
`;

const Spacer = styled.div`
  height: 20px;

  @media (max-width: 768px) {
    height: 15px;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    margin-top: 30px;
    margin-bottom: 40px;
  }

  @media (max-width: 480px) {
    margin-top: 20px;
    margin-bottom: 30px;
  }
`;

const ResponsiveImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const BigImage = styled(ResponsiveImage)`
  height: 400px;

  @media (max-width: 768px) {
    height: 300px;
  }

  @media (max-width: 480px) {
    height: 200px;
  }
`;

const WidthImage = styled(ResponsiveImage)`
  width: 800px;

  @media (max-width: 1024px) {
    width: 600px;
  }

  @media (max-width: 768px) {
    width: 400px;
  }

  @media (max-width: 480px) {
    width: 300px;
  }
`;

const HeightImage = styled(ResponsiveImage)`
  height: 650px;

  @media (max-width: 1024px) {
    height: 500px;
  }

  @media (max-width: 768px) {
    height: 400px;
  }

  @media (max-width: 480px) {
    height: 300px;
  }
`;

const OriginalImage = styled(ResponsiveImage)``;
