import { DefaultUserUiData } from "../../@types/project/ui/user";

export const MemberService = {
  isDuplicateInTarget: (
    user: { userGuid: string },
    targetUsers: DefaultUserUiData[]
  ) => {
    const index = targetUsers.findIndex((u) => u.userGuid === user.userGuid);
    if (index === -1) return false;
    return true;
  },

  findIndex: (
    user: { userGuid?: string },
    targetUsers: { userGuid?: string }[]
  ) => {
    return targetUsers.findIndex((u) => u.userGuid === user.userGuid);
  },
};
