import React from "react";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
}

const TabWrapper = ({ children }: Props) => {
  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.nav`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 55px;
  border-bottom: 1px solid #ddd;
  padding: 10px;
  padding-top: 50px;
  @media (min-width: 1024px) {
    padding: 20px;
  }
`;
export default TabWrapper;
