import { useRecoilState } from "recoil";
import { ProjectUserUiData } from "../../../@types/project/ui/user";
import { WorkstationService } from "../../../service/workstation/workstation.service";
import { useAlert } from "../../../components/alert/alert.service";
import {
  WorkstationUpdateUser,
  WorkstationUpdateUserForm,
} from "../../../@types/workstation/form/user";
import { userState } from "../../../constants/recoil/user";
import { t } from "i18next";
import { WorkstationUpdateService } from "../../../service/workstation/update.service";
import { ProjectUserMode } from "../../../@types/project/ui/user-search-mode";
import { WorkstationMemberResponse } from "../../../@types/workstation/response";

interface Props {
  projectId?: number;
  setRightUsers: React.Dispatch<React.SetStateAction<ProjectUserUiData[]>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setLeftUsers: React.Dispatch<React.SetStateAction<ProjectUserUiData[]>>;
  leftUsers: ProjectUserUiData[];
  rightUsers: ProjectUserUiData[];
  workstationId: string;
  getList: () => void;
  uwHostname: string;
  setUwHostname: React.Dispatch<React.SetStateAction<string>>;
  searchUsers: ProjectUserUiData[];
  setSearchUsers: React.Dispatch<React.SetStateAction<ProjectUserUiData[]>>;
}
export const _workstationMember = ({
  projectId,
  setRightUsers,
  setIsLoading,
  setLeftUsers,
  leftUsers,
  rightUsers,
  workstationId,
  getList,
  uwHostname,
  setUwHostname,
  searchUsers,
  setSearchUsers,
}: Props) => {
  const [myUser] = useRecoilState(userState);

  const { setAlert, setSuccess } = useAlert();

  const getWorkstation = () => {
    let projectId: number;

    WorkstationService.getWorkstationById(workstationId)
      .then(async res => {
        projectId = res.data.project.projectId;
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          setAlert(t("workstation.not_found_ws", "워크스테이션 조회에 실패했습니다."));

          return;
        }

        if (error.response && error.response.status === 500) {
          setAlert(t("common.error", "현재 서버가 원활하지 않습니다. 잠시 후 시도해주세요."));

          return;
        }
      });

    WorkstationService.getById(workstationId)
      .then(async res => {
        if (res.state === "success") {
          if (res.data) {
            setUwHostname(res.data.uwHostname || "");
            const NewleftUsers = hadleLeftUserToUiData(res.data.users);
            setLeftUsers(NewleftUsers);
            setRightUsers(
              await WorkstationUpdateService.getMembers({
                projectId: projectId,
                companyGuid: myUser.company && myUser.company.companyGuid,
                ownerUserGuid: myUser.userGuid,
                leftUsers: NewleftUsers,
              })
            );
          }
        }
      })
      .finally(() => setIsLoading(false));
  };

  const hadleLeftUserToUiData = (users: WorkstationMemberResponse[]): ProjectUserUiData[] => {
    const newUsers = users.map(_user => {
      const newUser = new ProjectUserUiData(_user.user);
      newUser.workstationUserId = _user.workstationUserId;
      return newUser;
    });
    return newUsers;
  };
  const isDuplicateInTarget = (
    user: { userGuid?: string },
    targetUsers: { userGuid?: string }[]
  ) => {
    const index = targetUsers.findIndex(u => u.userGuid === user.userGuid);
    if (index === -1) return false;
    return true;
  };
  const toLeft = (mode: ProjectUserMode): void => {
    if (mode === ProjectUserMode.COMPANY) {
      let newLeftUsers = [...leftUsers];
      let newRightUsers = [...rightUsers];
      rightUsers.forEach(user => {
        if (!user.isChecked) return;
        if (user.deleted) {
          const index = newLeftUsers.findIndex(u => u.workstationUserId === user.workstationUserId);
          newLeftUsers[index] = {
            ...user,
            isChecked: false,
            deleted: false,
          };
        } else {
          if (!isDuplicateInTarget(user, newLeftUsers)) {
            newLeftUsers.push({ ...user, isChecked: false });
          } else {
            setAlert(t("project.", "이미 추가된 멤버입니다."));
          }
        }
        newRightUsers = newRightUsers.filter(u => u.userGuid !== user.userGuid);
      });
      setLeftUsers(newLeftUsers);
      setRightUsers(newRightUsers);
    }
    if (mode === ProjectUserMode.SEARCH) {
      let newLeftUsers = [...leftUsers];
      searchUsers.forEach(user => {
        if (!user.isChecked) return;
        if (isDuplicateInTarget(user, newLeftUsers)) return;
        newLeftUsers.push({ ...user, isChecked: false });
      });
      setLeftUsers(newLeftUsers);
      setSearchUsers([]);
    }
  };

  const toRight = () => {
    let newLeftUsers = [...leftUsers];
    let newRightUsers = [...rightUsers];

    leftUsers.forEach(user => {
      if (!user.isChecked) return;
      if (user.workstationUserId) {
        newRightUsers.push({ ...user, isChecked: false, deleted: true });
        const index = newLeftUsers.findIndex(u => u.workstationUserId === user.workstationUserId);
        newLeftUsers[index] = {
          ...user,
          isChecked: false,
          deleted: true,
        };
      } else {
        newLeftUsers = newLeftUsers.filter(u => u.userGuid !== user.userGuid);
        if (!isDuplicateInTarget(user, newRightUsers)) {
          newRightUsers.push({ ...user, isChecked: false });
        }
      }
      setLeftUsers(newLeftUsers);
      setRightUsers(newRightUsers);
    });

    setLeftUsers(newLeftUsers);
  };

  const onSubmit = () => {
    let newUsers: WorkstationUpdateUser[] = [];
    leftUsers.map(user => {
      const newUser = new WorkstationUpdateUser(user.userGuid);
      if (user.workstationUserId) {
        newUser.workstationUserId = user.workstationUserId;
        if (user.deleted) {
          newUser.useState = { cd: "UseState.Deleted" };
          newUsers.push(newUser);
          return;
        }
        return;
      }
      return newUsers.push(newUser);
    });

    let newForm = new WorkstationUpdateUserForm(workstationId);
    newForm.users = newUsers;
    newForm.uwHostname = uwHostname;

    WorkstationService.updateUser(newForm)
      .then(res => {
        setSuccess(
          t(
            "workstation.workstation_user_has_been_mdified",
            "Workstation 사용자가 성공적으로 수정되었습니다."
          )
        );
        getWorkstation();
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          setAlert(t("workstation.not_found_ws", "워크스테이션 조회에 실패했습니다."));

          return;
        }

        if (error.response && error.response.status === 500) {
          setAlert(t("common.error", "현재 서버가 원활하지 않습니다. 잠시 후 시도해주세요."));

          return;
        }
      })
      .finally(() => {
        getList();
      });
  };

  return {
    getWorkstation,
    toRight,
    toLeft,
    onSubmit,
  };
};
