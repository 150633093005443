import React from "react";
import styled from "styled-components";
import RoundButton from "../../../components/button/round-button";
import { useNavigate } from "react-router-dom";
import { useT } from "../../../constants/i18n/useT";

interface Props {
  id: string;
  createDate: string;
}
const FindId3 = ({ id, createDate }: Props) => {
  const navigate = useNavigate();
  const t = useT();
  return (
    <Wrapper>
      <Body>
        <p>
          ID : <span id="blue">{id}</span>
        </p>
        <p>
          {t("user.creation_date", "생성일")} : {createDate}
        </p>
      </Body>
      <div className="login_btn">
        <RoundButton
          onClick={() => navigate("/login")}
          color="blue"
          size="full_lg"
        >
          {t("user.sign_in", "로그인")}
        </RoundButton>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  .login_btn {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
`;
const Body = styled.div`
  height: 120px;
  padding-top: 20px;
  p {
    font-size: 15px;
    color: #666666;
    margin-bottom: 10px;
  }
`;
export default FindId3;
