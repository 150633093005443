export enum CreditType {
  ALL = "All",
  STORAGE = "Storage",
  WORKSTATION = "Workstation",
  IMAGE = "Image",
}

// 사용요금
export interface CreditBook {
  project?: {
    projectId?: number;
    name?: string;
  };
  resourceId?: string;
  resourceSize?: string;
  name?: string;
  chargingStartDt?: string;
  chargingEndDt?: string;
  duration?: number;
  credit?: number;
  balance?: number;
  creditBookType?: string;
  startDt?: {
    date: string;
    time: string;
  };
  EndDt?: {
    date: string;
    time: string;
  };
}
