import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CreditBook, CreditType } from "../../../@types/billing/credit";
import { useT } from "../../../constants/i18n/useT";
import CreditSearch from "./components/search";
import ProjectSearch from "./components/project-search";
import SelectDate from "./components/select-date";
import SelectType from "./components/select-type";
import TableBody from "./components/table-body";
import TableHeader from "./components/table-header";
import PaginationWrapper from "../../../components/pagenation/pagenationWrapper";
import { CreditService } from "../../../service/billing/credit.service";
import NewPagenation from "../../../components/pagenation/new-pagenation";
import usePagination from "../../../components/pagenation/usePagenation";
import LineChart from "./components/chart";
import { AiOutlineLineChart } from "@react-icons/all-files/ai/AiOutlineLineChart";
import { useRecoilState } from "recoil";
import { userState } from "../../../constants/recoil/user";

const CreditHistory = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [billings, setBillings] = useState<CreditBook[]>([]);
  const [originBillings, setOriginBillings] = useState<CreditBook[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [projectTerm, setProjectTerm] = useState("");
  const [selectedType, setSelectedType] = useState<CreditType>(CreditType.ALL);
  const [showChart, setShowChart] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedDate, setSelectedDate] = useState("202408");
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [user, setUser] = useRecoilState(userState);

  const t = useT();

  useEffect(() => setOriginBillings(billings), [isLoading]);

  useEffect(() => {
    let filteredBillings = originBillings;

    // if (searchTerm) {
    //   filteredBillings = filteredBillings.filter(
    //     billing => billing.name && billing.name.toLowerCase().includes(searchTerm.toLowerCase())
    //   );
    // }

    // if (projectTerm) {
    //   filteredBillings = filteredBillings.filter(
    //     billing =>
    //       billing.project &&
    //       billing.project.name &&
    //       billing.project.name.toLowerCase().includes(projectTerm.toLowerCase())
    //   );
    // }

    // if (selectedType !== CreditType.ALL) {
    //   filteredBillings = filteredBillings.filter(
    //     billing => billing.creditBookType === selectedType
    //   );
    // }

    // setBillings(billings);

    loadBillings();
  }, [
    searchTerm,
    projectTerm,
    selectedType,
    // originBillings,
    currentPage,
    itemsPerPage,
    selectedDate,
  ]);

  const headers = [
    { title: "No.", width: 6 },
    { title: t("billing.date", "날짜"), width: 8 },
    { title: t("billing.target_name", "대상 이름"), width: 12 },
    { title: t("billing.usage_time", "이용 시간"), width: 15 },
    { title: t("billing.elapsed_time", "경과 시간"), width: 13 },
    { title: t("billing.type", "타입"), width: 9 },
    { title: t("billing.specs", "스펙"), width: 10 },
    { title: t("billing.project", "Project"), width: 9 },
    { title: t("billing.change", "증감"), width: 8 },
    { title: t("billing.credit", "Credit"), width: 10 },
  ];

  const loadBillings = async () => {
    setIsLoading(true);

    try {
      const params = new URLSearchParams();

      params.append("chargingMonth", selectedDate);

      if (selectedType && selectedType !== CreditType.ALL) {
        params.append("type", selectedType);
      }

      if (searchTerm) {
        params.append("name", searchTerm);
      }

      if (projectTerm) {
        params.append("projectName", projectTerm);
      }

      params.append("page", (currentPage - 1).toString());
      params.append("size", itemsPerPage.toString());

      const queryString = params.toString();

      const response = await CreditService.getCreditList(user.userGuid, queryString);

      setBillings(response.content);
      setTotalCount(response.totalElements);
    } catch (error) {
      console.error("Failed to load billings:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const handleItemsPerPageChange = (value: number) => {
    setItemsPerPage(value);
    setCurrentPage(1);
  };

  return (
    <Wrapper>
      <div className="user_right_con my_history_wrap">
        {showChart && <LineChart setShowChart={setShowChart} billings={billings} />}
        <Notice>
          ※
          {t(
            "billing.the_value_of_resource_usages",
            "자원 사용 종료, 혹은 6시간마다 업데이트 됩니다."
          )}
          {t(
            "billing.credit_not_match_the_realtime",
            "자원사용 종료 및 충전의 결과에 대한  credit 은 실시간 값과 일치하지 않을 수 있습니다."
          )}
          {t(
            "billing.you_can_check_your_current_credit",
            "현재 credit은 '내 정보'에서 확인 가능합니다."
          )}
        </Notice>
        <Filter>
          <div className="flex_end">
            <CreditSearch setSearchTerm={setSearchTerm} />
            <ProjectSearch setProjectTerm={setProjectTerm} />
            <SelectTypeWrapper>
              <SelectType
                setIsLoading={setIsLoading}
                setBillings={setBillings}
                setSelectedType={setSelectedType}
              />
            </SelectTypeWrapper>
            <SelectDate setIsLoading={setIsLoading} setSelectedDate={setSelectedDate} />
            <ChartIcon onClick={() => setShowChart(prev => !prev)} />
          </div>
        </Filter>
        <HistoryTable>
          <TableHeader headers={headers} />
          <TableBody isLoading={isLoading} billings={billings} headers={headers} />
        </HistoryTable>

        <NewPagenation
          totalCount={totalCount}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          itemsPerPage={itemsPerPage}
          handleItemsPerPageChange={handleItemsPerPageChange}
        />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .credit_date {
    font-weight: 700;
    text-align: center;
  }
`;

const Filter = styled.nav`
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  margin-left: 10px;
  align-items: center;
  height: 75px;

  .flex_end {
    display: flex;
    justify-content: end;
  }
`;

const Notice = styled.span`
  color: #fe3939c7;
  padding-left: 20px;
  margin-left: 20px;
  display: block;
  margin-top: 20px;
`;

const SelectTypeWrapper = styled.div`
  // margin-top: 15px;
  margin-right: 35px;
  // margin-left: 25px;
`;

const HistoryTable = styled.section`
  width: 95%;
  margin: auto;
  th,
  td {
    padding: 0 10px;
    height: 50px;
    position: relative;
  }
`;

const ChartIcon = styled(AiOutlineLineChart)`
  font-size: 27px;
  color: #ffffff;
  cursor: pointer;
  margin-left: 40px;
  margin-top: 35px;
  background-color: #017bfb;
  border-radius: 5px;
  padding: 5px;

  &:hover {
    color: #ffffff;
    background-color: #0056b3;
  }
`;

export default CreditHistory;
