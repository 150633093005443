import React, { useEffect, useState } from "react";
import styled from "styled-components";
import UserContactModal from "./create/modal";
import Button from "../../../components/button/Button";
import ContactList from "./components/list";
import { useT } from "../../../constants/i18n/useT";
import { Contact } from "../../../@types/user/contact";
import { ContactService } from "../../../service/contact/contact.service";
import { IoMdSearch } from "@react-icons/all-files/io/IoMdSearch";

const ContactIndex = () => {
  const [popCreate, setPopCreate] = useState(false);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [filteredContacts, setFilteredContacts] = useState<Contact[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState("");

  const getList = () => {
    ContactService.getList()
      .then(res => {
        if (res.state === "success") {
          console.log(res.data);
          setContacts(res.data);
          setFilteredContacts(res.data);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value.toLowerCase();
    setValue(searchValue);
    setFilteredContacts(
      contacts.filter(contact => contact.title && contact.title.toLowerCase().includes(searchValue))
    );
  };

  useEffect(() => getList(), []);
  const t = useT();

  return (
    <div className="user_right_con my_history_wrap">
      <Wrapper>
        <div className="button_box">
          <SearchBar>
            <input
              placeholder={
                t("user.please_enter_the_word_you_are_looking_for", "찾으시는 단어를 입력하세요") ||
                ""
              }
              onChange={handleSearch}
              value={value}
            />
            <button>
              {/* <img src="/assets/user/search.png" /> */}
              <IoMdSearch size={24} />
            </button>
          </SearchBar>
          <ButtonBox>
            <Button color="primary" onClick={() => setPopCreate(true)}>
              {t("user.inquire", "문의하기")}
            </Button>
          </ButtonBox>
        </div>
        <ContactList isLoading={isLoading} contacts={filteredContacts} />
      </Wrapper>
      {popCreate && !isLoading && (
        <UserContactModal getList={getList} isOpen={popCreate} setOpen={setPopCreate} />
      )}
    </div>
  );
};

const Wrapper = styled.section`
  width: 100%;
  text-align: center;
  .title {
    margin: 30px;
    font-size: 2rem;
  }
  .button_box {
    display: flex;
    justify-content: end;
    padding-top: 20px;
    padding-right: 30px;
  }
`;

const ButtonBox = styled.div`
  margin-left: 30px;
`;

const SearchBar = styled.article`
  width: 250px;
  border-bottom: 1px solid #aaaaaa;
  input {
    width: 200px;
    padding-left: 5px;
    height: 40px;
    box-sizing: border-box;
    position: relative;
    border: 0;
    line-height: 40px;
    background-color: transparent;
  }
  button {
    background: none;
    border: none;
  }
`;

const Category = styled.ul`
  display: flex;
  margin: auto;
  justify-content: center;

  li {
    padding: 10px 20px;
    font-size: 1.2rem;
    cursor: pointer;
    &.selected {
      font-weight: 600;
      text-decoration: underline;
      color: var(--GridaBlue);
    }
  }
`;

export default ContactIndex;
