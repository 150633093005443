import React from "react";

import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useT } from "../../constants/i18n/useT";

const Footer = () => {
  const navigate = useNavigate();
  const t = useT();

  return (
    <Wrapper>
      <div className="footer">
        <p onClick={() => navigate("/policy")} className="link">
          {t("common.terms_of_service", "이용약관")} |{" "}
          {t("common.privacy_policy", "개인정보 처리방침")}{" "}
        </p>
        <p>Copyright ⓒ M2M All rights reserved 그리다 주식회사 ( Business registration number: 289-81-02615 ) </p>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.footer`
  width: 100%;
  .footer {
    padding: 20px 0;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70px;
    p {
      margin-top: 10px;
    }
    .link {
      cursor: pointer;
      margin-right: 15px;
      font-weight: 600;
    }
    @media (min-width: 1024px) {
      flex-direction: row;
      justify-content: flex-start;
      padding: 0 30px;
    }
  }
`;

export default Footer;
