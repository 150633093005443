import { Code } from "../infra/code";

export class Price {
  size?: string;
  spec?: {
    cpu?: Code;
    ram?: Code;
    gpu?: Code;
  };
  unitPrice?: number;
  reserved1YearPrice?: number;
  reserved2YearPrice?: number;
  monthBill?: number;
}
