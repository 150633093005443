import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useT } from "../../../constants/i18n/useT";

const LoginFooter = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const t = useT();

  const goPage = (path: string) => () => {
    navigate(path);
  };

  return (
    <Wrapper>
      <ul className="login_list">
        {pathname !== "/login" && (
          <li onClick={goPage("/login")}>{t("user.sign_in", "로그인")}</li>
        )}

        {pathname !== "/find/id" && (
          <li onClick={goPage("/find/id")}>
            {t("user.find_id", "아이디 찾기")}
          </li>
        )}

        {pathname !== "/find/password" && (
          <li onClick={goPage("/find/password")}>
            {t("user.find_password", "비밀번호 찾기")}
          </li>
        )}
        <li onClick={goPage("/signup")}>{t("user.sign_up", "회원가입")}</li>
      </ul>

      <ul className="policy_list">
        <li onClick={goPage("/policy")}>
          {" "}
          {t("common.terms_of_service", "이용약관")}
        </li>
        <li>|</li>
        <li onClick={goPage("/policy")}>
          {" "}
          {t("common.privacy_policy", "개인정보 처리방침")}
        </li>
      </ul>
      <p className="reserved">ⓒ M2M All rights reserved </p>
    </Wrapper>
  );
};

const Wrapper = styled.footer`
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #eaeaea;
  padding-top: 15px;
  margin-top: 20px;
  text-align: center;
  .login_list {
    display: flex;
    justify-content: space-around;
    li {
      color: #7f7f7f;
      font-size: 12px;
      cursor: pointer;
      padding: 3px;
      border-radius: 3px;
      &:hover {
        color: #000;
        background-color: #f5f5f5;
      }
    }
  }
  .policy_list {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    li {
      margin: 0 10px;
      color: #565656;
      font-size: 11px;
      cursor: pointer;
    }
  }
  .reserved {
    margin-top: 5px;
    color: #565656;
    font-size: 11px;
  }
`;
export default LoginFooter;
