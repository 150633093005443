import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ProjectUpdate from "./modal/update/modal";

const usePopupProject = (redirect: string, getList: () => void) => {
  const [popUpdateProject, setPopUpdateProject] = useState(false);
  const [projectId, setProjectId] = useState(0);
  const location = useLocation();
  const state = location.state as { projectId: number };
  const hash = location.hash;
  const navigate = useNavigate();

  useEffect(() => {
    if (hash === "#project" && state.projectId) {
      handlePopUpdate(state.projectId);
      navigate(redirect);
    }
  }, [location]);

  const handlePopUpdate = (projectNum: number) => {
    setPopUpdateProject(true);
    setProjectId(projectNum);
  };

  const ui = (
    <>
      {popUpdateProject && (
        <ProjectUpdate
          isOpen={popUpdateProject}
          setOpen={setPopUpdateProject}
          projectId={projectId}
          getList={getList}
        />
      )}
    </>
  );

  return { ui };
};

export default usePopupProject;
