import React from "react";
import styled from "styled-components";
import Star from "../essential-star";

interface Props {
  label: string;
  essential?: boolean;
  children?: React.ReactNode;
}

const SelectLabel = ({ label, essential, children }: Props) => {
  return (
    <Wrapper>
      <label>
        {label}
        {essential && <Star />}
      </label>

      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: auto;
  margin-top: 10px;
  width: 100%;
  label {
    display: block;
    font-size: 14px;
    font-weight: bold;
    color: #979797;
    margin-left: 5px;
    text-align: left;
  }
  select {
    display: block;
    /* width: 100%; */
    min-width: 100px;
    height: 35px;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    margin-top: 10px;
    text-indent: 10px;
  }

  @media (min-width: 768px) {
    font-size: 11px;
  }
`;

export default SelectLabel;
