import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { handleUserInfo } from "./script/user.info";
import { userState } from "../../../constants/recoil/user";
import UserInfoLeftBox from "./components/top-info-list";
import UserInfoPassWordBox from "./components/top-update-pw";
import UserCreditLeftBox from "./components/bottom-charge-box";
import UserCreditRightBox from "./components/bottom-credit-box";

const UserInfo = () => {
  const [user, setUser] = useRecoilState(userState);
  const { getUser } = handleUserInfo({ user, setUser });

  useEffect(() => getUser(), []);

  return (
    <Wrapper>
      <TopWrapper>
        <UserInfoLeftBox getUser={getUser} user={user} />
        <UserInfoPassWordBox getUser={getUser} />
      </TopWrapper>

      <BottomContainer>
        <UserCreditLeftBox />
        <UserCreditRightBox user={user} />
      </BottomContainer>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TopWrapper = styled.article`
  border: 1px solid #ddd;
  background-color: #f1f1f1;
  width: 95%;
  margin: 20px auto;
  height: 100%;
  min-height: 600px;
  margin-top: 0;
  @media screen and (min-width: 768px) {
    display: flex;
    width: 95%;
    height: 50%;
    min-height: 400px;
  }
`;
const BottomContainer = styled.article`
  width: 95%;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  h5 {
    line-height: 20px;
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 500;
    color: #5e7290;
  }
  strong {
    color: var(--GridaBlue);
  }
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;
export default UserInfo;
