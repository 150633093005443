import React, { useEffect } from "react";
import ProjectListForm from "../../components/form.container.list.inner";
import NotFoundData from "../../components/item/notfound";
import NameWithCheckBox from "../../components/item/name-with-checkbox";
import { ProjectWorkstationUiData } from "../../../../../../../../@types/project/ui/workstation";
import { projectUiDataState } from "../../../../../../../../constants/recoil/project";
import { useRecoilState } from "recoil";
import { Project } from "../../../../../../../../@types/project/project";
import { useT } from "../../../../../../../../constants/i18n/useT";
interface Props {
  rightWorkstations: ProjectWorkstationUiData[];
  setRightWorkstations: React.Dispatch<
    React.SetStateAction<ProjectWorkstationUiData[]>
  >;
  project?: Project;
}
const ProjectWSRightComponent = ({
  rightWorkstations,
  setRightWorkstations,
  project,
}: Props) => {
  const [projectUiData] = useRecoilState(projectUiDataState);

  const setWorkstations = () => {
    const projectWorkstations: ProjectWorkstationUiData[] = [];
    // if (project && project.unassignedWorkstations) {
    //   project.unassignedWorkstations.forEach((workstation) => {

    if (projectUiData.unassignedWorkstations) {
      projectUiData.unassignedWorkstations.forEach((workstation) => {
        projectWorkstations.push({
          workstationId: workstation.workstationId,
          name: workstation.name,
          isChecked: false,
        });
      });
    }
    setRightWorkstations(projectWorkstations);
  };
  useEffect(() => {
    setWorkstations();
    return () => {
      setRightWorkstations([]);
    };
  }, [projectUiData]);

  const handleCheckbox = (workstation: ProjectWorkstationUiData) => {
    const index = rightWorkstations.findIndex(
      (w) => w.workstationId === workstation.workstationId
    );
    const newWorkstationList = [...rightWorkstations];
    newWorkstationList[index].isChecked = !newWorkstationList[index].isChecked;
    setRightWorkstations(newWorkstationList);
  };

  const t = useT();
  return (
    <ProjectListForm>
      {rightWorkstations.map((workstation: ProjectWorkstationUiData) => (
        <NameWithCheckBox
          key={workstation.workstationId}
          title={ProjectWorkstationUiData.fullName(workstation)}
          isChecked={workstation.isChecked}
          handleCheckbox={() => handleCheckbox(workstation)}
        />
      ))}
      {rightWorkstations.length == 0 && (
        <NotFoundData
          title={t(
            "project.there_is_no_workstation",
            "Workstation이 없습니다."
          )}
        />
      )}
    </ProjectListForm>
  );
};

export default ProjectWSRightComponent;
