import { APICLIENT, API } from "../api";

export class UpdateUser {
  userGuid?: string;
  description?: string;
  fullName?: string;
  mail?: string;
  mobile?: string;
  isSingleLogin?: boolean;
  individualCompanyName?: string;
  certificationNumber?: string;
  sessionPolicy?: { cd?: string };

  update = async () => {
    const response = await APICLIENT.AUTH_NOALERT.post(
      `${API.USER}/update`,
      {
        data: this,
      }
    );
    return response.data;
  };
  postChangeMail = async (certificationNumber: string) => {
    const response = await APICLIENT.AUTH_NOALERT.post(
      `${API.AUTH_MAIL}/changeMail`,
      {
        userGuid: this.userGuid,
        certificationNumber: certificationNumber,
      }
    );
    return response.data;
  };
  sendCertification = async () => {
    const response = await APICLIENT.AUTH_NOALERT.get(
      `${API.AUTH_MAIL}/sendCertificationNumber?mail=${this.mail}`
    );
    return response.data;
  };
  validEmailNumber = async (certificationNumber: string) => {
    const response = await APICLIENT.NONAUTH_NOALERT.get(
      `${API.AUTH_MAIL}/validateCertificationNumber?mail=${this.mail}&certificationNumber=${certificationNumber}`
    );
    return response.data;
  };
  userValidEmailNumber = async (certificationNumber: string) => {
    const response = await APICLIENT.AUTH_NOALERT.get(
      `${API.AUTH_MAIL}/validateCertificationNumber?mail=${this.mail}&certificationNumber=${certificationNumber}`
    );
    return response.data;
  };
  sendEmailToFindId = async () => {
    const response = await APICLIENT.NONAUTH_NOALERT.post(
      `${API.AUTH_FIND}/userId/sendCertificationNumber`,
      this
    );
    return response.data;
  };
  finduserId = async () => {
    const response = await APICLIENT.NONAUTH_NOALERT.post(
      `${API.AUTH_FIND}/userId`,
      this
    );
    return response.data;
  };
}
