import React from "react";
import styled from "styled-components";

interface Props {
  children: string;
  success?: boolean;
}

const TextAlert = ({ children, success }: Props) => {
  return (
    <Wrapper className={success ? "success" : "failed"}>{children}</Wrapper>
  );
};
const Wrapper = styled.p`
  height: 12px;
  font-size: 12px;
  color: #ff2c2c;
  margin-top: 5px;
  &.success {
    color: #00b140;
  }
`;

export default TextAlert;
