import { Code } from "../../infra/code";
import { DefaultUserUiData } from "../../project/ui/user";

export class StorageUserUiData implements DefaultUserUiData {
  userGuid: string;
  fullName: string;
  userId: string = "";
  isChecked: boolean = false;
  deleted: boolean = false;

  storageUserId?: number;
  storageId?: string;
  privilege?: Code;
  constructor(userGuid: string, fullName: string) {
    this.userGuid = userGuid;
    this.fullName = fullName;
  }
}
