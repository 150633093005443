import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { useT } from "../../../constants/i18n/useT";
import { useRecoilState } from "recoil";
import { localeState } from "../../../constants/recoil/locale";

const fadeIn = keyframes`
from {
  opacity: 0;
}
to {
  opacity: 1;
}
`;

const Page3 = () => {
  const t = useT();
  const [locale] = useRecoilState(localeState);

  const [isOpen, setIsOpen] = useState(false);
  const [img, setImg] = useState("");

  const openModal = (imgSrc: string) => {
    setImg(imgSrc);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div>
        <p>
          {t(
            "workstation.mac_client_download_message6",
            "개인정보 보호 및 보안 에서 “App Store 및 확인된 개발자”를 선택한 후,"
          )}
        </p>
        <p>
          {t(
            "workstation.mac_client_download_message7",
            "“그래도 실행 (Run anyway)”을 클릭합니다. 이 작업은 시스템 설정을 수정하기 때문에 Touch ID나 암호를 요구합니다."
          )}
        </p>
        <br />

        {locale === "ko_KR" && (
          <>
            <Image
              src="/assets/user/download/download3.png"
              onClick={() => openModal("/assets/user/download/download3.png")}
              alt="Download"
            />
            <Image
              src="/assets/user/download/download4.png"
              onClick={() => openModal("/assets/user/download/download4.png")}
              alt="Download"
            />
          </>
        )}

        {locale === "en_US" && (
          <>
            <Image
              src="/assets/user/download/en_download2.png"
              onClick={() =>
                openModal("/assets/user/download/en_download2.png")
              }
              alt="Download"
            />
            <Image
              src="/assets/user/download/en_download3.png"
              onClick={() =>
                openModal("/assets/user/download/en_download3.png")
              }
              alt="Download"
            />
          </>
        )}
        {locale === "ja_JP" && (
          <>
            <Image
              src="/assets/user/download/jp_download2.png"
              onClick={() =>
                openModal("/assets/user/download/jp_download2.png")
              }
              alt="Download"
            />
            <Image
              src="/assets/user/download/jp_download3.png"
              onClick={() =>
                openModal("/assets/user/download/jp_download3.png")
              }
              alt="Download"
            />
          </>
        )}
        {locale === "zh_CN" && (
          <>
            <Image
              src="/assets/user/download/cn_download2.png"
              onClick={() =>
                openModal("/assets/user/download/cn_download2.png")
              }
              alt="Download"
            />
            <Image
              src="/assets/user/download/cn_download3.png"
              onClick={() =>
                openModal("/assets/user/download/cn_download3.png")
              }
              alt="Download"
            />
          </>
        )}
      </div>

      {isOpen && (
        <Modal onClick={closeModal}>
          <ModalContent src={img} onClick={(e) => e.stopPropagation()} />
          <CloseButton onClick={closeModal}>&times;</CloseButton>
        </Modal>
      )}
    </>
  );
};
const Image = styled.img`
  border-radius: 10px;
  margin-top: 30px;
  width: 50%;
  height: 30%;
`;

const Modal = styled.div`
  position: fixed;
  z-index: 1050;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  animation: ${fadeIn} 0.5s ease-out;
`;

const ModalContent = styled.img`
  margin: auto;
  display: block;
  max-width: 90%;
  max-height: 80%;
  margin-top: 10%;
  animation: ${fadeIn} 0.5s ease-out;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
`;

export default Page3;
