import { WorkstationService } from "../../../service/workstation/workstation.service";
import { MappingWorkstation } from "./storage";
import { Spec } from "../../infra/spec";

export class WorkstationUpdateForm extends WorkstationService {
  name: string;
  workstationId: string;
  mappingWSList?: MappingWorkstation[];
  spec: { specId: string };
  description?: string;

  constructor(
    workstationId: string,
    name: string,
    spec: { specId: string },
    description?: string,
    mappingWSList?: MappingWorkstation[]
  ) {
    super();
    this.workstationId = workstationId;
    this.name = name;
    this.spec = spec;
    this.description = description;

    mappingWSList && (this.mappingWSList = mappingWSList);
  }
}
