import React from "react";
import styled from "styled-components";
import { useT } from "../../../../../constants/i18n/useT";
import { Storage } from "../../../../../@types/storage/storage";
import StorageTypeDonutChart from "./donut-chart";
import StorageCapacityDonutChart from "./donut-chart2";

interface Props {
  myStorages: Storage[];
  sharedAsDirectorStorages: Storage[];
  sharedAsMemberStorages: Storage[];
  sharedAsNoProjectStorages: Storage[];
  setShowCharts: React.Dispatch<React.SetStateAction<boolean>>;
}

const StorageStatusModal = ({
  myStorages,
  sharedAsDirectorStorages,
  sharedAsMemberStorages,
  sharedAsNoProjectStorages,
  setShowCharts,
}: Props) => {
  const t = useT();

  return (
    <ModalBackground onClick={() => setShowCharts(false)}>
      <ModalContent onClick={e => e.stopPropagation()}>
        <ModalHeader>
          <Title> {t("storage.storage_statistics", "스토리지 현황")}</Title>
          <CloseButton onClick={() => setShowCharts(false)}>X</CloseButton>
        </ModalHeader>
        <StorageTypeDonutChart
          myStorages={myStorages}
          sharedAsDirectorStorages={sharedAsDirectorStorages}
          sharedAsMemberStorages={sharedAsMemberStorages}
          sharedAsNoProjectStorages={sharedAsNoProjectStorages}
        />
        <StorageCapacityDonutChart
          myStorages={myStorages}
          sharedAsDirectorStorages={sharedAsDirectorStorages}
          sharedAsMemberStorages={sharedAsMemberStorages}
          sharedAsNoProjectStorages={sharedAsNoProjectStorages}
        />
      </ModalContent>
    </ModalBackground>
  );
};

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  z-index: 1001;
  position: relative;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  margin: 0;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;

  &:hover {
    color: #006ee4;
  }
`;

export default StorageStatusModal;
