import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from "../../components/button/Button";
import { PolicyService } from "../../service/user/policy.service";
import { useT } from "../../constants/i18n/useT";
import { useRecoilState } from "recoil";
import { localeState } from "../../constants/recoil/locale";
import { WindowService } from "../../service/common/window.service";

const PolicyPage = () => {
  const [policy, setPolicy] = useState<PolicyService>(new PolicyService());
  const navigate = useNavigate();
  const [locale] = useRecoilState(localeState);

  const t = useT();

  useEffect(() => {
    PolicyService.getLatest().then((res) => {
      if (res.state === "success") {
        return setPolicy(res.data);
      }
    });
  }, [locale]);

  return (
    <Wrapper className="content">
      <div className="g_sub_top" id="no_mt">
        <div className="g_sub_top_text">
          <h3>
            {t("common.terms_of_service", "이용약관")} /{" "}
            {t("common.privacy_policy", "개인정보 처리방침")}
          </h3>
          <div className="site_step">
            <div>
              <Link to="/">
                <img alt="img" src="/assets/sub/home_icon.png" /> Home
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="content_wrap">
        <div>
          <ContentBox>
            <div className="yak_title">
              <label htmlFor="terms_check">
                {t("common.terms_of_service", "이용약관")}
              </label>
            </div>
            <textarea
              cols={50}
              rows={13}
              readOnly={true}
              value={policy.termsOfUse.content}
            />
          </ContentBox>
          <ContentBox>
            <div className="yak_title">
              <label htmlFor="privacy_check">
                {t("common.privacy_policy", "개인정보 처리방침")}
              </label>
            </div>
            <textarea
              cols={50}
              rows={13}
              readOnly={true}
              value={policy.personalInfo.content}
            />
          </ContentBox>
        </div>
        <ButtonBox>
          <Button size="xxl" onClick={() => navigate(-1)}>
            {t("common.go_back", "이전으로")}
          </Button>
          <Button onClick={() => navigate("/")} color="primary" size="xxl">
            {t("common.go_to_homepage", "홈으로")}
          </Button>
        </ButtonBox>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  padding-top: 50px;
  .g_sub_top {
    margin-top: 0;
  }
`;

const ContentBox = styled.div`
  padding: 20px;
  margin-top: 20px;
  border: 1px solid #ddd;
  height: 300px;
  overflow: auto;

  .yak_title {
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  textarea {
    width: 100%;
    resize: none;
    border: 0;
    font-size: 15px;
    color: #888;
  }
  textarea[disabled] {
    background: #fff;
  }
`;
const ButtonBox = styled.article`
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
`;
export default PolicyPage;
