import { Workstation } from "../workstation";

export class Workstationlist {
  myWorkstations: Workstation[];
  sharedAsDirectorWorkstations: Workstation[];
  sharedAsMemberWorkstations: Workstation[];
  sharedAsNoProjectWorkstations: Workstation[];
  constructor() {
    this.myWorkstations = [];
    this.sharedAsDirectorWorkstations = [];
    this.sharedAsMemberWorkstations = [];
    this.sharedAsNoProjectWorkstations = [];
  }
}
