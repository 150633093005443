import React, { useEffect, useState } from "react";
import { Code } from "../../../../../../@types/infra/code";
import { Image } from "../../../../../../@types/infra/image";
import { useT } from "../../../../../../constants/i18n/useT";

interface Iprops {
  SWs: Code[];
  setSw: React.Dispatch<React.SetStateAction<string>>;
  sw: string;
  setSelectImages2: React.Dispatch<React.SetStateAction<Image[]>>;
  images: Image[];
  os: string;
  selectImages: Image[];
}

const WSCreateSW: React.FC<Iprops> = ({
  SWs,
  setSw,
  sw,
  setSelectImages2,
  os,
  images,
  selectImages,
}) => {
  const [showSW, setShowSW] = useState<Code[]>(SWs);
  const filterBySW = () => {
    if (!!images.length) {
      let tmpImages = images.filter(
        (image: Image) =>
          image.os &&
          image.os.cd === os &&
          image.installedSw &&
          image.installedSw.cd === sw
      );
      setSelectImages2(tmpImages);
    }
  };
  useEffect(() => {
    let mySW: Code[] = [];
    selectImages.map((image) => {
      if (image.installedSw) mySW.push(image.installedSw);
    });
    mySW = mySW.filter((v, i) => mySW.findIndex((x) => x.cd === v.cd) === i);
    setShowSW(mySW);
    if (mySW.length > 0 && mySW[0].cd) {
      setSw(mySW[0].cd);
    }
  }, [selectImages]);

  useEffect(() => {
    filterBySW();
  }, [os, sw]);
  const t = useT();
  return (
    <dd className="radio_middle ">
      {showSW.length > 0 ? (
        <select
          tabIndex={4}
          value={sw}
          onChange={(e) => setSw(e.target.value)}
          id="number"
        >
          {showSW.map((SW: Code, idx) => {
            return (
              <option key={idx} value={SW.cd} id={SW.cd}>
                {SW.name}
              </option>
            );
          })}
        </select>
      ) : (
        <p id="red">
          {t(
            "workstation.there_is_no_available_software_to_use",
            "사용 가능한 sw가 없습니다."
          )}
        </p>
      )}
    </dd>
  );
};

export default WSCreateSW;
