import { MappingWorkstation } from "./storage";
import { WorkstationService } from "../../../service/workstation/workstation.service";

export class WorkstationCreateForm extends WorkstationService {
  name: string;
  spec: { specId: string };
  image: { baseImage: { imageId: string } };
  mappingWSList: MappingWorkstation[];
  description?: string;

  constructor(name: string, specId: string, imageId: string, description?: string) {
    super();
    this.name = name;
    this.spec = { specId: specId };
    this.image = { baseImage: { imageId: imageId } };
    this.mappingWSList = [];
    this.description = description;
  }

  setStorages = (mappingWSList: MappingWorkstation[]) => {
    this.mappingWSList = mappingWSList;
  };
}
