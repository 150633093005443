import React from "react";
import styled from "styled-components";
import { Element as ScrollElement } from "react-scroll";
import { useT, useCurrentLanguage } from "../../../constants/i18n/useT";

const Etc = () => {
  const t = useT();
  const currentLanguage = useCurrentLanguage();

  return (
    <Wrapper>
      <ScrollElement name="ect1">
        <Title>{t("common.wacom_tablet", "와콤 타블렛")}</Title>
        <Spacer />
        <Paragraph>
          1. {t("common.guide_etc1", "M2M워크스테이션에 와콤타블렛 드라이브를 설치합니다")}
        </Paragraph>

        <Spacer />

        <Paragraph>
          2.
          {t(
            "common.guide_etc2",
            `M2M클라이언트 메뉴에서 Connetion > USB Devices를 선택하면 Devices 설정 창이 나옵니다.`
          )}
        </Paragraph>

        <Spacer />

        <ImageWrapper>
          <ResponsiveImage
            src={`/assets/guide/${currentLanguage === "ko_KR" ? "ect.png" : "en/en_ect.png"}`}
            alt="ect"
          />
        </ImageWrapper>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_etc3",
            `Show Human Interface Devices 체크하면 아래그림과 같이 메뉴가 추가로 보여지는데 그중 Wacom타블랫에 Connect를 실행합니다.`
          )}
        </Paragraph>

        <Spacer />

        <ImageWrapper>
          <ResponsiveImage
            src={`/assets/guide/${currentLanguage === "ko_KR" ? "ect2.png" : "en/en_ect2.png"}`}
            alt="ect"
          />
        </ImageWrapper>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_etc4",
            `Wacom 센터에서 펜 디스플레이를 보정하지 마세요. M2M 워크스테이션의 Wacom 센터에서 펜 디스플레이를 보정하지 않는 것이 좋습니다. 그렇게 하면 PCoIP 세션 중에 태블릿을 사용할 때 커서가 오프셋 될 수 있습니다.`
          )}
        </Paragraph>

        <Spacer />

        <Paragraph>3. {t("common.guide_etc5", "M2M client support Wacom tablets.")}</Paragraph>

        <Spacer />

        <ImageWrapper>
          <ResponsiveImage
            src={`/assets/guide/${currentLanguage === "ko_KR" ? "ect3.png" : "en/en_ect3.png"}`}
            alt="ect"
          />
        </ImageWrapper>

        <Spacer />

        <Paragraph>{t("common.guide_etc6", "주의: 터치 기능은 지원하지않습니다.")}</Paragraph>
      </ScrollElement>
    </Wrapper>
  );
};

export default Etc;

const Wrapper = styled.div`
  padding: 25px;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 20px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

const Paragraph = styled.p`
  font-size: 16px;
  line-height: 1.5;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 13px;
  }
`;

const Spacer = styled.div`
  height: 20px;

  @media (max-width: 768px) {
    height: 15px;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    margin-top: 30px;
    margin-bottom: 40px;
  }

  @media (max-width: 480px) {
    margin-top: 20px;
    margin-bottom: 30px;
  }
`;

const ResponsiveImage = styled.img`
  max-width: 100%;
  height: auto;

  @media (max-width: 768px) {
    height: auto;
  }

  @media (max-width: 480px) {
    height: auto;
  }
`;
