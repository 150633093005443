import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Storage } from "../../../../../../@types/storage/storage";
import { UnitPercent } from "../../../../../../@types/storage/ui/unit-size";

const UsageBar = ({ storage }: { storage: Storage }) => {
  const [percent, setPercent] = useState(
    new UnitPercent(storage.size && storage.size.cd, storage.used)
  );

  useEffect(() => {
    setPercent(new UnitPercent(storage.size && storage.size.cd, storage.used));
  }, [storage]);

  return (
    <Wrapper>
      <UsedSize id={percent.value >= 0.5 ? "white" : ""} className="percent">
        {percent.usedSizeToString}
      </UsedSize>
      <progress className="progress" value={percent.value} max={1} />
    </Wrapper>
  );
};
const Wrapper = styled.article`
  width: 100%;
  text-align: center;
  .percent {
    position: relative;
    font-weight: 600;
    top: 18px;
    z-index: 10;
  }
  #white {
    color: white;
  }
  .progress {
    appearance: none;
    width: 100%;
    margin: auto;
    height: 17px;
    position: relative;
    &::-webkit-progress-bar {
      background: #f0f0f0;
      border-radius: 5px;
      box-shadow: inset 3px 3px 10px #ccc;
      transition: 0.2s ease;
    }
    &::-webkit-progress-value {
      border-radius: 5px;
      background: var(--GridaBlue);
      transition: 0.5s ease;
      background: -webkit-linear-gradient(to right, #95c3f4, #0061c9);
      background: linear-gradient(to right, #95c3f4, #0061c9);
    }
  }
`;
const UsedSize = styled.p``;

export default UsageBar;
