export class ProjectFormUser {
  user?: { userGuid?: string };
  projectRole?: { cd?: string };
  projectUserId?: number;
  useState?: { cd: "UseState.Deleted" };
  constructor(userGuid?: string, projectRoleCd?: string) {
    this.user = { userGuid };
    this.projectRole = { cd: projectRoleCd };
  }
}
