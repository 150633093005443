import { APICLIENT, API } from "../api";

export class ContactService {
  title: string;
  content: string;
  constructor(props: { title: string; content: string }) {
    this.title = props.title;
    this.content = props.content;
  }
  submit = async () => {
    const response = await APICLIENT.AUTH.post(`${API.CONTACT}/post`, {
      data: this,
    });
    return response.data;
  };
  static getList = async () => {
    const response = await APICLIENT.AUTH.post(`${API.CONTACT}/getList`, {
      paginate: {
        limit: 100,
      },
    });
    return response.data;
  };

  static getAnswerList = async (id: number) => {
    const response = await APICLIENT.AUTH.post(`${API.CONTACT}/getAnswerList`, {
      filter: {
        parentId: id,
      },
      paginate: {
        limit: 100,
      },
    });
    return response.data;
  };
}
