import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { userState } from "../../constants/recoil/user";
import { cpuPriceState, gpuPriceState, storagePriceState } from "../../constants/recoil/price";
import { AuthService } from "../../service/user/user.service";
import { useNavigate } from "react-router-dom";

import Main1 from "./main-1";
import Main2 from "./main-2";
import Main3 from "./main-3";

const MainPage = () => {
  const [cpuPrice] = useRecoilState(cpuPriceState);
  const [gpuPrice] = useRecoilState(gpuPriceState);
  const [storagePrice] = useRecoilState(storagePriceState);
  const [user, setUser] = useRecoilState(userState);

  const [popCalculate, setPopCalculate] = useState(false);
  const [popContact, setPopContact] = useState(false);

  useEffect(() => {
    AuthService.getSession()
      .then(session => {
        console.log("Session data:", session);
        setUser(session);
        window.location.replace("/workstation");
      })
      .catch(error => {
        console.error("Failed to fetch session data:", error.response.status);
      });
  }, []);

  return (
    <>
      <Main1 />
      <Main2
        popCalculate={popCalculate}
        popContact={popContact}
        setPopCalculate={setPopCalculate}
        setPopContact={setPopContact}
      />
      <Main3 cpuPrice={cpuPrice} gpuPrice={gpuPrice} storagePrice={storagePrice} />
    </>
  );
};

export default MainPage;
