import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import ProjectListForm from "../../components/form.container.list.inner";
import { ProjectWorkstationUiData } from "../../../../../../../../@types/project/ui/workstation";
import { ProjectStorageUiData } from "../../../../../../../../@types/project/ui/storage";
import { useT } from "../../../../../../../../constants/i18n/useT";
import NameWithCheckBox from "../../components/item/name-with-checkbox";
import WSUpdateUserPage from "../../../../../../../workstation/components/modal/update-user/modal";
import WSUpdateStorage from "../../../../../../../workstation/components/modal/update/modal";
import ShareStorage from "../../../../../../../project/components/modal/update/modal/shareStorage";
import ShareUser from "../../../../../../../project/components/modal/update/modal/shareUser";
import { Tooltip } from "@mui/material";

interface Props {
  leftWorkstations: ProjectWorkstationUiData[];
  setLeftWorkstations: React.Dispatch<React.SetStateAction<ProjectWorkstationUiData[]>>;
  leftStorages: ProjectWorkstationUiData[];
  setLeftStorages: React.Dispatch<React.SetStateAction<ProjectStorageUiData[]>>;
  project?: any;
  getList: () => void;
  assignedWorkstations?: any[] | [];
  assignedStorages?: any[] | [];
  st: any[];
  setSt: React.Dispatch<React.SetStateAction<any[]>>;
  wsUser: any[];
  setWsUser: React.Dispatch<React.SetStateAction<any[]>>;
  currentWorkstation: any[];
  setCurrentWorkstation: React.Dispatch<React.SetStateAction<any[]>>;
}

const ProjectWSLeftComponent = ({
  leftWorkstations,
  setLeftWorkstations,
  leftStorages,
  setLeftStorages,
  project,
  getList,
  assignedWorkstations,
  assignedStorages,
  st,
  setSt,
  wsUser,
  setWsUser,
  currentWorkstation,
  setCurrentWorkstation,
}: Props) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownIndex, setDropdownIndex] = useState<number | null>(null);
  const [popUpdateUser, setPopUpdateUser] = useState(false);
  const [popUpdateStorage, setPopUpdateStorage] = useState(false);

  const t = useT();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (showDropdown && !(event.target as HTMLElement).closest(".dropdown-menu")) {
        setShowDropdown(false);
        setDropdownIndex(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);

  const handleContextMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    workstation: any,
    index: number
  ) => {
    event.preventDefault();
    setCurrentWorkstation(workstation);
    setShowDropdown(true);
    setDropdownIndex(index);
  };

  const handleCheckbox = (workstation: ProjectWorkstationUiData) => () => {
    const newWorkstations = leftWorkstations.map(ws => {
      if (ws.workstationId === workstation.workstationId) {
        return {
          ...ws,
          isChecked: !ws.isChecked,
        };
      }
      return ws;
    });
    setLeftWorkstations(newWorkstations);
  };

  const getUserName = (workstation: ProjectWorkstationUiData) => {
    let result = (
      <>
        {workstation.shareWith &&
          workstation.shareWith.map((stData: any) => {
            return <p key={stData.userGuid}>{stData.fullName}</p>;
          })}
      </>
    );
    return result;
  };

  const getStorageName = (workstation: ProjectWorkstationUiData) => {
    let result = (
      <>
        {workstation.storages &&
          workstation.storages.map((stData: any) => {
            return <p key={stData.storageId}>{stData.name}</p>;
          })}
      </>
    );
    return result;
  };

  return (
    <Wrapper>
      <ProjectListForm>
        {leftWorkstations.map(
          (workstation: ProjectWorkstationUiData, index: number) =>
            !workstation.deleted && (
              <NameWithCheckBoxWrapper
                key={workstation.workstationId}
                onContextMenu={e => handleContextMenu(e, workstation, index)}
              >
                <NameWithCheckBox
                  id={`checkbox-${workstation.workstationId}`}
                  title={ProjectWorkstationUiData.fullName(workstation)}
                  isChecked={workstation.isChecked}
                  handleCheckbox={handleCheckbox(workstation)}
                />
                {((workstation.storages && workstation.storages.length > 0) ||
                  (workstation.shareWith && workstation.shareWith.length > 0)) && (
                  <WSInfoWapper>
                    (
                    {workstation.shareWith.length > 0 && (
                      <WSInfoContent>
                        {t("project.user", "사용자")} :
                        <Tooltip title={getUserName(workstation)}>
                          <BoldUnderline>{workstation.shareWith.length}</BoldUnderline>
                        </Tooltip>
                      </WSInfoContent>
                    )}
                    {workstation.storages.length > 0 && (
                      <WSInfoContent>
                        {t("project.storage", "스토리지")} :
                        <Tooltip title={getStorageName(workstation)}>
                          <BoldUnderline>{workstation.storages.length}</BoldUnderline>
                        </Tooltip>
                      </WSInfoContent>
                    )}
                    )
                  </WSInfoWapper>
                )}
                {showDropdown && dropdownIndex === index && (
                  <DropdownMenu className="dropdown-menu">
                    <DropdownContent onClick={() => setPopUpdateUser(true)}>
                      {t("project.user_setting", "사용자 설정")}
                    </DropdownContent>
                    <DropdownContent
                      onClick={() => {
                        setPopUpdateStorage(true);
                      }}
                    >
                      {t("project.storage_setting", "스토리지 설정")}
                    </DropdownContent>
                  </DropdownMenu>
                )}
              </NameWithCheckBoxWrapper>
            )
        )}
      </ProjectListForm>

      {popUpdateUser && (
        <ShareUser
          isOpen={popUpdateUser}
          workstation={currentWorkstation}
          setOpen={setPopUpdateUser}
          projectId={project.projectId}
          projectName={project.name}
          getList={getList}
          wsUser={wsUser}
          setWsUser={setWsUser}
          setSt={setSt}
        />
      )}

      {popUpdateStorage && (
        <ShareStorage
          isOpen={popUpdateStorage}
          setOpen={setPopUpdateStorage}
          assignedWorkstations={assignedWorkstations}
          assignedStorages={assignedStorages}
          currentWorkstation={currentWorkstation}
          st={st}
          setSt={setSt}
          setWsUser={setWsUser}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const NameWithCheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  // &:hover {
  //   background-color: #f8f8f8;
  //   cursor: context-menu; /* 마우스 커서가 우클릭 가능하도록 변경 */
  // }
`;

const WSInfoWapper = styled.div`
  width: 100%;
  font-size: 11px;
  margin-right: auto;
`;

const WSInfoContent = styled.span`
  margin-right: 6px;
`;

const BoldUnderline = styled.span`
  font-weight: bold;
  text-decoration: underline;
  margin-left: 5px;
`;

const dropdownAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1000;
  width: 120px;
  top: 80%;
  left: 11%;
  transform: translateY(5px);
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  animation: ${dropdownAnimation} 0.3s ease-out forwards;
`;

const DropdownContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f8f8f8;
  }

  &:last-child {
    border-bottom: none;
  }
`;

export default ProjectWSLeftComponent;
