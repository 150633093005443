import React, { useState } from "react";
import { Workstation } from "../../../../../../@types/workstation/workstation";
import { useT } from "../../../../../../constants/i18n/useT";
import WSDelete from "../../../modal/delete/modal";
import WSRestart from "../../../modal/restart/modal";
import WSUpdate from "../../../modal/update/modal";
import styled from "styled-components";
import WSUpdateUserPage from "../../../modal/update-user/modal";
import { CardEditType } from "../../../../../../@types/type/card-edit";
import { CardDropdown } from "../../../../../../components/dropdown/card-dropdown";

// import { MdDelete } from "react-icons/md";
// import { BsFillShareFill } from "react-icons/bs";
// import { RiEdit2Fill } from "react-icons/ri";

import { MdDelete } from "@react-icons/all-files/md/MdDelete";
// import {BsFillShareFill} from "@react-icons/all-files/bs/BsFillShare"
import { MdShare } from "@react-icons/all-files/md/MdShare";
import { RiEdit2Fill } from "@react-icons/all-files/ri/RiEdit2Fill";

interface Props {
  status: string;
  workstation: Workstation;
  getList: () => void;
  editType?: CardEditType;
}

const OptionIcon = ({ status, workstation, getList, editType }: Props) => {
  const [popUpdate, setPopUpdate] = useState(false);
  // const [popRestart, setPopRestart] = useState(false);
  const [popDelete, setPopDelete] = useState(false);
  const [popUpdateUser, setPopUpdateUser] = useState(false);
  const t = useT();
  return (
    <div>
      {editType !== CardEditType.NONE && (
        <CardDropdown type="workstation" status={status}>
          {(editType === CardEditType.ALL || editType === CardEditType.UPDATE) && (
            <>
              <CardOptionItem setState={setPopUpdate} content={t("common.update", "수정")} />

              {status === "PowerOff" && (
                <CardOptionItem
                  setState={setPopUpdateUser}
                  content={t("common.share_setting", "공유")}
                />
              )}
            </>
          )}

          {/* {status === "Running" && (
            <CardOptionItem
              setState={setPopRestart}
              content={t("common.restart", "재시작")}
            />
          )} */}

          {editType === CardEditType.ALL && (
            <>
              {status === "PowerOff" && (
                <CardOptionItem setState={setPopDelete} content={t("common.delete", "삭제")} />
              )}
            </>
          )}
        </CardDropdown>
      )}
      {popUpdate && (
        <WSUpdate
          workstation={workstation}
          workstationId={workstation.workstationId!}
          getList={getList}
          setOpen={setPopUpdate}
          isOpen={popUpdate}
        />
      )}
      {popUpdateUser && (
        <WSUpdateUserPage
          isOpen={popUpdateUser}
          workstation={workstation}
          setOpen={setPopUpdateUser}
          projectId={workstation.project && workstation.project.projectId}
          getList={getList}
        />
      )}

      {popDelete && (
        <WSDelete
          getList={getList}
          setOpen={setPopDelete}
          isOpen={popDelete}
          workstation={workstation}
        />
      )}

      {/* {popRestart && (
        <WSRestart
          getList={getList}
          setOpen={setPopRestart}
          isOpen={popRestart}
          workstation={workstation}
        />
      )} */}
    </div>
  );
};

interface CProps {
  setState: (state: boolean) => void;
  content: string | null;
}

const CardOptionItem = ({ setState, content }: CProps) => {
  let iconComponent = null;

  switch (content) {
    case "수정":
      iconComponent = <RiEdit2Fill size={20} />;
      break;
    case "삭제":
      iconComponent = <MdDelete size={20} />;
      break;
    case "공유":
      iconComponent = <MdShare size={20} />;
      break;
    default:
      break;
  }

  return (
    <OptionWrapper onClick={() => setState(true)}>
      {iconComponent}
      <a>{content || ""}</a>
    </OptionWrapper>
  );
};

const OptionWrapper = styled.li`
  color: #333;
  display: flex;
  align-items: center;
  gap: 28px;
`;

export default OptionIcon;
