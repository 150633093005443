import React from "react";
import styled from "styled-components";
import { useT } from "../../../../../../../../constants/i18n/useT";
import { ProjectUserMode } from "../../../../../../../../@types/project/ui/user-search-mode";

interface Props {
  handleMode: (val: ProjectUserMode) => () => void;
  checkMode: (val: ProjectUserMode) => string;
  company?: boolean;
}

const ProjectMemberTab = ({ handleMode, checkMode, company = true }: Props) => {
  const t = useT();
  return (
    <Tab>
      <TabItem
        onClick={handleMode(ProjectUserMode.COMPANY)}
        className={checkMode(ProjectUserMode.COMPANY)}
      >
        {company && t("storage.affiliated_company", "소속 회사")}
        {!company && "Prev Member"}
      </TabItem>
      <TabItem
        onClick={handleMode(ProjectUserMode.SEARCH)}
        className={checkMode(ProjectUserMode.SEARCH)}
      >
        {t("storage.search_individual", "개인 검색")}
      </TabItem>
    </Tab>
  );
};
const Tab = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  cursor: pointer;
  .selected {
    font-weight: 800;
    color: #fff;
    background-color: var(--Active);
    border: 1px solid var(--Active);
    &:hover {
      background-color: var(--ActiveHover);
    }
  }
  .non_selected {
    font-weight: 500;
    color: #333;
    background-color: var(--InActiveHover);
    &:hover {
      background-color: var(--InActive);
    }
  }
  .no_content {
    cursor: default;
    background-color: var(--InActive);
  }
`;
const TabItem = styled.div`
  flex: 1;
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ProjectMemberTab;
