import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ProjectListForm from "../../components/form.container.list.inner";
import ProjectSearchBar from "../../components/search-bar";
import { MAX_LENGTH } from "../../../../../../../../constants/max-length";
import NameWithCheckBox from "../../components/item/name-with-checkbox";
import NotFoundData from "../../components/item/notfound";
import { ProjectUserUiData } from "../../../../../../../../@types/project/ui/user";
import { _projectMemberRight } from "../../../../../../script/setting/project.member";
import { useRecoilState } from "recoil";
import { userState } from "../../../../../../../../constants/recoil/user";
import ProjectMemberTab from "./tab";
import CircleProgress from "../../../../../../../../components/loading/CircleProgress";
import { ProjectUserMode } from "../../../../../../../../@types/project/ui/user-search-mode";

interface Props {
  rightUsers: ProjectUserUiData[];
  setRightUsers: React.Dispatch<React.SetStateAction<ProjectUserUiData[]>>;
  ownerGuid?: string;
  searchUsers: ProjectUserUiData[];
  setSearchUsers: React.Dispatch<React.SetStateAction<ProjectUserUiData[]>>;
  mode: ProjectUserMode;
  setMode: React.Dispatch<React.SetStateAction<ProjectUserMode>>;
}

const ProjectMemberRightComponent = ({
  rightUsers,
  setRightUsers,
  ownerGuid,
  searchUsers,
  setSearchUsers,
  mode,
  setMode,
}: Props) => {
  const [user] = useRecoilState(userState);

  const [value, setValue] = useState("");
  const [notFound, setNotFound] = useState(false);
  const { getSearchUser, t } = _projectMemberRight({
    setRightUsers,
    ownerGuid,
    value,
    setValue,
    setNotFound,
    setSearchUsers,
  });

  const handleCheckbox = (
    mode: ProjectUserMode,
    usr: ProjectUserUiData
  ) => () => {
    if (mode === ProjectUserMode.COMPANY) {
      const newUsers = rightUsers.map((u) =>
        u.userGuid === usr.userGuid ? { ...usr, isChecked: !usr.isChecked } : u
      );
      setRightUsers(newUsers);
    } else {
      const newUsers = searchUsers.map((u) =>
        u.userGuid === usr.userGuid ? { ...usr, isChecked: !usr.isChecked } : u
      );
      setSearchUsers(newUsers);
    }
  };

  const handleMode = (val: ProjectUserMode) => () => setMode(val);
  const checkMode = (val: ProjectUserMode) =>
    mode === val ? "selected" : "non_selected";
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!user.company) setMode(ProjectUserMode.SEARCH);
  }, []);
  // 🔥
  return (
    <Wrapper>
      <ProjectMemberTab
        company={user.company ? true : false}
        handleMode={handleMode}
        checkMode={checkMode}
      />
      <ProjectListForm size="lg">
        {isLoading && <CircleProgress />}
        {!isLoading && (
          <>
            {mode === ProjectUserMode.SEARCH && (
              <>
                <ProjectSearchBar
                  placeholder={t("user.search_user", "유저 검색")}
                  value={value}
                  setValue={setValue}
                  maxLength={MAX_LENGTH.ID}
                  onSubmit={getSearchUser}
                />
                {searchUsers.map((usr) => (
                  <NameWithCheckBox
                    key={usr.userGuid}
                    title={usr.fullName}
                    isChecked={usr.isChecked}
                    handleCheckbox={handleCheckbox(ProjectUserMode.SEARCH, usr)}
                    user={usr}
                  />
                ))}
                {notFound && (
                  <NotFoundData
                    title={t("user.user_not_found", "유저를 찾을 수 없습니다.")}
                  />
                )}
              </>
            )}

            {mode === ProjectUserMode.COMPANY &&
              rightUsers.map((usr) => (
                <NameWithCheckBox
                  key={usr.userGuid}
                  title={usr.fullName}
                  isChecked={usr.isChecked}
                  handleCheckbox={handleCheckbox(ProjectUserMode.COMPANY, usr)}
                  user={usr}
                />
              ))}
          </>
        )}
      </ProjectListForm>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default ProjectMemberRightComponent;
