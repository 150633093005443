import React from "react";
import styled from "styled-components";
import { Link as ScrollLink } from "react-scroll";
import { useT } from "../../../constants/i18n/useT";
import { FaProjectDiagram, FaEdit } from "react-icons/fa"; // 아이콘 패키지 추가

const ProjectLeft = () => {
  const t = useT();

  return (
    <Wrapper>
      <TitleMenuItem>{t("common.project_setting", "프로젝트 세팅")}</TitleMenuItem>
      <Menu>
        <ScrollLink to="project1" smooth={true} duration={500} offset={-100}>
          <MenuItem>
            <IconWrapper>
              <FaProjectDiagram />
            </IconWrapper>
            1. {t("common.project_creation_and_configuration", "프로젝트 생성 및 변경")}
          </MenuItem>
        </ScrollLink>
        <ScrollLink to="project2" smooth={true} duration={500} offset={-100}>
          <MenuItem>
            <IconWrapper>
              <FaEdit />
            </IconWrapper>
            2. {t("common.project_editing", "프로젝트 변경")}
          </MenuItem>
        </ScrollLink>
      </Menu>
    </Wrapper>
  );
};

export default ProjectLeft;

const Wrapper = styled.div`
  padding: 15px;
  background-color: #fafafa;
  border-radius: 10px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);

  @media (min-width: 650px) {
    position: fixed;
    top: 120px;
    left: 20px;
    width: 220px;
  }
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 12px;
  border-radius: 6px;
  font-size: 14px;
  color: #555;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: #e0e0e0;
    color: #017bfb;
  }
`;

const IconWrapper = styled.div`
  margin-right: 10px;
  font-size: 16px;
`;

const TitleMenuItem = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
`;
