import React from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { useT } from "../../../constants/i18n/useT";
import { cpuPriceState, gpuPriceState } from "../../../constants/recoil/price";

interface Props {
  cpuTotal: number;
  setCpuTotal: React.Dispatch<React.SetStateAction<number>>;
  gpuTotal: number;
  setGpuTotal: React.Dispatch<React.SetStateAction<number>>;
  handleNumberInput: (
    value: string,
    setState: React.Dispatch<React.SetStateAction<number>>
  ) => void;
}

const BillingPriceWorkstation = ({
  cpuTotal,
  gpuTotal,
  setCpuTotal,
  setGpuTotal,
  handleNumberInput,
}: Props) => {
  const [cpuPrice] = useRecoilState(cpuPriceState);
  const [gpuPrice] = useRecoilState(gpuPriceState);
  const t = useT();

  return (
    <Wrapper>
      <Title>
        <h5>{t("billing.workstation_service", "Workstation Service")}</h5>
        <div className="line_bar"></div>
      </Title>
      <PriceBox>
        <PriceEach>
          <label htmlFor="cpu">
            {t("billing.high_performance_cpu_servers", "고성능 CPU 서버")}
          </label>
          <input
            value={cpuTotal}
            onChange={e => handleNumberInput(e.target.value, setCpuTotal)}
            min={0}
            type="number"
            id="cpu"
          />
          <span className="input_left_text">GB</span>
          <Price>
            <span id="blue">
              {cpuPrice.monthBill && (cpuPrice.monthBill * cpuTotal).toLocaleString("ko-KR")}
            </span>{" "}
            {t("billing.krw", "원")}
          </Price>
        </PriceEach>

        <PriceEach>
          <label htmlFor="gpu">
            {t("billing.high_performance_gpu_servers", "고성능 GPU 서버")}
          </label>
          <input
            value={gpuTotal}
            onChange={e => handleNumberInput(e.target.value, setGpuTotal)}
            type="number"
            min={0}
            id="gpu"
          />
          <span className="input_left_text">GB</span>
          <Price>
            <span id="blue">
              {gpuPrice.monthBill && (gpuPrice.monthBill * gpuTotal).toLocaleString("ko-KR")}
            </span>{" "}
            {t("billing.krw", "원")}
          </Price>
        </PriceEach>
      </PriceBox>
    </Wrapper>
  );
};

const Wrapper = styled.article`
  padding: 20px;
  background: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  h5 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }
  .line_bar {
    flex-grow: 1;
    height: 3px;
    background: #333;
    margin-left: 10px;
  }
`;

const PriceBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const PriceEach = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  label {
    flex-shrink: 0;
    width: 140px;
    color: #5e7290;
    font-weight: bold;
  }

  input {
    width: 100px;
    height: 40px;
    padding: 0 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: right;
    font-size: 16px;
  }

  .input_left_text {
    margin-left: 10px;
    color: #5e7290;
    font-weight: bold;
  }
`;

const Price = styled.p`
  margin: 0;
  font-size: 17px;
  color: #333;
  .g_point_a {
    color: #007bff;
    font-weight: bold;
  }
`;

export default BillingPriceWorkstation;
