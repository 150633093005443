import React from "react";
import styled from "styled-components";

interface Props {
  title?: string | null;
}

const NotFoundData = ({ title }: Props) => {
  return <Wrapper>{title || ""}</Wrapper>;
};

const Wrapper = styled.div`
  color: #969696;
  padding: 5px;
  height: 20px;
  margin-top: 5px;
`;

export default NotFoundData;
