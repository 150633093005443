import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useT } from "../../constants/i18n/useT";
import { useRecoilState } from "recoil";
import {
  cpuPriceState,
  gpuPriceState,
  storagePriceState,
} from "../../constants/recoil/price";
import { Price } from "../../@types/billing/price";
import MainWSCard from "./components/card/spec-card";
import CalculateModal from "../billing/calculate/modal";
import ContactModal from "../contact/non-member/modal";
import ContactAndCalculateButtonBox from "./components/button/contact-calculate-button";
import { PriceService } from "../../service/billing/price.service";
import { PriceList } from "../../@types/billing/price-list";

interface Props {
  popCalculate: boolean;
  setPopContact: React.Dispatch<React.SetStateAction<boolean>>;
  popContact: boolean;
  setPopCalculate: React.Dispatch<React.SetStateAction<boolean>>;
}
const Main2 = ({
  popContact,
  popCalculate,
  setPopContact,
  setPopCalculate,
}: Props) => {
  const [cpuPrice, setCpuPrice] = useRecoilState(cpuPriceState);
  const [gpuPrice, setGpuPrice] = useRecoilState(gpuPriceState);
  const [, setStoragePrice] = useRecoilState(storagePriceState);

  const [priceData, setPriceData] = useState(new PriceList());

  const [, setCpuList] = useState<Price[]>([]);
  const [, setGpuList] = useState<Price[]>([]);
  const [, setStorageList] = useState<Price[]>([]);

  const getPriceList = () => {
    PriceService.getList().then((res) => {
      if (res.state === "success") {
        setPriceData(res.data);
      }
    });
  };

  const setPriceList = (priceData: PriceList) => {
    setCpuList(priceData.cpuPriceList || []);
    setGpuList(priceData.gpuPriceList || []);
    setStorageList(priceData.storagePriceList || []);
    setEachPrice();
  };

  const setEachPrice = () => {
    if (priceData.cpuPriceList[0]) setCpuPrice(priceData.cpuPriceList[0]);
    if (priceData.gpuPriceList[0]) setGpuPrice(priceData.gpuPriceList[0]);
    if (priceData.storagePriceList[0])
      setStoragePrice(priceData.storagePriceList[0]);
  };

  useEffect(() => getPriceList(), []);
  useEffect(() => setPriceList(priceData), [priceData]);

  const t = useT();

  return (
    <Wrapper>
      <Title data-testid="title" className="title">
        <strong id="blue">Workstation</strong>
        <h3>{t("main.workstation_example", "사용 워크스테이션 예시")}</h3>
      </Title>

      <WSCardList>
        <MainWSCard type="CPU" price={cpuPrice} />
        <MainWSCard type="GPU" price={gpuPrice} />
        <ContactAndCalculateButtonBox type="column" />
      </WSCardList>
      {popCalculate && (
        <CalculateModal setOpen={setPopCalculate} isOpen={popCalculate} />
      )}
      {popContact && (
        <ContactModal setOpen={setPopContact} isOpen={popContact} />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-image: url("/assets/main/price_bak2.png");
  background-attachment: fixed;
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 100px;
`;
const Title = styled.div`
  padding-top: 100px;
  padding-left: 20px;
  strong {
    font-size: 18px;
    font-weight: 900;
  }
  h3 {
    font-size: 28px;
    font-weight: 700;
    margin-top: 20px;
  }
  @media (min-width: 768px) {
    text-align: center;
    padding: 100px;
    strong {
      font-size: 18px;
    }
    h3 {
      font-size: 40px;
    }
  }
  @media (min-width: 1024px) {
    h3 {
      font-size: 45px;
    }
  }
`;

const WSCardList = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;

  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-around;
  }
`;

export default Main2;
