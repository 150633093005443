import React from "react";
import styled from "styled-components";
import SignupInput from "../../../../components/input/signup/signup-input";
import { useT } from "../../../../constants/i18n/useT";
import { SignupForm } from "../../../../@types/user/signup/signup.form";
import { InputService } from "../../../../service/common/input.service";
import CompanyInput from "./company-input";
import { RegisterCompany } from "../../../../@types/user/company";

interface Props {
  signupForm: SignupForm;
  setSignupForm: React.Dispatch<React.SetStateAction<SignupForm>>;
  company: RegisterCompany;
  setCompany: React.Dispatch<React.SetStateAction<RegisterCompany>>;
}

const SignupChapter2 = ({
  signupForm,
  setSignupForm,
  company,
  setCompany,
}: Props) => {
  const t = useT();

  const handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSignupForm((prev) => ({ ...prev, fullName: e.target.value }));
  };

  const handleMobile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSignupForm({
      ...signupForm,
      mobile: InputService.handleMobileNumber(e),
    });
  };

  return (
    <div className="chapter_2">
      <SignupTitle title={t("signup.user_info", "회원 정보")} />
      <SignupInput
        value={signupForm.fullName}
        essential
        onChange={handleName}
        title={t("signup.name", "이름") || ""}
        placeholder={
          t("signup.please_enter_your_name", "사용자 이름을 입력하세요.") || ""
        }
      />
      <SignupInput
        value={signupForm.mobile}
        essential
        onChange={handleMobile}
        title={t("signup.phone_number", "휴대폰 번호") || ""}
        placeholder={
          t(
            "signup.please_enter_your_phone_number",
            "휴대전화번호를 '-' 없이 입력해주세요."
          ) || ""
        }
      />
      <CompanyInput company={company} setCompany={setCompany} />
    </div>
  );
};

const SignupTitle = ({ title }: { title: string }) => {
  return (
    <TitleWrap>
      <h5>{title}</h5>
      <div className="line_bar" />
    </TitleWrap>
  );
};
const TitleWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  h5 {
    font-size: 20px;
    font-weight: bold;
  }
  .line_bar {
    width: 60%;
    height: 3px;
    background-color: #333;
    margin-left: 20px;
  }
  @media only screen and (min-width: 768px) {
    h5 {
      font-size: 24px;
    }
    .line_bar {
      width: 60%;
    }
  }
`;

export default SignupChapter2;
