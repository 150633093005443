import React from "react";

import { useRecoilState } from "recoil";
import { useT } from "../../../../constants/i18n/useT";
import { useAlert } from "../../../../components/alert/alert.service";
import { userState } from "../../../../constants/recoil/user";
import { AuthService } from "../../../../service/user/user.service";
import { WindowService } from "../../../../service/common/window.service";
import { ModalProps } from "../../../../components/modal";
import ModalPopup from "../../../../components/modal/popup";

interface Props extends ModalProps {
  userId?: string;
  password?: string;
}

export const SessionLoginModal = (props: Props) => {
  const { userId, password } = props;
  const [, setUser] = useRecoilState(userState);
  const { setAlert } = useAlert();

  const postLogin = () => {
    if (userId && password) {
      console.log(userId, password);
      AuthService.terminateLogin(userId, password)
        .then((res) => {
          if (res.state === "success") {
            setUser(res.data);
            WindowService.goWorkstation();
          } else {
            setAlert(res.message);
          }
        })
        .catch((err) =>
          setAlert(
            t(
              "common.error",
              "현재 서버가 원활하지 않습니다. 잠시 후 시도해주세요."
            )
          )
        );
    }
  };
  const t = useT();

  return (
    <ModalPopup
      onSubmit={() => postLogin()}
      buttonText={t("common.sign_in", "로그인")}
      {...props}
    >
      {t(
        "user.would_you_like_to_disconnect",
        "기존의 연결을 해제시키고 로그인하시겠습니까?"
      )}
    </ModalPopup>
  );
};
