import React from "react";

import { useT } from "../../../../constants/i18n/useT";

import Button from "../../../../components/button/Button";
import { ModalProps } from "../../../../components/modal";
import ModalPopup from "../../../../components/modal/popup";

const LoginFailed = (props: ModalProps) => {
  const t = useT();

  return (
    <ModalPopup size="sm" {...props}>
      <div className="pop_logout_text">
        <h5 className="pop_logout_title">
          {t("user.the_account_is_already_in_use", "이미 이용중인 계정입니다.")}
          <br />
          {t(
            "user.please_try_logging_in_again_after",
            "사용 종료 후 다시 로그인을 시도해 주십시오."
          )}
        </h5>
      </div>
      <div className="btn_box">
        <Button size="full" onClick={() => props.setOpen(false)}>
          {t("common.close", "닫기")}
        </Button>
      </div>
    </ModalPopup>
  );
};

export default LoginFailed;
