import { WindowService } from "../common/window.service";
import i18n from "../../constants/i18n";
import { API } from "./api";
import {
  requestSetting,
  axiosInstance,
  axiosInstanceWithToken,
  axiosNoAuthNoAlertInstance,
  checkToken,
} from "./setting";

export const APICLIENT = {
  AUTH: requestSetting(axiosInstanceWithToken),
  AUTH_NOALERT: requestSetting(axiosInstanceWithToken),

  NONAUTH: requestSetting(axiosInstance),
  NONAUTH_NOALERT: requestSetting(axiosNoAuthNoAlertInstance),
};

const responseSetting = () => {
  // 일반적 데이터 요청
  APICLIENT.AUTH.interceptors.response.use(
    response => {
      checkToken(response);

      // if (response.data.state !== "success") {
      //   console.error(response.data);

      switch (response.data.errorType) {
        // 토큰이 만료되었거나, 토큰이 없거나, 토큰이 잘못되었거나
        case "SessionNotFoundException":
          logout();
          break;

        case "ErrorException":
          alertError();
          break;
        // }
      }

      if (response.data.message) alert(response.data.message);

      return response;
    },
    err => {
      console.error(err);
      // alertError();
      return Promise.reject(err);
    }
  );

  APICLIENT.AUTH_NOALERT.interceptors.response.use(
    response => {
      checkToken(response);

      // if (response.data.state !== "success") {
      //   console.error(response.data);
      switch (response.data.errorType) {
        case "SessionNotFoundException":
          logout();
          break;
      }
      // }
      return response;
    },
    err => {
      console.error(err.response);
      return Promise.reject(err);
    }
  );

  APICLIENT.NONAUTH.interceptors.response.use(
    response => {
      checkToken(response);

      if (response.data.message) alert(response.data.message);

      return response;
    },
    err => {
      console.error(err.response);
      // alertError();
      return Promise.reject(err);
    }
  );

  APICLIENT.NONAUTH_NOALERT.interceptors.response.use(
    response => {
      checkToken(response);

      return response;
    },
    err => {
      console.error(err.response);
      return Promise.reject(err);
    }
  );

  if (process.env.NODE_ENV === "production") {
    // console.log = function no_console() {};
    // console.warn = function no_console() {};
  }
};
responseSetting();

const alertError = () => {
  alert(i18n.t("common.error", "현재 서버가 원활하지 않습니다. 잠시 후 시도해주세요."));
};

const logout = () => {
  sessionStorage.removeItem("x-access-token");
  localStorage.clear(); //
  WindowService.goLogin();
};

export { API };
