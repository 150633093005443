import { APICLIENT, API } from "../api";
import { CreditBook } from "../../@types/billing/credit";

export const CreditService = {
  // 사용하기 전에 충분한 크레딧이 있는지 확인
  hasEnoughCredit: async (userGuid?: string) => {
    const response = await APICLIENT.AUTH.get(`${API.CREDIT}/hasEnoughBalance/${userGuid}`);
    return response.data;
  },

  // "Email, Workstation" 등으로 받을 수 있다.
  getAlarmWaysPeriods: async () => {
    const response = await APICLIENT.NONAUTH_NOALERT.get(`${API.ALARM_INFO}/getAlarmWaysPeriods`);
    return response.data;
  },

  getCreditBookList: async (userGuid: string | undefined, chargingMonth: string) => {
    const response = await APICLIENT.AUTH.post(`${API.BILLING}/user/creditBook/getList`, {
      filter: {
        user: { userGuid },
        chargingMonth: chargingMonth,
      },
      paginate: { limit: 10000 },
    });
    return response.data;
  },

  getCreditList: async (userGuid: string | undefined, queryString: string) => {
    const response = await APICLIENT.AUTH.get(
      `${API.BILLING}/user/${userGuid}/credit/history?${queryString}`
    );
    return response.data;
  },

  /**
   * 날짜, 시간 나누기 ( chargingStartDt to startDt )
   * before : {2023-01-16 00:00:00}
   * after : {date: '2023-01-06', time: '00:00:00'}
   */
  ConvertDate: (credit: CreditBook) => {
    if (credit.chargingStartDt) {
      credit.startDt = {
        date: credit.chargingStartDt.split(" ")[0],
        time: credit.chargingStartDt.split(" ")[1],
      };
    }
    if (credit.chargingEndDt) {
      credit.EndDt = {
        date: credit.chargingEndDt.split(" ")[0],
        time: credit.chargingEndDt.split(" ")[1],
      };
    }
  },
  /**
   * 몇시간 몇분 몇초 썼는지
   * @param minute
   * @returns {hour,minute,second}
   */
  getConvertTime: (minute: number) => {
    let result: { hour: number; minute: number; second: number } = {
      hour: 0,
      minute: 0,
      second: 0,
    };

    if (Math.floor(minute / 60) > 0) {
      result.hour = Math.floor(minute / 60);
      minute -= Math.floor(minute / 60) * 60;
    }

    result.minute = minute;

    return result;
  },
};
