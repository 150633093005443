export class MappingWorkstation {
  storage: { storageId: string };
  driveLetter: string;
  mappingWSId?: string;

  constructor(storageId: string, driveLetter: string, mappingWSId?: string) {
    this.storage = { storageId: storageId };
    this.driveLetter = driveLetter;
    if (mappingWSId) this.mappingWSId = mappingWSId;
  }
}
