import React from "react";
import { useT } from "../../constants/i18n/useT";
import { ModalProps } from "../modal";
import ModalPopup from "../modal/popup";

interface Props extends ModalProps {
  setPopContact: React.Dispatch<React.SetStateAction<boolean>>;
}

const NoCreditAlertPage = (props: Props) => {
  const goChargePage = () => {
    props.setOpen(false);
    props.setPopContact(true);
  };
  const t = useT();

  return (
    <ModalPopup
      buttonText={t("common.inquire_charge", "충전 문의")}
      onSubmit={goChargePage}
      {...props}
    >
      <h5 className="pop_logout_title">
        {t("common.there_is_not_enough_credit", "Credit 잔액이 부족합니다.")}
        <br />
        {t("common.please_recharge_and_use", "충전 후 이용해주세요.")}
      </h5>
    </ModalPopup>
  );
};

export default NoCreditAlertPage;
