import React from "react";
import styled from "styled-components";
import { RiPlayCircleLine } from "@react-icons/all-files/ri/RiPlayCircleLine";

interface Props {
  setPopActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const PlayButton = ({ setPopActive }: Props) => {
  return (
    <Wrapper type="button" title="play" onClick={() => setPopActive(true)}>
      {/* <img id="play_btn" alt="play" src="/assets/user/play.png" /> */}
      <RiPlayCircleLine size={32} color="#05f509" />
    </Wrapper>
  );
};

const Wrapper = styled.button`
  background: none;
  border: none;
  margin-left: 40px;
  cursor: pointer;

  img {
    filter: invert(61%) sepia(70%) saturate(886%) hue-rotate(60deg) brightness(110%) contrast(111%);
    width: 23px;
    height: 23px;
  }
  @media (min-width: 768px) {
    img {
      width: 25px;
      height: 25px;
    }
  }
`;

export default PlayButton;
