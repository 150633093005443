import { Code } from "../../@types/infra/code";
import { APICLIENT, API } from "../api";

export class Account {
  locales: Code[] = [];
}

export const AccountService = {
  getUi: async () => {
    const response = await APICLIENT.NONAUTH_NOALERT.get(
      `${API.USER}/getUiData`
    );
    return response.data;
  },
  updateLocale: async (locale: string) => {
    const response = await APICLIENT.AUTH.post(`${API.USER}/update/locale`);
    return response.data;
  },
};
