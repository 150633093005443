import React, { useEffect, useState } from "react";
import Modal from "../../../../../../components/modal/modal-form";
import Button from "../../../../../../components/button/Button";
import styled from "styled-components";
import { RiErrorWarningLine } from "@react-icons/all-files/ri/RiErrorWarningLine";
import { useT } from "../../../../../../constants/i18n/useT";

interface Props {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getList: () => void;
}

const WSCantStart = ({ isOpen, setOpen, getList }: Props) => {
  const t = useT();

  return (
    <Modal isOpen={isOpen} setOpen={setOpen}>
      <ModalContent>
        <>
          <StyledRiErrorWarningLine />
          <WSContentWrapper>
            <WSContent>
              {t(
                "workstation.the_workstation_is_currently_unavailable_because_there_is_no_baremetal_available",
                "현재 가용 베어메탈이 없어 워크스테이션을 사용할 수 없습니다."
              )}
            </WSContent>
            <WSContent>
              {t(
                "workstation.please_try_again_in_a_moment",
                " 잠시 후에 다시 시도해 주세요."
              )}
            </WSContent>
          </WSContentWrapper>

          <ButtonWrapper>
            <CloseButton onClick={() => setOpen(false)}>
              {t("common.confirm", "확인")}
            </CloseButton>
          </ButtonWrapper>
        </>
        {/* )} */}
      </ModalContent>
    </Modal>
  );
};

const WSContentWrapper = styled.div``;

const WSContent = styled.h3`
  margin-top: 5px;
`;

const ModalContent = styled.div`
  text-align: center;
`;

const ButtonWrapper = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const CloseButton = styled(Button)`
  background-color: var(--InActive);
  color: var(--Active);
  margin-bottom: 30px;
`;

const StyledRiErrorWarningLine = styled(RiErrorWarningLine)`
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 40px;
  color: red;
`;
export default WSCantStart;
