import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { userState } from "../../../constants/recoil/user";
import { useAlert } from "../../../components/alert/alert.service";
import { useT } from "../../../constants/i18n/useT";
import ModalPopup from "../../../components/modal/popup";
import { AuthService } from "../../../service/user/user.service";
import { useNavigate } from "react-router-dom";
import { ModalProps } from "../../../components/modal";
import { WindowService } from "../../../service/common/window.service";
import { WorkstationService } from "../../../service/workstation/workstation.service";
import { Workstationlist } from "../../../@types/workstation/response";

const FILTER = {
  MY_WORKSTATION: "MY_WORKSTATION",
  SHARED_BY_OTHERS: "SHARED_BY_OTHERS",
};

const PROJECT_ASSIGNED = {
  TRUE: "TRUE",
  FALSE: "FALSE",
};

const PROJECT_ROLES = {
  ARTIST: "ARTIST",
  DIRECTOR: "DIRECTOR",
};

const PROGRESS_RAGE = {
  TRUE: "TRUE",
  FALSE: "FALSE",
};

const LogoutModal = (props: ModalProps) => {
  const [user, setUser] = useRecoilState(userState);
  const [usingWorkstation, setUsingWorkstation] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const t = useT();
  const navigate = useNavigate();

  const { setAlert } = useAlert();

  const broadcastChannel = new BroadcastChannel("logout-channel");

  const logout = () => {
    AuthService.logout()
      .then(res => {
        if (res.state === "success") {
          broadcastChannel.postMessage("logout"); // Send logout event
          return navigate("/");
        }
        return setAlert(
          t(
            "user.there_was_an_error_logging_out",
            "로그아웃 오류입니다. 로그인을 다시 부탁드립니다."
          )
        );
      })
      .catch(err => {
        setAlert(
          t(
            "user.there_was_an_error_logging_out",
            "로그아웃 오류입니다. 로그인을 다시 부탁드립니다."
          )
        );
        navigate("/login");
      })
      .finally(() => {
        setUser(new AuthService());
        WindowService.clearBrowser();
        props.setOpen(false);
      });
  };

  const getList = () => {
    Promise.all([
      WorkstationService.getMyWorkstations(
        user.userGuid!,
        FILTER.MY_WORKSTATION,
        PROGRESS_RAGE.TRUE
      ),
      WorkstationService.getProjectSharedWorkstationsForDirector(
        user.userGuid!,
        FILTER.SHARED_BY_OTHERS,
        PROJECT_ASSIGNED.TRUE,
        PROJECT_ROLES.DIRECTOR,
        PROGRESS_RAGE.TRUE
      ),
      WorkstationService.getProjectSharedWorkstationsForArtist(
        user.userGuid!,
        FILTER.SHARED_BY_OTHERS,
        PROJECT_ASSIGNED.TRUE,
        PROJECT_ROLES.ARTIST,
        PROGRESS_RAGE.TRUE
      ),
      WorkstationService.getUnassignedSharedWorkstations(
        user.userGuid!,
        FILTER.SHARED_BY_OTHERS,
        PROJECT_ASSIGNED.FALSE,
        PROGRESS_RAGE.TRUE
      ),
    ])
      .then(
        ([
          myWorkstations,
          unassignedSharedWorkstations,
          projectSharedWorkstationsForArtist,
          projectSharedWorkstationsForDirector,
        ]) => {
          const allWorkstation = {
            myWorkstations: myWorkstations,
            sharedAsDirectorWorkstations: projectSharedWorkstationsForDirector,
            sharedAsMemberWorkstations: projectSharedWorkstationsForArtist,
            sharedAsNoProjectWorkstations: unassignedSharedWorkstations,
          };

          setUsingWorkstation(WorkstationService.getUsingList(allWorkstation));
        }
      )
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <ModalPopup
      buttonText={t("common.sign_out", "로그아웃")}
      onSubmit={logout}
      type={usingWorkstation.length > 0 ? "delete" : "confirm"}
      {...props}
    >
      {!isLoading && (
        <>
          <h1>{t("user.would_you_like_to_sign_out", "로그아웃 하시겠습니까?")}</h1>
          <div>
            {usingWorkstation.length > 0 && (
              <div>
                {t(
                  "user.the_following_workstations_are_in_use",
                  "다음 Workstation이 사용중입니다."
                )}
                <p id="blue">{usingWorkstation.toString()}</p>
                <br />
                {t(
                  "user.even_if_you_log_out",
                  "로그아웃하셔도 워크스테이션 정보와 부팅 디스크 정보가 남아있습니다."
                )}
              </div>
            )}
          </div>
        </>
      )}
    </ModalPopup>
  );
};

export default LogoutModal;
