import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { useT } from "../../../constants/i18n/useT";
import { useRecoilState } from "recoil";
import { localeState } from "../../../constants/recoil/locale";

const fadeIn = keyframes`
from {
  opacity: 0;
}
to {
  opacity: 1;
}
`;

const Page5 = () => {
  const t = useT();
  const [locale] = useRecoilState(localeState);

  const [isOpen, setIsOpen] = useState(false);
  const [img, setImg] = useState("");

  const openModal = (imgSrc: string) => {
    setImg(imgSrc);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div>
        <p>
          {t(
            "workstation.mac_client_download_message9",
            "PCoIP 프로그램을 클릭하여 실행합니다. 이 때 Mac 보안 설정을 수정합니다."
          )}
        </p>
        <br />
        <p>
          {t(
            "workstation.mac_client_download_message10",
            "다운로드된 앱을 열 것인지 묻는 경고창이 뜰 수 있습니다. “열기” 버튼을 클릭합니다."
          )}
        </p>
        <br />
        <p>
          {t(
            "workstation.mac_client_download_message11",
            "PCoIP 프로그램이 성공적으로 실행되면 다음 오른쪽 창과 같은 다이얼로그가 표시되는 것을 확인할 수 있습니다."
          )}
        </p>
        <br />
        <p>
          {t(
            "workstation.mac_client_download_message12",
            "PCoIP 프로그램을 종료합니다."
          )}
        </p>

        {locale === "ko_KR" && (
          <>
            <Image1
              onClick={() => openModal("/assets/user/download/download6.png")}
              src="/assets/user/download/download6.png"
              alt="Download"
            />
            <Image2
              onClick={() => openModal("/assets/user/download/download7.png")}
              src="/assets/user/download/download7.png"
              alt="Download"
            />
          </>
        )}
        {locale === "en_US" && (
          <>
            <Image1
              onClick={() =>
                openModal("/assets/user/download/en_download4.png")
              }
              src="/assets/user/download/en_download4.png"
              alt="Download"
            />
            <Image2
              onClick={() =>
                openModal("/assets/user/download/en_download5.png")
              }
              src="/assets/user/download/en_download5.png"
              alt="Download"
            />
          </>
        )}
        {locale === "ja_JP" && (
          <>
            <Image1
              onClick={() =>
                openModal("/assets/user/download/jp_download4.png")
              }
              src="/assets/user/download/jp_download4.png"
              alt="Download"
            />
            <Image2
              onClick={() =>
                openModal("/assets/user/download/jp_download5.png")
              }
              src="/assets/user/download/jp_download5.png"
              alt="Download"
            />
          </>
        )}
        {locale === "zh_CN" && (
          <>
            <Image1
              onClick={() =>
                openModal("/assets/user/download/cn_download4.png")
              }
              src="/assets/user/download/cn_download4.png"
              alt="Download"
            />
            <Image2
              onClick={() =>
                openModal("/assets/user/download/cn_download5.png")
              }
              src="/assets/user/download/cn_download5.png"
              alt="Download"
            />
          </>
        )}

        <br />
        <br />
        <p>
          {t(
            "workstation.mac_client_download_message13",
            "M2MClient를 실행하기 위한 준비가 모두 완료되었습니다. M2M 웹페이지에 접속하여 워크스테이션을 실행하세요."
          )}
        </p>
      </div>
      {isOpen && (
        <Modal onClick={closeModal}>
          <ModalContent src={img} onClick={(e) => e.stopPropagation()} />
          <CloseButton onClick={closeModal}>&times;</CloseButton>
        </Modal>
      )}
    </>
  );
};

const Image1 = styled.img`
  margin-top: 15px;
  width: 35%;
`;

const Image2 = styled.img`
  margin-top: 15px;
  width: 65%;
`;

const Modal = styled.div`
  position: fixed;
  z-index: 1050;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  animation: ${fadeIn} 0.5s ease-out;
`;

const ModalContent = styled.img`
  margin: auto;
  display: block;
  max-width: 90%;
  max-height: 80%;
  margin-top: 10%;
  animation: ${fadeIn} 0.5s ease-out;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
`;

export default Page5;
