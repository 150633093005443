import React from "react";

import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useT } from "../../constants/i18n/useT";
import Button from "../button/Button";

const NotFoundPage = () => {
  const navigate = useNavigate();
  const t = useT();

  return (
    <Wrapper>
      <div className="content-box">
        <h1>{t("common.sorry", "죄송합니다.")}</h1>
        <h1>
          {t(
            "common.the_page_you_requested_could_not_be_found",
            "요청하신 페이지를 찾을 수 없습니다."
          )}
        </h1>
      </div>
      <Button color="blue" onClick={() => navigate(-1)}>
        {t("common.go_back", "이전으로")}
      </Button>
    </Wrapper>
  );
};
const Wrapper = styled.section`
  height: 100vh;
  width: 100vw;
  background: url("/assets/main/top_back.jpg") center no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .content-box {
    width: 30vw;
    height: 15vh;
    margin: 0 auto 100px auto;
    background-color: #ffffff;
    h1 {
      margin: 30px;
    }
  }
`;

export default NotFoundPage;
