import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ProjectMemberComponent from "./member";
import ProjectWSComponent from "./workstation";
import ProjectStorageComponent from "./storage";
import { ProjectForm } from "../../../../../../@types/project/form";
import { Project } from "../../../../../../@types/project/project";
import { ProjectStorageUiData } from "../../../../../../@types/project/ui/storage";
import { ProjectWorkstationUiData } from "../../../../../../@types/project/ui/workstation";
import { ProjectUserUiData } from "../../../../../../@types/project/ui/user";
import { _projectSetting } from "../../../../script/setting/project.setting";

interface Props {
  projectForm: ProjectForm;
  setProjectForm: React.Dispatch<React.SetStateAction<ProjectForm>>;
  project?: Project;
  getList: () => void;
  assignedStorages?: any[] | [];
  assignedWorkstations?: any[] | [];
}

const ProjectSettingComponent = ({
  projectForm,
  setProjectForm,
  project,
  getList,
  assignedStorages,
  assignedWorkstations,
}: Props) => {
  const [leftStorages, setLeftStorages] = useState<ProjectStorageUiData[]>([]);
  const [leftWorkstations, setLeftWorkstations] = useState<ProjectWorkstationUiData[]>([]);
  const [leftUsers, setLeftUsers] = useState<ProjectUserUiData[]>([]);

  const [ws, setWs] = useState<any[]>([]);
  const [st, setSt] = useState<any[]>([]);
  const [user, setUser] = useState<any[]>([]);
  const [wsUser, setWsUser] = useState<any[]>([]);

  const [currentStorage, setCurrentStorage] = useState<any>();
  const [currentWorkstation, setCurrentWorkstation] = useState<any>();
  const [currentUser, setCurrentUser] = useState<any>();

  const { handleProjectForm } = _projectSetting({
    project,
    leftUsers,
    leftWorkstations,
    leftStorages,
    projectForm,
    setProjectForm,

    ws,
    currentStorage,
    st,
    currentWorkstation,
    user,
    wsUser,
  });

  useEffect(() => {
    handleProjectForm();
  }, [
    leftStorages,
    leftWorkstations,
    leftUsers,
    project,
    ws,
    currentStorage,
    st,
    currentWorkstation,
    user,
    wsUser,
  ]);

  // useEffect(() => {
  //   console.log("leftStorages: ", leftStorages);
  // }, [leftStorages]);

  // useEffect(() => {
  //   console.log("leftWorkstations: ", leftWorkstations);
  // }, [leftWorkstations]);

  // useEffect(() => {
  //   console.log("leftUsers: ", leftUsers);
  // }, [leftUsers]);

  return (
    <Wrapper>
      <ProjectMemberComponent project={project} leftUsers={leftUsers} setLeftUsers={setLeftUsers} />
      <ProjectWSComponent
        project={project}
        leftStorages={leftStorages}
        setLeftStorages={setLeftStorages}
        leftWorkstations={leftWorkstations}
        setLeftWorkstations={setLeftWorkstations}
        assignedStorages={assignedStorages}
        assignedWorkstations={assignedWorkstations}
        getList={getList}
        setSt={setSt}
        st={st}
        setCurrentWorkstation={setCurrentWorkstation}
        currentWorkstation={currentWorkstation}
        setWsUser={setWsUser}
        wsUser={wsUser}
      />
      <ProjectStorageComponent
        project={project}
        leftStorages={leftStorages}
        setLeftStorages={setLeftStorages}
        leftWorkstations={leftWorkstations}
        setLeftWorkstations={setLeftWorkstations}
        assignedStorages={assignedStorages}
        assignedWorkstations={assignedWorkstations}
        getList={getList}
        setWs={setWs}
        ws={ws}
        setCurrentStorage={setCurrentStorage}
        currentStorage={currentStorage}
        setUser={setUser}
        user={user}
      />
    </Wrapper>
  );
};
const Wrapper = styled.div`
  overflow-y: scroll;
  height: 100%;
  padding: 0 20px;
  &::-webkit-scrollbar {
    background-color: rgb(255, 255, 255);
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(112, 112, 112, 0.514);
    border-radius: 10px;
    height: 10px;
  }
`;

export default ProjectSettingComponent;
