import { APICLIENT, API } from "../api";

export class FindUserService {
  userId?: string;
  fullName?: string;
  mail?: string;
  certificationNumber?: string;
  newPassword?: string;
  checkValidateId = async () => {
    const response = await APICLIENT.NONAUTH_NOALERT.get(
      `${API.AUTH_FIND}/validateId/${this.userId}`
    );
    return response.data;
  };

  sendPwCertification = async () => {
    const response = await APICLIENT.NONAUTH_NOALERT.post(
      `${API.AUTH_FIND}/password/sendCertificationNumber`,
      this
    );
    return response.data;
  };
  validEmailNumber = async () => {
    const response = await APICLIENT.NONAUTH_NOALERT.get(
      `${API.AUTH_MAIL}/validateCertificationNumber?mail=${this.mail}&certificationNumber=${this.certificationNumber}`
    );
    return response.data;
  };
  changePassword = async () => {
    const response = await APICLIENT.NONAUTH_NOALERT.post(
      `${API.AUTH_FIND}/password`,
      this
    );
    return response.data;
  };
}
