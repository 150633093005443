import React from 'react'
import styled from 'styled-components'

const Star = () => {
  return (
    <Wrapper id='blue'>*</Wrapper>
  )
}
const Wrapper = styled.span`
  padding-left: 5px;
`

export default Star