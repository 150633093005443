import React from "react";
import { Price } from "../../../../@types/billing/price";
import styled from "styled-components";
import { useT } from "../../../../constants/i18n/useT";

interface Props {
  price: Price;
  type: "CPU" | "GPU";
}
const MainWSCard = ({ price, type }: Props) => {
  const t = useT();

  return (
    <Wrapper>
      <div className="image">
        {type == "CPU" && <img alt="img" src="/assets/main/cpu_icon.png" />}
        {type == "GPU" && <img alt="img" src="/assets/main/gpu_icon.png" />}
      </div>
      {price.spec && (
        <div className="content">
          {type == "CPU" && <h5>{t("main.cpu_tasks", "CPU 작업")}</h5>}
          {type == "GPU" && <h5>{t("main.gpu_tasks", "GPU 작업")}</h5>}
          <dl>
            <dt>CPU</dt>
            <dd data-testid="cpu_name">
              {price.spec.cpu && price.spec.cpu.name}
            </dd>
          </dl>
          <dl>
            <dt>RAM</dt>
            <dd>{price.spec.ram && price.spec.ram.name}</dd>
          </dl>
          {type == "GPU" && (
            <dl>
              <dt>GPU</dt>
              <dd>{price.spec.gpu && price.spec.gpu.name}</dd>
            </dl>
          )}
        </div>
      )}
      <div className="bottom_info">
        <p>※ {t("main.includes_pcoip_agent", "PCoIP Agent 포함")}</p>
        <p>
          ※ {price.unitPrice}
          {t("billing.krw", "원") + t("billing.per_minute", "/분")}
        </p>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.article`
  margin-bottom: 40px;
  width: 180px;
  min-height: 250px;
  background-color: #fafafa;
  border: 1px dashed #ddd;
  padding: 30px;
  .image {
    text-align: center;
    height: 100px;
    img {
      width: 100px;
    }
  }
  .content {
    h5 {
      font-size: 18px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    dl {
      font-size: 15px;
      margin-bottom: 10px;
      display: flex;
      justify-content: flex-start;
      dt {
        width: 35%;
        flex: none;
      }
      dd {
        color: #5e7290;
      }
    }
  }
  .bottom_info {
    margin-top: 20px;
    color: #888;
    font-size: 13px;
  }

  &:nth-child(1) {
    margin-right: 15vw;
  }
  &:nth-child(2) {
    margin-left: 15vw;
  }

  @media (min-width: 1024px) {
    &:nth-child(1) {
      margin: auto;
    }
    &:nth-child(2) {
      margin: auto;
    }
    margin-top: 30px;
    width: 250px;
    min-height: 400px;
    background-color: #fafafa;
    border: 1px dashed #ddd;
    padding: 30px;

    .image {
      text-align: center;
      padding-top: 20px;
    }
    .content {
      padding-bottom: 4vh;
      min-height: 150px;

      h5 {
        font-size: 1.3rem;
        margin-top: 1.5rem;
        margin-bottom: 0.7rem;
      }
      dl {
        font-size: 0.9rem;
        margin-bottom: 0.8rem;
        display: flex;
        justify-content: flex-start;
        dt {
          width: 4rem;
          flex: none;
        }
        dd {
          color: #5e7290;
        }
      }
    }
    .bottom_info {
      color: #888;
    }
  }
`;

export default MainWSCard;
