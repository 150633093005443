import { User } from "../../user/user";

export interface DefaultUserUiData {
  userGuid: string;
  fullName: string;
  userId: string;
  isChecked: boolean;
  deleted: boolean;
}

export class ProjectUserUiData implements DefaultUserUiData {
  isChecked: boolean;
  deleted: boolean;
  userGuid: string;
  fullName: string;
  userId: string;

  projectRole?: { cd: string; name?: string };
  projectUserId?: number;
  workstationUserId?: number;
  storageUserId?: number;

  constructor(user: User, projectRole?: { cd: string; name?: string }) {
    this.isChecked = false;
    this.deleted = false;
    this.userGuid = user.userGuid || "";
    this.fullName = user.fullName || "";
    this.userId = user.userId || "";
    this.projectRole = projectRole || { cd: "Project.Role.Artist" };
  }
}
