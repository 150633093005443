import React, { useRef, useEffect, useCallback } from "react";
import styled from "styled-components";
import * as d3 from "d3";
import { useT } from "../../../../constants/i18n/useT";

interface BillingData {
  chargingEndDt: string;
  balance: number;
  credit: number;
  resourceSize: string;
  creditBookType: string;
  name: string;
}

interface LineChartProps {
  billings: any[];
  setShowChart: React.Dispatch<React.SetStateAction<boolean>>;
}

const LineChart: React.FC<LineChartProps> = ({ billings, setShowChart }) => {
  const t = useT();

  const ref = useRef<SVGSVGElement | null>(null);
  const modalRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        setShowChart(false);
      }
    },
    [setShowChart]
  );

  const handleEscKey = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setShowChart(false);
      }
    },
    [setShowChart]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleEscKey);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("keydown", handleEscKey);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleEscKey, handleClickOutside]);

  useEffect(() => {
    if (!billings || billings.length === 0) return;

    const chartData = billings
      .map((billing: BillingData) => ({
        chargingEndDt: billing.chargingEndDt,
        balance: +billing.balance,
        credit: billing.credit,
        resourceSize: billing.resourceSize,
        creditBookType: billing.creditBookType,
        name: billing.name,
      }))
      .reverse();

    const groupedData = d3.group(chartData, d => d.chargingEndDt);

    const minBalanceData = Array.from(groupedData.values()).map((group: BillingData[]) =>
      group.reduce((min, data) => (data.balance < min.balance ? data : min), group[0])
    );

    const svg = d3
      .select(ref.current)
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("viewBox", "0 0 1600 800")
      .attr("preserveAspectRatio", "xMidYMid meet")
      .style("background", "#ffffff")
      .style("overflow", "visible");

    const margin = { top: 50, right: 30, bottom: 70, left: 60 };
    const width = 1600 - margin.left - margin.right;
    const height = 800 - margin.top - margin.bottom;

    svg.selectAll("*").remove();

    const x = d3
      .scaleBand()
      .domain(Array.from(groupedData.keys()))
      .range([0, width])
      .padding(0.1);

    const minY = d3.min(minBalanceData, d => d.balance) || 0;
    const maxY = d3.max(minBalanceData, d => d.balance) || 0;
    const customMinY = minY - 10;
    const customMaxY = maxY + 10;

    const y = d3
      .scaleLinear()
      .domain([customMinY, customMaxY])
      .range([height, 0]);

    const xAxis = (g: any) =>
      g
        .attr("transform", `translate(${margin.left},${height})`)
        .call(
          d3
            .axisBottom(x)
            .tickValues(x.domain().filter((d, i) => !(i % Math.ceil(x.domain().length / 10))))
            .tickSize(-height)
            .tickSizeOuter(0)
        )
        .selectAll("text")
        .attr("transform", "rotate(45)")
        .style("text-anchor", "start")
        .attr("x", 9)
        .attr("y", 5)
        .style("font-size", "12px")
        .style("color", "#555");

    const yAxis = (g: any) =>
      g
        .attr("transform", `translate(${margin.left},0)`)
        .call(d3.axisLeft(y).tickSize(-width))
        .call((g: any) => g.select(".domain").remove())
        .selectAll("text")
        .style("font-size", "12px")
        .style("color", "#555");

    svg
      .append("g")
      .call(yAxis)
      .selectAll(".tick line")
      .attr("stroke", "#ddd");

    svg
      .append("g")
      .call(xAxis)
      .selectAll(".tick line")
      .attr("stroke", "#ddd");

    const line = d3
      .line<BillingData>()
      .defined(d => !isNaN(d.balance))
      .x(d => x(d.chargingEndDt)! + x.bandwidth() / 2 + margin.left)
      .y(d => y(d.balance))
      .curve(d3.curveMonotoneX);

    svg
      .append("path")
      .datum(minBalanceData)
      .attr("fill", "none")
      .attr("stroke", "#017bfb")
      .attr("stroke-width", 3)
      .attr("stroke-linejoin", "round")
      .attr("stroke-linecap", "round")
      .attr("d", line)
      .attr("stroke-dasharray", function() {
        const length: any = this.getTotalLength();
        return `${length} ${length}`;
      })
      .attr("stroke-dashoffset", function() {
        return this.getTotalLength();
      })
      .transition()
      .ease(d3.easeLinear)
      .attr("stroke-dashoffset", 0);

    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0)
      .style("display", "none");

    svg
      .selectAll(".dot")
      .data(minBalanceData)
      .enter()
      .append("circle")
      .attr("class", "dot")
      .attr("cx", d => x(d.chargingEndDt)! + x.bandwidth() / 2 + margin.left)
      .attr("cy", d => y(d.balance))
      .attr("r", 0)
      .attr("fill", "#017bfb")
      .transition()
      .duration(1000)
      .attr("r", 6);

    svg
      .selectAll(".dot")
      .on("mouseover", (event, d: any) => {
        const dataAtTime = groupedData.get(d.chargingEndDt) || [];
        const tooltipContent = dataAtTime
          .map(
            data =>
              `<div class="tooltip-item">
              <strong>Name:</strong> ${data.name}<br/>
              <strong>Date:</strong> ${data.chargingEndDt}<br/>
              <strong>Type:</strong> ${data.creditBookType}<br/>
              <strong>Size:</strong> ${data.resourceSize}<br/>
              <strong>Credit:</strong> ${data.credit}<br/>
              <strong>Balance:</strong> ${data.balance}<br/>
            </div>`
          )
          .join("");

        tooltip
          .style("display", "block")
          .style("opacity", 1)
          .html(`<div class="tooltip-content">${tooltipContent}</div>`);

        const tooltipNode = tooltip.node() as HTMLElement;
        const { pageX, pageY } = event;
        const { clientWidth, clientHeight } = document.documentElement;
        const tooltipWidth = tooltipNode.offsetWidth;
        const tooltipHeight = tooltipNode.offsetHeight;

        let left = pageX + 10;
        let top = pageY - 20;

        if (left + tooltipWidth > clientWidth) {
          left = pageX - tooltipWidth - 10;
        }

        if (top + tooltipHeight > clientHeight) {
          top = pageY - tooltipHeight - 10;
        }

        tooltip.style("left", `${left}px`).style("top", `${top}px`);
      })
      .on("mousemove", event => {
        const tooltipNode = tooltip.node() as HTMLElement;
        const { pageX, pageY } = event;
        const { clientWidth, clientHeight } = document.documentElement;
        const tooltipWidth = tooltipNode.offsetWidth;
        const tooltipHeight = tooltipNode.offsetHeight;

        let left = pageX + 10;
        let top = pageY - 20;

        if (left + tooltipWidth > clientWidth) {
          left = pageX - tooltipWidth - 10;
        }

        if (top + tooltipHeight > clientHeight) {
          top = pageY - tooltipHeight - 10;
        }

        tooltip.style("left", `${left}px`).style("top", `${top}px`);
      })
      .on("mouseout", event => {
        const tooltipNode = tooltip.node() as HTMLElement;
        if (tooltipNode && !tooltipNode.contains(event.relatedTarget as Node)) {
          tooltip
            .transition()
            .duration(300)
            .style("opacity", 0)
            .on("end", () => tooltip.style("display", "none"));
        }
      });

    tooltip
      .on("mouseover", () => {
        tooltip
          .transition()
          .duration(300)
          .style("opacity", 1)
          .style("display", "block");
      })
      .on("mouseout", event => {
        const tooltipNode = tooltip.node() as HTMLElement;
        if (tooltipNode && !tooltipNode.contains(event.relatedTarget as Node)) {
          tooltip
            .transition()
            .duration(300)
            .style("opacity", 0)
            .on("end", () => tooltip.style("display", "none"));
        }
      });
  }, [billings]);

  return (
    <Overlay>
      <Modal ref={modalRef}>
        <ChartTitle>{t("billing.recent_credit_change" || "최근 Credit 현황")}</ChartTitle>
        <CloseButton onClick={() => setShowChart(false)}>X</CloseButton>
        <svg ref={ref}></svg>
      </Modal>
    </Overlay>
  );
};

const Overlay = styled.div`
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Modal = styled.div`
  position: relative;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 1400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  animation: fadeIn 0.5s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    color: #0056a3;
  }
`;

const ChartTitle = styled.div`
  font-weight: bold;
  font-size: 30px;
  margin-top: 15px;
  margin-bottom: 30px;
  color: #017bfb;
`;

const tooltipStyles = `
.tooltip {
  position: absolute;
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  pointer-events: auto;
  z-index: 1001;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  max-width: 300px;
  max-height: 200px;
  overflow: auto;
  color: #333;
  font-family: 'Arial', sans-serif;
}

.tooltip-content {
  font-size: 14px;
  line-height: 1.5;
  color: #333;
}

.tooltip-item {
  margin-bottom: 10px;
  padding: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.tooltip-item:last-child {
  margin-bottom: 0;
  border-bottom: none;
}

.tooltip-item strong {
  color: #1976d2;
}

.tooltip::-webkit-scrollbar {
  width: 6px;
}

.tooltip::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.tooltip::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.9);
}
`;

const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = tooltipStyles;
document.head.appendChild(styleSheet);

export default LineChart;
