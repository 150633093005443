// PaginationSelect.js
import React from "react";
import styled from "styled-components";

interface Props {
  value: number;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const PaginationSelect = ({ value, onChange }: Props) => {
  return (
    <Select value={value} onChange={onChange}>
      <option value={10}>10개</option>
      <option value={20}>20개</option>
      <option value={30}>30개</option>
      <option value={40}>40개</option>
      <option value={50}>50개</option>
    </Select>
  );
};

const Select = styled.select`
  width: 60px;
`;

export default PaginationSelect;
