import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SignupHeader from "./components/header";
import SignupPage1 from "./components/signup1/page";
import SignupPage2 from "./components/signup2/page";
import { SignupForm } from "../../@types/user/signup/signup.form";

const SignupPage = () => {
  const [session, setSession] = useState<1 | 2>(1);
  const [signupForm, setSignupForm] = useState(new SignupForm());

  useEffect(() => window.scrollTo(0, 0), [session]);

  const signupProps = { signupForm, setSession, setSignupForm };

  return (
    <Wrapper>
      <SignupHeader session={session} />
      {session === 1 && <SignupPage1 {...signupProps} />}
      {session === 2 && <SignupPage2 {...signupProps} />}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: 100%;
  min-height: 100vh;
`;

export default SignupPage;
