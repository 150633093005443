import { WorkstationUpdateUser } from "./user";

export class WorkstationUpdateUserForm {
  workstationId?: string;
  uwHostname?: string;
  users: WorkstationUpdateUser[];
  constructor(workstationId?: string) {
    this.workstationId = workstationId;
    this.users = [];
  }
}
export { WorkstationUpdateUser };
