import React from "react";
import styled from "styled-components";
import { Element as ScrollElement } from "react-scroll";
import { useT, useCurrentLanguage } from "../../../constants/i18n/useT";

const Service = () => {
  const t = useT();
  const currentLanguage = useCurrentLanguage();

  return (
    <Wrapper>
      <ScrollElement name="service1">
        <Title>1. {t("common.membership_registration_and_withdrawal", "회원 가입 및 탈퇴")}</Title>
        <Spacer />
        <Paragraph>
          {t(
            "common.guide_service1",
            `M2M 서비스 사용을 위해선 먼저 회원 가입 절차가 필요합니다. 홈페이지(https://m2m.grida.dev)의 오른쪽 위에 “회원가입” 버튼을 클릭하여 가입절차를 시작할 수 있습니다(아래 그림 참고).`
          )}
        </Paragraph>
        <ImageWrapper>
          <WidthImage
            src={`/assets/guide/${currentLanguage === "ko_KR" ? "signup.png" : "en/en_signup.png"}`}
            alt="signup"
          />
        </ImageWrapper>
        <Spacer />
        <Paragraph>
          {t(
            "common.guide_service2",
            `회원 가입의 절차는 이용약관 및 개인정보처리방침 동의, 로그인 및 회원 정보 입력 순으로 진행됩니다.`
          )}
        </Paragraph>
        <Spacer />
        <Paragraph>
          {t(
            "common.guide_service3",
            `먼저, 이용약관 및 개인정보 처리방침 동의가 필요합니다. 2가지 항목 모두 동의가 필수적입니다. 회원 가입을 원하지 않으면 ‘동의하지 않습니다’ 버튼을 클릭하여 회원 가입 절차를 종료할 수 있습니다.`
          )}
        </Paragraph>
        <ImageWrapper>
          <HeightImage
            src={`/assets/guide/${currentLanguage === "ko_KR" ? "policy.png" : "en/en_policy.png"}`}
            alt="policy"
          />
        </ImageWrapper>
        <Spacer />
      </ScrollElement>

      <ScrollElement name="service2">
        <Title>2. {t("common.user_account_information", "사용자 계정 정보 입력")}</Title>
        <Spacer />
        <Paragraph>
          {t(
            "common.guide_service4",
            `약관 동의가 끝나면 로그인 및 사용자 정보 입력 단계로 이동합니다. 로그인을 위하여 아이디, email, 비밀번호를 입력합니다. 모두 필수정보이며 email의 경우 기존의 다른 계정에서 사용한 email을 다시 사용할 수 없습니다. 기존 사용 email 사용이 불가피한 경우에는 홈페이지의 contact를 통해 문의하시기 바랍니다.`
          )}
        </Paragraph>
        <Spacer />
        <Paragraph>
          {t(
            "common.guide_service5",
            `사용자의 이름과 연락처(휴대폰번호)를 추가로 입력하고 옵션 사항으로 법인의 경우 회사명을 입력할 수 있습니다. 모든 입력을 끝내고 ‘가입’ 버튼을 누르면 “회원가입 성공” 메시지가 팝업됩니다.`
          )}
        </Paragraph>
        <Spacer />
        <ImageWrapper>
          <HeightImage
            src={`/assets/guide/${
              currentLanguage === "ko_KR" ? "login-info.png" : "en/en_login-info.png"
            }`}
            alt="login-info"
          />
        </ImageWrapper>
        <ImageWrapper>
          <OriginalImage
            src={`/assets/guide/${
              currentLanguage === "ko_KR" ? "signup-success.png" : "en/en_signup-success.png"
            }`}
            alt="signup-success"
          />
        </ImageWrapper>
      </ScrollElement>

      <ScrollElement name="service3">
        <Title>3. {t("common.login_and_logout", "로그인 및 로그아웃")}</Title>
        <Spacer />
        <Paragraph>
          {t(
            "common.guide_service6",
            `이제 사용자는 로그인 페이지로 이동하여 M2M 서비스에 로그인 할 수 있습니다. 로그인 페이지에서 회원 가입 시에 입력한 아이디와 비밀번호를 입력하고 로그인 버튼을 클릭합니다. 이때, 회원가입 시 입력한 아이디/패스워드와 일치하지 않으면 “사용자 정보가 일치하지 않습니다” 는 메시지와 함께 로그인이 실패하게 됩니다. 아이디와 패스워드를 올바르게 입력하였으면 로그인에 성공하고 이때 이메일 인증이 안된 경우 다음과 같은 이메일 인증 요청 메시지가 추가적으로 팝업됩니다.`
          )}
        </Paragraph>
        <Spacer />
        <ImageWrapper>
          <BigImage
            src={`/assets/guide/${currentLanguage === "ko_KR" ? "email.png" : "en/en_email.png"}`}
            alt="email"
          />
        </ImageWrapper>
        <Spacer />
        <Paragraph>
          {t(
            "common.guide_service7",
            `이메일 인증 요청 메세지사용자가 입력한 이메일에서 M2M 서비스에서 발송된 회원 가입 인증메일을 확인할 수 있습니다. 해당 메일에서 “이메일 인증하기’ 버튼을 클릭하면 이메일 인증이 완료됩니다. (주의: M2M 서비스의 정상적인 사용을 위해선 반드시 이메일 인증이 필요합니다.)`
          )}
        </Paragraph>
        <ImageWrapper>
          <HeightImage
            src={`/assets/guide/${currentLanguage === "ko_KR" ? "email2.png" : "en/en_email2.png"}`}
            alt="email2"
          />
        </ImageWrapper>
        <ImageWrapper>
          <BigImage
            src={`/assets/guide/${currentLanguage === "ko_KR" ? "email3.png" : "en/en_email3.png"}`}
            alt="email3"
          />
        </ImageWrapper>
        <Spacer />
        <Paragraph>
          {t(
            "common.guide_service8",
            `이메일 인증이 완료된 상황에서 정상적으로 로그인하면 메인 M2M 서비스 페이지로 이동하게 됩니다.`
          )}
        </Paragraph>
        <ImageWrapper>
          <BigImage
            src={`/assets/guide/${
              currentLanguage === "ko_KR" ? "main-workstation.png" : "en/en_main-workstation.png"
            }`}
            alt="main-workstation"
          />
        </ImageWrapper>
        <Spacer />
        <Paragraph>
          {t(
            "common.guide_service9",
            `로그아웃을 위해선 오른쪽 위의 사용자 아이콘에서 ‘로그아웃’을 클릭합니다. 로그아웃하면 M2M 서비스 홈페이지로 이동하게 됩니다.`
          )}
        </Paragraph>
        <ImageWrapper>
          <WidthImage
            src={`/assets/guide/${currentLanguage === "ko_KR" ? "logout.png" : "en/en_logout.png"}`}
            alt="logout"
          />
        </ImageWrapper>
      </ScrollElement>
    </Wrapper>
  );
};

export default Service;

const Wrapper = styled.div`
  padding: 25px;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 20px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

const Paragraph = styled.p`
  font-size: 16px;
  line-height: 1.5;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 13px;
  }
`;

const Spacer = styled.div`
  height: 20px;

  @media (max-width: 768px) {
    height: 15px;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    margin-top: 30px;
    margin-bottom: 40px;
  }

  @media (max-width: 480px) {
    margin-top: 20px;
    margin-bottom: 30px;
  }
`;

const ResponsiveImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const BigImage = styled(ResponsiveImage)`
  height: 400px;

  @media (max-width: 768px) {
    height: 300px;
  }

  @media (max-width: 480px) {
    height: 200px;
  }
`;

const WidthImage = styled(ResponsiveImage)`
  width: 800px;

  @media (max-width: 1024px) {
    width: 600px;
  }

  @media (max-width: 768px) {
    width: 400px;
  }

  @media (max-width: 480px) {
    width: 300px;
  }
`;

const HeightImage = styled(ResponsiveImage)`
  height: 650px;

  @media (max-width: 1024px) {
    height: 500px;
  }

  @media (max-width: 768px) {
    height: 400px;
  }

  @media (max-width: 480px) {
    height: 300px;
  }
`;

const OriginalImage = styled(ResponsiveImage)``;
