import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useT } from "../../../constants/i18n/useT";
import Search from "./components/search";
import TableBody from "./components/table-body";
import TableHeader from "./components/table-header";
import usePagination from "../../../components/pagenation/usePagenation";
import PaginationWrapper from "../../../components/pagenation/pagenationWrapper";
import { userAlarmData } from "../../../@types/\balarm/userAlarmData";

// import { UserAlarms } from "../../../service/userAlarms/userAlarms";
import PaginationComponent from "../../../components/pagenation/pagenation";
import PaginationSelectBox from "../../../components/pagenation/selectBox";

const AlarmHistory = () => {
  const [alarmsList, setAlarmList] = useState<userAlarmData[]>([]);

  // const [currentPage, setCurrentPage] = useState(1);
  // const [pagenationSize, setPagenationSize] = useState(10);

  // const handlePagenationSize = (
  //   event: React.ChangeEvent<HTMLSelectElement>
  // ) => {
  //   const newPagenationSize = parseInt(event.target.value);
  //   setPagenationSize(newPagenationSize);
  //   setCurrentPage(1);
  // };

  // const startIndex = (currentPage - 1) * pagenationSize;
  // const endIndex = startIndex + pagenationSize;

  // const handlePageChange = (newPage: number) => {
  //   setCurrentPage(newPage);
  // };
  const t = useT();

  const {
    currentPage,
    pagenationSize,
    startIndex,
    endIndex,
    handlePageChange,
    handlePageSizeChange,
  } = usePagination(10);

  const headers = [
    { title: "No.", width: 2 },
    { title: t("user.date", "날짜"), width: 6 },
    { title: t("user.project", "프로젝트"), width: 6 },
    { title: t("user.content", "내역"), width: 16 },
  ];

  const handleUserAlarmsUpdate = (data: userAlarmData[]) => {
    setAlarmList(data);
  };

  return (
    <>
      <div className="user_right_con my_history_wrap">
        {/* <Navbar>
          <p className="description">
            ※ 최근 활동에 대해 30개 이내로 확인하실 수 있습니다.
          </p>
          <div className="flex_end">
            <Search />
          </div>
        </Navbar> */}
        <AlarmTable>
          <TableHeader headers={headers} />

          <TableBody
            headers={headers}
            onUserAlarmsUpdate={handleUserAlarmsUpdate}
            startIndex={startIndex}
            endIndex={endIndex}
          />
        </AlarmTable>

        <PaginationWrapper
          totalItems={alarmsList.length}
          pagenationSize={pagenationSize}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </div>
    </>
  );
};

const Wrapper = styled.section``;

const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  align-items: center;
  .description {
    color: #fe3939c7;
    padding-left: 20px;
  }
  .flex_end {
    display: flex;
    justify-content: end;
  }
`;
const AlarmTable = styled.section`
  /* padding-top: 20px; */
  width: 95%;
  margin: auto;
  th,
  td {
    padding: 0 10px;
    height: 45px;
    position: relative;
  }
`;

export default AlarmHistory;

{
  /* <Pagination
  count={Math.ceil(alarmsList.length / pagenationSize)}
  page={currentPage}
  onChange={(event, newPage) => setCurrentPage(newPage)}
  color="primary"
  siblingCount={1}
  boundaryCount={1}
  showFirstButton
  showLastButton
/> */
}
