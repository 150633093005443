import React, { InputHTMLAttributes, useState, forwardRef } from "react";
import styled from "styled-components";
import Star from "../essential-star";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  errorMessage?: null | string;
  children?: React.ReactNode;
  essential?: boolean;
  inline?: boolean;
  ref?: React.RefObject<HTMLInputElement>;
}

const SignupInput = forwardRef<HTMLInputElement, Props>(
  ({ errorMessage, children, type, essential, inline, ...props }, ref) => {
    const [inputType, setInputType] = useState(type || "text");
    const [isFocus, setIsFocus] = useState(false);

    const handleType = () => {
      setInputType((prevType) =>
        prevType === "password" ? "text" : "password"
      );
    };

    return (
      <Wrapper id={inline ? "inline" : ""}>
        <label>
          {props.title}
          {essential && <Star />}
        </label>

        <Input className={`input_wrapper ${isFocus ? "focus" : ""}`}>
          <input
            ref={ref}
            id={props.value !== "" && errorMessage ? "error-input" : ""}
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
            type={inputType}
            {...props}
            autoComplete="new-password"
          />

          {type === "password" && (
            <span onClick={handleType}>
              {inputType === "password" && (
                <img src="/assets/icon/eye-open.png" alt="eye-open" />
              )}
              {inputType === "text" && (
                <img src="/assets/icon/eye-close.png" alt="eye-close" />
              )}
            </span>
          )}
          {errorMessage && props.value !== "" && (
            <div className="description">
              <div className="error">{errorMessage}</div>
            </div>
          )}
          {children}
        </Input>
      </Wrapper>
    );
  }
);
const Wrapper = styled.div`
  &#inline {
    display: flex;
    align-items: center;
  }
  position: relative;
  margin: auto;
  margin-top: 10px;
  width: 100%;
  height: 75px;

  text-align: left;

  label {
    font-size: 14px;
    font-weight: bold;
    color: #979797;
    margin-left: 5px;
    min-width: 50px;
  }

  .error {
    font-size: 10px;
    color: #979797;
    margin-top: 5px;
    margin-left: 10px;
    min-height: 12px;
    color: #ff2c2c;
    position: absolute;
    top: 63px;
    left: 0px;
  }
  @media (min-width: 768px) {
    .error {
      font-size: 10px;
    }
  }
`;

const Input = styled.div`
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  padding: 8px 0px;
  border-radius: 8px;
  margin-right: 30px;
  background: var(--background-surface, #fff);
  input {
    width: 100%;
    height: 100%;
    border: 1px solid #d4d4d4;
    display: block;
    border-radius: 5px;

    &:focus {
      border: 1px solid #50a0fa;
    }
    text-indent: 10px;
    &::placeholder {
      font-size: 10px;
    }
    &#error-input {
      border: 1px solid #ff2c2c !important;
    }
  }

  //엣지 브라우저 input eye 자동으로 나오는것 display
  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }

  img {
    margin-left: 10px;
    margin-right: 2px;
    cursor: pointer;
  }
`;

export default SignupInput;
