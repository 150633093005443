// PaginationComponent.jsx
import React from "react";
import { Pagination } from "@mui/material";

interface Props {
  totalItems: number;
  pagenationSize: number;
  currentPage: number;
  onPageChange: (newPage: number) => void;
}

const PaginationComponent: React.FC<Props> = ({
  totalItems,
  pagenationSize,
  currentPage,
  onPageChange,
}) => {
  const pageCount = Math.ceil(totalItems / pagenationSize);

  return (
    <Pagination
      count={pageCount}
      page={currentPage}
      onChange={(event, newPage) => onPageChange(newPage)}
      color="primary"
      siblingCount={1}
      boundaryCount={1}
      showFirstButton
      showLastButton
    />
  );
};

export default PaginationComponent;
