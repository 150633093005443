import { LinearProgress } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { useT } from "../../../../constants/i18n/useT";
import UserContactEach from "./each";
import { Contact } from "../../../../@types/user/contact";

interface Props {
  contacts: Contact[];
  isLoading: boolean;
}

const ContactList = ({ contacts, isLoading }: Props) => {
  const t = useT();
  return (
    <Wrapper>
      <TableWrapper>
        <Table>
          <TableHead>
            <tr>
              <td>No.</td>
              <td>{t("user.title", "제목")}</td>
              <td>{t("user.created_date", "날짜")}</td>
              <td>{t("user.status", "상태")}</td>
            </tr>
          </TableHead>
          <TableBody>
            {contacts.map((article, idx) => (
              <UserContactEach key={idx} idx={idx} article={article} />
            ))}
          </TableBody>
        </Table>
        {!isLoading && contacts.length === 0 && (
          <div className="no_data">
            <Nodata>
              <h1>No data found</h1>
            </Nodata>
          </div>
        )}
        {isLoading && <LinearProgress />}
      </TableWrapper>
    </Wrapper>
  );
};
const Wrapper = styled.article`
  margin: auto;
  width: 95%;
  border: 0.3px solid #bababa;
  margin-top: 20px;
  border-radius: 10px 10px 0 0;
  background-color: #fff;
`;
const TableWrapper = styled.article`
  height: 650px;
  border-radius: 10px 10px 0 0;

  @media (max-width: 1480px) {
    height: 450px;
  }

  margin: auto;
  overflow-y: auto;
  &::-webkit-scrollbar {
    background-color: rgb(255, 255, 255);
    width: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(219, 219, 219, 0.514);
    border-radius: 10px;
    height: 10px;
  }
  table {
    width: 100%;
  }
  thead {
    border-radius: 10px 10px 0 0;
    font-size: 17px;
    font-weight: 600;
    td {
      color: #ffffff;
      text-align: center;
      padding: 5px 0;
      border-left: 0.5px solid #c4c4c4;

      &:first-child {
        width: 50px;
        border-left: none;
      }
    }
  }
  .no_data {
    padding-top: 30px;
  }
`;

const Nodata = styled.div`
  width: 100%;
  text-align: center;
  h1 {
    font-size: 20px;
  }
`;

const Table = styled.table``;
const TableHead = styled.thead`
  tr {
    border-bottom: 0.5px solid #575757;
    border-top: 0.5px solid #575757;
    background: var(--Active);
    margin-bottom: 10px;
    line-height: 30px;
    font-size: 15px;
  }
`;
const TableBody = styled.tbody`
  min-height: 300px;
`;

export default ContactList;
