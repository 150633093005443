import { API } from "../../../service/api";
import { APICLIENT } from "../../../service/api";

export class NonMemberContactForm {
  mail: string = "";
  title: string = "";
  content: string = "";
  isIndividualInfoUseAgree: boolean = false;
  fullName?: string = "";
  mobile?: string = "";
  individualCompanyName?: string = "";

  static submit = async (contactForm: NonMemberContactForm) => {
    if (contactForm.mobile === "") {
      delete contactForm.mobile;
    }
    if (contactForm.fullName === "") {
      delete contactForm.fullName;
    }
    if (contactForm.individualCompanyName === "") {
      delete contactForm.individualCompanyName;
    }

    const response = await APICLIENT.NONAUTH.post(`${API.CONTACT}/post`, {
      data: contactForm,
    });
    return response.data;
  };
}
