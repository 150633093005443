import React, { useEffect, useState, useRef } from "react";
import { useRecoilState } from "recoil";
import { userState } from "../../../../../../constants/recoil/user";
import styled from "styled-components";
import Hourglass from "../../../../../../components/button/hour-glass";
import { StatusType } from "../card-setting";
import PlayButton from "../button/play";
import PowerOffButton from "../button/power-off";
import { RunClientButton } from "../button/run-client";
import { LogoutClientButton } from "../button/logout";
import { UsingClientButton } from "../button/using";
import WSActive from "../../../modal/active/modal";
import { Workstation } from "../../../../../../@types/workstation/workstation";
import { useAlert } from "../../../../../../components/alert/alert.service";
import { Tooltip } from "@mui/material";
import { FiMonitor } from "@react-icons/all-files/fi/FiMonitor";

interface Props {
  status: StatusType;
  name?: string;
  workstation: Workstation;
  powerOn: boolean;
  setPopQuit: React.Dispatch<React.SetStateAction<boolean>>;
  setPopRestart: React.Dispatch<React.SetStateAction<boolean>>;
  getList: () => void;
}

const CardTitle = ({
  status,
  name,
  workstation,
  powerOn,
  setPopQuit,
  setPopRestart,
  getList,
}: Props) => {
  const [popActive, setPopActive] = useState(false);

  const getHourGlass = () => {
    switch (status) {
      case "Booting":
      case "PowerOn":
      case "NotPowerOn":
      case "ShuttingDown":
        return <Hourglass />;
    }
  };

  const getIcon = () => {
    if (status === "Error") return <FiMonitor size={28} />;
    if (status === "PowerOff") return <FiMonitor size={28} />;
    return <FiMonitor size={28} />;
  };

  const [user] = useRecoilState(userState);

  const { setSuccess } = useAlert();

  const prevLoginUser = useRef(workstation.loginUser);

  useEffect(() => {
    if (prevLoginUser.current && !workstation.loginUser) {
      setSuccess("로그아웃 되었습니다.");
    }

    prevLoginUser.current = workstation.loginUser;
  }, [workstation.loginUser]);

  const isLoginUserEmpty = (loginUser: any) => {
    return loginUser && Object.keys(loginUser).length === 0;
  };

  return (
    <>
      <Wrapper>
        {getIcon()}
        <div className="ws_name">{name}</div>
      </Wrapper>

      {status === "PowerOff" && <PlayButton setPopActive={setPopActive} />}
      {getHourGlass()}

      {status === "Running" &&
        workstation &&
        workstation.loginUser &&
        workstation.loginUser.userGuid === user.userGuid && (
          <PowerOffButton
            setPopQuit={setPopQuit}
            setPopRestart={setPopRestart}
            workstation={workstation}
            getList={getList}
          />
        )}

      {status === "Running" && isLoginUserEmpty(workstation.loginUser) && (
        <PowerOffButton
          setPopQuit={setPopQuit}
          setPopRestart={setPopRestart}
          workstation={workstation}
          getList={getList}
        />
      )}

      {status === "Running" && isLoginUserEmpty(workstation.loginUser) && powerOn && (
        <RunClientButton workstation={workstation} getList={getList} />
      )}

      {status === "Running" &&
        workstation.loginUser &&
        workstation.loginUser.userGuid === user.userGuid &&
        powerOn && <RunClientButton workstation={workstation} getList={getList} />}

      {/* {status === "Running" &&
        workstation.loginUser &&
        workstation.loginUser.userGuid === user.userGuid &&
        powerOn && (
          <LogoutClientButton workstation={workstation} getList={getList} />
        )} */}

      {/* 사용중일때 */}
      {/* {status === "Running" &&
        workstation.loginUser &&
        workstation.loginUser.userGuid !== user.userGuid &&
        powerOn && <UsingClientButton />} */}

      {popActive && (
        <WSActive
          getList={getList}
          setOpen={setPopActive}
          isOpen={popActive}
          workstation={workstation}
        />
      )}
    </>
  );
};
const Wrapper = styled.article`
  width: 130px; // => 170px
  font-size: 13px; // => 15px
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 45px;
  img {
    width: 22px; // => 25px
    height: 18px; // => 20px
  }

  .ws_name {
    padding-left: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  @media (min-width: 768px) {
    width: 170px;
    font-size: 15px;
    img {
      width: 25px;
      height: 20px;
    }
  }
`;

export default CardTitle;
