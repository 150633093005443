import { Checkbox } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Code } from "../../../../../../@types/infra/code";
import TypeSelectBox from "../../../../../project/components/modal/create/setting-create-update/components/item/select";
import { StorageUserUiData } from "../../../../../../@types/storage/ui/storage.user";

interface Props {
  handleCheckbox: () => void;
  isChecked: boolean;
  title?: string;
  typeList?: Code[];
  handleType?: (type: string) => void;
  user?: StorageUserUiData;
}

const StorageCheckBox = ({
  handleCheckbox,
  isChecked,
  title,
  typeList,
  handleType,
  user,
}: Props) => {
  const [type, setType] = useState(
    (user && user.privilege && user.privilege.cd) || ""
  );

  useEffect(() => {
    if (handleType) handleType(type);
  }, [type]);

  useEffect(() => {
    if (typeList && typeList[0]) {
      if (type == "") setType(typeList[0].cd || "");
    }
  }, [typeList]);

  return (
    <Wrapper>
      <>
        <div onClick={handleCheckbox} className="name_title">
          <Checkbox checked={isChecked} value={isChecked} />
          <p>{title}</p>
        </div>
        {typeList && (
          <TypeSelectBox typeList={typeList} setType={setType} type={type} />
        )}
      </>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  padding: 3px 5px;
  height: 25px;
  width: 90%;
  font-size: 13px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background-color: #f8f8f8;
  }
  .name_title {
    display: flex;
    align-items: center;
  }
`;

export default StorageCheckBox;
