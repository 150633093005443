import React, { useEffect, useState } from "react";
import { Workstation } from "../../../../../../@types/workstation/workstation";
import Modal from "../../../../../../components/modal/modal-form";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Button from "../../../../../../components/button/Button";
import styled from "styled-components";
import { useT } from "../../../../../../constants/i18n/useT";
import { useAlert } from "../../../../../../components/alert/alert.service";

import { WorkstationService } from "../../../../../../service/workstation/workstation.service";

interface Props {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  workstations: Workstation[];
  autoStart: boolean;
  setAutoStart: React.Dispatch<React.SetStateAction<boolean>>;
  getList: () => void;
}

const WSCreateSuccess = ({ isOpen, setOpen, getList, workstations, autoStart }: Props) => {
  const [isSuccess, setIsSuccess] = useState<boolean>(true);
  const [cantStart, setCantStart] = useState<boolean>(false);

  const [recentWS, setRecentWS] = useState<Workstation | null>(null);
  const [disabled, setDisabled] = useState<boolean>(false);

  const t = useT();
  const { setSuccess, setAlert } = useAlert();

  useEffect(() => {
    if (workstations.length > 0) {
      const latest = workstations.reduce((prev, current) =>
        prev.createdAt! > current.createdAt! ? prev : current
      );

      setRecentWS(latest);
    }
  }, [workstations]);

  useEffect(() => {
    if (autoStart) {
      setIsSuccess(false);
      setRecentWS(recentWS);
      onSubmit();
    }
  }, [recentWS]);

  const onSubmit = () => {
    setDisabled(true);

    if (recentWS && recentWS.workstationId) {
      WorkstationService.start(recentWS.workstationId)
        .then(() => {
          setOpen(false);
        })
        .catch(error => {
          if (error.response && error.response.status === 400) {
            setAlert(
              t("workstation.workstation_aleady_start", "이미 사용 중인 워크스테이션 입니다.")
            );
            setOpen(false);

            return;
          }

          if (error.response && error.response.status === 402) {
            setAlert(t("workstation.insufficient_credit", "크레딧이 부족합니다."));
            setOpen(false);
            return;
          }

          if (error.response && error.response.status === 403) {
            setAlert(t("workstation.not_have_permission", "워크스테이션 실행 권한이 없습니다.."));
            setOpen(false);
            return;
          }

          if (error.response && error.response.status === 404) {
            setAlert(t("workstation.not_found_ws", "워크스테이션 조회에 실패했습니다."));
            setOpen(false);
            return;
          }

          if (error.response && error.response.status === 500) {
            setAlert(t("common.error", "현재 서버가 원활하지 않습니다. 잠시 후 시도해주세요."));

            return;
          }

          if (error.response && error.response.status === 503) {
            setCantStart(true);
          }

          setIsSuccess(false);
        })
        .finally(() => {
          getList();
        });
    }
  };
  return (
    <Modal isOpen={isOpen} setOpen={setOpen}>
      <ModalContent>
        <WSCreate>
          {t("workstation.workstation_creation_successful", "Workstation 생성 성공")}
          <CheckCircleIcon style={{ fontSize: 30, color: "limegreen" }} />
        </WSCreate>
        <WSName>({recentWS && recentWS.name})</WSName>
        {isSuccess && (
          <ButtonWrapper>
            <CloseButton onClick={() => setOpen(false)}>{t("common.close", "닫기")}</CloseButton>
            <Button color="primary" disabled={disabled} onClick={() => onSubmit()}>
              {t("workstation.start_workstation", "Workstation 시작")}
            </Button>
          </ButtonWrapper>
        )}
        {cantStart && (
          <>
            <br />
            <br />
            <h4>
              {t(
                "workstation.the_workstation_is_currently_unavailable_because_there_is_no_baremetal_available",
                "현재 가용 베어메탈이 없어 워크스테이션을 사용할 수 없습니다."
              )}
            </h4>
            <h4>
              {t("workstation.please_try_again_in_a_moment", " 잠시 후에 다시 시도해 주세요.")}
            </h4>
            <ButtonWrapper>
              <CloseButton onClick={() => setOpen(false)}>
                {t("common.confirm", "확인")}
              </CloseButton>
            </ButtonWrapper>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

const WSCreate = styled.h2`
  margin-top: 40px;
`;

const WSName = styled.h3`
  margin-top: 20px;
`;

const ModalContent = styled.div`
  text-align: center;
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const CloseButton = styled(Button)`
  background-color: var(--InActive);
  color: var(--Active);
  margin-bottom: 30px;
`;

export default WSCreateSuccess;
