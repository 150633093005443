export class ProjectFormStorage {
  isPublic?: boolean;
  storage?: { storageId?: string };
  workstations?: Array<{
    workstationId?: any;
    driveLetter?: any;
    useStateCd?: "UseState.Deleted" | undefined;
  }>;

  shareWith?: Array<{
    userGuid: string;
    privilegeCd?: string;
    useStateCd?: "UseState.Deleted";
  }>;
  projectStorageId?: number;
  useState?: { cd: "UseState.Deleted" };

  storageId?: string;

  constructor(
    storageId?: string,
    workstations?: Array<{
      workstationId?: any;
      driveLetter?: any;
      useStateCd?: "UseState.Deleted" | undefined;
    }>,
    shareWith?: Array<{
      userGuid: string;
      privilegeCd?: string;
      useStateCd?: "UseState.Deleted" | undefined;
    }>
  ) {
    this.storage = { storageId };
    this.workstations = workstations;
    this.shareWith = shareWith;
  }
}
