import { Checkbox } from "@mui/material";
import React from "react";
import styled from "styled-components";

interface Props {
  checked: boolean;
  onClick?: () => void;
  value: boolean;
  label?: string | null;
}

const CheckboxLabel = ({ checked, onClick, value, label }: Props) => {
  return (
    <Wrapper>
      {label && <label>{label}</label>}
      <Checkbox onClick={onClick} checked={checked} value={value} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  label {
    display: block;
    font-size: 14px;
    font-weight: bold;
    color: #979797;
    margin-left: 5px;
  }
`;

export default CheckboxLabel;
