export class StorageUpdateUserForm {
  storageId?: string;
  users: StorageUpdateUser[] = [];
  constructor(storageId?: string) {
    this.storageId = storageId;
  }
}

export class StorageUpdateUser {
  user?: { userGuid?: string };
  storageUserId?: number;
  useState?: { cd: "UseState.Deleted" };
  privilege?: { cd?: string };
  useStateCd?: "UseState.Deleted";
  constructor(userGuid?: string, privilegeCd?: string, useStateCd?: "UseState.Deleted") {
    this.user = { userGuid };
    this.privilege = { cd: privilegeCd };
    this.useStateCd = useStateCd;
  }
}
