import React from "react";
import { MAX_LENGTH } from "../../../../../../../constants/max-length";
import styled from "styled-components";
import { InputService } from "../../../../../../../service/common/input.service";
import { IoMdSearch } from "@react-icons/all-files/io/IoMdSearch";

interface Props {
  placeholder: string;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  maxLength?: number;
  onSubmit: () => void;
}

const ProjectSearchBar = ({ placeholder, value, setValue, maxLength, onSubmit }: Props) => {
  const DEFUALT_MAX_LENGTH = 20;

  return (
    <Wrapper>
      <input
        maxLength={maxLength || DEFUALT_MAX_LENGTH}
        placeholder={placeholder}
        value={value}
        onChange={e => setValue(e.target.value)}
        onKeyPress={e => InputService.pressEnter(e, onSubmit)}
      />
      <button type="button">
        {/* <img src="/assets/user/search.png" onClick={() => onSubmit()} /> */}
        <IoMdSearch size={24} onClick={() => onSubmit()} />
      </button>
    </Wrapper>
  );
};
const Wrapper = styled.article`
  width: 95%;
  padding-left: 5px;
  border-bottom: 1px solid #aaaaaa;
  height: 40px;
  box-sizing: border-box;
  position: relative;
  input {
    border: 0;
    line-height: 40px;
    background-color: transparent;
  }
  button {
    border: 0;
    background: transparent;
    position: absolute;
    top: 9px;
    right: 5px;
  }
`;
export default ProjectSearchBar;
