import { LinearProgress } from "@mui/material";
import { useRecoilState } from "recoil";
import { userState } from "../../constants/recoil/user";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import StorageNavbar from "../../components/tab/storage-nav";
import EachStoargeList from "./components/card/components/card-list";
import ServicePageWrapper from "../../components/wrapper/service-page";
import { StorageList } from "../../@types/storage/response";
import usePopupProject from "../project/components/usePopupProject";
import { StorageService } from "../../service/storage/storage.service";
import { Storage } from "../../@types/storage/storage";

const StorageListPage = () => {
  const [user, setUser] = useRecoilState(userState);

  const MY_STORAGE = "MY_STORAGE";
  const SHARED_BY_OTHERS = "SHARED_BY_OTHERS";

  const PROJECT_ASSIGNED = {
    TRUE: "true",
    FALSE: "false",
  };

  const PROJECT_ROLES = {
    ARTIST: "ARTIST",
    DIRECTOR: "DIRECTOR",
  };

  const [rawStorages, setEveryStorages] = useState<StorageList>(new StorageList());

  const [myStorages, setMyStorages] = useState<Storage[]>([]);
  const [sharedAsDirectorStorages, setSharedAsDirectorStorages] = useState<Storage[]>([]);
  const [sharedAsMemberStorages, setSharedAsMemberStorages] = useState<Storage[]>([]);
  const [sharedAsNoProjectStorages, setSharedAsNoProjectStorages] = useState<Storage[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  const getList = () => {
    setIsLoading(true);

    StorageService.getList().then(res => {
      if (res.state === "success") {
        console.log("storage:", res.data);
      }
    });

    Promise.all([
      StorageService.getMyStorage(user.userGuid!, MY_STORAGE),
      StorageService.getSharedAsDirectorStorages(
        user.userGuid!,
        SHARED_BY_OTHERS,
        PROJECT_ASSIGNED.TRUE,
        PROJECT_ROLES.DIRECTOR
      ),
      StorageService.getSharedAsArtistStorages(
        user.userGuid!,
        SHARED_BY_OTHERS,
        PROJECT_ASSIGNED.TRUE,
        PROJECT_ROLES.ARTIST
      ),
      StorageService.getSharedAsNoProjectStorages(
        user.userGuid!,
        SHARED_BY_OTHERS,
        PROJECT_ASSIGNED.FALSE
      ),
    ])
      .then(([myStorageRes, sharedAsDirectorRes, sharedAsMemberRes, sharedAsNoProjectRes]) => {
        setMyStorages(myStorageRes);
        setSharedAsDirectorStorages(sharedAsDirectorRes);
        setSharedAsMemberStorages(sharedAsMemberRes);
        setSharedAsNoProjectStorages(sharedAsNoProjectRes);

        const OriginalStorages = {
          myStorages: myStorageRes,
          sharedAsDirectorStorages: sharedAsDirectorRes,
          sharedAsMemberStorages: sharedAsMemberRes,
          sharedAsNoProjectStorages: sharedAsNoProjectRes,
        };

        setEveryStorages(OriginalStorages);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getList();
  }, []);

  const { ui } = usePopupProject("/storage", getList);

  const checkNoData = () => {
    return (
      !isLoading &&
      !myStorages.length &&
      !sharedAsDirectorStorages.length &&
      !sharedAsMemberStorages.length &&
      !sharedAsNoProjectStorages.length
    );
  };

  return (
    <Wrapper>
      <ServicePageWrapper title="Storage">
        <StorageNavbar
          rawStorages={rawStorages}
          getList={getList}
          myStorages={myStorages}
          sharedAsDirectorStorages={sharedAsDirectorStorages}
          sharedAsMemberStorages={sharedAsMemberStorages}
          sharedAsNoProjectStorages={sharedAsNoProjectStorages}
          setMyStorages={setMyStorages}
          setSharedAsDirectorStorages={setSharedAsDirectorStorages}
          setSharedAsMemberStorages={setSharedAsMemberStorages}
          setSharedAsNoProjectStorages={setSharedAsNoProjectStorages}
        />
        {isLoading && <LinearProgress />}

        {!isLoading && (
          <AllStorageList>
            <EachStoargeList
              storages={myStorages}
              getList={getList}
              title="My Storage"
              id="owned"
            />
            <EachStoargeList
              storages={sharedAsDirectorStorages}
              getList={getList}
              title="Shared As Director"
              id="owned"
            />

            <EachStoargeList
              storages={sharedAsMemberStorages}
              getList={getList}
              title="Shared As Member"
              id="privileged"
            />
            <EachStoargeList
              storages={sharedAsNoProjectStorages}
              getList={getList}
              title="Shared As No Project"
              id="privileged"
            />
          </AllStorageList>
        )}
        {checkNoData() && <h1 className="no_data">No data found</h1>}
      </ServicePageWrapper>
      {ui}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .no_data {
    text-align: center;
    margin-top: 50px;
  }
`;

const AllStorageList = styled.div`
  h3 {
    margin: 30px;
    font-size: 20px;
  }
  padding: 10px 30px;
  list-style: none;
`;

export default StorageListPage;
