import { Storage } from "../storage";

export class StorageForm {
  name: string = "";
  size: { cd?: string } = { cd: "" };
  description?: string = "";
  isFtp: boolean = true;

  storageId?: string; // 없으면 생성, 있으면 수정
  constructor(storage?: Storage) {
    if (storage) {
      this.storageId = storage.storageId;
      this.name = storage.name || "";
      this.size = storage.size || { cd: "" };
      this.description = storage.description || "";
      this.isFtp = storage.isFtp || false;
    }
  }
}
