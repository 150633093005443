import React, { useState } from "react";
import styled from "styled-components";
import PasswordUpdate from "./update-pw/modal";
import Button from "../../../../components/button/Button";
import { useT } from "../../../../constants/i18n/useT";

const UserInfoPassWordBox = ({ getUser }: { getUser: () => void }) => {
  const [popPassword, setPopPassword] = useState(false);
  const t = useT();
  return (
    <Wrapper>
      <div>
        <h5>{t("user.password", "비밀번호")}</h5>
        <p>
          {t(
            "user.it_is_safe_to_change_your_password_periodically",
            "비밀번호는 주기적으로 변경하여 사용하시는 것이 안전합니다."
          )}
          <br />
        </p>
        <p>
          {t(
            "user.please_refrain_from_using_passwords_that_can_be_inferred",
            "연속된 숫자나 생년월일, 전화번호 등과 같이 유추 가능한 비밀번호의 사용을 자제해주시길 바랍니다."
          )}
        </p>

        <div className="buttonbox">
          <Button color="black" size="sm" onClick={() => setPopPassword(true)}>
            {t("user.update_password", "비밀번호 변경")}
          </Button>
          <img
            src="/assets/user/pass.png"
            alt="password"
            className="password_edit"
          />
        </div>
      </div>

      {popPassword && (
        <PasswordUpdate
          getList={getUser}
          setOpen={setPopPassword}
          isOpen={popPassword}
        />
      )}
    </Wrapper>
  );
};
const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  height: 300px;
  padding: 30px;
  p {
    font-size: 12px;
    min-height: 45px;
  }
  h5 {
    font-size: 17px;
    margin-bottom: 10px;
    height: 25px;
  }

  .buttonbox {
    margin-top: 40px;
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      height: 40px;
      width: 40px;
      margin-right: 30px;
    }
  }
  @media screen and (min-width: 768px) {
    width: 50%;
    max-width: 600px;
    justify-content: end;
  }
`;

export default UserInfoPassWordBox;
