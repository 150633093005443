import React from "react";
import styled from "styled-components";
import { useT } from "../../../../../constants/i18n/useT";
import ModalPopup from "../../../../../components/modal/popup";
import { ModalProps } from "../../../../../components/modal";

interface Props extends ModalProps {
  setPopContact: React.Dispatch<React.SetStateAction<boolean>>;
}
const UserCreditCharge = (props: Props) => {
  const t = useT();

  const onSubmit = () => {
    props.setOpen(false);
    props.setPopContact(true);
  };

  return (
    <ModalPopup
      buttonText={t("common.inquire_charge", "충전 문의")}
      onSubmit={onSubmit}
      {...props}
    >
      <h1>
        {t(
          "billing.if_you_want_to_test",
          "테스트용 크레딧 충전은 문의 바랍니다"
        )}
      </h1>
    </ModalPopup>
  );
};
const Wrapper = styled.div`
  padding: 20px;
  padding-bottom: 30px;
  padding-top: 0;
`;

export default UserCreditCharge;
