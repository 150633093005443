import React, { useState } from "react";
import { Workstation } from "../../../../../@types/workstation/workstation";
import { useAlert } from "../../../../../components/alert/alert.service";
import { useT } from "../../../../../constants/i18n/useT";
import ModalPopup from "../../../../../components/modal/popup";
import { StatusType } from "../../card/components/card-setting";
import { WorkstationService } from "../../../../../service/workstation/workstation.service";
import { ModalProps } from "../../../../../components/modal";

interface Props extends ModalProps {
  setPowerOn: React.Dispatch<React.SetStateAction<boolean>>;
  workstation: Workstation;
  setStatus: React.Dispatch<React.SetStateAction<StatusType>>;
  setVisualPercent: React.Dispatch<React.SetStateAction<number>>;
}
const WSQuit = ({
  isOpen,
  setOpen,
  workstation,
  setStatus,
  setPowerOn,
  setVisualPercent,
}: Props) => {
  const [isResponse, setIsResponse] = useState(false);
  const { setSuccess, setAlert } = useAlert();

  const onSubmit = () => {
    setIsResponse(true);
    WorkstationService.shutdown(workstation.workstationId!)
      .then(res => {
        setSuccess(workstation.name + t("workstation.is_shutting_down", "를 종료합니다."));

        setStatus("ShuttingDown");
        setPowerOn(false);
        setVisualPercent(0.03);
      })
      .catch(error => {
        if (error.response && error.response.status === 400) {
          setAlert(
            t("workstation.workstation_is_not_running.", "워크스테이션이 실행중이 아닙니다.")
          );

          return;
        }

        if (error.response && error.response.status === 404) {
          setAlert(t("workstation.not_found_ws", "워크스테이션 조회에 실패했습니다."));

          return;
        }

        if (error.response && error.response.status === 500) {
          setAlert(t("common.error", "현재 서버가 원활하지 않습니다. 잠시 후 시도해주세요."));

          return;
        }
      })
      .finally(() => {
        setOpen(false);
      });
  };
  const t = useT();
  return (
    <ModalPopup
      isOpen={isOpen}
      isResponse={isResponse}
      setOpen={setOpen}
      buttonText={t("common.quit", "종료")}
      onSubmit={onSubmit}
    >
      <h1>
        {t(
          "workstation.would_you_like_to_shut_down_the_workstation",
          "Workstation을 종료하시겠습니까?"
        )}
      </h1>
      <p>
        {t(
          "workstation.you_can_start_the_workstation_again_whenever_you_need_it",
          "필요 시 workstation을 언제든 시작하실 수 있습니다."
        )}
      </p>
      {/* <p id="blue">{workstation.name}</p> */}
    </ModalPopup>
  );
};

export default WSQuit;
