import { DateService } from "../../../service/common/date.service";
import { ProjectFormStorage } from "./storage";
import { ProjectFormUser } from "./user";
import { ProjectFormWorkstation } from "./workstation";

export class ProjectForm {
  name: string = "";
  description?: string = "";
  user?: { userGuid?: string };

  storages: ProjectFormStorage[] = [];
  users: ProjectFormUser[] = [];
  workstations: ProjectFormWorkstation[] = [];

  isPublic: boolean = true;
  plannedStartDt: string = DateService.API_YYYY_MM_DD(new Date());
  plannedEndDt: string = DateService.API_YYYY_MM_DD(new Date());
  projectId?: number; // 없으면 새로 생성, 있으면 수정
}

export { ProjectFormUser, ProjectFormWorkstation, ProjectFormStorage };
