import React from "react";
import WSNavbar from "../../components/tab/workstation-nav";
import { LinearProgress } from "@mui/material";

import styled from "styled-components";
import ServicePageWrapper from "../../components/wrapper/service-page";
import WorkstationCardList from "./components/card/card-list";

import { CardEditType } from "../../@types/type/card-edit";
import useWorkstation from "./components/useWorkstation";
import usePopupProject from "../project/components/usePopupProject";

const WorkstationListPage = () => {
  const {
    everyWorkstations,
    isLoading,
    getList,
    showWorkstations,
    reset,
    checkNoData,
    setCreateWs,
  } = useWorkstation();

  const { ui } = usePopupProject("/workstation", getList);

  return (
    <Wrapper>
      <ServicePageWrapper title="Workstation">
        <WSNavbar
          setCreateWs={setCreateWs}
          everyWorkstations={everyWorkstations}
          workstations={showWorkstations.myWorkstations}
          reset={reset}
          getList={getList}
        />
        {isLoading && <LinearProgress />}

        <WorkstationCardList
          title="My Workstation"
          workstations={showWorkstations.myWorkstations}
          getList={getList}
          editType={CardEditType.ALL}
          isLoading={isLoading}
        />

        <WorkstationCardList
          title="Shared as Director"
          workstations={showWorkstations.sharedAsDirectorWorkstations}
          getList={getList}
          editType={CardEditType.UPDATE}
          isLoading={isLoading}
        />

        <WorkstationCardList
          title="Shared as Member"
          workstations={showWorkstations.sharedAsMemberWorkstations}
          getList={getList}
          editType={CardEditType.NONE}
          isLoading={isLoading}
        />

        <WorkstationCardList
          title="Shared as No Project"
          workstations={showWorkstations.sharedAsNoProjectWorkstations}
          getList={getList}
          editType={CardEditType.NONE}
          isLoading={isLoading}
        />

        {checkNoData() && <h1 className="no_data">No data found</h1>}
      </ServicePageWrapper>
      {ui}
    </Wrapper>
  );
};
const Wrapper = styled.section`
  .no_data {
    text-align: center;
    margin-top: 50px;
  }
`;

export default WorkstationListPage;
