import React, { useState } from "react";
import styled from "styled-components";
import LoginForm from "../components/login-wrapper";
import { useT } from "../../../constants/i18n/useT";
import FindPassWord1 from "./chapter1";
import { useAlert } from "../../../components/alert/alert.service";
import { ValidType } from "../../../@types/type/valid";
import FindPassword2 from "./chapter2";
import FindPassword3 from "./chapter3";
import FindPassword4 from "./chapter4";
import { REGEX } from "../../../constants/regex";
import { FindUserService } from "../../../service/user/user.find.service";
import { useNavigate } from "react-router-dom";

const FindPasswordPage = () => {
  const [session, setSession] = useState(1);
  const [, setOpen] = useState(false);
  const [id, setId] = useState("");
  const [s1Message, setS1Message] = useState("");

  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [s2Message, setS2Message] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [s3Message, setS3Message] = useState("");

  const [emailNumber, setEmailNumber] = useState("");
  const [verified, setVerified] = useState(false);
  const [failed, setFailed] = useState("");

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const [validPassword, setValidPassword] = useState(ValidType.EMPTY);
  const [validPassword2, setValidPassword2] = useState(ValidType.INVALID);
  const t = useT();

  const navigate = useNavigate();
  const { setSuccess, setAlert } = useAlert();

  const checkId = () => {
    // session1
    if (id === "")
      return setS1Message(
        t("user.please_enter_id", "아이디를 입력하세요") || ""
      );

    const newUser = new FindUserService();
    newUser.userId = id;
    newUser.checkValidateId().then((res) => {
      if (res.state === "success") return setSession(2);
      setS1Message(
        res.message ||
          t(
            "common.error",
            "현재 서버가 원활하지 않습니다. 잠시 후 시도해주세요."
          )
      );
    });
  };
  const getCertNumber = () => {
    // session 2
    const newUser = new FindUserService();
    newUser.userId = id;
    newUser.fullName = name;
    newUser.mail = mail;

    newUser.sendPwCertification().then((res) => {
      if (res.state === "success") {
        setSession(3);
        setMinutes(10);
        setSeconds(0);
        return;
      }
      setS2Message(
        res.message ||
          t(
            "common.error",
            "현재 서버가 원활하지 않습니다. 잠시 후 시도해주세요."
          )
      );
    });
  };

  const checkEmailNum = () => {
    // session 3
    if (emailNumber !== "") {
      const newUser = new FindUserService();
      newUser.userId = id;
      newUser.fullName = name;
      newUser.mail = mail;
      newUser.certificationNumber = emailNumber;

      newUser.validEmailNumber().then((res) => {
        if (res.state === "success") {
          setVerified(true);
          setSession(4);
          return;
        }
        setFailed(
          res.message ||
            t(
              "common.error",
              "현재 서버가 원활하지 않습니다. 잠시 후 시도해주세요."
            )
        );
      });
    }
  };

  const onSubmit = () => {
    // session 4
    if (validPassword !== ValidType.VALID || validPassword2 !== ValidType.VALID)
      setS3Message(
        t("user.please_check_your_password", "비밀번호를 확인하세요.") || ""
      );

    const newUser = new FindUserService();
    newUser.userId = id;
    newUser.fullName = name;
    newUser.mail = mail;
    newUser.certificationNumber = emailNumber;
    newUser.newPassword = password;
    newUser.changePassword().then((res) => {
      if (res.state === "success") {
        setSuccess(
          t("user.your_password_has_been_reset", "비밀번호를 재설정 했습니다.")
        );
      }
      setOpen(false);
      navigate("/login");
    });
  };

  const checkPassword = () => {
    setS3Message("");
    if (password2 === "") {
      // 비밀번호가 바뀌면 2번 비밀번호 확인
      setValidPassword2(ValidType.EMPTY); // 비워져있다면 2번 empty
    } else {
      // 내용이 있다면 2번 inValid
      setValidPassword2(ValidType.INVALID);
    }
    if (password === "") {
      setValidPassword(ValidType.EMPTY); // 비밀번호가 비워져있다면 1번 empty
    } else {
      // 비밀번호가 있다면
      if (REGEX.PASSWORD.test(password)) {
        // 유효성 검사를 하고
        setValidPassword(ValidType.VALID); // 통과하면 1번 valid
        if (password === password2) {
          // 2번 확인 후 1번과 2번이 같으면
          setValidPassword2(ValidType.VALID); // 2번 valid
        }
      } // 유효성 검사를 통과못하면
      else {
        setValidPassword(ValidType.INVALID);
      } // 1번 inValid
    }
  };
  const checkPassword2 = () => {
    setS3Message("");
    if (password2 === "") {
      // 2번이 비워져있다면 empty
      setValidPassword2(ValidType.EMPTY);
    } else {
      // 2번이 내용이 있고, 1번과 동일하며 1번이 valid면
      if (password === password2 && validPassword === ValidType.VALID) {
        setValidPassword2(ValidType.VALID); // 2번 valid
      } else {
        setValidPassword2(ValidType.INVALID); // 내용이 다르거나 1번이 inValid면 2번 inValid
      }
    }
  };

  const props = {
    id,
    setId,
    s1Message,
    checkId,
    name,
    setName,
    mail,
    setMail,
    s2Message,
    getCertNumber,
    emailNumber,
    setEmailNumber,
    verified,
    failed,
    minutes,
    setMinutes,
    seconds,
    setSeconds,
    checkEmailNum,
    password,
    setPassword,
    validPassword,
    password2,
    setPassword2,
    validPassword2,
    s3Message,
    checkPassword,
    checkPassword2,
    onSubmit,
  };

  return (
    <LoginForm>
      <Wrapper>
        {session === 1 && <FindPassWord1 {...props} />}
        {session === 2 && <FindPassword2 {...props} />}
        {session === 3 && <FindPassword3 {...props} />}
        {session === 4 && <FindPassword4 {...props} />}
      </Wrapper>
    </LoginForm>
  );
};

const Wrapper = styled.div``;

export default FindPasswordPage;
