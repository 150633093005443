import React, { useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { userState } from "../../../constants/recoil/user";
import { Account, AccountService } from "../../../service/user/account.service";
import i18next from "i18next";
import { useAlert } from "../../alert/alert.service";
import { useT } from "../../../constants/i18n/useT";
import { Dropdown } from "../../dropdown/Dropdown";
import { ChevronDown } from "react-feather";
import { localeState } from "../../../constants/recoil/locale";
import { GrLanguage } from "@react-icons/all-files/gr/GrLanguage";

interface Props {
  isLogin: boolean;
  type: "desktop" | "mobile";
}

export const LocaleUpdate = ({ isLogin, type }: Props) => {
  const DEFAULT_LOCALE = "ko_KR";
  const [locale, setLocale] = useRecoilState(localeState);

  const t = useT();
  const [account, setAccount] = useState<Account>(new Account());
  const [user, setUser] = useRecoilState(userState);

  const [open, setOpen] = useState(false);

  const { setSuccess } = useAlert();

  const updateLocale = (local: string) => {
    setLocale(local);
    setOpen(false);
    if (isLogin) {
      setUser({ ...user, locale: { cd: local } });
      AccountService.updateLocale(local).then(res => {
        if (res.state === "success") {
          setSuccess(t("user.language_has_been_changed", "언어가 변경되었습니다."));
        }
      });
    }
  };

  useEffect(() => {
    setLocale(sessionStorage.getItem("x-locale") || DEFAULT_LOCALE);
  }, []);
  useEffect(() => {
    if (locale) {
      sessionStorage.setItem("x-locale", locale);
      i18next.changeLanguage(locale);
    }
  }, [locale]);

  useEffect(() => {
    if (user.locale) {
      setLocale((user.locale && user.locale.cd) || DEFAULT_LOCALE);
    }
  }, [user]);

  useEffect(() => {
    AccountService.getUi().then(res => {
      if (res.state === "success") {
        if (res.data) setAccount(res.data);
      }
    });
  }, []);

  const getLocaleName = useMemo(() => {
    const localName = account.locales.find(loc => loc.cd === locale);
    return localName && localName.name;
  }, [account.locales, locale]);
  if (type === "desktop") {
    return (
      <DesktopWrapper>
        <Dropdown
          title={
            <a href="" className="lang_title">
              <StyledLanguageIcon size={20} />
              {getLocaleName}
            </a>
          }
        >
          {account.locales.map(locale => (
            <li onClick={() => updateLocale(locale.cd || DEFAULT_LOCALE)} key={locale.cd}>
              <a>{locale.name}</a>
            </li>
          ))}
        </Dropdown>
      </DesktopWrapper>
    );
  }
  return (
    <MobileWrapper>
      <li>
        <a href="" className="lang_title" onClick={() => setOpen(!open)}>
          <StyledLanguageIcon size={20} />
          {getLocaleName}
          <ChevronDown size={20} className="chevron" />
        </a>
        {open && (
          <ul className="lang_list">
            {account.locales.map(locale => (
              <li key={locale.cd}>
                <a onClick={() => updateLocale(locale.cd || DEFAULT_LOCALE)}>{locale.name}</a>
              </li>
            ))}
          </ul>
        )}
      </li>
    </MobileWrapper>
  );
};

const DesktopWrapper = styled.div`
  .lang_title {
    display: flex;
    align-items: center;
  }
`;

const MobileWrapper = styled.div`
  .lang_title {
    display: flex;
    align-items: center;
  }
  .lang_list {
    background-color: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 5px 0;
    z-index: 10;
    height: 150px;
    margin-top: 10px;
    overflow-y: auto;
    width: 150px;
    li {
      padding: 5px;
      a {
        font-size: 10px;
        font-weight: 400;
        display: block;
        padding: 5px 10px;
        color: #333;
        &:hover {
          background-color: #f5f5f5;
        }
      }
    }
    &::-webkit-scrollbar {
      background-color: rgb(255, 255, 255);
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(219, 219, 219, 0.514);
      border-radius: 10px;
      height: 10px;
    }
  }
`;

const StyledLanguageIcon = styled(GrLanguage)`
  margin-right: 7px;
`;

export default LocaleUpdate;
