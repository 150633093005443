import React from "react";
import styled from "styled-components";
import { Element as ScrollElement } from "react-scroll";
import { useT, useCurrentLanguage } from "../../../constants/i18n/useT";

const UserInfo = () => {
  const t = useT();
  const currentLanguage = useCurrentLanguage();

  return (
    <Wrapper>
      <ScrollElement name="userInfo1">
        <Title>1. {t("common.my_information", "내정보")}</Title>
        <Spacer />
        <Paragraph>
          {t(
            "common.guide_user1",
            "페이지의 맨 왼쪽 컬럼에서 “My Page”를 클릭하면 사용자 조회 및 수정페이지로 이동됩니다."
          )}
        </Paragraph>

        <Spacer />

        <ImageWrapper>
          <ResponsiveImage
            src={`/assets/guide/${
              currentLanguage === "ko_KR" ? "my-info.png" : "en/en_my-info.png"
            }`}
            alt="signup"
          />
        </ImageWrapper>

        <Spacer />
        <Paragraph>
          {t(
            "common.guide_user2",
            "‘회원정보 수정’을 클릭하면 사용자의 표시이름과 연락처(휴대번호)를 수정할 수 있습니다."
          )}
        </Paragraph>

        <Spacer />
        <Paragraph>
          {t(
            "common.guide_user3",
            `중복 로그인 설정도 가능하며 “No Limit”, “Keep Current Session”, “Terminate Current Session”를 선택할 수 있습니다. “No Limit”를 선택하면 서로 다른 곳(브라우저)에서 동시에 로그인이 가능하고 이외의 옵션은 하나의 로그인 만을 허용하게 됩니다. “Keep Current Session”을 선택하면 다른 곳에서 로그인이 불가능하게 되고 “Terminate Current Session”을 선택하면 다른 곳에서 로그인 하는 경우 기존의 로그인이 끊기게 됩니다.`
          )}
        </Paragraph>

        <Spacer />
        <Paragraph>
          {t(
            "common.guide_user4",
            `회원 탈퇴을 원할 경우 회원탈퇴 버튼을 클릭하면 됩니다. 회원 탈퇴 후 재가입 시에 기존의 사용한 이메일의 재사용이 불가능할 수 있습니다. 이경우 contact 를 통해 관리자에게 문의하십시오.`
          )}
        </Paragraph>

        <Spacer />
        <Paragraph>
          {t(
            "common.guide_user5",
            `“이메일 변경” 버튼을 클릭하면 이메일 변경이 가능합니다. 변경할 이메일을 입력하고 확인 버튼을 누르면 해당 이메일로 인증메일이 발송됩니다. 메일을 확인하고 인증버튼을 누르면 새로운 이메일의 사용이 가능하게 됩니다. 비밀번호 변경을 위해서 ‘비밀번호 변경’ 버튼을 클릭합니다. 기존 비밀번호와 새로운 비밀번호를 입력하여 비밀번호를 변경할 수 있습니다.`
          )}
        </Paragraph>

        <Spacer />

        <ImageWrapper>
          <ResponsiveImage
            src={`/assets/guide/${
              currentLanguage === "ko_KR" ? "change-pw.png" : "en/en_change-pw.png"
            }`}
            alt="change-pw"
          />
        </ImageWrapper>

        <Spacer />
        <Paragraph>
          {t(
            "common.guide_user6",
            `M2M 서비스 사용을 위해선 크레딧이 필요합니다. 회원 가입을 하게 되면 자동으로 기본 크레딧(4,000)이 부여됩니다. 워크스테이션, 스토리지의 사용에 따라 크레딧이 차감됩니다. 현재의 크레딧이 My Page 의 오른쪽 아래에 표시됩니다. 만약 크레딧이 부족하게 되면 생성한 자원에 대한 접근이 불가능할 수 있습니다. 사용자는 크레딧 부족이 예상되는 경우 1시간, 30분, 10분 전에 자동으로 크레딧 부족 알림을 받게 됩니다. 알림은 이메일로 전달되고 추가로 워크스테이션 접속 화면에도 윈도우가 팝업됩니다.`
          )}
        </Paragraph>

        <Spacer />
        <Paragraph>
          {t(
            "common.guide_user7",
            `오른쪽 아래의 ‘충전’ 버튼을 누르면 크레딧 충전이 가능합니다. 현재는 contact 를 통해 관리자에게 요청할 수 있습니다. M2M에 입금이 확인되면 관리자는 해당 금액을 사용자의 계정에 충전하게 됩니다.`
          )}
        </Paragraph>
      </ScrollElement>

      <Spacer />

      <ScrollElement name="userInfo2">
        <Title>2. {t("common.credit_history", `Credit 내역`)}</Title>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_user8",
            `‘Credit’ 내역 탭을 클릭하면 크레딧 변동 히스토리를 확인할 수 있습니다. Credit 변동 리스트는 자원 사용이 종료되면 즉시 업데이트 되고 계속 사용 중인 자원에 대해선 6시간마다 업데이트 됩니다. Credit의 실시간 현황은 ‘내 정보’ 탭에서 확인 가능합니다.`
          )}
        </Paragraph>

        <Spacer />

        <ImageWrapper>
          <ResponsiveImage
            src={`/assets/guide/${currentLanguage === "ko_KR" ? "credit.png" : "en/en_credit.png"}`}
            alt="credit"
          />
        </ImageWrapper>
      </ScrollElement>

      <Spacer />

      <ScrollElement name="userInfo3">
        <Title>3. {t("common.notifications_and_logs", `알림 및 로그`)}</Title>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_user9",
            `“알림 및 로그” 탭에서는 프로젝트 관련 자원 할당/변경 및 일반 워크스테이션 및 스토리지의 사용자 설정 등의 주요 액션에 대한 로그의 히스토리가 기록된다.`
          )}
        </Paragraph>

        <Spacer />

        <ImageWrapper>
          <ResponsiveImage
            src={`/assets/guide/${
              currentLanguage === "ko_KR" ? "alarm-log.png" : "en/en_alarm-log.png"
            }`}
            alt="alarm-log"
          />
        </ImageWrapper>
      </ScrollElement>

      <ScrollElement name="userInfo4">
        <Title>4. {t("common.customer_support", `고객센터`)}</Title>

        <Spacer />

        <Paragraph>
          {t(
            "common.guide_user10",
            `서비스 사용 방법이나 불편/개선 사항을 M2M 에 요청, 문의 할 수 있습니다. ‘고객센터’ 탭을 선택하고 ‘문의하기’ 버튼을 클릭하여 요청 사항을 M2M 에 전달할 수 있습니다. 과거에 질문한 내용도 리스트로 보여집니다.`
          )}
        </Paragraph>

        <Spacer />

        <ImageWrapper>
          <ResponsiveImage
            src={`/assets/guide/${
              currentLanguage === "ko_KR" ? "contact.png" : "en/en_contact.png"
            }`}
            alt="contact"
          />
        </ImageWrapper>
      </ScrollElement>
    </Wrapper>
  );
};

export default UserInfo;

const Wrapper = styled.div`
  padding: 25px;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 20px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

const Paragraph = styled.p`
  font-size: 16px;
  line-height: 1.5;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 13px;
  }
`;

const Spacer = styled.div`
  height: 20px;

  @media (max-width: 768px) {
    height: 15px;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    margin-top: 30px;
    margin-bottom: 40px;
  }

  @media (max-width: 480px) {
    margin-top: 20px;
    margin-bottom: 30px;
  }
`;

const ResponsiveImage = styled.img`
  max-width: 100%;
  height: auto;
`;
