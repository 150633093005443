import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useT } from "../../../../../constants/i18n/useT";
import CheckBoxCircle from "../../../../../components/input/input-label/circle-checkbox";

interface Props {
  check1: boolean;
  setCheck1: React.Dispatch<React.SetStateAction<boolean>>;
  check2: boolean;
  setCheck2: React.Dispatch<React.SetStateAction<boolean>>;
}

const CheckAll = ({ check1, setCheck1, check2, setCheck2 }: Props) => {
  const t = useT();
  const checkAll = () => {
    if (isAllChecked()) {
      setCheck1(false);
      setCheck2(false);
      return;
    }
    setCheck1(true);
    setCheck2(true);
  };

  const isAllChecked = useCallback(() => {
    if (check1 && check2) return true;
    return false;
  }, [check1, check2]);

  return (
    <Wrapper>
      <div className="check_all">
        <CheckBoxCircle
          check={isAllChecked()}
          onClick={() => checkAll()}
          title={t(
            "user.i_agree_to_all_the_terms_and_conditions",
            "모든 약관에 동의합니다."
          )}
          name="all_check"
        />
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  padding: 20px;
  background: #efefef;
  border-radius: 3px;
  margin-bottom: 20px;
  .check_all {
    display: flex;
    align-items: center;

    label {
      font-size: 14px;
      color: #333;
    }
  }
`;
export default CheckAll;
