import { Modal } from "@mui/material";
import React from "react";
import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import { useT } from "../../constants/i18n/useT";
import Button from "../button/Button";

const SIZES = {
  sm: css`
    width: 370px;
  `,
  md: css`
    width: 550px;
  `,
  lg: css`
    width: 650px;
  `,
  xl: css`
    width: 700px;
  `,
  xxl: css`
    width: 1100px;
  `,
  full: css`
    width: 90vw;
    max-width: 1500px;
    min-width: 1200px;
  `,
};

interface Props {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: any;
  size?: "sm" | "md" | "lg" | "xl" | "xxl" | "full";
  title?: string | any;
}
const ModalForm = ({ isOpen, setOpen, children, size, title }: Props) => {
  const sizeStyle = SIZES[size || "md"];

  return (
    <Modal
      className="modal_background"
      open={isOpen}
      onClose={() => setOpen(false)}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
      hideBackdrop
    >
      <Wrapper sizeStyle={sizeStyle}>
        {title && (
          <div className="user_pop_title">
            <h3>{title}</h3>
          </div>
        )}
        <div onClick={() => setOpen(false)} className="pop_close">
          <button type="button">
            <img src="/assets/sub/close.png" />
          </button>
        </div>
        {children}
      </Wrapper>
    </Modal>
  );
};

interface StyleProps {
  sizeStyle: FlattenSimpleInterpolation;
}

const Wrapper = styled.div<StyleProps>`
  z-index: 11;
  overflow: hidden;
  position: fixed;
  margin: 0 auto;
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
  right: 0;
  background: rgb(255, 255, 255);
  border: 0.5px solid rgba(181, 180, 180, 0.406);
  min-height: 220px;
  max-height: 100%;
  overflow-y: auto;
  border-radius: 5px;
  ${(p) => p.sizeStyle}

  .user_pop_title {
    width: 100%;
    padding: 15px 0;
    background: var(--Active);
    color: #fff;
    text-align: center;
    h3 {
      font-size: 22px;
    }
  }

  &:focus {
    outline: none;
  }
  .close_btn {
    position: absolute;
    top: 20px;
    color: #333;
    z-index: 20;
    right: 20px;
    border: none;
    font-weight: 1000;
    font-size: 15px;
    width: 20px;
    height: 20px;
    background-color: rgba(51, 51, 51, 0);
  }
  .pop_close {
    position: absolute;
    top: 18px;
    right: 20px;
    z-index: 10;
  }
  .pop_close button {
    border: 0;
    background: transparent;
    width: 17px;
  }
  .pop_close button img {
    width: 100%;
  }
`;

interface BProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: () => void;
}

ModalForm.ButtonBox = ({ setOpen, onSubmit }: BProps) => {
  const t = useT();

  return (
    <BWrapper>
      <Button size="xl" onClick={() => setOpen(false)}>
        {t("common.close", "닫기")}
      </Button>
      <Button size="xl" color="primary" onClick={() => onSubmit()}>
        {t("common.save", "저장")}
      </Button>
    </BWrapper>
  );
};

const BWrapper = styled.article`
  padding-bottom: 20px;
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin: auto;
  margin-top: 20px;
`;

export default ModalForm;
