import React, { useEffect, useState } from "react";
import { MAX_LENGTH } from "../../../../../constants/max-length";
import { useT } from "../../../../../constants/i18n/useT";
import ModalPopup from "../../../../../components/modal/popup";
import InputLabel from "../../../../../components/input/input-label/input";
import UpdateWSStorage from "./storage";
import { Workstation } from "../../../../../@types/workstation/workstation";
import { Drives, StorageService } from "../../../../../service/storage/storage.service";
import { RefindedStorage } from "../../../../../@types/storage/form/refined-storage";
import { Spec } from "../../../../../@types/infra/spec";
import { MappingStorage, MappingWorkstation } from "../../../../../@types/workstation/form/storage";
import { WorkstationService } from "../../../../../service/workstation/workstation.service";

import { useAlert } from "../../../../../components/alert/alert.service";
import { WorkstationUpdateForm } from "../../../../../@types/workstation/form/update-form";
import { StorageType } from "../../../../../@types/storage/form/storage-type";
import { Storage } from "../../../../../@types/storage/storage";
import { REGEX } from "../../../../../constants/regex";
import SelectLabel from "../../../../../components/input/input-label/select";
import WSCreateSpec from "../create/components/spec";
import CircleProgress from "../../../../../components/loading/CircleProgress";
import { ProjectService } from "../../../../../service/project/project.service";
import { useRecoilState } from "recoil";
import { userState } from "../../../../../constants/recoil/user";
import { error } from "console";

interface Props {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getList: () => void;
  workstationId: string;
  workstation: any;
  onlyProjectAssignedStorage?: boolean;
}

const MOUNTABLE = {
  MOUNTABLE_MY_STORAGE: "MOUNTABLE_MY_STORAGE",
  MOUNTABLE_SHARED_BY_OTHERS: "MOUNTABLE_SHARED_BY_OTHERS",
};

const Project = {
  PROJECT_STORAGE: "PROJECT_STORAGE",
};

const STORAGE_USAGE = {
  TRUE: "true",
  FALSE: "false",
};

const WSUpdate = ({
  isOpen,
  setOpen,
  getList,
  workstationId,
  workstation,
  onlyProjectAssignedStorage,
}: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState("");
  const [validName, setValidName] = useState(false);

  const [specs, setSpecs] = useState<Spec[]>([]);
  const [showSpecSelect, setShowSpecSelect] = useState(false);
  const [spec, setSpec] = useState<Spec>({});
  const [description, setDescription] = useState("");

  const [refinedStorages, setRefinedStorages] = useState<RefindedStorage[]>([]);
  const [drives, setDrives] = useState(new Drives()); // {'x':true,'y':true...}
  const [mappingWSList, setMappingWSList] = useState<MappingStorage[]>([]);

  const [isResponse, setIsResponse] = useState(false);

  const { setSuccess, setAlert } = useAlert();
  const [user] = useRecoilState(userState);

  const getWorkstation = () => {
    WorkstationService.getById(workstationId).then(res => {
      if (res.state === "success") {
        setMappingWSList(res.data.mappingWSList);
        setName(res.data.name);
        setStorage();
        setSpec(res.data.spec);
        setDescription(res.data.description);
        console.log(res);
      }
    });
    // .finally(() => setIsLoading(false));
  };

  const getUiData = () => {
    WorkstationService.getUi().then(res => {
      console.log(res.data);
      if (res.state === "success") {
        setSpecs(res.data.specs);
        // setImages(res.data.images);
        // codesToData(res.data.codes);
        // if (res.data.storages) {
        //   setRefinedStorages(filterStorage(res.data.storages));
        // }
      }
    });
  };

  useEffect(() => {
    console.log(workstation, "????");
  }, []);
  const onSubmit = () => {
    if (!validName) {
      return setAlert(
        t("workstation.enter_a_4_15_character_name", "한글 영문 포함 4~15자 이내로 입력해주세요.")
      );
    }

    const newWorkstation = new WorkstationUpdateForm(
      workstationId,
      name,
      { specId: spec.specId || "" },
      description
    );
    const myStorages: MappingWorkstation[] = [];

    refinedStorages.map(storage => {
      if (storage.using) {
        let storagetmp = new MappingWorkstation(
          storage.storageId || "",
          storage.letter,
          storage.maapingWSId
        );
        myStorages.push(storagetmp);
      }
    });
    if (myStorages.length > 0) newWorkstation.mappingWSList = myStorages;

    setIsResponse(true);

    newWorkstation
      .update()
      .then(res => {
        setSuccess(newWorkstation.name + t("workstation.has_been_updated", "이 수정되었습니다."));
      })
      .catch(error => {
        if (error.response && error.response.status === 400) {
          setAlert(t("workstation.workstation_running.", "워크스테이션이 실행중입니다."));

          return;
        }

        if (error.response && error.response.status === 404) {
          setAlert(t("workstation.not_found_ws", "워크스테이션 조회에 실패했습니다."));

          return;
        }

        if (error.response && error.response.status === 409) {
          setAlert(
            t("workstation.same_name_workstation", "이미 같은 이름의 워크스테이션이 존재합니다.")
          );

          return;
        }

        if (error.response && error.response.status === 500) {
          setAlert(t("common.error", "현재 서버가 원활하지 않습니다. 잠시 후 시도해주세요."));

          return;
        }
      })
      .finally(() => {
        setIsResponse(false);
        getList();
      });
  };

  const setStorage = async () => {
    try {
      const storagelist: RefindedStorage[] = [];
      const test: any[] = [];

      const myStorages = await StorageService.getStorageByWorkstationId(
        user.userGuid!,
        MOUNTABLE.MOUNTABLE_MY_STORAGE,
        workstation.workstationId!,
        STORAGE_USAGE.FALSE
      );

      const sharedStorages = await StorageService.getStorageByWorkstationId(
        user.userGuid!,
        MOUNTABLE.MOUNTABLE_SHARED_BY_OTHERS,
        workstation.workstationId!,
        STORAGE_USAGE.FALSE
      );

      if (workstation.project.projectId) {
        const projectStorage = await ProjectService.getAssignedStorages(
          user.userGuid!,
          Project.PROJECT_STORAGE,
          workstation.project.projectId
        );

        projectStorage.forEach((projStorage: any) => {
          myStorages.forEach((myStorage: Storage) => {
            if (projStorage.storage.storageId === myStorage.storageId) {
              storagelist.push(new RefindedStorage(StorageType.OWNED, myStorage));
            }
          });

          sharedStorages.forEach((sharedStorage: Storage) => {
            if (projStorage.storage.storageId === sharedStorage.storageId) {
              storagelist.push(new RefindedStorage(StorageType.PRIVIL, sharedStorage));
            }
          });
        });
      } else {
        myStorages.forEach((storage: Storage) => {
          storagelist.push(new RefindedStorage(StorageType.OWNED, storage));
        });

        sharedStorages.forEach((storage: Storage) => {
          storagelist.push(new RefindedStorage(StorageType.PRIVIL, storage));
        });
      }

      setRefinedStorages(storagelist);
    } catch (error) {
      console.error("Failed to fetch storages:", error);
    } finally {
      setIsLoading(false);
    }

    // if (workstation.project && workstation.project.projectId) {
    //   StorageService.getByProjectId(workstation.project.projectId)
    //     .then((res) => {
    //       if (res.state === "success") {
    //         console.log(res.data, "이거??");
    //         let storagelist: RefindedStorage[] = [];
    //         res.data.map((storage: Storage) => {
    //           storagelist.push(new RefindedStorage(StorageType.OWNED, storage));
    //         });
    //         setRefinedStorages(storagelist);
    //         console.log(storagelist, "2번@@@@@");
    //       }
    //     })
    //     .finally(() => setIsLoading(false));
    // } else {
    //   StorageService.getByNoProject()
    //     .then((res) => {
    //       if (res.state === "success") {
    //         console.log(res.data, "이거??");
    //         let storagelist: RefindedStorage[] = [];
    //         res.data.map((storage: Storage) => {
    //           storagelist.push(new RefindedStorage(StorageType.OWNED, storage));
    //         });
    //         setRefinedStorages(storagelist);
    //         console.log(storagelist, "2번@@@@@");
    //       }
    //     })
    //     .finally(() => setIsLoading(false));
    // }
  };

  const checkName = () => {
    if (name.match(REGEX.STORAGE_NAME)) {
      setValidName(true);
    } else setValidName(false);
  };

  useEffect(() => {
    getUiData();
    getWorkstation();
  }, []);

  useEffect(() => checkName(), [name]);

  const t = useT();

  return (
    <ModalPopup
      isOpen={isOpen}
      setOpen={setOpen}
      buttonText={t("common.update", "수정")}
      onSubmit={onSubmit}
      size="md"
      isResponse={isResponse}
    >
      <>
        {!onlyProjectAssignedStorage && (
          <>
            <InputLabel
              value={name}
              onChange={e => setName(e.target.value)}
              label={t("workstation.name", "이름")}
              // essential
              // isValid={validName}
              description={t(
                "workstation.enter_a_4_15_character_name",
                "한글 영문 포함 4~15자 이내로 입력해주세요."
              )}
              maxLength={MAX_LENGTH.WORKSTATION}
              placeholder={name}
            />

            <SelectLabel essential label="Spec">
              {isLoading ? (
                <CircleProgress />
              ) : (
                <WSCreateSpec
                  showSpecSelect={showSpecSelect}
                  setShowSpecSelect={setShowSpecSelect}
                  spec={spec}
                  specs={specs}
                  setSpec={setSpec}
                />
              )}
            </SelectLabel>
          </>
        )}
        <UpdateWSStorage
          refinedStorage={refinedStorages}
          setRefinedStorages={setRefinedStorages}
          drives={drives}
          setDrives={setDrives}
          mappingWSList={mappingWSList}
          isLoading={isLoading}
        />
        <InputLabel
          value={description}
          onChange={e => setDescription(e.target.value)}
          label={t("common.description", "비고")}
          placeholder={description}
        />
      </>
    </ModalPopup>
  );
};

export default WSUpdate;
