import React, { useState } from "react";
import styled from "styled-components";
import LoginBackGroundWrapper from "../../../login/components/login-background-wrapper";
import { useT } from "../../../../constants/i18n/useT";

import RoundButton from "../../../../components/button/round-button";
import SignupAlert from "../modal/success-alert";
import { SignupForm } from "../../../../@types/user/signup/signup.form";

import { RegisterCompany } from "../../../../@types/user/company";

import { SignUpService } from "../../../../service/user/signup.service";
import { useAlert } from "../../../../components/alert/alert.service";
import SignupChapter1 from "./chapter-1";
import SignupChapter2 from "./chapter-2";

interface Props {
  signupForm: SignupForm;
  setSignupForm: React.Dispatch<React.SetStateAction<SignupForm>>;
}

const SignupPage2 = ({ signupForm, setSignupForm }: Props) => {
  const [isResponse, setIsResponse] = useState(false);

  const [validId, setValidId] = useState(false);
  const [validEmail, setValidEmail] = useState(false);

  const [canUseId, setCanUseId] = useState(false);
  const [canUseEmail, setCanUseEmail] = useState(false);

  const [validPassword, setValidPassword] = useState(false);
  const [validPassword2, setValidPassword2] = useState(false);
  // 회원정보
  const [company, setCompany] = useState<RegisterCompany>(
    new RegisterCompany()
  );

  const [popSuccessAlert, setSuccessPopAlert] = useState(false);

  const t = useT();
  const { setAlert } = useAlert();

  const checkToSubmit = () => {
    if (!validId) {
      return setAlert(t("user.please_check_the_id", "ID를 확인해주세요"));
    }
    if (!validEmail) {
      return setAlert(
        t("user.please_format_the_email_properly", "메일 형식을 맞춰주세요")
      );
    }
    if (!canUseId) {
      return setAlert(t("user.please_check_the_id", "ID 중복확인을 해주세요"));
    }
    if (!canUseEmail) {
      return setAlert(
        t("user.please_check_the_email", "이메일 중복확인을 해주세요")
      );
    }

    if (!validPassword || !validPassword2) {
      return setAlert(
        t("user.please_check_your_password", "비밀번호를 확인하세요.")
      );
    }
    if (checkPasswordContainsId(signupForm.userId, signupForm.password)) {
      return setAlert(
        t(
          "user.please_do_not_include_your_id",
          "비밀번호에 ID가 포함되지 않게 해주세요."
        )
      );
    }
    if (signupForm.fullName.length === 0 || signupForm.mobile.length < 8) {
      return setAlert(
        t(
          "user.please_check_your_membership_information",
          "회원정보를 확인해주세요"
        )
      );
    }
    onSubmit();
  };

  const checkPasswordContainsId = (id: string, password: string) => {
    return password.includes(id);
  };

  const onSubmit = () => {
    setIsResponse(true);
    if (company.id) {
      setSignupForm((prev) => ({
        ...prev,
        company: { companyGuid: company.id },
      }));
    } else {
      if (company.name !== "") {
        setSignupForm((prev) => ({
          ...prev,
          individualCompanyName: company.name,
        }));
      }
    }

    console.log("signupForm", signupForm);
    SignUpService.create(signupForm)
      .then((res) => {
        if (res.state === "success") return setSuccessPopAlert(true);
        if (res.message) return setAlert(res.message);
        return setAlert(
          t(
            "common.error",
            "현재 서버가 원활하지 않습니다. 잠시 후 시도해주세요."
          )
        );
      })
      .catch((err) => {
        setAlert(
          t(
            "common.error",
            "현재 서버가 원활하지 않습니다. 잠시 후 시도해주세요."
          )
        );
      })
      .finally(() => setIsResponse(false));
  };

  const props = {
    signupForm,
    setSignupForm,
    canUseId,
    canUseEmail,
    setCanUseId,
    setCanUseEmail,
    setValidId,
    setValidEmail,
    setValidPassword,
    setValidPassword2,
    validId,
    validEmail,
    validPassword,
    validPassword2,
    company,
    setCompany,
  };

  return (
    <LoginBackGroundWrapper>
      <Wrapper>
        <SignupChapter1 {...props} />
        <SignupChapter2 {...props} />

        <div className="submit_btn">
          {!isResponse && (
            <RoundButton onClick={checkToSubmit} size="full_lg" color="primary">
              {t("signup.sign_up", "회원가입")}
            </RoundButton>
          )}
          {isResponse && (
            <RoundButton size="full_lg" color="disabled">
              {t("signup.signing_up", "회원가입 진행중...")}
            </RoundButton>
          )}
        </div>
      </Wrapper>
      {popSuccessAlert && (
        <SignupAlert
          name={signupForm.fullName}
          isOpen={popSuccessAlert}
          setOpen={setSuccessPopAlert}
        />
      )}
    </LoginBackGroundWrapper>
  );
};

const SignupTitle = ({ title }: { title: string }) => {
  return (
    <TitleWrap>
      <h5>{title}</h5>
      <div className="line_bar" />
    </TitleWrap>
  );
};

const TitleWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  h5 {
    font-size: 20px;
    font-weight: bold;
  }
  .line_bar {
    width: 60%;
    height: 3px;
    background-color: #333;
    margin-left: 20px;
  }
  @media only screen and (min-width: 768px) {
    h5 {
      font-size: 24px;
    }
    .line_bar {
      width: 60%;
    }
  }
`;

const Wrapper = styled.div`
  padding: 0 20px;
  margin-bottom: 50px;
  .see_pw_box {
    margin-top: 20px;
    font-size: 12px;
  }
  .chapter_2 {
    margin-top: 50px;
  }
  .submit_btn {
    margin-top: 50px;
  }
  .btn_box {
    margin: 10px;
  }
`;

export default SignupPage2;
