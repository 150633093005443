import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import { Code } from "../../@types/infra/code";
import { Storage } from "../../@types/storage/storage";
import { Workstation } from "../../@types/workstation/workstation";
const { persistAtom } = recoilPersist();

export class ProjectUiData {
  codes: Code[] = [
    { cd: "Project.Role.PD", name: "PD" },
    { cd: "Project.Role.Artist", name: "Artist" },
  ];
  unassignedStorages: Storage[] = [];
  unassignedWorkstations: Workstation[] = [];
}

export const projectUiDataState = atom<ProjectUiData>({
  key: "projectUiDataState",
  default: new ProjectUiData(),
  effects_UNSTABLE: [persistAtom],
});
