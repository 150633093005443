import React, { useEffect, useState } from "react";
import { PolicyService } from "../../../../service/user/policy.service";
import styled from "styled-components";
import PolicyTextArea from "./policy/policy-textara";
import { useT } from "../../../../constants/i18n/useT";
import CheckAll from "./policy/check-all-button";
import Button from "../../../../components/button/Button";
import { useAlert } from "../../../../components/alert/alert.service";
import { useRecoilState } from "recoil";
import LoginBackGroundWrapper from "../../../login/components/login-background-wrapper";
import { SignupForm } from "../../../../@types/user/signup/signup.form";
import { localeState } from "../../../../constants/recoil/locale";
import { WindowService } from "../../../../service/common/window.service";

interface Props {
  setSession: React.Dispatch<React.SetStateAction<1 | 2>>;
  signupForm: SignupForm;
  setSignupForm: React.Dispatch<React.SetStateAction<SignupForm>>;
}

const SignupPage1 = ({ setSession, signupForm, setSignupForm }: Props) => {
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);

  const t = useT();
  const { setAlert } = useAlert();
  const agree = () => {
    if (!check1 || !check2) {
      return setAlert(
        t("signup.you_must_agree_to_all_terms", "모든 약관에 동의해주세요")
      );
    }

    setSignupForm({ ...signupForm, agreed: true });
    setSession(2);
  };

  const dontAgree = () => {
    if (
      window.confirm(
        t(
          "signup.we_collect_use_personal_information",
          "상담 내용에 대해 답변하기 위해, 전화번호, 이메일 등의 개인정보를 수집/이용합니다. "
        ) +
          t(
            "signup.you_have_the_right_to_refuse_consent",
            "동의를 거부할 권리가 있습니다. "
          ) +
          t(
            "signup.however_if_you_refuse_to_consent",
            "다만, 동의 거부 시, 서비스가 제한될 수 있습니다. 확인을 누르면 메인페이지로 이동됩니다."
          )
      )
    )
      WindowService.goMain();
  };

  const [locale] = useRecoilState(localeState);
  const [policyVersion, setPolicy] = useState(new PolicyService());

  useEffect(() => getPolicy(), [locale]);

  const getPolicy = () => {
    PolicyService.getLatest().then((res) => {
      if (res.state === "success") {
        setSignupForm((prev) => ({
          ...prev,
          termsOfUseVersion: res.data.termsOfUse.agreementVersion,
          personalInfoVersion: res.data.personalInfo.agreementVersion,
        }));
        setPolicy(res.data);
      }
    });
  };

  return (
    <Wrapper>
      <LoginBackGroundWrapper>
        <PolicyTextArea
          title={t("user.agree_to_privacy_policy", "개인정보 처리방침 동의")}
          check={check1}
          setCheck={setCheck1}
          value={policyVersion.personalInfo.content}
          step={1}
        />
        <PolicyTextArea
          title={t("user.agree_to_terms_of_use", "이용약관 동의")}
          check={check2}
          setCheck={setCheck2}
          value={policyVersion.termsOfUse.content}
          step={2}
        />
        <CheckAll
          check1={check1}
          setCheck1={setCheck1}
          check2={check2}
          setCheck2={setCheck2}
        />
        <ButtonBox>
          <Button onClick={() => agree()} color="primary" size="full">
            {t("user.agree", "동의합니다.")}
          </Button>
          <Button onClick={() => dontAgree()} size="full">
            {t("user.disagree", "동의하지 않습니다.")}
          </Button>
        </ButtonBox>
      </LoginBackGroundWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: 90%;
  margin: auto;
  min-height: 100vh;
`;
const ButtonBox = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px auto;
  height: 100px;
  justify-content: space-between;
  width: 90%;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    button {
      width: 45%;
      max-width: 500px;
    }
    height: 150px;
  }
`;
export default SignupPage1;
